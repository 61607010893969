import { PopoverOrigin } from "@mui/material";
import { PropsWithChildren } from "react";

type PopoverProps = {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};

export const PopoverContent = (props: PropsWithChildren<PopoverProps>) => {
    const { children } = props;

    return <>{children}</>;
};
