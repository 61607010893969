import { Box, styled } from "@mui/material";

export const S = {
    Page: styled(Box)(({ theme }) => ({
        padding: theme.spacing(12.5, 12.5, 0),
        maxWidth: "100vw",

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(24, 12.5, 0),
        },
    })),
};
