import { useMutation } from "@apollo/client";
import React, { createContext, useCallback, useContext, useMemo } from "react";

import {
    CreateTargetSettingMutation,
    CreateTargetSettingMutationVariables,
    CreateTargetSettingsInput,
    DeleteTargetSettingMutation,
    DeleteTargetSettingMutationVariables,
    Pathway,
    TargetSetting,
    UpdateTargetSettingMutation,
    UpdateTargetSettingMutationVariables,
    UpdateTargetSettingsInput,
} from "graphql-types/graphql";

import * as targetSettingsMutations from "./targetSettingMutations";
import { FORECAST_DASHBOARD_QUERY } from "../ForecastDashboard/forecastQueries";

type TargetSettingType = Omit<TargetSetting, "organizationId"> | null;

type ContextValueType = {
    pathways: Pathway[];
    targetSettings: TargetSettingType;
};

type ContextType = {
    loading: boolean;
    pathways: Pathway[];
    targetSettings: TargetSettingType;
    areTargetSettingsShown: boolean;
    updateTargetSettings: (data: UpdateTargetSettingsInput) => void;
    createTargetSettings: (data: CreateTargetSettingsInput) => void;
    deleteTargetSettings: () => void;
};

const TargetSettingContext = createContext<ContextType | undefined>(undefined);

export const TargetSettingProvider = (prop: {
    value: ContextValueType;
    children: JSX.Element | JSX.Element[];
}) => {
    const { targetSettings, pathways } = prop.value;

    const mutationConfig = useMemo(
        () => ({
            refetchQueries: [FORECAST_DASHBOARD_QUERY],
            awaitRefetchQueries: true,
        }),
        []
    );

    const [createTargetSettingMutation, { loading: createLoading }] =
        useMutation<
            CreateTargetSettingMutation,
            CreateTargetSettingMutationVariables
        >(targetSettingsMutations.createTargetSetting, mutationConfig);

    const [updateTargetSettingMutation, { loading: updateLoading }] =
        useMutation<
            UpdateTargetSettingMutation,
            UpdateTargetSettingMutationVariables
        >(targetSettingsMutations.updateTargetSetting, mutationConfig);

    const [deleteTargetSettingMutation, { loading: deleteLoading }] =
        useMutation<
            DeleteTargetSettingMutation,
            DeleteTargetSettingMutationVariables
        >(targetSettingsMutations.deleteTargetSetting, mutationConfig);

    const createTargetSettings = useCallback(
        (data: CreateTargetSettingsInput) => {
            createTargetSettingMutation({
                variables: {
                    input: {
                        targetSetting: data,
                    },
                },
            });
        },
        [createTargetSettingMutation]
    );

    const updateTargetSettings = useCallback(
        (data: UpdateTargetSettingsInput) => {
            if (!targetSettings) return;

            updateTargetSettingMutation({
                variables: {
                    input: {
                        id: targetSettings.id,
                        update: data,
                    },
                },
            });
        },
        [targetSettings, updateTargetSettingMutation]
    );

    const deleteTargetSettings = useCallback(() => {
        if (!targetSettings) return;

        deleteTargetSettingMutation({
            variables: {
                input: {
                    id: targetSettings.id,
                },
            },
        });
    }, [targetSettings, deleteTargetSettingMutation]);

    const loading = useMemo(
        () => createLoading || updateLoading || deleteLoading,
        [createLoading, updateLoading, deleteLoading]
    );

    return (
        <TargetSettingContext.Provider
            value={{
                loading,
                pathways,
                targetSettings,
                areTargetSettingsShown: targetSettings !== null,
                deleteTargetSettings,
                updateTargetSettings,
                createTargetSettings,
            }}
        >
            {prop.children}
        </TargetSettingContext.Provider>
    );
};

const useTargetSettings = () => {
    const context = useContext(TargetSettingContext);
    if (context === undefined) {
        throw new Error(
            "useTargetSettings must be used within a TargetSettingProvider"
        );
    }
    return context;
};

export default useTargetSettings;
