import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { GridEventListener, useGridApiRef } from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DYNAMIC_GRID_TABLE_HEIGHT, TablePagination } from "components/Table";
import { AssetContext } from "containers/AssetPage/AssetPage";
import {
    GetAssetAutomaticSourcesQuery,
    GetAssetAutomaticSourcesQueryVariables,
} from "graphql-types/graphql";

import { useAssetIntegrationsQuery } from "./automatic-source-table.hooks";
import { S } from "./automatic-source-table.styles";
import {
    formatAssetIntegrationsToAutomaticSources,
    formatAutomaticDataRows,
    getAllocationColumns,
    getColumns,
} from "./automatic-sources-table.helpers";
import { ASSET_AUTOMATIC_SOURCES } from "./automaticSourcesTable.query";
import { AutomaticSourceRowData } from "./automaticSourcesTable.types";
import AutomaticSourceToolbar from "./components/AutomaticSourceToolbar";
import { DataSourceDrawer } from "./components/DataSourceDrawer";
import { AutomaticSource } from "../asset-sources.helpers";

type Props = {
    assetId: string;
};

export const AutomaticSourcesTable = (props: Props) => {
    const { assetId } = props;
    const { t } = useTranslation(["report"]);

    const asset = useContext(AssetContext);
    const apiRef = useGridApiRef();

    const { useConsumptionAllocation, useAssetIntegrationAsDataSource } =
        useFlags();

    // Query data
    const { data, loading, refetch } = useQuery<
        GetAssetAutomaticSourcesQuery,
        GetAssetAutomaticSourcesQueryVariables
    >(ASSET_AUTOMATIC_SOURCES, {
        variables: {
            locationId: assetId,
        },
        skip: useAssetIntegrationAsDataSource,
    });

    const {
        totalCount,
        sources: assetIntegrationSources,
        loading: assetIntegrationLoading,
        tablePaginationProps,
        filterProps,
    } = useAssetIntegrationsQuery(assetId, !useAssetIntegrationAsDataSource);

    // State
    const [rows, setRows] = useState<AutomaticSource[]>([]);
    const [drawerState, setDrawerState] = useState<{
        isOpen: boolean;
        data?: AutomaticSourceRowData;
    }>({ isOpen: false });

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        setDrawerState({
            data: { assetName: asset.displayName, ...params.row },
            isOpen: true,
        });
    };

    const sources = useMemo(
        () => data?.location.automaticDataSources ?? [],
        [data]
    );

    const columns = useMemo(() => {
        const defaultColumns = getColumns(t, useAssetIntegrationAsDataSource);

        if (!useConsumptionAllocation) return defaultColumns;

        return [...defaultColumns, ...getAllocationColumns(t)];
    }, [t, useConsumptionAllocation, useAssetIntegrationAsDataSource]);

    const sourcesRows = useAssetIntegrationAsDataSource
        ? formatAssetIntegrationsToAutomaticSources(
              assetIntegrationSources,
              asset.longAddress
          )
        : formatAutomaticDataRows(rows);

    const refetchSources = () =>
        useAssetIntegrationAsDataSource ? null : refetch();

    const paginationProps = useAssetIntegrationAsDataSource
        ? tablePaginationProps
        : {
              slotProps: {
                  pagination: {
                      ActionsComponent: TablePagination,
                  },
              },
          };

    return (
        <Box>
            <AutomaticSourceToolbar
                apiRef={apiRef}
                count={
                    useAssetIntegrationAsDataSource
                        ? totalCount
                        : sources.length
                }
                sources={sources}
                setSources={setRows}
                locationDisplayName={asset.displayName}
                {...filterProps}
            />

            <DataSourceDrawer
                refetch={refetchSources}
                data={drawerState.data}
                isOpen={drawerState.isOpen}
                onClose={() =>
                    setDrawerState((prevState) => ({
                        ...prevState,
                        isOpen: false,
                    }))
                }
            />
            <S.Table
                columns={columns}
                rows={sourcesRows}
                onRowClick={useConsumptionAllocation ? onRowClick : undefined}
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                minHeight="400px"
                apiRef={apiRef}
                isLoading={loading || assetIntegrationLoading}
                /* Pagination fields */
                paginationMode={
                    useAssetIntegrationAsDataSource ? "server" : "client"
                }
                /* Sorting */
                sortingMode={
                    useAssetIntegrationAsDataSource ? "server" : "client"
                }
                {...paginationProps}
            />
        </Box>
    );
};
