import {
    Box,
    BoxProps,
    Theme,
    Popover as MUIPopover,
    PopoverOrigin,
} from "@mui/material";
import { useState, useCallback, useMemo, ReactNode } from "react";

type PopoverProps = {
    parent: ({ isActive }: { isActive?: boolean }) => JSX.Element | null;
    children: ReactNode;
    mouseOver: boolean;
    parentBoxProps?: BoxProps;
    hasMaxWidth?: boolean;
    origin?: {
        anchor: PopoverOrigin;
        transform: PopoverOrigin;
    };
};

export default function Popover({
    parent,
    children,
    mouseOver,
    parentBoxProps,
    origin,
    hasMaxWidth = true,
}: PopoverProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const parentProps = useMemo(() => {
        if (mouseOver) {
            return {
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
            };
        }
        return {
            onClick: handlePopoverOpen,
        };
    }, [mouseOver, handlePopoverOpen, handlePopoverClose]);

    const popoverProps = useMemo(() => {
        if (mouseOver) {
            return { sx: { pointerEvents: "none", maxWidth: "none" } };
        }

        return { onClick: handlePopoverClose };
    }, [mouseOver, handlePopoverClose]);

    const paperStyle = useCallback(
        (theme: Theme) => ({
            maxWidth: hasMaxWidth ? theme.spacing(50) : "none",
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.spacing(1),
            boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
            padding: theme.spacing(2, 3),
        }),
        [hasMaxWidth]
    );

    return (
        <>
            <Box {...parentProps} {...parentBoxProps}>
                {parent({ isActive: Boolean(anchorEl) })}
            </Box>
            <MUIPopover
                data-testid={
                    mouseOver ? "mouse-over-popover" : "mouse-click-popover"
                }
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                {...popoverProps}
                anchorOrigin={
                    origin?.anchor ?? {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }
                transformOrigin={
                    origin?.transform ?? {
                        vertical: "top",
                        horizontal: "left",
                    }
                }
                slotProps={{
                    paper: { sx: paperStyle },
                }}
                disableRestoreFocus
                disableScrollLock
            >
                {children}
            </MUIPopover>
        </>
    );
}
