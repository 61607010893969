import { ChartsAxisContentProps } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import {
    ChartTooltip,
    getBenchmarkSection,
    getProjectedSection,
} from "glue/Chart/components/ChartTooltip";
import { formatNumberLocale } from "utils/report.helpers";

export const AssetEmissionChartTooltip = (props: ChartsAxisContentProps) => {
    const { axis, axisValue, dataIndex, series } = props;
    const { t } = useTranslation();

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const index = dataIndex;
    const header =
        axis.valueFormatter && axisValue
            ? axis.valueFormatter(axisValue, {
                  location: "tooltip",
              })
            : axisValue?.toString();

    const [emissionSeries]: any = series.filter(
        ({ data, id }) => data[index] && id === "emission"
    );

    if (!emissionSeries || !emissionSeries.data[index]) {
        return (
            <ChartTooltip sections={[{ header: header ?? "", items: [] }]} />
        );
    }

    const assetValue = {
        label: t("portfolioDashboard.boxes.assets", "Assets"),
        value: emissionSeries.assetCount[index],
    };

    const areaValue = {
        label: t("portfolioDashboard.boxes.area", "Area"),
        value:
            formatNumberLocale(emissionSeries.totalArea[index], t, { max: 0 }) +
            t("portfolioDashboard.boxes.sqm", "m²"),
    };

    const emissionValue = {
        label: emissionSeries.label,
        value: emissionSeries.valueFormatter(emissionSeries.data[index]),
    };

    const projectedValue = getProjectedSection(series, index);
    const benchmarkSection = getBenchmarkSection(series, index, t);

    return (
        <ChartTooltip
            sections={[
                {
                    header: header ?? "",
                    items: [
                        assetValue,
                        areaValue,
                        emissionValue,
                        ...(projectedValue ? [projectedValue] : []),
                    ],
                },
                ...(benchmarkSection ? [benchmarkSection] : []),
            ]}
        />
    );
};
