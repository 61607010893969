import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { downloadReport } from "components/Report/report.helpers";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { useOrganizationContext } from "context";
import { Card } from "glue";

type Props = {
    updatedDate: string;
};

export const RealESGListItem = (props: Props) => {
    const { updatedDate } = props;

    const { t, i18n } = useTranslation(["report"]);

    const { activeYear } = useSelectedYearContext();
    const organization = useOrganizationContext();

    const report = {
        id: "real-esg",
        from: activeYear.toString(),
        framework: "RealESG" as const,
        segmentName: organization?.name ?? "",
    };

    const handleDownload = () => {
        toast.promise(
            async () =>
                downloadReport(report, t, {
                    method: "POST",
                    body: JSON.stringify({
                        year: activeYear,
                        language: i18n.language,
                    }),
                }),
            {
                pending: t("labels.loading", "Loading..."),
                success: t(
                    "reportsList.success.download",
                    "Report downloaded successfully"
                ),
                error: t(
                    "reportsList.errors.download",
                    "An error occurred trying to download the report. Please try again later."
                ),
            }
        );
    };

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    {t("reportsList.realEsg.title", "Real ESG")}
                </Card.Title>
            </Card.Header>
            <Card.Content>
                <Typography>
                    {t(
                        "reportsList.realEsg.description",
                        "The real estate reporting framework - Real ESG is a reporting framework built by actors in the Danish real estate sector, for the real estate sector to reliably and uniformly report on ESG for real estate portfolios. See more here"
                    )}
                    &nbsp;
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://ejd.dk/viden/skabeloner-og-vaerktoejer/real-esg-the-real-estate-reporting-framework/"
                    >
                        https://ejd.dk/viden/skabeloner-og-vaerktoejer/real-esg-the-real-estate-reporting-framework/
                    </a>
                </Typography>
                <Typography variant="body2" color="grey.400">
                    {t(
                        "reportsList.item.date",
                        "Last updated: {{ updatedDate }}",
                        { updatedDate }
                    )}
                </Typography>
            </Card.Content>
            <Card.Footer>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleDownload}
                >
                    {t(
                        "reportsList.item.button.downloadLabel",
                        "Download Report"
                    )}
                </Button>
            </Card.Footer>
        </Card>
    );
};
