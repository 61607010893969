import { useQuery } from "@apollo/client";
import { Container, Paper } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import { getReportLanguageFromI18n } from "components/Report/report.helpers";
import { getInventoryColumnDefinition } from "components/Report/ReportTablesBackEnd/ReportInventory";
import { Table, TablePagination, TableToolbarExport } from "components/Table";
import {
    AssessmentAggregation,
    AssessmentDataType,
    GetAssetEmissionSummaryInventoryQuery,
    GetAssetEmissionSummaryInventoryQueryVariables,
} from "graphql-types/graphql";

import { GET_ASSET_EMISSION_SUMMARY_INVENTORY } from "./assetInventoryQueries";

export const AssetEmissionSummaryInventory = ({
    locationId,
    from,
    to,
    assessmentType,
}: {
    locationId: string;
    from: string;
    to: string;
    assessmentType: AssessmentDataType;
}) => {
    const { t, i18n } = useTranslation();
    const language = getReportLanguageFromI18n(i18n.language);

    const { data, loading } = useQuery<
        GetAssetEmissionSummaryInventoryQuery,
        GetAssetEmissionSummaryInventoryQueryVariables
    >(GET_ASSET_EMISSION_SUMMARY_INVENTORY, {
        variables: {
            locationId,
            aggregation: AssessmentAggregation.MONTH,
            dataType: assessmentType,
            from,
            to,
            language,
        },
        context: { headers: { "x-lang": i18n.language } },
    });

    const columns = useMemo(() => {
        return data?.simpleAssetInventoryHeaders
            ? getInventoryColumnDefinition(data.simpleAssetInventoryHeaders, t)
            : [];
    }, [data, t]);

    const rows = useMemo(
        () => data?.location.emissionSummaryInventory || [],
        [data]
    );

    const CustomToolbar = () => (
        <GridToolbarContainer
            sx={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
        >
            <TableToolbarExport
                csvOptions={{
                    fileName:
                        assessmentType === AssessmentDataType.COMBINED
                            ? t(
                                  "assetInventory.combined.fileName",
                                  "combined_data_inventory",
                                  { ns: "report" }
                              )
                            : t(
                                  "assetInventory.estimate.fileName",
                                  "estimate_data_inventory",
                                  { ns: "report" }
                              ),
                }}
            />
        </GridToolbarContainer>
    );

    if (loading || !data) {
        return (
            <Container sx={{ textAlign: "center", padding: 2 }}>
                {loading ? (
                    <Loading
                        description={t(
                            "labels.inventoryLoading",
                            "We are processing the inventory",
                            { ns: "report" }
                        )}
                    />
                ) : (
                    t(
                        "assessment.common.noData",
                        "No available data for this period."
                    )
                )}
            </Container>
        );
    }

    return (
        <Paper sx={{ p: 1 }}>
            <Table
                isLoading={loading}
                rows={rows}
                columns={columns}
                sortable={true}
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePagination,
                    },
                }}
                slots={{ toolbar: CustomToolbar }}
            />
        </Paper>
    );
};
