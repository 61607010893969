import { SelectChangeEvent, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetGroupOption } from "components/AssetsGroup/types";

import { S } from "../asset-group-selector.styles";

type StartAdornmentProps = {
    isAssetGroup: boolean;
};

function StartAdornment(props: StartAdornmentProps) {
    const { isAssetGroup } = props;

    return (
        <S.SelectAdornment position="start">
            <S.AssetIcon variant={isAssetGroup ? "active" : "default"} />
        </S.SelectAdornment>
    );
}

type EndAdornmentProps = {
    count: number;
};

const EndAdornment = (props: EndAdornmentProps) => {
    const { count } = props;

    const { t } = useTranslation();

    return (
        <S.SelectAdornment position="end">
            <Typography fontSize={12} color="grey.500">
                {t("shared.assetWithCount", { count })}
            </Typography>
        </S.SelectAdornment>
    );
};

type Props = {
    isAssetGroup: boolean;
    activeAssetGroup: AssetGroupOption;
    onChange: (filterId: string) => void;
};

export function Selector(props: PropsWithChildren<Props>) {
    const { activeAssetGroup, isAssetGroup, onChange, children } = props;

    const [open, setOpen] = useState(false);

    const assetGroup = activeAssetGroup ?? { id: "", name: "", count: 0 };

    return (
        <S.Select
            displayEmpty
            size="small"
            open={open}
            value={assetGroup.id}
            onClick={() => setOpen(!open)}
            onChange={(event: SelectChangeEvent<unknown>) =>
                onChange(event.target.value as string)
            }
            IconComponent={() => null}
            startAdornment={<StartAdornment isAssetGroup={isAssetGroup} />}
            endAdornment={<EndAdornment count={assetGroup.count} />}
            renderValue={() => assetGroup.name || "..."}
            MenuProps={{
                disableScrollLock: true,
                PaperProps: {
                    sx: { boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)" },
                },
            }}
        >
            {children}
        </S.Select>
    );
}
