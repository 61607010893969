import { TFunction } from "i18next";

import { DataQualityTooltipInfo } from "../PortfolioDataQuality";
import { ChartType } from "../types";

export const getYearlyBreakdownOptions = (t: TFunction) => [
    {
        value: ChartType.PORTFOLIO_INTENSITY,
        title: t(
            "portfolioDashboard.portfolioSelector.portIntensityByMonth",
            "Portfolio Intensity by Month"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.WEIGHTED_AVERAGE,
        title: t(
            "portfolioDashboard.portfolioSelector.avgEmissionByMonth",
            "Building Average by Month"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.MARKET_PERFORMANCE,
        title: t(
            "portfolioDashboard.portfolioSelector.marketPerformance",
            "Market Performance Distribution"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.BASELINE_PERFORMANCE,
        title: t(
            "portfolioDashboard.portfolioSelector.performance",
            "Portfolio Benchmark"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.MONTH_BY_MONTH,
        title: t(
            "portfolioDashboard.portfolioSelector.monthByMonth",
            "Total Emission by Month"
        ),
        unit: t("portfolioDashboard.chartHelpers.kgCO2e", "kg CO2e"),
    },
    {
        value: ChartType.DATA_QUALITY_MONTH_BY_MONTH,
        title: t(
            "portfolioDashboard.portfolioSelector.dataQualityMonthByMonth",
            "Data Quality by Month"
        ),
        unit: t(
            "portfolioDashboard.portfolioSelector.percentagePerMonth",
            "%/mth."
        ),
    },
];

export const getPortfolioOverviewOptions = (t: TFunction) => [
    {
        value: ChartType.PORTFOLIO_INTENSITY,
        title: t(
            "portfolioDashboard.portfolioSelector.portIntensity",
            "Portfolio Intensity"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.TOTAL_EMISSION,
        title: t(
            "portfolioDashboard.portfolioSelector.totalEmission",
            "Total Emissions"
        ),
        unit: t(
            "portfolioDashboard.portfolioSelector.tonsTotal",
            "ton CO2e/yr."
        ),
    },
    {
        value: ChartType.WEIGHTED_AVERAGE,
        title: t(
            "portfolioDashboard.portfolioSelector.avgEmission",
            "Building Average"
        ),
        unit: t("portfolioDashboard.portfolioSelector.kgsAvg", "kg CO2e/m²"),
    },
    {
        value: ChartType.DATA_QUALITY,
        title: t(
            "portfolioDashboard.portfolioSelector.dataQuality",
            "Data Quality"
        ),
        unit: t(
            "portfolioDashboard.portfolioSelector.percentagePerYear",
            "%/yr."
        ),
    },
];

export const getPortfolioChartTooltips = () => [
    {
        value: ChartType.DATA_QUALITY,
        element: <DataQualityTooltipInfo />,
    },
];
