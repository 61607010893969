import { Box, Typography, styled } from "@mui/material";

import { convertToRem } from "utils/maths";

export const S = {
    CountLabel: styled(Typography)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: convertToRem(32),
        height: convertToRem(20),
        borderRadius: convertToRem(20),
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: convertToRem(14),
    })),

    PopoverContent: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
    })),

    QualityRow: styled(Box)(() => ({
        display: "flex",
        width: "100%",
        gap: convertToRem(16),
        justifyContent: "space-between",
        alignItems: "center",
    })),

    Title: styled(Typography)(() => ({
        fontWeight: 700,
        fontSize: convertToRem(14),
    })),
    Count: styled(Typography)(() => ({
        fontWeight: 400,
        fontSize: convertToRem(14),
    })),
};
