import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import {
    AssetGroupFilterCategory,
    FloorAreaType,
    GetAssetsGroupPropertySizeQuery,
} from "graphql-types/graphql";

import { PROPERTY_SIZE_QUERY } from "./property-size.query";
import { FilterOptionSelect } from "../../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionPropertySize(props: Props) {
    const { onChange } = props;

    const { t } = useTranslation();

    const { data, loading } =
        useQuery<GetAssetsGroupPropertySizeQuery>(PROPERTY_SIZE_QUERY);

    const buildingAreas = data?.me.organization?.buildingAreas?.filter(
        (buildingArea) => buildingArea.floorAreaType !== FloorAreaType.XXXLARGE
    );

    const list = buildingAreas?.map((buildingArea) => {
        const { floorAreaType, area } = buildingArea;

        return {
            key: FloorAreaType[floorAreaType],
            value: `${area.min} - ${area.max} ${t(
                "filter.m2",
                "m²"
            )}, ${floorAreaType}`,
        };
    });

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.PROPERTYSIZE,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect
            loading={loading}
            list={list ?? []}
            onSelectedValue={onSelectedValue}
        />
    );
}
