import { styled, Typography } from "@mui/material";

export const GridTableHeaderText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "bold",
}));

export const GridTableHeaderSubText = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(3),
    fontWeight: 500,
    color: theme.palette.grey[400],
}));

export const Label = styled(Typography)(({ theme }) => ({
    fontSize: "0.75rem",
    textTransform: "uppercase",
    fontWeight: 600,
    color: theme.palette.grey[500],
}));
