import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Card } from "glue";
import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";

import {
    getTranslatedReportDescription,
    getTranslatedReportTitle,
} from "../Report/reportPageHelpers";

type Props = {
    reportType: ReportFramework;
    updatedDate: string;
};

export const ReportsListItem = (props: Props) => {
    const { reportType, updatedDate } = props;

    const navigate = useNavigate();
    const { t } = useTranslation(["report"]);

    const reportPath = {
        pathname: "/reports/" + reportType,
        search: new URLSearchParams({
            filter: AssessmentDataType.COMBINED,
        }).toString(),
    };

    function onReportSelected() {
        navigate(reportPath);
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    {getTranslatedReportTitle(t, reportType)}
                </Card.Title>
            </Card.Header>
            <Card.Content>
                <Typography>
                    {getTranslatedReportDescription(t, reportType)}
                </Typography>
                <Typography variant="body2" color="grey.400">
                    {t(
                        "reportsList.item.date",
                        "Last updated: {{ updatedDate }}",
                        { updatedDate }
                    )}
                </Typography>
            </Card.Content>
            <Card.Footer>
                <Button
                    variant="contained"
                    size="small"
                    onClick={onReportSelected}
                >
                    {t("reportsList.item.button.label", "View Report")}
                </Button>
            </Card.Footer>
        </Card>
    );
};
