import { Box, MenuItem, styled, TextField } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getYearLabel } from "../PortfolioDashboard/Charts/charts.helper";

const YearBox = styled(Box)(({ theme }) => ({
    borderRadius: theme.spacing(2),
}));

const YearTextField = styled(TextField)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
}));

export interface YearOption {
    label: string;
    value: number;
}

interface YearSelectorProps {
    selectedYear?: string | number;
    availableYears?: YearOption[] | null;
    onYearChange: (value: number) => void;
    fullWidth?: boolean;
    label?: string;
}

const YearSelector = ({
    selectedYear,
    availableYears,
    onYearChange,
    fullWidth,
    label,
}: YearSelectorProps) => {
    const { t } = useTranslation();
    const handleDateChange = useCallback(
        (event: any) => onYearChange(event.target.value),
        [onYearChange]
    );

    return (
        <YearBox>
            <YearTextField
                fullWidth={fullWidth}
                label={label}
                variant="outlined"
                onChange={handleDateChange}
                InputLabelProps={{
                    shrink: true,
                }}
                size={fullWidth ? "medium" : "small"}
                value={selectedYear}
                select
            >
                {availableYears?.map(({ value }) => (
                    <MenuItem key={value} value={value}>
                        {getYearLabel(value, t)}
                    </MenuItem>
                ))}
            </YearTextField>
        </YearBox>
    );
};

export default YearSelector;
