import { Typography } from "@mui/material";
import React, { useState, CSSProperties, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from "react-papaparse";

const GREY = "#CCC";
const GREY_LIGHT = "#cfdee2";
const DEFAULT_REMOVE_HOVER_COLOR = "#1e3b4d";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
);

const styles = {
    zone: {
        alignItems: "center",
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        padding: 20,
        cursor: "pointer",
    } as CSSProperties,
    file: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 20,
        display: "flex",
        height: 120,
        width: 120,
        position: "relative",
        zIndex: 1,
        flexDirection: "column",
        justifyContent: "center",
    } as CSSProperties,
    info: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    size: {
        borderRadius: 3,
        fontSize: 12,
        padding: "0.5rem 1rem",
        marginBottom: "0.5em",
        justifyContent: "center",
        display: "flex",
    } as CSSProperties,
    name: {
        borderRadius: 3,
        padding: "0.5rem 1rem",
        marginBottom: "0.5em",
    } as CSSProperties,
    progressBar: {
        bottom: 14,
        position: "absolute",
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    default: {
        borderColor: GREY,
    } as CSSProperties,
    remove: {
        height: 23,
        position: "absolute",
        right: "-0.5rem",
        top: "-0.5rem",
        width: 23,
        zIndex: -1,
    } as CSSProperties,
};

interface PapaParseProps<T> {
    handleUpload: (data: Array<T>) => void;
    removeUpload: (event: Event) => void;
    handleFile: (data: any) => void;
}

const FileHandler = (props: { acceptedFile: any; handleFileUpload: any }) => {
    const { acceptedFile, handleFileUpload } = props;
    useEffect(() => {
        handleFileUpload(acceptedFile);
    }, [acceptedFile, handleFileUpload]);

    return null;
};

function CSVReader<T>({
    handleUpload,
    removeUpload,
    handleFile,
}: PapaParseProps<T>) {
    const { CSVReader } = useCSVReader();
    const [zoneHover, setZoneHover] = useState(false);
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    );
    const { t } = useTranslation();

    const handleFileUpload = useCallback(
        (file: any) => handleFile(file),
        [handleFile]
    );

    return (
        <CSVReader
            config={{ header: true }}
            onUploadAccepted={(results: any) => {
                setZoneHover(false);
                handleUpload(results.data);
            }}
            onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
            }}
            onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
            }}
        >
            {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
                Remove,
            }: any) => (
                <div
                    {...getRootProps()}
                    style={Object.assign({}, styles.zone, zoneHover)}
                >
                    {acceptedFile ? (
                        <>
                            <FileHandler
                                acceptedFile={acceptedFile}
                                handleFileUpload={handleFileUpload}
                            />
                            <div style={styles.file}>
                                <div style={styles.info}>
                                    <span style={styles.name}>
                                        {acceptedFile.name}
                                    </span>
                                    <span style={styles.size}>
                                        {formatFileSize(acceptedFile.size)}
                                    </span>
                                </div>
                                <div style={styles.progressBar}>
                                    <ProgressBar />
                                </div>
                                <div
                                    {...getRemoveFileProps()}
                                    style={styles.remove}
                                    onMouseOver={(event: Event) => {
                                        event.preventDefault();
                                        setRemoveHoverColor(
                                            REMOVE_HOVER_COLOR_LIGHT
                                        );
                                    }}
                                    onMouseOut={(event: Event) => {
                                        event.preventDefault();
                                        setRemoveHoverColor(
                                            DEFAULT_REMOVE_HOVER_COLOR
                                        );
                                    }}
                                    onClick={(event: Event) => {
                                        getRemoveFileProps().onClick(event);
                                        removeUpload(event);
                                    }}
                                >
                                    <Remove color={removeHoverColor} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <Typography>
                            {t(
                                "dataUploadPage.clickToUpload",
                                "Click or drag to upload"
                            )}
                        </Typography>
                    )}
                </div>
            )}
        </CSVReader>
    );
}

export default CSVReader;
