import { Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ChangeLogTable } from "components/ChangeLog/ChangeLogTable";
import { useChangeLogQuery } from "components/ChangeLog/useChangeLogQuery";

export const ChangeLogPage = () => {
    const { t } = useTranslation();

    const { data, loading } = useChangeLogQuery();

    return (
        <>
            <Typography variant="h2">
                {t("navbar.changeLog", "Change Log")}
            </Typography>
            <Typography mb={4}>
                {t(
                    "asset.changeLog.pageDescription",
                    "This represents all the changes made to the master data of assets."
                )}
            </Typography>

            <Paper>
                <ChangeLogTable
                    isChangeLogPage={true}
                    changeLogs={data}
                    isLoading={loading}
                />
            </Paper>
        </>
    );
};
