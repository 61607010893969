import { Box, Stack } from "@mui/material";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import React from "react";

import InfoPopover from "components/Popovers/InfoPopover";

import {
    GridTableHeaderSubText,
    GridTableHeaderText,
} from "../../styling/typography";

export const TableHeader = (props: {
    params: GridColumnHeaderParams;
    infoText?: React.ReactNode;
    subHeaderText?: string;
}) => {
    const { params, infoText, subHeaderText } = props;
    return (
        <Box>
            <Stack flexDirection="row" alignItems="center" gap={1.5}>
                {infoText && <InfoPopover element={infoText} />}
                <GridTableHeaderText>
                    {params.colDef.headerName}
                </GridTableHeaderText>
            </Stack>

            {subHeaderText && (
                <GridTableHeaderSubText>{subHeaderText}</GridTableHeaderSubText>
            )}
        </Box>
    );
};
