import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { Chart } from "glue/Chart";
import { AssessmentDataType } from "graphql-types/graphql";

import { getYearlyBuildingAverageChartData } from "./yearlyBuildingAverageChart.helpers";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";

type Props = {
    activeTab: AssessmentDataType;
    assessmentSummaries: SummaryYearOverview[];
};

export const YearlyBuildingAverageChart = (props: Props) => {
    const { activeTab, assessmentSummaries } = props;
    const { t } = useTranslation();

    const { series, xAxis } = useMemo(() => {
        return getYearlyBuildingAverageChartData(
            t,
            activeTab,
            assessmentSummaries
        );
    }, [activeTab, t, assessmentSummaries]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={[
                {
                    label: t(
                        "portfolioDashboard.chartHelpers.kgCO2em2",
                        "kg CO2e / m²"
                    ),
                },
            ]}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
