import { Typography } from "@mui/material";
import { t } from "i18next";

type Props = {
    count?: number;
};

export const AssetSourceLabel = (props: Props) => {
    const { count } = props;

    if (!count) {
        return (
            <Typography color="grey.500" mb={1} fontSize={14}>
                {t("dataLocationCategory.noData", "No Data")}
            </Typography>
        );
    }

    return (
        <Typography color="grey.500" mb={1} fontSize={14}>
            {t("common.labels.activeSourceWithCount", "active source", {
                count,
            })}
        </Typography>
    );
};
