import { Box, styled } from "@mui/material";

export const S = {
    Panel: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(6),
        padding: theme.spacing(4, 8),
    })),
    Container: styled(Box)({
        gap: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }),
};
