import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AssessmentDataType } from "graphql-types/graphql";

import TextDropdown from "../Dropdown/TextDropdown";
import OrganizationYearSelector from "../YearSelector/OrganizationYearSelector";

type Props = {
    isYearSelectorHidden?: boolean;
    isAssessmentTypeSelectorHidden?: boolean;
    activeType: AssessmentDataType;
    onAssessmentTypeChange: (value: AssessmentDataType) => void;
};

const YearAndAssessmentTypeFilter = (props: Props) => {
    const {
        isYearSelectorHidden,
        isAssessmentTypeSelectorHidden,
        activeType,
        onAssessmentTypeChange,
    } = props;
    const { t } = useTranslation();

    const assessmentTypes = [
        {
            title: t("portfolioDashboard.tabs.epcAssets", "EPC"),
            value: AssessmentDataType.ESTIMATE,
            explainer: t(
                "general.explainer.epc",
                "This overview only includes data from EPC sources"
            ),
        },
        {
            title: t("portfolioDashboard.tabs.allAssets", "Best effort"),
            value: AssessmentDataType.COMBINED,
            explainer: t(
                "general.explainer.bestEffort",
                "This overview aggregates data from all your data sources including EPCs, Meters and Proxy Data"
            ),
        },
    ];

    return (
        <Box display="flex" alignItems="center" gap={2}>
            {!isYearSelectorHidden && <OrganizationYearSelector />}
            {!isAssessmentTypeSelectorHidden && (
                <TextDropdown
                    value={activeType}
                    onChange={onAssessmentTypeChange}
                    options={assessmentTypes}
                />
            )}
        </Box>
    );
};

export default YearAndAssessmentTypeFilter;
