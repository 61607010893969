import { gql } from "@apollo/client";

export const LEGACY_BENCHMARKS_BY_ASSET_ID_QUERY = gql`
    query GetLegacyBenchmarksByAssetId(
        $assetId: ID
        $valueType: LegacyBenchmarkValueType
    ) {
        getLegacyBenchmarksByAssetId(assetId: $assetId) {
            id
            internalIdentifier
            benchmarkType
            segment
            countryCode
            numberOfBuildings
            updatedAt
            segmentLabel
            values(filter: { type: { eq: $valueType } }) {
                id
                from
                to
                category
                value
                unit
                type
            }
        }
    }
`;
