import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    AllocationObjectType,
    AssetPropertyTree,
    CountryCode,
    GetPropertyQuery,
    GetPropertyQueryVariables,
} from "graphql-types/graphql";
import { getPropertyObjectTypeTranslation } from "utils/translations";

import { S } from "./property-modal.styles";
import { PropertyForm } from "./PropertyForm";
import { GET_PROPERTY } from "../../queries";
import { ModalProperty } from "../../types";

type Props = {
    isOpen: boolean;
    countryCode: CountryCode;
    parentAssetProperty?: AssetPropertyTree | null;
    onConfirmAddProperty?: (
        property: ModalProperty,
        parentAssetProperty?: AssetPropertyTree | null
    ) => void;
    onConfirmEditProperty?: (
        property: Partial<ModalProperty>,
    ) => void;
    onClose: () => void;
    parentName?: string | null;
    propertyId?: string;
};

export const PropertyModal = (props: Props) => {
    const {
        isOpen,
        onClose,
        onConfirmAddProperty,
        onConfirmEditProperty,
        parentAssetProperty,
        parentName,
        countryCode,
        propertyId,
    } = props;

    const { t } = useTranslation();

    const isEditMode = useMemo(() => propertyId !== undefined, [propertyId]);

    const { data, loading } = useQuery<
        GetPropertyQuery,
        GetPropertyQueryVariables
    >(GET_PROPERTY, {
        skip: !isEditMode,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        variables: { id: propertyId! },
    });

    function onCloseModal() {
        onClose();
    }

    function onAddProperty(property: ModalProperty) {
        if(!onConfirmAddProperty) return;
        onConfirmAddProperty(property, parentAssetProperty);
        onCloseModal();
    }

    function onEditProperty(property: Partial<ModalProperty>) {
        if(!onConfirmEditProperty) return;
        onConfirmEditProperty(property);
        onCloseModal();
    }

    const objectType = parentAssetProperty
        ? getPropertyObjectTypeTranslation(parentAssetProperty.objectType)
        : getPropertyObjectTypeTranslation(AllocationObjectType.ASSET);
    const formattedParentName = parentName ? ` "${parentName}"` : "";
    const addHeaderTitle = `${t(
        "assetDetailsComponent.addProperty",
        "add property"
    )} ${t("common.labels.to", "to")} ${objectType} ${formattedParentName}`;
    const editHeaderTitle = t("assetDetailsComponent.editProperty", "edit property"
    );
    const headerTitle = isEditMode ? editHeaderTitle: addHeaderTitle;

    return (
        <S.Modal open={isOpen} onClose={onClose}>
            <S.ModalBody>
                <S.Header>
                    <S.HeaderTitle>{headerTitle}</S.HeaderTitle>
                    <S.ClosedIconBox>
                        <S.ClosedIcon onClick={onClose} />
                    </S.ClosedIconBox>
                </S.Header>
                {
                    !loading && (
                        <PropertyForm
                            countryCode={countryCode}
                            onConfirmAddProperty={onAddProperty}
                            onConfirmEditProperty={onEditProperty}
                            onClose={onCloseModal}
                            parentObjectType={parentAssetProperty?.objectType}
                            isEditMode={isEditMode}
                            property={data?.property}
                        />
                    )
                }
            </S.ModalBody>
        </S.Modal>
    );
};
