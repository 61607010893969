import { gql } from "@apollo/client";

export const GET_ASSET_PROPERTY_ALLOCATION_COUNT = gql`
    query GetAssetPropertyAllocationCount($assetId: ID!) {
        locationIntegrations(
            filter: {
                allocations: {
                    and: [
                        { percentage: { eq: 100 } }
                        { assetId: { eq: $assetId } }
                    ]
                }
            }
        ) {
            totalCount
        }
    }
`;
