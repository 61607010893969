import {
    BenchmarkMarker,
    BenchmarkMarkers,
    LegacyBenchmarkMarker,
} from "components/types";

const BENCHMARK_STORAGE_KEY_PREFIX = "benchmark-storage";

export function useBenchmarkMarkersStorage() {
    const storage = window.localStorage;

    function getKey(key: string) {
        return `${BENCHMARK_STORAGE_KEY_PREFIX}-${key}`;
    }

    function setStorage(key: string, benchmarkMarkers: BenchmarkMarkers) {
        const storageKey = getKey(key);

        storage.setItem(storageKey, JSON.stringify(benchmarkMarkers));
    }

    function getStorage(key: string): BenchmarkMarkers {
        const storageKey = getKey(key);
        const benchmarkMarkers = storage.getItem(storageKey);

        return benchmarkMarkers ? JSON.parse(benchmarkMarkers) : [];
    }

    function findStorageBenchmarkMarker(key: string, id: string) {
        const list = getStorage(key);

        return list.find((benchmark) => benchmark.id === id);
    }

    function saveBenchmarkMarkerStorage(
        key: string,
        benchmarkMarker: BenchmarkMarker | LegacyBenchmarkMarker
    ) {
        const storageKey = getKey(key);
        const storageList = getStorage(key);

        const hasBenchmark = storageList.find(
            (benchmark) => benchmark.id === benchmarkMarker.id
        );

        if (!hasBenchmark) {
            storage.setItem(
                storageKey,
                JSON.stringify([...storageList, benchmarkMarker])
            );

            return storageList;
        }

        const updatedBenchmarkMarkers = storageList.map((benchmark) => {
            if (benchmark.id === benchmarkMarker.id) {
                return benchmarkMarker;
            }

            return benchmark;
        });

        setStorage(key, updatedBenchmarkMarkers);

        return updatedBenchmarkMarkers;
    }

    return {
        findStorageBenchmarkMarker,
        saveBenchmarkMarkerStorage,
    };
}
