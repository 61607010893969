import { useMutation } from "@apollo/client";
import { uniqBy } from "lodash";

import {
    AssessmentDataType,
    CreateAssessmentOriginInput,
    CreateAssessmentOriginMutation,
    CreateAssessmentOriginMutationVariables,
    CreateManyAssessmentsInput,
    CreateManyAssessmentsMutation,
    CreateManyAssessmentsMutationVariables,
    CreateManyLocationMetadataInput,
    UploadFileMutation,
    UploadFileMutationVariables,
    UpsertManyLocationMetadataMutation,
    UpsertManyLocationMetadataMutationVariables,
} from "graphql-types/graphql";

import {
    CREATE_MANY_ASSESSMENTS,
    UPLOAD_FILE,
    CREATE_ASSESSMENT_ORIGIN,
    UPSERT_MANY_LOCATION_METADATA,
} from "./uploadQueries";

export const useSubmitAssessments = () => {
    const [upload] = useMutation<
        UploadFileMutation,
        UploadFileMutationVariables
    >(UPLOAD_FILE);
    const [createAssessments] = useMutation<
        CreateManyAssessmentsMutation,
        CreateManyAssessmentsMutationVariables
    >(CREATE_MANY_ASSESSMENTS);
    const [createAssessmentOrigin] = useMutation<
        CreateAssessmentOriginMutation,
        CreateAssessmentOriginMutationVariables
    >(CREATE_ASSESSMENT_ORIGIN);
    const [upsertManyLocationMetadata] = useMutation<
        UpsertManyLocationMetadataMutation,
        UpsertManyLocationMetadataMutationVariables
    >(UPSERT_MANY_LOCATION_METADATA);

    const handleUpload = async (
        fileVariables: UploadFileMutationVariables,
        assessmentOriginVariables: CreateAssessmentOriginInput,
        assessmentsVariables: CreateManyAssessmentsInput,
        locationMetaDataVariables: CreateManyLocationMetadataInput
    ) => {
        const isEpcUpload =
            assessmentOriginVariables.assessmentType ===
            AssessmentDataType.ESTIMATE;
        const fileData = await upload({ variables: fileVariables });

        if (!fileData.data) {
            return;
        }

        const { data: assessmentOriginData } = await createAssessmentOrigin({
            variables: {
                input: {
                    assessmentOrigin: {
                        ...assessmentOriginVariables,
                        fileId: fileData.data.uploadFile.id,
                    },
                },
            },
        });

        if (!assessmentOriginData) {
            return;
        }

        const assessmentData = await createAssessments({
            variables: {
                input: {
                    assessments: assessmentsVariables.assessments.map((a) => ({
                        ...a,
                        originId:
                            assessmentOriginData?.createOneAssessmentOrigin.id,
                    })),
                },
            },
        });

        if (!assessmentData.data) {
            return;
        }

        if (isEpcUpload) {
            const uniqueLocationMetadataInput = uniqBy(
                locationMetaDataVariables.locationMetadata,
                (a) => `${a.locationId}_${a.externalId}`
            );

            await upsertManyLocationMetadata({
                variables: {
                    input: uniqueLocationMetadataInput,
                },
            });
        }

        return { assessmentData, fileData, assessmentOriginData };
    };

    return [handleUpload];
};
