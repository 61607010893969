import { useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { formatDateContext } from "components/Report/report.helpers";
import { DateContext } from "components/Report/reportDateContext";
import { Table, TablePagination } from "components/Table";
import {
    DeleteReportMutation,
    DeleteReportMutationVariables,
    GetSavedReportsQuery,
    ReportFragmentFragment,
    UpdateReportMutation,
    UpdateReportMutationVariables,
} from "graphql-types/graphql";
import { formatNumberLocale } from "utils/report.helpers";

import DownloadReport from "./DownloadReport";
import { ReportDeleteModal } from "./ReportDeleteModal";
import {
    DELETE_REPORT,
    SAVED_REPORTS,
    UPDATE_REPORT,
} from "./reportListQueries";

export type ReportEntity = ReportFragmentFragment;

export const ReportsSaved = () => {
    const { t } = useTranslation(["report"]);

    const [reportToDeleteId, setReportToDeleteId] = useState<string | null>(
        null
    );

    const { data, loading } = useQuery<GetSavedReportsQuery>(SAVED_REPORTS);
    const [deleteReport, { loading: deleteLoading }] = useMutation<
        DeleteReportMutation,
        DeleteReportMutationVariables
    >(DELETE_REPORT, {
        update(cache, { data }) {
            const reports = cache.readQuery<GetSavedReportsQuery>({
                query: SAVED_REPORTS,
            });
            if (!data || !reports) {
                return;
            }

            cache.writeQuery<GetSavedReportsQuery>({
                query: SAVED_REPORTS,
                data: {
                    getActiveReports: reports.getActiveReports.filter(
                        (r) => r.id !== data.deleteReport.id
                    ),
                },
            });
        },
    });
    const [updateReport, { loading: updateLoading }] = useMutation<
        UpdateReportMutation,
        UpdateReportMutationVariables
    >(UPDATE_REPORT);

    const handleCellEditCommit = useCallback(
        (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (oldRow.note === newRow.note) return oldRow;

            return new Promise<GridRowModel>((resolver) => {
                const { id, note } = newRow;

                updateReport({
                    variables: { input: { id, update: { note } } },
                }).then(() => resolver(newRow));
            });
        },
        [updateReport]
    );

    const handleDeleteReport = useCallback(
        (isReportToBeDeleted: boolean) => {
            if (!reportToDeleteId) return;

            if (isReportToBeDeleted) {
                toast.promise(
                    deleteReport({
                        variables: { input: { id: reportToDeleteId } },
                    }),
                    {
                        error: {
                            render() {
                                return t(
                                    "reportsList.errors.delete",
                                    "An error occurred trying to delete the report. Please try again later."
                                );
                            },
                            autoClose: 5000,
                            theme: "colored",
                            closeButton: false,
                        },
                    }
                );
            }

            setReportToDeleteId(null);
        },
        [deleteReport, reportToDeleteId, t]
    );

    const reports = useMemo(() => {
        if (!data) return [];

        return data.getActiveReports.map((r) => ({
            id: r.id,
            dateSaved: r.createdAt,
            segment: r.segmentName,
            assets: r.numberOfAssets,
            period: { from: r.from, to: r.to },
            framework: r.framework,
            createdBy: r.createdBy.name,
            note: r.note === "" ? null : r.note,
            data: r.source,
            download: r,
            delete: r.id,
        }));
    }, [data]);

    const columns = useMemo(() => {
        const fields = [
            {
                field: "dateSaved",
                headerName: t("reportsList.gridTable.dateSaved", "Date Saved"),
                flex: 0.6,
                valueFormatter: (value: any) =>
                    DateTime.fromISO(value).toLocaleString(),
            },
            {
                field: "segment",
                headerName: t("reportsList.gridTable.segment", "Segment"),
            },
            {
                field: "assets",
                headerName: t("reportsList.gridTable.assets", "Assets"),
                flex: 0.3,
                valueFormatter: (value: any) =>
                    formatNumberLocale(value, t, { max: 0 }),
            },
            {
                field: "framework",
                headerName: t("reportsList.gridTable.framework", "Framework"),
                flex: 0.5,
            },
            {
                field: "period",
                headerName: t("reportsList.gridTable.period", "Period"),
                valueFormatter: (value: any) =>
                    formatDateContext(value as DateContext),
            },
            {
                field: "data",
                headerName: t("reportsList.gridTable.data", "Data"),
                flex: 0.6,
            },
            {
                field: "note",
                headerName: t("reportsList.gridTable.note", "Note"),
                editable: true,
                renderCell: ({ value, ...rest }) => {
                    return (
                        <Box width="100%" position="relative">
                            <Typography>{value ?? "-"}</Typography>

                            <Edit
                                className="show-on-hover"
                                sx={{
                                    visibility: "hidden",
                                    position: "absolute",
                                    fontSize: 20,
                                    right: 0,
                                    top: 0,
                                }}
                            />
                        </Box>
                    );
                },
            },
            {
                field: "createdBy",
                headerName: t("reportsList.gridTable.createdBy", "Created By"),
                flex: 0.5,
            },
            {
                field: "download",
                headerAlign: "center",
                headerName: t("common.download", "Download", {
                    ns: "translation",
                }),
                flex: 0.45,
                renderCell: ({ value }) => (
                    <DownloadReport report={value as ReportEntity} />
                ),
            },
            {
                field: "delete",
                headerAlign: "center",
                headerName: t("reportsList.gridTable.delete", "Delete"),
                disableExport: true,
                flex: 0.35,
                renderCell: ({ value }) => (
                    <IconButton
                        sx={{ mx: "auto" }}
                        onClick={() => setReportToDeleteId(value)}
                    >
                        <Delete color="primary" fontSize="medium" />
                    </IconButton>
                ),
            },
        ] as GridColDef[];

        return fields.map((f) => ({
            ...f,
            flex: f.flex || 1,
            sortable: false,
        }));
    }, [t]);

    return (
        <>
            <Typography sx={{ mt: 4 }}>
                {t(
                    "reportsList.savedReportsDescription",
                    "To add a report to this list, please select a framework and save your report from there.",
                    { ns: "report" }
                )}
            </Typography>
            <Paper sx={{ mt: 4 }}>
                <Table
                    isLoading={loading || deleteLoading || updateLoading}
                    columns={columns}
                    rows={reports}
                    onCellEdit={handleCellEditCommit}
                    slotProps={{
                        pagination: {
                            ActionsComponent: TablePagination,
                        },
                    }}
                />
            </Paper>

            <ReportDeleteModal
                isOpen={!!reportToDeleteId}
                onClose={handleDeleteReport}
            />
        </>
    );
};
