import { gql } from "@apollo/client";

const USER_PROFILE_QUERY = gql`
    query GetUserProfile {
        me {
            id
            name
            email
            phone
        }
    }
`;

export default USER_PROFILE_QUERY;
