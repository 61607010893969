import { Box, Typography, styled } from "@mui/material";

export const S = {
    SectionList: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: theme.spacing(94),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
    })),

    Section: styled(Box)(() => ({
        display: "flex",
        flexDirection: "column",
    })),

    Row: styled(Box)(() => ({
        display: "flex",
        flexDirection: "row",
        gap: 2,
        flexWrap: "wrap",
    })),

    RowBody: styled(Box)(() => ({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 4,
    })),

    RowDelimiter: styled(Box)(() => ({
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
    })),

    Delimiter: styled(Box)(() => ({
        marginTop: "1rem",
        marginBottom: "1rem",
    })),

    DelimiterLabel: styled(Typography)(() => ({
        textTransform: "uppercase",
    })),
};
