import { useTranslation } from "react-i18next";

import { getBenchmarkMarkerColor } from "components/PortfolioDashboard/Charts/utils";
import { getBenchmark } from "components/PortfolioDashboard/helpers/utilities";
import { BenchmarkMarker } from "components/types";
import { useOrganizationContext } from "context";
import {
    AssessmentDataType,
    Benchmark,
    BenchmarkType,
} from "graphql-types/graphql";

import { useRegionTranslation } from "./useRegionTranslation";

export function useBenchmarkMarker() {
    const { t } = useTranslation();
    const { getRegionName } = useRegionTranslation();

    const organization = useOrganizationContext();

    const convertToBenchmarkMarker = (
        type: AssessmentDataType,
        benchmarks: Benchmark[]
    ): BenchmarkMarker[] => {
        return benchmarks.map((benchmark) => {
            const { countryCode } = benchmark;

            const value = getBenchmark(type, benchmark) || 0;
            const labels =
                benchmark.type === BenchmarkType.MARKET
                    ? {
                          title: t(
                              "benchmark.countryBenchmark",
                              "Country Benchmark"
                          ),
                          subTitle: getRegionName(countryCode),
                          description:
                              countryCode === "DK"
                                  ? t(
                                        "benchmark.portfolioBenchmarkMarketDescriptionDK",
                                        "The national benchmark in Denmark is calculated from the 'Report Emission Effects of 60 Buildings' by the Danish State's Building Institute."
                                    )
                                  : t(
                                        "benchmark.portfolioBenchmarkMarketDescriptionGB",
                                        "The national benchmark in the UK is calculated from the '2022 CREEM Report for UK Real Estate Buildings'."
                                    ),
                      }
                    : {
                          title: t(
                              "benchmark.portfolioBenchmark",
                              "Portfolio Benchmark"
                          ),
                          subTitle: `${t(
                              "settings.profile.baselineYear",
                              "Baseline Year"
                          )} ${organization?.baselineYear}`,
                          description: t(
                              "benchmark.portfolioBenchmarkBaselineDescription",
                              ""
                          ),
                      };
            return {
                ...benchmark,
                value,
                isVisible: true,
                color: getBenchmarkMarkerColor(benchmark),
                title: labels.title,
                subTitle: labels.subTitle,
                description: labels.description,
                benchmarkType: benchmark.type,
            };
        });
    };

    return {
        convertToBenchmarkMarker,
    };
}
