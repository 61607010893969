import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import {
    Box,
    Paper,
    styled,
    Typography,
    SvgIcon,
    SvgIconProps,
} from "@mui/material";
import {
    GridColDef,
    GridComparatorFn,
    GridCellParams,
    GridColumnHeaderParams,
    GridRowParams,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    useGridApiRef,
    GridSortingInitialState,
} from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { VerticalCoverageCell } from "components/Cells/VerticalCoverageCell";
import {
    AssetAlertFragment,
    AssetAlerts,
    BenchmarkType,
} from "graphql-types/graphql";
import { useBrowserStorage } from "hooks";
import { assessmentVerticalToTitle } from "utils/assessment";
import { customNumberSorter } from "utils/sorters";

import AssetAlertCell, { getAssetAlertInfo } from "./AssetAlertCell";
import { DisplayLocation } from "./assetTypes";
import { formatDataQualityColumn } from "./utils";
import { theme } from "../../styling/themes";
import i18n from "../../utils/i18n";
import { DASH_SEPARATOR, NO_BUILDING_FOUND } from "../../utils/strings.helpers";
import BenchmarkSelect, {
    ActiveBenchmarkContext,
} from "../BenchmarkSelect/BenchmarkSelect";
import {
    MarketBenchmarkInfo,
    PortfolioBenchmarkInfo,
} from "../BenchmarkSelect/BenchmarkText";
import EpcClassificationsCell from "../Cells/EpcClassificationsCell";
import NationalBuildingIdsCell from "../Cells/NationalBuildingIdsCell";
import {
    mapDataQualitySources,
    sortByDataQuality,
} from "../DataQuality/DataQuality.helpers";
import DataQualityDistributionBar from "../DataQuality/DataQualityDistributionBar";
import DataQualityTooltip from "../DataQuality/DataQualityTooltip";
import {
    DYNAMIC_GRID_TABLE_HEIGHT,
    Table,
    TableHeader,
    TablePagination,
    TableToolbarExport,
} from "../Table";
import { toolbarSlotProps } from "../Table/TableToolbarExport";

const GridToolbarComponents = styled(GridToolbarContainer)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(32),
    right: theme.spacing(12.5),
}));

const Ellipse = styled(Box)(({ theme }) => ({
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    transform: "translateX(-15px)",
    borderRadius: "20px",
    marginTop: theme.spacing(0.5),
}));

const NameCell = styled(Box)({
    transform: "translateX(-8px)",
});

const CellErrorStateWrapper = styled(Box)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: "bold",
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    paddingRight: theme.spacing(1.25),
}));

const SortArrows = styled(Box)(() => ({
    p: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
}));

const SortArrowDown = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="m 0 0 l 24 0 -12 12 z" />
    </SvgIcon>
);

const SortArrowUp = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="m 0 24 l 24 0 l -12 -12 z" />
    </SvgIcon>
);

const ArrowDropUp = styled(SortArrowUp)(({ theme }) => ({
    fill: theme.palette.grey[400],
    paddingBottom: 0.75,
    fontSize: theme.spacing(2.25),
}));

const ArrowDropDown = styled(SortArrowDown)(({ theme }) => ({
    fill: theme.palette.grey[400],
    paddingTop: 0.75,
    fontSize: theme.spacing(2.25),
}));

const NationalIdentifierInfo = () => {
    return (
        <Trans i18nKey="portfolioDashboard.locationTable.headerInfoBox.nationalIdentifier">
            National identifiers will vary based on the country the asset is
            located in.
            <br />
            In Denmark the identifier is called "BBR"
            <br />
            In the UK the identifier is called "URPN"
        </Trans>
    );
};

const DataQualityInfo = () => {
    return (
        <Trans i18nKey="portfolioDashboard.locationTable.headerInfoBox.dataQuality">
            The quality distribution of consumption data per asset based on the
            specified source and year.
        </Trans>
    );
};

const CustomPerformanceHeader = (params: GridColumnHeaderParams) => {
    const { activeBenchmark } = useContext(ActiveBenchmarkContext);

    const infoPopText =
        activeBenchmark?.type === BenchmarkType.BASELINE ? (
            <PortfolioBenchmarkInfo />
        ) : (
            <MarketBenchmarkInfo />
        );

    return (
        <HeaderContainer>
            <TableHeader
                params={params as GridColumnHeaderParams}
                infoText={infoPopText}
            />
            <BenchmarkSelect />
        </HeaderContainer>
    );
};

interface AssetProps {
    activeLocations: DisplayLocation[] | null;
    chosenAssets: string[];
    isUsingExternalId: boolean;
    downloadFileName: string;
    hasNationalIdentifiers: boolean;
}

const getPerformanceCell = (params: GridCellParams) => {
    const { value } = params;

    const num = value as number;

    if (num === 0) {
        return `${num}%`;
    }

    return (
        <Box display="flex" whiteSpace="nowrap" alignItems="center">
            {value === DASH_SEPARATOR ? (
                DASH_SEPARATOR
            ) : (
                <Box display="flex" whiteSpace="nowrap" alignItems="center">
                    {num > 0 ? (
                        <ArrowUpward color="error" fontSize="small" />
                    ) : (
                        <ArrowDownward color="success" fontSize="small" />
                    )}
                    {Math.abs(num)}%
                </Box>
            )}
        </Box>
    );
};

const getNameCell = (params: GridCellParams) => {
    if (!params.value) {
        return DASH_SEPARATOR;
    }

    const value = params.value as string;
    const errorEllipse = params.row.showExpiredEpcLabel && (
        <Ellipse as="span" color="error" />
    );
    const warningEllipse = params.value === NO_BUILDING_FOUND && (
        <Ellipse as="span" color="warning" />
    );
    const ellipseStyle = errorEllipse || warningEllipse || null;
    return (
        <>
            {ellipseStyle}
            <NameCell as="span">{value}</NameCell>
        </>
    );
};

export const castAndReplaceChars = (val: string) => {
    if (val === DASH_SEPARATOR || val === null) {
        return -Infinity;
    }
    const { t } = i18n;
    const thousandSeparator = t("thousandSeparator", ",");
    const decimalSeparator = t("decimalSeparator", ".");
    return parseFloat(
        val.replace(thousandSeparator, "").replace(decimalSeparator, ".")
    );
};

const cellHasNoBuilding = (val: any) => {
    return (val as string) === NO_BUILDING_FOUND;
};

const customNameSorter: GridComparatorFn = (a, b) => {
    if (cellHasNoBuilding(a) && !cellHasNoBuilding(b)) {
        return -1;
    } else if (!cellHasNoBuilding(a) && cellHasNoBuilding(b)) {
        return 1;
    } else {
        return (a as string).localeCompare(b as string, "da-DK", {
            numeric: true,
            sensitivity: "base",
        });
    }
};

const getLocationColumns = (
    isUsingExternalId: boolean | null,
    t: TFunction,
    hasNationalIdentifiers: boolean
): GridColDef[] => {
    const idCol = {
        field: "externalId",
        headerName: t("portfolioDashboard.locationTable.Id", "ID"),
        renderHeader: (params: GridColumnHeaderParams) =>
            TableHeader({
                params,
            }),
        flex: 0.8,
    };

    const idCols = isUsingExternalId ? [idCol] : [];

    const verticalCoverageCol = {
        field: "verticals",
        headerName: t(
            "portfolioDashboard.locationTable.verticalCoverage",
            "Verticals"
        ),
        renderHeader: (params: GridColumnHeaderParams) =>
            TableHeader({
                params,
                subHeaderText: t(
                    "portfolioDashboard.locationTable.subheader.verticalCoverage",
                    "Electricity, Heating, Water"
                ),
                infoText: t(
                    "portfolioDashboard.locationTable.headerInfoBox.verticalCoverage",
                    "This column gives you a glanceable overview if consumption data is present for electricity, heating and water. Additional verticals could also be present but will not be displayed in this column."
                ),
            }),
        renderCell: (params: GridCellParams) =>
            VerticalCoverageCell({ verticals: params.value as any }),
        valueFormatter: (value: any) =>
            value instanceof Array
                ? value
                      .map(
                          (v) =>
                              `${assessmentVerticalToTitle(
                                  v.vertical,
                                  t
                              )}: ${v.sources
                                  .map((s: any) => mapDataQualitySources(s, t))
                                  .join(", ")}`
                      )
                      .join(" / ")
                : "",
        sortable: false,
        flex: 1,
    } as GridColDef;

    return [
        {
            field: "names",
            cellClassName: (params: GridCellParams) =>
                params.value === NO_BUILDING_FOUND ? "no-building-address" : "",
            headerName: t("portfolioDashboard.locationTable.name", "Asset"),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    subHeaderText: t(
                        "portfolioDashboard.locationTable.subheader.names",
                        "Address or Name of building"
                    ),
                }),
            flex: 2,
            renderCell: getNameCell,
            sortComparator: customNameSorter,
        },
        ...idCols,
        {
            field: "nationalBuildingIds",
            headerName: t(
                "portfolioDashboard.locationTable.nationalIdentifier",
                "National Identifier"
            ),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    infoText: <NationalIdentifierInfo />,
                }),
            flex: 1.4,
            valueFormatter: (value: any) =>
                value instanceof Array ? value.join(", ") : "",
            renderCell: (params) =>
                NationalBuildingIdsCell(params.value as string[]),
            hideable: !hasNationalIdentifiers,
        },
        {
            field: "area",
            headerName: t("portfolioDashboard.locationTable.area", "Area"),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    subHeaderText: t(
                        "portfolioDashboard.locationTable.subheader.area",
                        "m²"
                    ),
                }),
            flex: 0.9,
            sortComparator: customNumberSorter,
            renderCell: (params) => (
                <>
                    {params.row.showAreaNotFoundMessage ? (
                        <CellErrorStateWrapper as="span">
                            {t(
                                "portfolioDashboard.locationTable.notFound",
                                "Not Found"
                            )}
                        </CellErrorStateWrapper>
                    ) : (
                        params.value
                    )}
                </>
            ),
        },
        {
            field: "totalEmission",
            headerName: t(
                "portfolioDashboard.locationTable.totalEmission",
                "Total Emission"
            ),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    subHeaderText: t(
                        "portfolioDashboard.locationTable.subheader.emission",
                        "kg/CO2e"
                    ),
                }),
            sortComparator: customNumberSorter,
            flex: 1,
            renderCell: (params) => params.value,
        },
        {
            field: "dataQualityFromSelectedYear",
            headerName: t(
                "portfolioDashboard.locationTable.dataQuality.title",
                "Data Quality"
            ),
            renderHeader: (params) =>
                TableHeader({
                    params,
                    infoText: <DataQualityInfo />,
                }),
            flex: 1.5,
            sortComparator: (params1, params2) => {
                if (params1 === undefined) {
                    return -1;
                } else if (params2 === undefined) {
                    return 1;
                }

                return sortByDataQuality(params1, params2);
            },
            valueFormatter: (value) => formatDataQualityColumn(value, t),
            renderCell: (params) =>
                params.value ? (
                    <DataQualityTooltip dataQuality={params.value}>
                        <DataQualityDistributionBar {...params.value} />
                    </DataQualityTooltip>
                ) : (
                    <Typography data-testid="no-data-available">
                        {t(
                            "portfolioDashboard.locationTable.dataQuality.noData",
                            "No data available"
                        )}
                    </Typography>
                ),
        },
        {
            field: "emissionPerSize",
            headerName: `${t(
                "portfolioDashboard.locationTable.emissionPerSqm",
                "Emission/m² (kg/CO2e)"
            )}`,
            renderHeader: (params) =>
                TableHeader({
                    params,
                    subHeaderText: t(
                        "portfolioDashboard.locationTable.subheader.emissionPerSize",
                        "kg/CO2e/m²"
                    ),
                }),
            sortComparator: customNumberSorter,
            flex: 1,
            renderCell: (params) => (
                <>
                    {params.row.showExpiredEpcLabel ? (
                        <CellErrorStateWrapper as="span">
                            {t(
                                "portfolioDashboard.locationTable.expiredEpc",
                                "EPC Expired"
                            )}
                        </CellErrorStateWrapper>
                    ) : (
                        params.value
                    )}
                </>
            ),
        },
        {
            field: "performanceLabel",
            headerName: t(
                "portfolioDashboard.locationTable.performance",
                "Performance"
            ),
            renderHeader: CustomPerformanceHeader,
            flex: 1.5,
            sortComparator: customNumberSorter,
            renderCell: getPerformanceCell,
        },
        ...[verticalCoverageCol],
        {
            field: "classifications",
            flex: 1.5,
            sortable: false,
            headerName: t(
                "portfolioDashboard.locationTable.classifications",
                "Classifications"
            ),
            valueFormatter: (value: any) =>
                value instanceof Array ? value.join(", ") : "",
            renderCell: (params) =>
                EpcClassificationsCell(params.value as string[]),
        },
        {
            field: "assetAlerts",
            headerName: t("assessmentOverview.assetList.alert", "Alarm"),
            renderHeader: (params: GridColumnHeaderParams) =>
                TableHeader({ params }),
            sortable: false,
            flex: 1,
            valueFormatter: (value: AssetAlertFragment[]) => {
                const alertTexts = value.flatMap(({ type, data }) =>
                    getAssetAlertInfo(t, type, data)
                );

                return t("common.lists", "{{list, list}}", {
                    list: alertTexts,
                });
            },
            renderCell: ({ value }: GridCellParams) => {
                const assetAlerts =
                    value instanceof Array ? (value as AssetAlerts[]) : [];

                return <AssetAlertCell assetAlerts={assetAlerts} />;
            },
        },
    ];
};

const CustomSortAscIcon = () => (
    <SortArrows>
        <ArrowDropUp style={{ fill: theme.palette.primary.main }} />
        <ArrowDropDown />
    </SortArrows>
);

const CustomUnsortedIcon = () => (
    <SortArrows>
        <ArrowDropUp />
        <ArrowDropDown />
    </SortArrows>
);

const CustomSortDescIcon = () => (
    <SortArrows>
        <ArrowDropUp />
        <ArrowDropDown style={{ fill: theme.palette.primary.main }} />
    </SortArrows>
);

function AssetListOverview(props: AssetProps) {
    const {
        activeLocations,
        isUsingExternalId,
        downloadFileName,
        hasNationalIdentifiers,
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const apiRef = useGridApiRef();

    const [hiddenColumns, setHiddenColumns] = useBrowserStorage<string[]>(
        "assetsListHiddenColumns",
        []
    );

    const [sortState, setOrderState] =
        useBrowserStorage<GridSortingInitialState>("assetsListOrder", {});

    useEffect(() => {
        if (sortState) {
            const currentState = apiRef.current.exportState();

            apiRef.current.restoreState({
                ...currentState,
                sorting: sortState || [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRowClick = useCallback(
        (props: GridRowParams) =>
            navigate(`/location-overview/${props.row.id}`),
        [navigate]
    );

    const tableColumns = useMemo(
        () => getLocationColumns(isUsingExternalId, t, hasNationalIdentifiers),
        [isUsingExternalId, t, hasNationalIdentifiers]
    );

    const CustomToolbar = useCallback(
        () => (
            <GridToolbarComponents>
                <GridToolbarColumnsButton slotProps={toolbarSlotProps} />
                <TableToolbarExport
                    csvOptions={{ fileName: downloadFileName }}
                    hasCustomStyle={true}
                />
            </GridToolbarComponents>
        ),
        [downloadFileName]
    );

    return (
        <Paper>
            <Table
                apiRef={apiRef}
                onSortModelChange={(sortModel) => setOrderState({ sortModel })}
                rows={activeLocations || []}
                columns={tableColumns}
                isColumnHeaderVerticallyCentered={false}
                slots={{
                    toolbar: CustomToolbar,
                    columnSortedAscendingIcon: CustomSortAscIcon,
                    columnSortedDescendingIcon: CustomSortDescIcon,
                    columnUnsortedIcon: CustomUnsortedIcon,
                }}
                minHeight="400px"
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: t(
                            "table.rowPerPageTranslation",
                            "View"
                        ),
                        ActionsComponent: TablePagination,
                    },
                    panel: {
                        sx: {
                            "& span": { fontSize: "0.95rem" },
                            "& .MuiDataGrid-columnsManagementHeader": {
                                display: "none",
                            },

                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.3)",

                            borderBottomRightRadius: 12,
                            borderBottomLeftRadius: 12,
                        },
                    },
                }}
                sortable={true}
                filterable={true}
                onRowClick={handleRowClick}
                initialHiddenColumns={hiddenColumns}
                updateHiddenColumns={setHiddenColumns}
            />
        </Paper>
    );
}

export default AssetListOverview;
