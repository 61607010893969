import { alpha } from "@mui/material";
import {
    axisClasses,
    ResponsiveChartContainer,
    ResponsiveChartContainerProps,
} from "@mui/x-charts";

export const S = {
    Container: (props: ResponsiveChartContainerProps) => (
        <ResponsiveChartContainer
            sx={(theme) => ({
                // https://mui.com/x/react-charts/axis/#fixing-overflow-issues
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translateY(160px)",
                },
                "& .MuiChartsAxis-root": {
                    "& text": {
                        fill: theme.palette.grey[500],
                    },
                    "& line": {
                        stroke: theme.palette.grey[500],
                    },
                },

                "& .MuiChartsGrid-line": {
                    stroke: alpha(theme.palette.grey[300], 0.45),
                },

                // Dashed line for the target series
                "& .MuiLineElement-series-target": {
                    strokeDasharray: "14 10",
                },
                "& .MuiMarkElement-series-target": {
                    fill: "red",
                    stroke: "red",
                },
            })}
            {...props}
        />
    ),
};
