import {
    Box,
    Button,
    FormControlLabel,
    styled,
    Switch,
    Typography,
    Link as MuiLink,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";
import { convertToRem } from "utils/maths";

import { AssetsShowingBox } from "./ReportContent";
import { DateContextType, useReportDateContext } from "./reportDateContext";
import { AssetDisplayType, getReportPageTitle } from "./reportPageHelpers";
import InfoPopover from "../Popovers/InfoPopover";

const BackLink = styled(Link)(({ theme }) => ({
    fontSize: convertToRem(14),
    fontWeight: 700,
    color: theme.palette.primary.main,
    textDecoration: "underline",
}));

const TitleContainer = styled(Box)({
    display: "flex",
    margin: "1rem 0",
    gap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
});

interface InputProps {
    dataType: AssessmentDataType;
    assetDisplay: AssetDisplayType;
    reportType: ReportFramework;
    openModal: () => void;
}

export const ReportHeader = ({
    dataType,
    assetDisplay,
    reportType,
    openModal,
}: InputProps) => {
    const { t } = useTranslation();
    const { dateContextType, setDateContextType } = useReportDateContext();

    const isCRREM = reportType === ReportFramework.CRREM;

    const CrremInfoBox = (
        <Trans i18nKey="crrem.info" ns="report">
            CRREM is an international excel based risk assessment tool. This
            summary provides your asset, consumption and emissions data in the
            CRREM defined framework. You may download and copy and paste the
            data directly in your CRREM excel sheet. Please find more
            information on the CRREM Reference Guide
            <MuiLink
                target="_blank"
                href="https://www.crrem.eu/tool/reference-guide/"
            >
                there
            </MuiLink>
        </Trans>
    );

    return (
        <>
            <BackLink to="/reports">
                {t("labels.backlink", "Back to Reports", { ns: "report" })}
            </BackLink>
            <TitleContainer>
                <Typography variant="h1" display="flex" gap={2}>
                    {getReportPageTitle(t, dataType, reportType)}
                    {isCRREM && <InfoPopover element={CrremInfoBox} />}
                </Typography>

                <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={openModal}
                        sx={{ maxHeight: "5rem" }}
                    >
                        {t("labels.saveAndDownload", "Save and Download", {
                            ns: "report",
                        })}
                    </Button>
                </Box>
            </TitleContainer>

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
            >
                <AssetsShowingBox>
                    {t(
                        "labels.outOfLocations",
                        "Showing {{count}} location out of {{total}} locations",
                        {
                            count: assetDisplay.filteredAssets || 0,
                            total: assetDisplay.unfilteredAssets || "loading",
                        }
                    )}
                </AssetsShowingBox>
                {!isCRREM && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    dateContextType ===
                                    DateContextType.DATE_RANGE
                                }
                                onChange={(event) =>
                                    setDateContextType(
                                        event.target.checked
                                            ? DateContextType.DATE_RANGE
                                            : DateContextType.YEAR
                                    )
                                }
                            />
                        }
                        label={t(
                            "labels.customReportingPeriod",
                            "Custom Reporting Period",
                            { ns: "report" }
                        )}
                    />
                )}
            </Box>
        </>
    );
};
