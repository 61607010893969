import { t } from "i18next";
import { match } from "ts-pattern";

import {
    AllocationObjectType,
    ConsumptionType,
    CountryCode,
    IntegrationType,
    PropertyObjectType,
    PropertyOwnershipType,
} from "graphql-types/graphql";

export const getIntegrationTypeTranslation = (source: IntegrationType) => {
    return match(source)
        .with(IntegrationType.OIS, () =>
            t("common.integrationType.OIS", "OIS", {
                ns: "translation",
            })
        )
        .with(IntegrationType.EPC, () =>
            t("common.integrationType.EPC", "EPC", {
                ns: "translation",
            })
        )
        .with(IntegrationType.EPC_UK, () =>
            t("common.integrationType.EPC_UK", "EPC UK", {
                ns: "translation",
            })
        )
        .with(IntegrationType.UN_CATEGORIZED, () =>
            t("common.integrationType.UN_CATEGORIZED", "Uncategorized", {
                ns: "translation",
            })
        )
        .with(IntegrationType.DEXMA, () =>
            t("common.integrationType.DEXMA", "Dexma", {
                ns: "translation",
            })
        )
        .with(IntegrationType.ELECTRALINK, () =>
            t("common.integrationType.ELECTRALINK", "Electralink", {
                ns: "translation",
            })
        )
        .with(IntegrationType.ENTOLABS, () =>
            t("common.integrationType.ENTOLABS", "Entolabs", {
                ns: "translation",
            })
        )
        .with(IntegrationType.HOFOR, () =>
            t("common.integrationType.HOFOR", "Hofor", {
                ns: "translation",
            })
        )
        .with(IntegrationType.INVOICE, () =>
            t("common.integrationType.INVOICE", "Invoice", {
                ns: "translation",
            })
        )
        .with(IntegrationType.MANUAL, () =>
            t("common.integrationType.MANUAL", "Manual", {
                ns: "translation",
            })
        )
        .with(IntegrationType.METER, () =>
            t("common.integrationType.METER", "Meter", {
                ns: "translation",
            })
        )
        .with(IntegrationType.TECHEM, () =>
            t("common.integrationType.TECHEM", "Techem", {
                ns: "translation",
            })
        )
        .with(IntegrationType.VITANI, () =>
            t("common.integrationType.VITANI", "Vitani", {
                ns: "translation",
            })
        )
        .with(IntegrationType.SOFTVAERKE, () =>
            t("common.integrationType.SOFTVAERKE", "Softværke", {
                ns: "translation",
            })
        )
        .exhaustive();
};

export const getConsumptionTypeTranslation = (value: ConsumptionType) => {
    return match(value)
        .with(ConsumptionType.EMISSION, () =>
            t("common.consumptionType.EMISSION", "Emission", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY, () =>
            t("common.consumptionType.ENERGY", "Other", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING, () =>
            t("common.consumptionType.ENERGY_HEATING", "General", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_REMOTE, () =>
            t("common.consumptionType.ENERGY_HEATING_REMOTE", "Remote", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS, () =>
            t("common.consumptionType.ENERGY_HEATING_FUELS", "Fuels", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_GAS, () =>
            t("common.consumptionType.ENERGY_HEATING_FUELS_GAS", "Gas", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS",
                "Natural Gas",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS_CITYGAS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS_CITYGAS",
                "City Gas",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_GAS_BIOGAS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_GAS_BIOGAS",
                "Biogas",
                {
                    ns: "translation",
                }
            )
        )
        .with(
            ConsumptionType.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS,
            () =>
                t(
                    "common.consumptionType.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS",
                    "Liquified Natural Gas",
                    {
                        ns: "translation",
                    }
                )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_LIQUID",
                "Liquid Fuels",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL, () =>
            t("common.consumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL", "Oil", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_RAPESEED, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_RAPESEED",
                "Rapeseed Oil",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_FUELOIL, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_FUELOIL",
                "Fuel Oil",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_FUELGASOIL, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_FUELGASOIL",
                "Fuel Gas Oil",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_PETROLEUM, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL_PETROLEUM",
                "Petroleum",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO, () =>
            t("common.consumptionType.ENERGY_HEATING_BIO", "Bio", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO_BIOMASS, () =>
            t("common.consumptionType.ENERGY_HEATING_BIO_BIOMASS", "Biomass", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD",
                "Wood Biomass",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD_CHIPS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD_CHIPS",
                "Wood Chips",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD_PELLETS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD_PELLETS",
                "Wood Pellets",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_BIO_BIOMASS_STRAW_PELLETS, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_BIO_BIOMASS_STRAW_PELLETS",
                "Straw Pellets",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_INCINERATION, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_INCINERATION",
                "Incineration",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_ELECTRICITY, () =>
            t(
                "common.consumptionType.ENERGY_HEATING_ELECTRICITY",
                "Electricity",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.ENERGY_HEATING_WASTE, () =>
            t("common.consumptionType.ENERGY_HEATING_WASTE", "Waste", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_ELECTRICITY, () =>
            t("common.consumptionType.ENERGY_ELECTRICITY", "General", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_ELECTRICITY_COAL, () =>
            t("common.consumptionType.ENERGY_ELECTRICITY_COAL", "Coal", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_ELECTRICITY_SOLAR, () =>
            t("common.consumptionType.ENERGY_ELECTRICITY_SOLAR", "Solar", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_ELECTRICITY_WIND, () =>
            t("common.consumptionType.ENERGY_ELECTRICITY_WIND", "Wind", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_ELECTRICITY_NUCLEAR, () =>
            t("common.consumptionType.ENERGY_ELECTRICITY_NUCLEAR", "Nuclear", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_COOLING, () =>
            t("common.consumptionType.ENERGY_COOLING", "Cooling", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.ENERGY_COOLING_REMOTE, () =>
            t(
                "common.consumptionType.ENERGY_COOLING_REMOTE",
                "Cooling Remote / District Chilled Water",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.WASTE, () =>
            t("common.consumptionType.WASTE", "Waste", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_WATER, () =>
            t("common.consumptionType.WASTE_WATER", "Waste Water", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_FOOD, () =>
            t("common.consumptionType.WASTE_FOOD", "Food", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_METAL, () =>
            t("common.consumptionType.WASTE_METAL", "Metal", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_GLASS, () =>
            t("common.consumptionType.WASTE_GLASS", "Glass", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_PLASTIC_HARD, () =>
            t("common.consumptionType.WASTE_PLASTIC_HARD", "Plastic Hard", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_PLASTIC_SOFT, () =>
            t("common.consumptionType.WASTE_PLASTIC_SOFT", "Plastic Soft", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_FOOD_DRINK_CARTON, () =>
            t(
                "common.consumptionType.WASTE_FOOD_DRINK_CARTON",
                "Food and Drink Cartons",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.WASTE_GIFT_PAPER, () =>
            t("common.consumptionType.WASTE_GIFT_PAPER", "Gift Paper", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_PAPER, () =>
            t("common.consumptionType.WASTE_PAPER", "Paper", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_CARDBOARD, () =>
            t("common.consumptionType.WASTE_CARDBOARD", "Cardboard", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_RESIDUAL, () =>
            t("common.consumptionType.WASTE_RESIDUAL", "Residual", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_ENVIRONMENTALLY_HAZARDOUS_BUSINESS, () =>
            t(
                "common.consumptionType.WASTE_ENVIRONMENTALLY_HAZARDOUS_BUSINESS",
                "Environmentally Hazardous Waste from Businesses",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.WASTE_HAZARDOUS_HOUSEHOLD, () =>
            t(
                "common.consumptionType.WASTE_HAZARDOUS_HOUSEHOLD",
                "Hazardous Household",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.WASTE_BATTERY, () =>
            t("common.consumptionType.WASTE_BATTERY", "Batteries", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_SMALL_ELECTRONICS, () =>
            t(
                "common.consumptionType.WASTE_SMALL_ELECTRONICS",
                "Small Electronics",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.WASTE_TEXTILES, () =>
            t("common.consumptionType.WASTE_TEXTILES", "Textiles", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_GARDEN, () =>
            t("common.consumptionType.WASTE_GARDEN", "Garden", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_BULKY, () =>
            t("common.consumptionType.WASTE_BULKY", "Bulky", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WASTE_CONSTRUCTION, () =>
            t(
                "common.consumptionType.WASTE_CONSTRUCTION",
                "Construction Waste such as Asphalt, Concrete, Iron, Gypsum, etc.",
                {
                    ns: "translation",
                }
            )
        )
        .with(ConsumptionType.TRANSPORTATION, () =>
            t("common.consumptionType.TRANSPORTATION", "Transportation", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.TRANSPORTATION_PLANE, () =>
            t("common.consumptionType.TRANSPORTATION_PLANE", "Plane", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.TRANSPORTATION_TRAIN, () =>
            t("common.consumptionType.TRANSPORTATION_TRAIN", "Train", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.TRANSPORTATION_BUS, () =>
            t("common.consumptionType.TRANSPORTATION_BUS", "Bus", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.TRANSPORTATION_CAR, () =>
            t("common.consumptionType.TRANSPORTATION_CAR", "Car", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.TRANSPORTATION_BIKE, () =>
            t("common.consumptionType.TRANSPORTATION_BIKE", "Bike", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.PURCHASED, () =>
            t("common.consumptionType.PURCHASED", "Purchased", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.WATER, () =>
            t("common.consumptionType.WATER", "Water", {
                ns: "translation",
            })
        )
        .with(ConsumptionType.UNCATEGORIZED, () =>
            t("common.consumptionType.UNCATEGORIZED", "Uncategorized", {
                ns: "translation",
            })
        )
        .exhaustive();
};

export const getAllocationObjectTypeTranslation = (
    value: AllocationObjectType
) => {
    return match(value)
        .with(AllocationObjectType.ASSET, () =>
            t("common.allocationObjectType.ASSET", "Asset", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.PLOT, () =>
            t("common.allocationObjectType.PLOT", "Plot", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.BUILDING, () =>
            t("common.allocationObjectType.BUILDING", "Building", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.UNIT, () =>
            t("common.allocationObjectType.UNIT", "Unit", {
                ns: "translation",
            })
        )
        .exhaustive();
};

export const getPropertyObjectTypeTranslation = (
    value: PropertyObjectType | AllocationObjectType
) => {
    return match(value)
        .with(AllocationObjectType.ASSET, () =>
            t("common.asset", "Asset", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.PLOT, PropertyObjectType.PLOT, () =>
            t("common.allocationObjectType.PLOT", "Plot", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.BUILDING, PropertyObjectType.BUILDING, () =>
            t("common.allocationObjectType.BUILDING", "Building", {
                ns: "translation",
            })
        )
        .with(AllocationObjectType.UNIT, PropertyObjectType.UNIT, () =>
            t("common.allocationObjectType.UNIT", "Unit", {
                ns: "translation",
            })
        )
        .exhaustive();
};

export const getPropertyOwnershipTranslation = (
    value: PropertyOwnershipType
) => {
    return match(value)
        .with(PropertyOwnershipType.LANDLORD, () =>
            t("common.landlord", "Landlord", {
                ns: "translation",
            })
        )
        .with(PropertyOwnershipType.TENANT, () =>
            t("common.tenant", "Tenant", {
                ns: "translation",
            })
        )
        .with(PropertyOwnershipType.MIXED, () =>
            t("common.mixed", "Mixed", {
                ns: "translation",
            })
        )
        .with(PropertyOwnershipType.OTHER, () =>
            t("common.other", "Other", {
                ns: "translation",
            })
        )
        .exhaustive();
};

export const getNationalPropertyTranslation = (
    value: string,
    countryCode?: CountryCode
) => {
    if (
        !countryCode ||
        ![CountryCode.DK, CountryCode.GB].includes(countryCode)
    ) {
        return null;
    }

    if (countryCode === CountryCode.DK) {
        return value
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    if (countryCode === CountryCode.GB) {
        const typeBrokenByUppercaseLetters = value.split(/(?=[A-Z])/).join(" ");
        return typeBrokenByUppercaseLetters
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }
};
