import { Trans, useTranslation } from "react-i18next";

import { ChangeLogTable } from "components/ChangeLog/ChangeLogTable";
import { useChangeLogQuery } from "components/ChangeLog/useChangeLogQuery";
import { BaseModal } from "components/Modal/BaseModal";

type Props = {
    isOpen: boolean;
    assetId: string;
    assetName: string;
    editsCount: number;
    handleClose: () => void;
};

export const ChangeLogModal = (props: Props) => {
    const { isOpen, assetId, assetName, editsCount, handleClose } = props;

    const { t } = useTranslation();

    const { data, loading } = useChangeLogQuery(assetId, !isOpen);

    return (
        <BaseModal
            fullScreen
            sx={{ m: 12 }}
            open={isOpen}
            title={`${assetName} - ${t("navbar.changeLog", "Change Log")}`}
            handleClose={handleClose}
        >
            <Trans
                i18nKey="asset.changeLog.editDescription"
                values={{ edit: editsCount }}
                count={editsCount}
            />

            <ChangeLogTable
                changeLogs={data}
                isLoading={loading}
                assetName={assetName}
            />
        </BaseModal>
    );
};
