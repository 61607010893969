import { TFunction } from "i18next";

export const i18nJoinItems = (t: TFunction, items: readonly string[]) => {
    if (items.length === 0) {
        return "";
    } else if (items.length === 1) {
        return items[0];
    } else if (items.length === 2) {
        return `${items[0]} ${t("common.and", "and", { ns: "translation" })} ${
            items[1]
        }`;
    } else {
        const copiedItems = [...items];
        const lastItem = copiedItems.pop();

        return (
            copiedItems.join(", ") +
            ` ${t("common.and", "and", { ns: "translation" })} ${lastItem}`
        );
    }
};
