import { Paper, Typography, styled } from "@mui/material";

type Props = {
    value: string | number;
    label: string;
    secondaryLabel?: string;
};

const Brick = styled(Paper)(({ theme }) => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr min-content 32px",
    alignItems: "center",
    padding: theme.spacing(2, 4),
    gap: 4,
}));

const NumberTextBrick = (props: Props) => {
    const { value, label, secondaryLabel } = props;
    return (
        <Brick>
            <Typography color="grey.600">{label}</Typography>
            <Typography variant="h2">{value}</Typography>
            {secondaryLabel && (
                <Typography
                    variant="h5"
                    color="grey.600"
                    sx={{ alignSelf: "flex-start" }}
                >
                    {secondaryLabel}
                </Typography>
            )}
        </Brick>
    );
};

export default NumberTextBrick;
