import { styled, Box, Typography } from "@mui/material";
import { Trans } from "react-i18next";

import { convertToRem } from "utils/maths";

const ContentContainer = styled(Box)(({ theme }) => ({
    margin: theme.spacing(12.5, 6, 0),
}));

const PageTitleTypo = styled(Typography)({
    fontSize: convertToRem(26),
    fontWeight: "bold",
});

export default function SelectorHeader() {
    return (
        <ContentContainer>
            <PageTitleTypo>
                <Trans i18nKey="integrationOnboarding.mainTitle">
                    Integration Onboarding
                </Trans>
            </PageTitleTypo>
        </ContentContainer>
    );
}
