import { ChartsAxisContentProps } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import { UnitMass } from "components/AssessmentStats/types";
import { getFormattedValueToMassUnit } from "components/PortfolioDashboard/Charts/charts.helper";

import { ChartTooltip } from "../ChartTooltip";

export const ChartAxisTooltip = (props: ChartsAxisContentProps) => {
    const { axisValue, dataIndex, series } = props;
    const { t } = useTranslation();

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const header = axisValue?.toString() ?? "";

    const validSeries = series.filter((serie) => serie.data[dataIndex]);
    const sectionItems = validSeries.map((serie: any) => ({
        label: serie.label,
        value: serie.valueFormatter(serie.data[dataIndex]),
        color: serie.color,
    }));

    const totalEmission = validSeries
        .filter(
            (serie: any) =>
                serie.layout === "vertical" && serie.id !== "projected"
        )
        .map((serie: any) => serie.data[dataIndex])
        .reduce((acc: number, curr: number) => acc + curr, 0);

    const totalEmissionSubHeader = {
        label: t("forecastDashboard.labels.totalEmissions", "Total Emissions"),
        value:
            getFormattedValueToMassUnit(
                totalEmission,
                UnitMass.KILOGRAM,
                t,
                false
            ) ?? "",
    };

    return (
        <ChartTooltip
            sections={[
                {
                    header,
                    subHeaders: [totalEmissionSubHeader],
                    items: sectionItems,
                },
            ]}
        />
    );
};
