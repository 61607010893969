import { useTheme } from "@mui/material";
import { t } from "i18next";
import { sum } from "lodash";

import { Popover } from "glue/Popover";

import { SourcesQualityCount } from "./asset-property-list.types";
import { S } from "./PropertySourcesCount.styles";

type Props = {
    qualityCount: SourcesQualityCount;
};

export const PropertySourcesCount = (props: Props) => {
    const { palette } = useTheme();
    const { qualityCount } = props;
    const totalCount = sum(Object.values(qualityCount));
    return (
        <Popover>
            <S.CountLabel>{totalCount}</S.CountLabel>
            <Popover.Content>
                <S.PopoverContent>
                    <S.QualityRow>
                        <S.Title color={palette.highQuality.main}>
                            {t("dataQuality.highQuality")}
                        </S.Title>
                        <S.Count>{qualityCount.HIGH}</S.Count>
                    </S.QualityRow>
                    <S.QualityRow>
                        <S.Title color={palette.mediumQuality.main}>
                            {t("dataQuality.mediumQuality")}
                        </S.Title>
                        <S.Count>{qualityCount.MEDIUM}</S.Count>
                    </S.QualityRow>
                    <S.QualityRow>
                        <S.Title color={palette.lowQuality.main}>
                            {t("dataQuality.lowQuality")}
                        </S.Title>
                        <S.Count>{qualityCount.LOW}</S.Count>
                    </S.QualityRow>
                </S.PopoverContent>
            </Popover.Content>
        </Popover>
    );
};
