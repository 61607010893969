import { ApolloCache } from "@apollo/client";
import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { NotificationType } from "components/Notifications/NotificationBar";
import { i18nJoinItems } from "components/TranslationHelpers/TranslationHelpers";
import {
    AssessmentVertical,
    EmissionUnit,
    GetAllAssetDetailsQuery,
    GetAssetAddressQuery,
    PropertyType,
    UpdateLocationInput,
    LocationManagementType,
    IntegrationEpcAssessmentData,
    IntegrationEpcUkAssessmentData,
    AssetEpcDataFragment,
} from "graphql-types/graphql";
import { assessmentVerticalToTitle } from "utils/assessment";
import {
    getAddresses,
    getEpcIdentifier,
    getNationalBuildingIds,
} from "utils/assessmentHelpers";
import { formatDate } from "utils/date.utils";
import { formatNumberLocale, getUnitByKey } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { ASSET_DETAILS_QUERY } from "./assetDetailsQuery";
import { ManualAssessment } from "../AssetDataOverview/ManualSourcesTable/manualSourcesTable.types";
import { ASSET_ADDRESS_QUERY } from "../AssetOverview/assetOverviewQuery";

export type AssetDetailsAssessment =
    AssetEpcDataFragment["assessments"][number];
type EPCData = IntegrationEpcAssessmentData | IntegrationEpcUkAssessmentData;

export interface LocationOverviewRow {
    id: string;
    address: string;
    category: string;
    epcLinks: string[];
    epc: string;
    nationalIdentifiers: string[];
    score: string[];
    status: DataSourceStatus | null;
    to: string;
    from: string;
    area: number;
    totalElectricityConsumption: {
        value?: number | null;
        unit?: EmissionUnit | null;
    };
    totalHeatingConsumption: {
        value?: number | null;
        unit?: EmissionUnit | null;
    };
}

export interface MasterDataEditableFields {
    name: string;
    externalId: string;
    buildingArea: number | null;
    ownership: number | null;
    propertyType: PropertyType | null;
    management: LocationManagementType | null;
    purchaseDate: DateTime | null;
    saleDate: DateTime | null;
    legalOwner: string;
    numberOfProperties: number | null;
    assetType: string | null;
}

export const getEPCStatus = (assessments: AssetDetailsAssessment[]) => {
    if (assessments.length === 0) return null;

    const isManualUpload = assessments.some((a) => a.origin);

    const currentDate = DateTime.local().toISODate() || "";
    if (assessments[0].to < currentDate) {
        return DataSourceStatus.EXPIRED;
    }
    if (isManualUpload) {
        return DataSourceStatus.MANUAL;
    }

    return DataSourceStatus.ACTIVE;
};

export const convertDateFromAssessment = (date: string) =>
    DateTime.fromISO(date.replace("Z", "")).toFormat("yyyy-MM");

export type AssessmentWithDataAndOrigin = {
    data?: AssetDetailsAssessment["data"] | null | undefined;
    origin?: AssetDetailsAssessment["origin"];
};

export const getEpcLinkFromSingleAssessment = (
    assessment: AssessmentWithDataAndOrigin | ManualAssessment
) => {
    const data = assessment?.data;
    if (!data) {
        return null;
    }

    if (assessment.origin) {
        return "originalPdfLink" in data && data.originalPdfLink !== ""
            ? data.originalPdfLink
            : null;
    }

    const assessmentLink =
        "assessmentLink" in data && data.assessmentLink !== ""
            ? data.assessmentLink
            : null;
    if (assessmentLink) {
        return assessmentLink;
    }
    const pdfLink =
        "pdfLink" in data && data.pdfLink !== "" ? data.pdfLink : null;
    if (pdfLink) {
        return pdfLink;
    }

    return null;
};

export const getEpcLinksFromAssessments = (
    assessments: readonly AssessmentWithDataAndOrigin[]
) => {
    const links = assessments.map((assessment) =>
        getEpcLinkFromSingleAssessment(assessment)
    );

    return _.chain(links).compact().uniq().value();
};

export const getFormattedConsumptionWithUnit = (
    t: TFunction,
    value?: number | null,
    unit?: EmissionUnit | null
) => {
    if (value && unit) {
        return `${formatNumberLocale(value, t)} ${getUnitByKey(unit, t)}`;
    }

    return DASH_SEPARATOR;
};

export const mapEpcSources = (
    assessments: AssetDetailsAssessment[],
    t: TFunction
): LocationOverviewRow => {
    const addresses = getAddresses(assessments);
    const nationalBuildingIds = getNationalBuildingIds(assessments);
    const assessmentData = assessments.map(
        (assessment) => assessment.data
    ) as EPCData[];

    const epcIdentifier = getEpcIdentifier(assessmentData[0], t);
    const classifications = _.chain(assessmentData)
        .flatMap((assessment) => assessment.classification)
        .uniq()
        .value();

    const verticals = assessments.flatMap((assessment) =>
        assessmentVerticalToTitle(assessment.vertical, t)
    );

    const electricityAssessment = assessments.find(
        (assessment) => assessment.vertical === AssessmentVertical.ELECTRICITY
    );
    const heatingAssessment = assessments.find(
        (assessment) => assessment.vertical === AssessmentVertical.HEATING
    );

    return {
        id: assessments[0].id,
        address: i18nJoinItems(t, addresses),
        score: classifications ?? t("common.dashSeparator", DASH_SEPARATOR),
        epc: epcIdentifier,
        category: verticals.join(", "),
        nationalIdentifiers: nationalBuildingIds,
        from: formatDate(assessments[0].from),
        to: formatDate(assessments[0].to),
        epcLinks: getEpcLinksFromAssessments(assessments),
        status: getEPCStatus(assessments),
        area: assessments[0].areaData?.totalArea ?? 0,
        totalElectricityConsumption: {
            value: electricityAssessment?.consumptionData?.totalConsumption,
            unit: electricityAssessment?.unit,
        },
        totalHeatingConsumption: {
            value: heatingAssessment?.consumptionData?.totalConsumption,
            unit: heatingAssessment?.unit,
        },
    };
};

export const getFormattedPropertyType = (
    propertyType: PropertyType,
    t: TFunction
) => {
    switch (propertyType) {
        case PropertyType.AGRICULTURAL:
            return t("assetDetailsComponent.agricultural", "Agricultural");
        case PropertyType.COMMERCIAL_RETAIL:
            return t(
                "assetDetailsComponent.commercial_retail",
                "Commercial retail"
            );
        case PropertyType.CULTURAL_PUBLIC_SPACES:
            return t("assetDetailsComponent.public_spaces", "Public spaces");
        case PropertyType.EDUCATIONAL:
            return t("assetDetailsComponent.educational", "Educational");
        case PropertyType.GOVERNMENT_CIVIC:
            return t(
                "assetDetailsComponent.government_civic",
                "Government (civic)"
            );
        case PropertyType.HEALTHCARE:
            return t("assetDetailsComponent.healthcare", "Healthcare");
        case PropertyType.HOSPITALITY_LODGING:
            return t(
                "assetDetailsComponent.hospitality_lodging",
                "Hospitality & Lodging"
            );
        case PropertyType.INDUSTRIAL:
            return t("assetDetailsComponent.industrial", "Industrial");
        case PropertyType.LEISURE_RECREATION:
            return t(
                "assetDetailsComponent.leisure_recreation",
                "Leisure & Recreation"
            );
        case PropertyType.MIXED_USE:
            return t("assetDetailsComponent.mixed_use", "Mixed use");
        case PropertyType.OFFICE_ADMINISTRATION:
            return t(
                "assetDetailsComponent.office_administration",
                "Office & Administration"
            );
        case PropertyType.OTHER:
            return t("assetDetailsComponent.other", "Other");
        case PropertyType.RESIDENTIAL:
            return t("assetDetailsComponent.residential", "Residential");
        case PropertyType.SPECIAL_USE:
            return t("assetDetailsComponent.special_use", "Special use");
        case PropertyType.TECHNOLOGY_SCIENCE:
            return t(
                "assetDetailsComponent.technology_science",
                "Technology & Science"
            );
        case PropertyType.TRANSPORT_INFRASTRUCTURE:
            return t(
                "assetDetailsComponent.transport_infrastructure",
                "Transport & Infrastructure"
            );
        default:
            // ideally, should be a sentry log and returns a default value
            console.error(`Unhandled property type: ${propertyType}`);
            return DASH_SEPARATOR;
    }
};

export const getBuildingType = (
    building?: GetAllAssetDetailsQuery["location"]["buildings"][number]
) => {
    if (!building?.buildingCategory) return DASH_SEPARATOR;

    const { useCode, label } = building.buildingCategory;

    return `${label}${useCode ? ` - (${useCode})` : ""}`;
};

export const getMasterDataUpdateNotification = (
    notificationType: NotificationType | null,
    t: TFunction
) => {
    switch (notificationType) {
        case NotificationType.SUCCESS:
            return t(
                "asset.masterData.notifications.success",
                "Your changes have been saved successfully."
            );
        case NotificationType.ERROR:
            return t(
                "asset.masterData.notifications.error",
                "An error occurred trying to update your master data."
            );
        default:
            return "";
    }
};

export const updateAssetDetailsCache = (
    cache: ApolloCache<unknown>,
    data: UpdateLocationInput,
    locationId: string
) => {
    const locationQuery = {
        query: ASSET_DETAILS_QUERY,
        variables: { locationId },
    };

    const addressDetailsQuery = {
        query: ASSET_ADDRESS_QUERY,
        variables: { locationId },
    };
    const assetDetails =
        cache.readQuery<GetAllAssetDetailsQuery>(locationQuery);

    const addressDetails =
        cache.readQuery<GetAssetAddressQuery>(addressDetailsQuery);

    if (!assetDetails) {
        return;
    }

    const changeCount = Object.keys(data).length;

    cache.writeQuery<GetAllAssetDetailsQuery>({
        ...locationQuery,
        data: {
            changeLogsFieldsCount:
                assetDetails.changeLogsFieldsCount + changeCount,
            location: {
                ...assetDetails.location,
                ...data,
                displayName: data.name ?? assetDetails.location.displayName,
            },
        },
    });

    if (!addressDetails) {
        return;
    }

    cache.writeQuery<GetAssetAddressQuery>({
        ...addressDetailsQuery,
        data: {
            location: {
                ...addressDetails.location,
                displayName: data.name ?? addressDetails.location.displayName,
            },
        },
    });
};
