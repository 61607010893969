import { styled, Box, Tab, Typography, Tabs } from "@mui/material";
import React from "react";

import { theme } from "../../styling/themes";

const TabsContainer = styled(Box)(({ theme }) => ({
    height: theme.spacing(9),
    display: "flex",
    maxWidth: "100%",
    width: "fit-content",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "7px",
}));

const TabsBox = styled(Tabs)(({ theme }) => ({
    minHeight: theme.spacing(9),
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 600px)"]: {
        width: theme.spacing(87.5),
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 500px)"]: {
        width: theme.spacing(62.5),
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 400px)"]: {
        width: "70vw",
    },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: theme.spacing(9),
    textAlign: "left",
    color: theme.palette.text.primary,
    fontSize: theme.spacing(3.25),
    padding: theme.spacing(2, 10),
    fontWeight: "bold",
    whiteSpace: "nowrap",
    zIndex: 1,

    "&.Mui-selected": {
        color: theme.palette.background.paper,
    },

    "&:focus": {
        outline: "0px auto -webkit-focus-ring-color",
    },

    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 400px)"]: {
        minWidth: "20px",
    },
}));

const TabSubtext = styled(Typography)(({ theme }) => ({
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: theme.spacing(2.8),
    textWrap: "wrap",
}));

const tabIndicatorProps = {
    style: {
        height: "100%",
        padding: "10px 4px",
        borderRadius: "7px",
        border: "solid 1px #dee8ff",
        backgroundColor: theme.palette.primary.main,
    },
};

type TabsData = {
    title: string;
    explainer?: string;
    value?: number;
};

type Props = {
    activeTab: number;
    tabsData: TabsData[];
    onChange: (event: React.ChangeEvent<object>, newValue: number) => void;
};

const TabsPanel = (props: Props) => {
    const { activeTab, tabsData, onChange } = props;

    const explainer =
        tabsData.find((t) => t.value === activeTab)?.explainer ??
        tabsData[activeTab]?.explainer;

    return (
        <Box>
            <TabsContainer>
                <TabsBox
                    value={activeTab}
                    variant="fullWidth"
                    onChange={onChange}
                    TabIndicatorProps={tabIndicatorProps}
                >
                    {tabsData.map(({ title, value }, index) => (
                        <StyledTab
                            value={value ?? index}
                            key={index}
                            disableFocusRipple={true}
                            disableRipple={true}
                            label={title}
                        />
                    ))}
                </TabsBox>
            </TabsContainer>
            {explainer && <TabSubtext>{explainer}</TabSubtext>}
        </Box>
    );
};

export default TabsPanel;
