import { gql } from "@apollo/client";

export const BENCHMARKS_QUERY = gql`
    query GetOrganizationBenchmarks {
        benchmarks {
            edges {
                node {
                    id
                    type
                    countryCode
                    emissionPerArea {
                        bestEffort {
                            benchmark
                        }
                        epc {
                            benchmark
                        }
                        meter {
                            benchmark
                        }
                    }
                }
            }
        }
    }
`;
