import { gql } from "@apollo/client";

export const PERFORMANCE_LOCATION = gql`
    fragment PerformanceLocation on Location {
        id
        displayName
        ownedArea
        externalId
        assetAlerts(filter: { from: { in: $year } }) {
            id
            type
        }
        benchmarkPerformances(
            filter: { from: { in: $year }, benchmarkId: { eq: $benchmarkId } }
        ) {
            to
            from
            dataType
            benchmarkType
            performance
            ownedEmission
        }
    }
`;

export const YEARLY_PERFORMANCE_QUERY = gql`
    query GetYearlyPerformance(
        $benchmarkId: ID!
        $year: [DateTime!]
        $assetGroupId: ID
    ) {
        me {
            id
            organization {
                id
                reportingYear
                getLocations(assetGroupId: $assetGroupId) {
                    ...PerformanceLocation
                }
            }
        }
    }
    ${PERFORMANCE_LOCATION}
`;
