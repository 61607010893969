import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getOnboardingInputTranslatedPlaceholder } from "./utils/tableUtils";

type InputProps = {
    onChange: (key: string, value: string | string[]) => void;
    keys: string[];
};

export const IntegrationOnboardingInput = ({ onChange, keys }: InputProps) => {
    const { t } = useTranslation();
    const hiddenFields = ["password", "passphrase"];

    return (
        <Box sx={{ display: "flex", gap: 4 }}>
            {keys.map((key, i) => {
                const lowerCaseKey = key.toLowerCase();
                const placeholder = getOnboardingInputTranslatedPlaceholder(
                    t,
                    lowerCaseKey
                );

                const isMultiLineInput = key === "privateKey";
                const isFieldHidden = hiddenFields.includes(lowerCaseKey);

                return (
                    <TextField
                        key={i}
                        name={key}
                        fullWidth
                        multiline={isMultiLineInput}
                        placeholder={placeholder}
                        type={isFieldHidden ? "password" : "text"}
                        onChange={(e) =>
                            onChange(e.target.name, e.target.value)
                        }
                    />
                );
            })}
        </Box>
    );
};
