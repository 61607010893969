import { IconButton, Paper, styled, Tabs, TabsProps } from "@mui/material";

export const S = {
    Container: styled(Paper)(({ theme }) => ({
        display: "grid",
        gap: theme.spacing(4),
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: "min-content fit-content(100%) 1fr min-content",

        borderRadius: "5px",
        height: theme.spacing(27.15),
        padding: theme.spacing(6.25),
        marginBottom: theme.spacing(4),
    })),
    IconButton: styled(IconButton)(({ theme }) => ({
        width: theme.spacing(12.5),
        height: theme.spacing(12.5),
        borderRadius: theme.spacing(2.5),
        border: "solid 2px currentColor",
    })),
    Tabs: styled((props: TabsProps) => (
        <Tabs
            {...props}
            TabIndicatorProps={{ sx: { height: "5px", borderRadius: "5px" } }}
        />
    ))({
        justifySelf: "end",
    }),
};
