import { chain, sumBy } from "lodash";
import { DateTime } from "luxon";

import { AssetEmission } from "../types/monthlyChart.types";
import { EmissionSummaryLocation } from "../utils/portfolioIntensityByMonthChart.utils";

export const getTotalEmissionSummariesEmissionByMonth = (
    assets: EmissionSummaryLocation[]
): AssetEmission[] => {
    const totalArea = sumBy(assets, "ownedArea");
    const emissionSummaries = assets
        .filter(({ ownedArea }) => !!ownedArea)
        .flatMap(({ emissionSummaries, ownedArea }) =>
            emissionSummaries.map((emissionSummary) => ({
                ...emissionSummary,
                ownedArea,
            }))
        );

    return chain(emissionSummaries)
        .compact()
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((emissionSummariesByMonth, month) => {
            const emission = sumBy(emissionSummariesByMonth, "ownedEmission");
            const assetArea = sumBy(emissionSummariesByMonth, "ownedArea");

            return {
                month: Number(month),
                assetArea,
                totalArea,
                assetCount: assets.length,
                emission,
                emissionPerArea: emission / assetArea,
            };
        })
        .sortBy((emissionSummariesByMonth) => emissionSummariesByMonth.month)
        .value();
};
