import React, { FC, useState } from "react";

import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";

import LocationIntegrationStep from "./LocationIntegrationStep";
import OrganizationIntegrationStep from "./OrganizationIntegrationStep";

enum OnboardingSteps {
    ORGANIZATION = 0,
    LOCATION_INTEGRATION = 1,
}

interface Props {
    integrationType: SupportedIntegrationForOnboarding;
}

const IntegrationCreatorModal: FC<Props> = ({ integrationType }) => {
    const [index, setIndex] = useState(OnboardingSteps.ORGANIZATION);
    const modalStepNavigationalHandler = () => {
        setIndex(OnboardingSteps.LOCATION_INTEGRATION);
    };

    const isOrganizationIntegrationStep = index === 0;
    const isOISIntegration =
        integrationType === SupportedIntegrationForOnboarding.OIS;
    const isElectraLink =
        integrationType === SupportedIntegrationForOnboarding.ELECTRALINK;

    if (isOrganizationIntegrationStep && !isOISIntegration && !isElectraLink) {
        return (
            <OrganizationIntegrationStep
                integrationType={integrationType}
                modalStepNavHandler={modalStepNavigationalHandler}
            />
        );
    }

    return <LocationIntegrationStep integrationType={integrationType} />;
};

export default IntegrationCreatorModal;
