// from https://react.i18next.com/latest/using-with-hooks
// Step-by-step guide

// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const options = {
    caches: ["localStorage", "cookie"],
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        supportedLngs: ["en", "da"],

        detection: options,
        debug: process.env.NODE_ENV === "development",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: true,
        },
        returnNull: false,

        backend: {
            loadPath: "{{lng}}/{{ns}}.json",
            request: async function (
                options: any,
                url: any,
                payload: any,
                callback: any
            ) {
                const data = await import(`../locales/${url}`).catch(
                    (error) => ({ error })
                );
                if (data && data.default) {
                    callback(null, { status: 200, data: data.default });
                } else {
                    const error = data?.error || new Error("Not found");
                    callback(error, { status: 500 });
                }
            },
        },
    });

export default i18n;
