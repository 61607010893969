import { Box, Button, Typography } from "@mui/material";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import {
    AllocationObjectType,
    ConsumptionType,
    IntegrationType,
} from "graphql-types/graphql";

import {
    filterAutomaticSources,
    getStatusFilter,
} from "./automatic-source-toolbar.helpers";
import { AutomaticSourceFilter, Filter } from "./Filters";
import { AutomaticSource } from "../../asset-sources.helpers";
import { DataSourceStatusFilter } from "../../types/dataSourceTypes";
import { QueryFilter } from "../automatic-source-table.hooks";

export const DEFAULT_STATUS_FILTER = [DataSourceStatusFilter.ACTIVE];
const allocationOptions = Object.values(AllocationObjectType);

type Props = {
    sources: AutomaticSource[];
    locationDisplayName?: string | null;
    setSources: (sources: AutomaticSource[]) => void;
    apiRef: MutableRefObject<GridApiCommunity>;

    // Query filter options
    count: number;
    queryFilter: QueryFilter;
    filterOptions: Pick<Filter, "consumptionTypes" | "integrationTypes">;
    onFilterChange: (key: keyof QueryFilter, value: any) => void;
};

const AutomaticSourceToolbar = (props: Props) => {
    const {
        filterOptions,
        locationDisplayName,
        sources,
        setSources,
        apiRef,
        count,
        queryFilter,
        onFilterChange,
    } = props;
    const { t } = useTranslation(["report"]);

    const { useConsumptionAllocation, useAssetIntegrationAsDataSource } =
        useFlags();

    const fileName = `${locationDisplayName} - ${t(
        "automaticSources.fileName",
        "automatic_sources",
        { ns: "translation" }
    )}`;

    const [statusFilter, setStatusFilter] = useState<DataSourceStatusFilter[]>(
        DEFAULT_STATUS_FILTER
    );

    const [sourceOptions, setSourceOptions] = useState<IntegrationType[]>([]);
    const [sourceFilter, setSourceFilter] = useState<IntegrationType[]>([]);

    const [consumptionTypeOptions, setConsumptionTypeOptions] = useState<
        ConsumptionType[]
    >([]);
    const [consumptionTypeFilter, setConsumptionTypeFilter] = useState<
        ConsumptionType[]
    >([]);

    const [propertyAllocationFilter, setPropertyAllocationFilter] = useState<
        AllocationObjectType[]
    >(Object.values(AllocationObjectType));

    useEffect(() => {
        const availableSources = Object.values(IntegrationType).filter((type) =>
            sources.find((source) => source.type === type)
        );
        const availableConsumptionTypes = Object.values(ConsumptionType).filter(
            (type) => sources.find((source) => source.consumptionType === type)
        );

        setConsumptionTypeOptions(availableConsumptionTypes);
        setConsumptionTypeFilter(availableConsumptionTypes);

        setSourceOptions(availableSources);
        setSourceFilter(availableSources);

        setSources(
            filterAutomaticSources(
                sources,
                DEFAULT_STATUS_FILTER,
                availableSources,
                availableConsumptionTypes,
                useConsumptionAllocation
                    ? Object.values(AllocationObjectType)
                    : []
            )
        );
    }, [sources, setSources, useConsumptionAllocation]);

    const handleFilterChange = (key: keyof Filter, value: any) => {
        match(key)
            .with("source", () => setStatusFilter(value))
            .with("integrationTypes", () => setSourceFilter(value))
            .with("consumptionTypes", () => setConsumptionTypeFilter(value))
            .with("allocationTypes", () => setPropertyAllocationFilter(value))
            .exhaustive();

        setSources(
            filterAutomaticSources(
                sources,
                key === "source" ? value : statusFilter,
                key === "integrationTypes" ? value : sourceFilter,
                key === "consumptionTypes" ? value : consumptionTypeFilter,
                key === "allocationTypes" ? value : propertyAllocationFilter
            )
        );
    };

    const handleQueryFilterChange = (key: keyof Filter, values: any) => {
        if (key === "source") {
            const value =
                values.length === 1
                    ? values[0] === "ACTIVE"
                    : values.length === 0
                    ? undefined
                    : null;

            onFilterChange("isActive", value);
            return;
        }

        onFilterChange(key, values);
    };

    const filter = useAssetIntegrationAsDataSource
        ? {
              ...queryFilter,
              source: getStatusFilter(queryFilter.isActive),
          }
        : {
              source: statusFilter,
              integrationTypes: sourceFilter,
              consumptionTypes: consumptionTypeFilter,
              allocationTypes: propertyAllocationFilter,
          };

    const options = useAssetIntegrationAsDataSource
        ? {
              source: Object.values(DataSourceStatusFilter),
              allocationTypes: allocationOptions,
              ...filterOptions,
          }
        : {
              source: Object.values(DataSourceStatusFilter),
              integrationTypes: sourceOptions,
              consumptionTypes: consumptionTypeOptions,
              allocationTypes: allocationOptions,
          };

    const onFilterChangeHandler = useAssetIntegrationAsDataSource
        ? handleQueryFilterChange
        : handleFilterChange;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 3 }}>
            <AutomaticSourceFilter
                filters={filter}
                options={options}
                onFilterChange={onFilterChangeHandler}
            />

            <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                <Typography>
                    {count}{" "}
                    {t("pageheader.data", "Data Sources", {
                        ns: "translation",
                    })}
                </Typography>
                <Button
                    variant="contained"
                    onClick={() =>
                        apiRef?.current.exportDataAsCsv({
                            fileName,
                        })
                    }
                >
                    {t("common.download", "Download", {
                        ns: "translation",
                    })}
                </Button>
            </Box>
        </Box>
    );
};

export default AutomaticSourceToolbar;
