import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    Box,
    ListItem,
    ListSubheader,
    Popper,
    TextField,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, {
    Children,
    cloneElement,
    createContext,
    forwardRef,
    isValidElement,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";

import { DASH_SEPARATOR } from "utils/strings.helpers";

import { LocationWithFilteringLabel } from "./types";

const LISTBOX_PADDING = 8;

function renderRow(props: any) {
    const { data, index, style } = props;
    return cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref: any) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
    const ref: React.MutableRefObject<any> = useRef(null);

    useEffect(() => {
        if (!ref.current) return null;

        return ref.current.resetAfterIndex(0, true);
    }, [data]);
    return ref;
}

const ListboxComponent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = Children.toArray(children);

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: any) => {
        if (isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };
    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const renderGroup = (params: any) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];

const AutocompleteContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    backgroundColor: theme.palette.grey[100],
    borderRadius: "4px",
    width: "25%",
    minWidth: "450px",

    "& > .MuiAutocomplete-root": {
        flexGrow: 1,
        backgroundColor: "transparent",
        border: "none",

        "& > * ": {
            backgroundColor: "inherit",
        },
    },
}));

const AutocompleteRow = styled(ListItem)(({ theme }) => ({
    display: "grid",
    padding: theme.spacing(0, 4),
    gridGap: theme.spacing(2),
    gridTemplateColumns: "45% 35% 20%",
    color: theme.palette.grey[400],

    "& > *": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}));

type Props = {
    assets: LocationWithFilteringLabel[];
    onAssetSelected: (l: LocationWithFilteringLabel) => void;
};

export const ForecastAssetSearch = (props: Props) => {
    const { onAssetSelected, assets } = props;
    const { t } = useTranslation();

    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLDivElement>) =>
            setShowSearchIcon(e.target.innerText === ""),
        [setShowSearchIcon]
    );
    const handleChange = useCallback(
        (
            _: React.ChangeEvent<object>,
            l: string | LocationWithFilteringLabel | null
        ) => {
            onAssetSelected(l as LocationWithFilteringLabel);
        },
        [onAssetSelected]
    );

    return (
        <AutocompleteContainer>
            {showSearchIcon && <SearchIcon sx={{ color: "primary.main" }} />}
            <Autocomplete
                sx={{
                    "& .MuiOutlinedInput-root": {
                        outline: "none",
                        borderRadius: "0",
                        padding: "0",

                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                    },
                }}
                freeSolo
                disablePortal
                disableListWrap
                selectOnFocus
                onBlur={handleBlur}
                onChange={handleChange}
                PopperComponent={Popper}
                ListboxComponent={ListboxComponent}
                renderGroup={renderGroup}
                options={assets}
                groupBy={(option) =>
                    option.name ? option.name[0] : DASH_SEPARATOR
                }
                getOptionLabel={(option) =>
                    typeof option !== "string"
                        ? `${option.name} ${option.externalId}`
                        : ""
                }
                renderOption={(props, option) => (
                    <AutocompleteRow {...props}>
                        <Typography fontWeight={600} color="black">
                            {option.name}
                        </Typography>
                        <Typography>{option.externalId}</Typography>
                        <Typography>{option.origin}</Typography>
                    </AutocompleteRow>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={t(
                            "forecastDashboard.filtering.placeholder",
                            "Search by National Building ID, ID or Name"
                        )}
                    />
                )}
            />
        </AutocompleteContainer>
    );
};
