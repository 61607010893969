import { gql } from "@apollo/client";

export const EMISSION_SUMMARIES_QUERY = gql`
    query GetEmissionSummaries(
        $aggregation: AssessmentAggregation!
        $from: DateTime!
        $to: DateTime!
        $dataType: AssessmentDataType!
        $assetGroupId: ID
    ) {
        me {
            id
            organization {
                id
                getLocations(assetGroupId: $assetGroupId) {
                    id
                    ownedArea
                    emissionSummaries(
                        from: $from
                        to: $to
                        aggregation: $aggregation
                        dataType: $dataType
                    ) {
                        id
                        from
                        ownedEmission
                    }
                }
            }
        }
    }
`;
