import { gql } from "@apollo/client";

export const GET_LOCATION_INTEGRATION = gql`
    query GetLocationIntegrations($type: IntegrationType!) {
        me {
            organization {
                getLocations {
                    id
                    displayName
                    integrations(filter: { type: { eq: $type } }) {
                        id
                        startedAt
                        endedAt
                        data {
                            ... on IntegrationElectralinkLocationIntegrationData {
                                postCode
                                partialMpan
                                address
                            }
                        }
                    }
                }
            }
        }
    }
`;
