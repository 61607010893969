import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MenuProps = {
    PaperProps: {
        sx: {
            maxWidth: 350,
            maxHeight: 300,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        },
    },
};

type Props<T> = {
    loading?: boolean;
    list: { key: string; value: string }[];
    onSelectedValue: (value: T) => void;
};

export function FilterOptionSelect<T>(props: Props<T>) {
    const { onSelectedValue, list, loading = false } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState<T | "">("");

    const options = list.map((item) => (
        <MenuItem value={item.key} key={item.key} sx={{ whiteSpace: "normal" }}>
            {item.value}
        </MenuItem>
    ));

    function onValueChange(event: SelectChangeEvent<T>) {
        const selectedKey = event.target.value as T;
        const selectedValue = list.find((option) => option.key === selectedKey)
            ?.value as string;
        setValue(value);
        onSelectedValue({
            key: selectedKey,
            value: selectedValue,
        } as T);
    }

    const inputLabel = loading
        ? t("labels.loading", "Loading")
        : t("assetGroup.selectFilter", "Select Filter");

    return (
        <FormControl
            disabled={loading}
            sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <InputLabel id="range-to" size="small">
                {inputLabel}
            </InputLabel>
            <Select
                size="small"
                labelId="range-to"
                fullWidth
                value={value}
                onChange={onValueChange}
                MenuProps={MenuProps}
                displayEmpty
                label={t("assetGroup.selectFilter", "Select Filter")}
            >
                {options}
            </Select>
        </FormControl>
    );
}
