import { useMutation, useQuery } from "@apollo/client";
import { Box, Container, Divider, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import {
    AddOrganizationIntegrationWithDataMutation,
    AddOrganizationIntegrationWithDataMutationVariables,
    GetEntolabsOrganizationsQuery,
    GetOrganizationIntegrationQuery,
    GetOrganizationIntegrationQueryVariables,
    OrganizationIntegrationData,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";

import GenericModalStep from "./GenericModalStep";
import { IntegrationOnboardingInput } from "./IntegrationOnboardingInput";
import { IntegrationOnboardingSelect } from "./IntegrationOnboardingSelect";
import { ADD_ORGANIZATION_INTEGRATION_WITH_DATA_MUTATION } from "../graphql/organizationIntegrationOnboardingMutation";
import {
    GET_ENTOLABS_ORGANIZATIONS_QUERY,
    GET_ORGANIZATION_INTEGRATION,
} from "../graphql/organizationIntegrationOnboardingQuery";
import { UNSUPPORTED_INTEGRATION_TYPE } from "../utils";
interface Props {
    integrationType: Exclude<
        SupportedIntegrationForOnboarding,
        SupportedIntegrationForOnboarding.OIS
    >;
    modalStepNavHandler: () => void;
}

const parseQueryResult = (data?: GetOrganizationIntegrationQuery | null) => {
    if (!data) {
        console.warn("Mutation result data null or undefined");
        return;
    }

    if ("getOrganizationIntegration" in data) {
        return data.getOrganizationIntegration;
    } else {
        console.error("Unsupported integration type in mutation response");
    }
};

const OrganizationIntegrationContent = ({
    integrationType,
    credentials,
    isForbidden,
    showError,
    setCredentials,
}: {
    integrationType: SupportedIntegrationForOnboarding;
    credentials?: OrganizationIntegrationData;
    isForbidden: boolean;
    showError: boolean;
    setCredentials: (credentials: OrganizationIntegrationData) => void;
}) => {
    const { t } = useTranslation();

    const isEntolabs =
        integrationType === SupportedIntegrationForOnboarding.ENTOLABS;

    const { data } = useQuery<GetEntolabsOrganizationsQuery>(
        GET_ENTOLABS_ORGANIZATIONS_QUERY,
        { skip: !isEntolabs || isForbidden }
    );

    const selectorValues = useMemo(() => {
        return data?.entolabsOrganizations?.map(({ id, name }) => ({
            value: id.toString(),
            label: `${name} (${id})`,
        }));
    }, [data]);

    const keys = useMemo(() => {
        switch (integrationType) {
            case SupportedIntegrationForOnboarding.VITANI:
                return ["BASEURL", "USERNAME", "PASSWORD"];
            case SupportedIntegrationForOnboarding.HOFOR:
                return ["username", "password", "privateKey", "passphrase"];
            case SupportedIntegrationForOnboarding.ENTOLABS:
                return ["entolabsOrganizationIds"];
            default:
                return ["apiKey"];
        }
    }, [integrationType]);

    const onChange = (key: string, value: string | string[]) => {
        switch (integrationType) {
            case SupportedIntegrationForOnboarding.DEXMA:
            case SupportedIntegrationForOnboarding.TECHEM:
            case SupportedIntegrationForOnboarding.VITANI:
            case SupportedIntegrationForOnboarding.HOFOR:
            case SupportedIntegrationForOnboarding.ENTOLABS:
                setCredentials({
                    ...credentials,
                    [key]: value,
                } as OrganizationIntegrationData);
                break;
            default:
                throw new Error(UNSUPPORTED_INTEGRATION_TYPE);
        }
    };

    if (isForbidden)
        return (
            <Typography variant="h3" textAlign="center">
                {t(
                    "integrationOnboarding.invalidPermissions",
                    "User missing required permission, contact an admin within your organization"
                )}
            </Typography>
        );

    return (
        <Box
            min-height="20vh"
            display="flex"
            flexDirection="column"
            sx={{ justifyContent: "center", gap: 4, px: 4 }}
        >
            <Typography>
                {t(
                    "integrationOnboarding.credentialsLabel",
                    "Enter the following {{integrationType}} credentials",
                    { integrationType }
                )}
            </Typography>

            <Divider />

            {selectorValues ? (
                <IntegrationOnboardingSelect
                    selectorValues={selectorValues}
                    onChange={onChange}
                    keys={keys}
                />
            ) : (
                <IntegrationOnboardingInput onChange={onChange} keys={keys} />
            )}

            <Divider />

            {showError && (
                <Typography color="error">
                    {t(
                        "integrationOnboarding.credentialsInvalidLabel",
                        "Credentials invalid, try again"
                    )}
                </Typography>
            )}
        </Box>
    );
};

const OrganizationIntegrationStep = ({
    integrationType,
    modalStepNavHandler,
}: Props) => {
    const { t } = useTranslation();
    const [credentials, setCredentials] =
        useState<OrganizationIntegrationData>();

    const [isForbidden, setIsForbidden] = useState(false);
    const [showError, setShowError] = useState(false);

    const [mutationLoading, setMutationLoading] = useState(false);

    const {
        data: integrationQueryResult,
        loading: integrationQueryLoading,
        error: queryError,
        refetch,
    } = useQuery<
        GetOrganizationIntegrationQuery,
        GetOrganizationIntegrationQueryVariables
    >(GET_ORGANIZATION_INTEGRATION, {
        fetchPolicy: "network-only",
        variables: { integrationType },
    });
    const [addOrganizationIntegration] = useMutation<
        AddOrganizationIntegrationWithDataMutation,
        AddOrganizationIntegrationWithDataMutationVariables
    >(ADD_ORGANIZATION_INTEGRATION_WITH_DATA_MUTATION);

    useEffect(() => {
        if (
            integrationQueryResult &&
            parseQueryResult(integrationQueryResult)?.id
        ) {
            modalStepNavHandler();
        }
    }, [integrationQueryResult, modalStepNavHandler]);

    useEffect(() => {
        const isForbiddenError =
            queryError &&
            queryError.graphQLErrors.find(
                (e) => e.extensions.code === "FORBIDDEN"
            );

        if (isForbiddenError) {
            setIsForbidden(true);
        }
    }, [queryError]);

    if (integrationQueryLoading || mutationLoading) {
        return (
            <Container>
                <Loading description={t("loading.title", "Loading")} />
            </Container>
        );
    }

    const onSubmit = () => {
        const variables: AddOrganizationIntegrationWithDataMutationVariables = {
            integrationData: {
                integrationType,
                data: { ...credentials },
            },
        };

        setShowError(false);
        setMutationLoading(true);
        addOrganizationIntegration({
            variables,
        })
            .then(() => refetch())
            .catch((res) => {
                setShowError(true);
                console.error("Error adding organization integration", res);
            })
            .finally(() => setMutationLoading(false));
    };

    return (
        <GenericModalStep
            header={t(
                "integrationOnboarding.createOrganizationIntegrationHeader",
                "Credentials"
            )}
            body={
                <OrganizationIntegrationContent
                    integrationType={integrationType}
                    credentials={credentials}
                    setCredentials={setCredentials}
                    isForbidden={isForbidden}
                    showError={showError}
                />
            }
            disableSubmitBtn={Boolean(!credentials)}
            onSubmit={onSubmit}
        />
    );
};

export default OrganizationIntegrationStep;
