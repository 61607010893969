import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";

import { UnitMass } from "components/AssessmentStats/types";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";
import { PortfolioChartData } from "../types/chart.types";

export const getTotalEmissionsChartData = (
    t: TFunction,
    assessmentSummaries: SummaryYearOverview[]
): PortfolioChartData => {
    const xAxisData = assessmentSummaries.map(({ year }) => year);
    const assetCount = assessmentSummaries.map(({ assetCount }) => assetCount);
    const totalArea = assessmentSummaries.map(({ totalArea }) => totalArea);

    const seriesData = assessmentSummaries.map(({ emission }) => {
        return getConvertedGramsToMassUnit(emission, UnitMass.TON);
    });

    const projectedSeriesData = assessmentSummaries.map((summedYear) => {
        const isCurrentYear = getCurrentYear() === summedYear.year;

        const calculatedProjectedEmission =
            summedYear.projectedEmission - summedYear.emission;

        if (!calculatedProjectedEmission || calculatedProjectedEmission < 0) {
            return null;
        }

        return isCurrentYear
            ? getConvertedGramsToMassUnit(
                  calculatedProjectedEmission,
                  UnitMass.TON
              )
            : null;
    });

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.TON, t, false) || "";

    return {
        series: [
            {
                type: "bar",
                label: t(
                    "portfolioDashboard.boxes.emissions",
                    "Total Emissions"
                ),
                stack: "total",
                id: "emission",
                data: seriesData,
                assetCount,
                totalArea,
                valueFormatter,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.projected", "Projected"),
                stack: "total",
                id: "projected",
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
    };
};
