import { gql } from "@apollo/client";

export const LEGAL_OWNERS_QUERY = gql`
    query GetAssetGroupLegalOwners {
        me {
            id
            organization {
                legalOwners
            }
        }
    }
`;
