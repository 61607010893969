import { P, match } from "ts-pattern";

import {
    AssetIntegrationAllocation,
    AssetPropertyTree,
    ConsumptionType,
    DataQualityTypes,
    PropertyObjectType,
} from "graphql-types/graphql";

import {
    PropertyFlatTree,
    SourcesQualityCount,
} from "./asset-property-list.types";

export const getConsumptionKey = (consumptionType: ConsumptionType) => {
    return match(consumptionType)
        .with(
            P.string.includes(ConsumptionType.ENERGY_ELECTRICITY),
            () => ConsumptionType.ENERGY_ELECTRICITY
        )
        .with(
            P.string.includes(ConsumptionType.ENERGY_HEATING),
            () => ConsumptionType.ENERGY_HEATING
        )
        .with(
            P.string.includes(ConsumptionType.WATER),
            () => ConsumptionType.WATER
        )
        .otherwise(() => "");
};

export const getPropertyAllocationsSummary = (
    allocations: AssetIntegrationAllocation[]
) => {
    const consumptionCount: Record<string, number> = {};

    allocations.forEach((allocation) => {
        allocation.assetIntegration.consumptionTypes.forEach((type) => {
            const consumptionKey = getConsumptionKey(type);

            consumptionCount[consumptionKey] =
                (consumptionCount[consumptionKey] || 0) + 1;
        });
    });

    return consumptionCount;
};

export const getPropertyQualityCount = (
    allocations: AssetIntegrationAllocation[]
) => {
    const qualityCount: SourcesQualityCount = {
        [DataQualityTypes.HIGH]: 0,
        [DataQualityTypes.MEDIUM]: 0,
        [DataQualityTypes.LOW]: 0,
    };

    return allocations.reduce((acc, curr) => {
        const { dataQuality } = curr.assetIntegration;
        if (!dataQuality) {
            return acc;
        }

        acc[dataQuality] += 1;
        return acc;
    }, qualityCount);
};

export const flatPropertyTree = (
    properties: AssetPropertyTree[],
    depth = 0
): PropertyFlatTree[] => {
    let result: PropertyFlatTree[] = [];

    properties.forEach((property) => {
        // Add the current property with its depth
        const allocationsCount = getPropertyAllocationsSummary(
            property.allocations
        );
        const qualityCount = getPropertyQualityCount(property.allocations);
        result.push({
            ...property,
            depth,
            allocationsCount,
            qualityCount,
        });

        // If there are children, recursively add them with incremented depth
        if (property.children && property.children.length > 0) {
            result = result.concat(
                flatPropertyTree(property.children, depth + 1)
            );
        }
    });

    return result;
};

export const getPropertyTypeOptions = (
    parentObjectType?: PropertyObjectType | null
) => {
    if (!parentObjectType) {
        return [
            PropertyObjectType.PLOT,
            PropertyObjectType.BUILDING,
            PropertyObjectType.UNIT,
        ];
    }

    return match(parentObjectType)
        .with(PropertyObjectType.PLOT, () => {
            return [
                PropertyObjectType.PLOT,
                PropertyObjectType.BUILDING,
                PropertyObjectType.UNIT,
            ];
        })
        .with(PropertyObjectType.BUILDING, () => {
            return [PropertyObjectType.BUILDING, PropertyObjectType.UNIT];
        })
        .with(PropertyObjectType.UNIT, () => {
            return [PropertyObjectType.UNIT];
        })
        .exhaustive();
};
