import { WarningRounded } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DisclaimerPopover from "components/DisclaimerPopover/DisclaimerPopover";
import { convertToRem } from "utils/maths";

const ChartSubtitleContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    display: "flex",
}));

export const ChartDisclaimer = () => {
    const { t } = useTranslation();

    const DisclaimerMessage = useMemo(
        () => (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <span>{t("forecastDashboard.chartDisclaimer.message1")}</span>
                <span>{t("forecastDashboard.chartDisclaimer.message2")}</span>
                <span>{t("forecastDashboard.chartDisclaimer.message3")}</span>
            </Box>
        ),
        [t]
    );

    return (
        <ChartSubtitleContainer
            color="grey.700"
            sx={{ fontSize: convertToRem(14), display: "flex", gap: "0.25rem" }}
        >
            <WarningRounded color="warning" sx={{ mr: 4 }} />
            <b>
                {t("forecastDashboard.chartDisclaimer.note", "Please Note. ")}
            </b>
            {t(
                "forecastDashboard.chartDisclaimer.disclaimer",
                "Do not use for strategy or certification purposes."
            )}
            <DisclaimerPopover
                element={DisclaimerMessage}
                linkText={t(
                    "forecastDashboard.chartDisclaimer.readmore",
                    "read more"
                )}
            />
        </ChartSubtitleContainer>
    );
};
