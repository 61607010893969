import "react-toastify/dist/ReactToastify.css";

import {
    CheckCircleOutlined,
    ErrorOutlined,
    InfoOutlined,
    WarningOutlined,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

const icons = {
    default: <InfoOutlined />,
    error: <ErrorOutlined />,
    info: <InfoOutlined />,
    success: <CheckCircleOutlined />,
    warning: <WarningOutlined />,
};

function Toast() {
    return (
        <ToastContainer
            position={toast.POSITION.BOTTOM_LEFT}
            closeOnClick
            progressStyle={{
                background: "none",
                backgroundColor: "currentColor",
            }}
            toastStyle={{
                color: "#1E3B4D",
            }}
            icon={({ type }) => icons[type]}
        />
    );
}

export default Toast;
