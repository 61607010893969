import { Circle } from "@mui/icons-material";
import { Stack, useTheme } from "@mui/material";
import {
    GridValueGetter,
    GridColDef,
    GridValidRowModel,
} from "@mui/x-data-grid";
import { TFunction } from "i18next";
import _ from "lodash";

import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import { IntegrationType } from "graphql-types/graphql";

import { EmissionDetailsCell } from "./EmissionDetailsCell";
import { AssessmentOverviewVerticalValue, ColorProps } from "./types";

export const assessmentOverviewVerticalValueGetter: GridValueGetter = (
    value: never,
    row: GridValidRowModel,
    col: GridColDef
): AssessmentOverviewVerticalValue => {
    const dataIdentifiers = col.field.split(".");
    const dataRoot = `${dataIdentifiers[0]}.${dataIdentifiers[1]}`;

    const emissionValue = _.get(row, `${dataRoot}.emissionLabel`) as string;
    const consumptionValues = _.get(
        row,
        `${dataRoot}.consumptionLabels`
    ) as AssessmentOverviewVerticalValue["consumptionValues"];
    const sources = _.get(row, `${dataRoot}.sources`) as IntegrationType[];
    const hasEmissionUnit = _.get(
        row,
        `${dataRoot}.hasEmissionUnit`
    ) as boolean;

    return {
        sources,
        hasEmissionUnit,
        emissionValue,
        consumptionValues,
    };
};

export const TableCategoryHeader = ({
    name,
    categoryColor,
}: {
    name: string;
    categoryColor: ColorProps | undefined;
}) => {
    const theme = useTheme();

    const color = categoryColor
        ? theme.palette[categoryColor]?.main
        : undefined;

    return (
        <Stack
            className="MuiDataGrid-columnHeaderTitle"
            direction="row"
            alignItems="center"
            gap={1}
        >
            <Circle sx={{ color, fontSize: 10 }} />
            {name}
        </Stack>
    );
};

const formatArrayValues =
    (mappingFunction: (value: any) => string) => (value: any) =>
        Array.isArray(value) && value.length > 0
            ? value.map(mappingFunction).join(", ")
            : "-";

export const generateColumnDefinitions = (
    { field, color, headerName }: GridColDef & { color: ColorProps },
    t: TFunction
): GridColDef[] => [
    {
        field,
        valueGetter: assessmentOverviewVerticalValueGetter,
        renderHeader: () =>
            TableCategoryHeader({
                name: headerName ?? "Uncategorized",
                categoryColor: color ?? "uncategorized",
            }),
        renderCell: ({ value }) => EmissionDetailsCell(value, t),
        flex: 1,
        disableExport: true,
    },

    //Downloadable columns
    {
        field: `${color}.emissionValue`,
        headerName: `${headerName} - (${t(
            "pageheader.emissions",
            "Emissions"
        )})`,
        valueFormatter: (value: any) => (!value ? 0 : value),
    },
    {
        field: `${color}.emissionUnit`,
        headerName: `${headerName} - (${t(
            "pageheader.emissionsUnit",
            "Emissions Unit"
        )})`,
    },
    {
        field: `${color}.consumptionValue`,
        headerName: `${headerName} - (${t(
            "dataPage.gridTable.consumption",
            "Consumption"
        )})`,
        valueFormatter: formatArrayValues(({ consumption }) => consumption),
    },
    {
        field: `${color}.consumptionUnit`,
        headerName: `${headerName} - (${t(
            "dataPage.gridTable.consumptionUnit",
            "Consumption Unit"
        )})`,
        valueFormatter: formatArrayValues(({ unit }) => unit),
    },
    {
        field: `${color}.sources`,
        headerName: `${headerName} - (${t("pageheader.data", "Data Sources")})`,
        valueFormatter: formatArrayValues((source: string) =>
            mapDataQualitySources(source, t)
        ),
    },
];
