import { Box, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import YearAndAssessmentTypeFilter from "components/YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import { NavTabs } from "glue/NavTabs";

const MainPortfolioContainer = styled(Box)(() => ({
    display: "flex",
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 800px)"]: {
        flexDirection: "column",
    },
}));

const PortfolioDashboard = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const { assessmentType, setAssessmentType } =
        useAssessmentDataTypeContext();

    const tabLinks = [
        {
            path: "/portfolio/overview",
            label: t("subheader.overview", "Overview"),
        },
        {
            path: "/portfolio",
            label: t("subheader.yearlyBreakdown", "Yearly Breakdown"),
        },
    ];

    return (
        <Box>
            <Box marginBottom={12}>
                <Typography variant="h2">
                    {t(
                        "portfolioDashboard.header.title",
                        "Your Portfolio Overview"
                    )}
                </Typography>
                <Typography variant="h5" color="grey.400" mt={2}>
                    {t("portfolioDashboard.header.subTitle", "Last Updated")}:{" "}
                    {DateTime.local().plus({ days: -1 }).toLocaleString()}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <NavTabs tabs={tabLinks} />
                    </Box>

                    <YearAndAssessmentTypeFilter
                        activeType={assessmentType}
                        onAssessmentTypeChange={setAssessmentType}
                        isYearSelectorHidden={pathname === tabLinks[0].path}
                    />
                </Box>
            </Box>

            <MainPortfolioContainer>
                <Outlet />
            </MainPortfolioContainer>
        </Box>
    );
};

export default PortfolioDashboard;
