import { Box, Menu, MenuItem } from "@mui/material";
import { PropsWithChildren, useState } from "react";

type Props<T extends string> = {
    enabled?: boolean;
    options: { label: string; value: T }[];
    onChange: (value: T) => void;
};

function ContextMenu<T extends string>(props: PropsWithChildren<Props<T>>) {
    const { options, onChange, enabled = true } = props;

    const [position, setPosition] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        if (!enabled) return;

        setPosition(
            position === null
                ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 }
                : null
        );
    };

    const handleClose = (value?: T) => {
        if (value !== undefined) onChange(value);
        setPosition(null);
    };

    return (
        <Box
            onContextMenu={handleContextMenu}
            style={{ cursor: "context-menu" }}
        >
            {props.children}
            <Menu
                open={position !== null}
                onClose={() => handleClose()}
                anchorReference="anchorPosition"
                sx={{ "& .MuiPaper-root": { padding: 0 } }}
                anchorPosition={
                    position !== null
                        ? { top: position.mouseY, left: position.mouseX }
                        : undefined
                }
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.value}
                        onClick={() => handleClose(option.value)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default ContextMenu;
