import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";

import { AverageEmissionByMonth } from "./monthlyBuildingAverageChart.types";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";
import {
    getAssetEmissionSummariesEmissionByMonth,
    getMonthlyChartData,
} from "../helpers/monthlyChart.helpers";
import { EmissionSummaryLocation } from "../types/monthlyChart.types";
import { getAverageEmissionByMonth } from "../utils/buildingAverageByMonthChart.utils";

type Props = {
    emissionSummaries?: EmissionSummaryLocation[];
};

export const MonthlyBuildingAverageChart = (props: Props) => {
    const { emissionSummaries = [] } = props;

    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();

    const { series, xAxis, yAxis } = useMemo(() => {
        const assetEmissionByMonth =
            getAssetEmissionSummariesEmissionByMonth(emissionSummaries);

        const averageEmissionsByMonth =
            getAverageEmissionByMonth(assetEmissionByMonth);

        return getMonthlyChartData<AverageEmissionByMonth>(
            averageEmissionsByMonth,
            "emissionPerArea",
            t("portfolioDashboard.boxes.average", "Average"),
            activeYear,
            t
        );
    }, [emissionSummaries, activeYear, t]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
