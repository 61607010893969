import { Clear } from "@mui/icons-material";
import { Box, capitalize, Typography } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    DrawerHeader,
    DrawerSubTitle,
    StyledCloseButton,
    StyledDrawer,
} from "./benchmark-drawer.styles";

type Props = {
    title: string;
    isOpen: boolean;
    onClose: () => void;
};

export function BenchmarkDrawer(props: PropsWithChildren<Props>) {
    const { title, isOpen, onClose, children } = props;

    const { t } = useTranslation();

    const [toggleDrawer, setToggleDrawer] = useState(false);

    function onCloseDrawer() {
        setToggleDrawer(!toggleDrawer);
        onClose();
    }

    useEffect(() => {
        setToggleDrawer(isOpen);
    }, [isOpen]);

    return (
        <StyledDrawer
            anchor="right"
            open={toggleDrawer}
            onClose={onCloseDrawer}
        >
            <DrawerHeader>
                <DrawerSubTitle>
                    {capitalize(t("benchmark.plural", "Benchmarks"))}
                </DrawerSubTitle>
                <Typography variant="h2">{title}</Typography>

                <StyledCloseButton onClick={onCloseDrawer}>
                    <Clear />
                </StyledCloseButton>
            </DrawerHeader>
            <Box sx={{ paddingX: "1rem" }}>{children}</Box>
        </StyledDrawer>
    );
}
