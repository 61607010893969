import { useMutation } from "@apollo/client";
import { Clear } from "@mui/icons-material";
import {
    Dialog,
    Typography,
    TextField,
    Button,
    DialogTitle,
    DialogContent,
    IconButton,
    DialogActions,
    Box,
    Checkbox,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import FromToDatePicker from "components/DatePicker/FromToDatePicker";
import {
    CREATE_REPORT,
    SAVED_REPORTS,
} from "containers/ReportsListPage/reportListQueries";
import {
    AssessmentDataType,
    CreateReportMutation,
    CreateReportMutationVariables,
    GetSavedReportsQuery,
    ReportFramework,
    ReportLanguage,
} from "graphql-types/graphql";

import { Confirmation } from "./components/Confirmation";
import { downloadReport } from "../report.helpers";
import { DateContext } from "../reportDateContext";
import {
    getReportDateInterval,
    getSourceText,
    getTranslatedFrameworkName,
} from "../reportPageHelpers";

type ReportModalProps = {
    isOpen: boolean;
    reportType: ReportFramework;
    source: AssessmentDataType;
    dateContext: DateContext;
    onClose: () => void;
};

export const ReportModal = ({
    isOpen,
    dateContext,
    reportType,
    source,
    onClose,
}: ReportModalProps) => {
    const { t, i18n } = useTranslation(["report"]);

    const [activeAssetGroup] = useActiveAssetGroup();

    const [note, setNote] = useState("");
    const [includeAuditInventory, setIncludeAuditInventory] = useState(false);

    const [createReportMutation] = useMutation<
        CreateReportMutation,
        CreateReportMutationVariables
    >(CREATE_REPORT, {
        update(cache, { data }) {
            const reports = cache.readQuery<GetSavedReportsQuery>({
                query: SAVED_REPORTS,
            });
            if (!data || !reports) {
                return;
            }

            cache.writeQuery<GetSavedReportsQuery>({
                query: SAVED_REPORTS,
                data: {
                    getActiveReports: reports.getActiveReports.concat([
                        data.createReport,
                    ]),
                },
            });
        },
        context: { headers: { "x-lang": i18n.language } },
    });

    const reportInterval = useMemo(
        () => getReportDateInterval(dateContext),
        [dateContext]
    );

    const handleCancel = useCallback(() => {
        setNote("");
        onClose();
    }, [onClose]);

    const handleSaveAndDownload = useCallback(() => {
        const segmentName = activeAssetGroup.name;
        const numberOfAssets = activeAssetGroup.count;

        const language = i18n.language.toUpperCase() as ReportLanguage;

        handleCancel();
        toast.promise(
            createReportMutation({
                variables: {
                    reportInput: {
                        note,
                        source,
                        segmentName,
                        numberOfAssets,
                        framework: reportType,
                        from: dateContext.from,
                        to: dateContext.to,
                        language,
                        includeAuditInventory,
                    },
                },
            }).then((response: any) => {
                downloadReport(response.data.createReport, t);
            }),
            {
                pending: t("labels.loading", "Loading..."),
                success: {
                    render() {
                        return <Confirmation />;
                    },
                    icon: false,
                    closeButton: false,
                    autoClose: 10000,
                    type: "default",
                },
                error: t(
                    "reportsList.errors.saveAndDownload",
                    "An error occurred trying to save and download the report. Please try again later."
                ),
            }
        );
    }, [
        createReportMutation,
        note,
        source,
        reportType,
        dateContext,
        i18n,
        t,
        handleCancel,
        includeAuditInventory,
        activeAssetGroup,
    ]);

    const handleAuditInventoryChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIncludeAuditInventory(event.target.checked);
        },
        []
    );

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{ m: 0, "& .MuiDialog-paper": { p: "24px 28px" } }}
        >
            <DialogTitle sx={{ pb: 0 }} variant="h3">
                {getTranslatedFrameworkName(t, reportType)}
            </DialogTitle>
            <IconButton
                onClick={onClose}
                sx={{ position: "absolute", right: 40, top: 32 }}
            >
                <Clear />
            </IconButton>

            <DialogContent sx={{ fontSize: 14 }}>
                <Typography fontWeight={700} gutterBottom>
                    {t("columns.dataSource", "Data Source")}
                </Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={getSourceText(source, t)}
                    disabled
                />

                <Typography fontWeight={700} sx={{ mt: 8 }} gutterBottom>
                    {t("columns.timePeriod", "Time Period")}
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 4,
                    }}
                >
                    {reportInterval.to ? (
                        <FromToDatePicker
                            disabled
                            from={reportInterval.from}
                            to={reportInterval.to ?? null}
                            onChange={() => null}
                        />
                    ) : (
                        <TextField
                            size="small"
                            fullWidth
                            value={reportInterval.from.year}
                            disabled
                        />
                    )}
                </Box>
                <Typography variant="body2" gutterBottom>
                    {t(
                        "modalDescription.1",
                        "To edit the details above, please close and update it on the reports page. Then click Save and Download again.",
                        { ns: "report" }
                    )}
                </Typography>
                <Typography fontWeight={700} sx={{ mt: 8 }} gutterBottom>
                    {t(
                        "modalDescription.includeAuditInventoryTitle",
                        "Full inventory for auditors"
                    )}
                </Typography>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Checkbox
                        checked={includeAuditInventory}
                        onChange={handleAuditInventoryChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ padding: 0 }}
                    />
                    <Typography variant="body2">
                        {t(
                            "modalDescription.includeAuditInventoryCheckbox",
                            "Include in download"
                        )}
                    </Typography>
                </Box>
                <Typography fontWeight={700} sx={{ mt: 8 }} gutterBottom>
                    {t("labels.addNote", "Add a note (Optional)")}
                </Typography>
                <TextField
                    size="small"
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <Typography variant="body2">
                    {t(
                        "modalDescription.2",
                        "Once saved, this report will be added to your “Saved Reports” list.",
                        {
                            ns: "report",
                        }
                    )}
                </Typography>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", pt: 4 }}>
                <Button variant="outlined" onClick={handleCancel}>
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button variant="contained" onClick={handleSaveAndDownload}>
                    {t("labels.saveAndDownload", "Save and Download")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
