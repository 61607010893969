import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime, Interval } from "luxon";

export type DateInterval = { from: string; to: string };

export enum DateRange {
    YEARS_INTO_PAST = 10,
}

export const getStartAndEndOfYear = (year: number) => {
    const fromDateTime = DateTime.fromObject({ year }, { zone: "utc" });
    return {
        from: fromDateTime.toISO() || "",
        to: fromDateTime.endOf("year").toISO() || "",
    };
};
export const MONTHS_IN_YEAR = 12 as const;

export const formatDate = (date: string | DateTime) => {
    if (date instanceof DateTime) {
        return date.toLocaleString(DateTime.DATE_SHORT);
    }

    return DateTime.fromISO(date.split("T")[0], {
        zone: "local",
    }).toLocaleString(DateTime.DATE_SHORT);
};

export const formatInputDate = (date: string | DateTime) => {
    // Using { zone: utc } prevent it from using local timezone and going ahead of time
    if (date instanceof DateTime) {
        return date.toISODate();
    }
    return DateTime.fromISO(date, { zone: "utc" }).toISODate();
};

export const convertToInclusiveDateTime = (date: DateTime) => {
    return DateTime.fromISO(date.toISO() ?? "", {
        zone: "utc",
    }).endOf("day");
};

export const validateAndFormatDateString = (dateString: string) => {
    if (DateTime.fromISO(dateString).isValid) return formatDate(dateString);

    return null;
};

export const getReportingYear = (reportingYear?: number | null) => {
    return reportingYear
        ? reportingYear
        : DateTime.local().year - DateRange.YEARS_INTO_PAST;
};

export const createYearRange = (startYear: number, endYear?: number) => {
    const lastYear = endYear ? endYear : DateTime.local().year;
    const yearRange = _.range(startYear, lastYear + 1).map((number) => {
        return { label: number.toString(), value: number };
    });

    return yearRange.reverse();
};

const getDaysPassedInCurrentYear = () => {
    const today = new Date();
    const firstDayOfCurrentYear = new Date(new Date().getFullYear(), 0, 1);

    const diffTime = Math.abs(
        today.getTime() - firstDayOfCurrentYear.getTime()
    );
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
};

const getDaysInYear = (year: number) => {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
};

export const getYTDAmountOfValue = (value: number) => {
    const currentYear = new Date().getFullYear();
    const daysInCurrentYear = getDaysInYear(currentYear);
    const daysPassed = getDaysPassedInCurrentYear();

    return (value / daysInCurrentYear) * daysPassed;
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

export const getYearLabel = (year: string, t: TFunction) => {
    const currentYear = new Date().getFullYear();

    if (year === currentYear.toString()) {
        return year + t("portfolioDashboard.yearSelect.ytd", " YTD");
    }

    return year;
};

export const getIntervals = (input: DateInterval[]) => {
    return input.map((interval) => {
        return Interval.fromDateTimes(
            DateTime.fromISO(interval.from),
            DateTime.fromISO(interval.to)
        );
    });
};

export const areIntervalsOverlapping = (
    intervals: Interval[],
    comparisonIntervals?: Interval[]
) => {
    if (comparisonIntervals) {
        return intervals.some((interval) => {
            return comparisonIntervals.some((comparisonInterval) => {
                return interval.overlaps(comparisonInterval);
            });
        });
    } else {
        return intervals.some((interval, index) => {
            return intervals.some((intervalCompare, indexCompare) => {
                if (index === indexCompare) return false;
                return interval.overlaps(intervalCompare);
            });
        });
    }
};

export const toDate = (dateString: string) => {
    return DateTime.fromISO(dateString, { zone: "utc" });
};

export const isYearInRange = (
    year: number,
    from: string,
    to: string
): boolean => {
    return year >= toDate(from).year && year <= toDate(to).year;
};
