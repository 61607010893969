import { ActiveBenchmarkProvider } from "components/BenchmarkSelect/BenchmarkSelect";

import AssetListData from "../../components/AssetList/AssetListData";

function AssetListPage() {
    return (
        <ActiveBenchmarkProvider>
            <AssetListData />
        </ActiveBenchmarkProvider>
    );
}

export default AssetListPage;
