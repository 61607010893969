import { gql } from "@apollo/client";

export const createTargetSetting = gql`
    mutation createTargetSetting($input: CreateOneTargetSettingInput!) {
        createOneTargetSetting(input: $input) {
            id
        }
    }
`;

export const updateTargetSetting = gql`
    mutation updateTargetSetting($input: UpdateOneTargetSettingInput!) {
        updateOneTargetSetting(input: $input) {
            id
        }
    }
`;

export const deleteTargetSetting = gql`
    mutation deleteTargetSetting($input: DeleteOneTargetSettingInput!) {
        deleteOneTargetSetting(input: $input) {
            id
        }
    }
`;
