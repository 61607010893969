import { Help } from "@mui/icons-material";
import {
    Box,
    Paper,
    styled,
    Typography,
    Popover,
    IconButton,
    ListItem,
    List,
} from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { TargetType } from "graphql-types/graphql";

import useTargetSettings from "../TargetSettings/useTargetSettings";
interface InputProps {
    baselineYear: number;
}

const BlockLabel = styled(Paper)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 4),
    width: "100%",
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
    "& .MuiPaper-root": {
        width: 500,
        minWidth: 500,
        maxWidth: 600,
        padding: theme.spacing(4, 6),

        fontSize: 15,
        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: "list-item",
    padding: theme.spacing(0.5, 0),
}));

const StyledInnerList = styled(List)(({ theme }) => ({
    listStyleType: "lower-alpha",
    paddingLeft: 24,
    paddingTop: 0,

    ":last-child": { paddingBottom: 0 },
}));

const TargetDegreePopover = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
        setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                sx={{ "&:focus": { outline: "none" } }}
                size="small"
                onClick={handleClick}
            >
                <Help sx={{ fontSize: "1.25em" }} />
            </IconButton>
            <StyledPopover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Trans i18nKey="forecastDashboard.labels.targetDegreePopover">
                    Our degree pathways are calculated based on CRREM’s 2023
                    pathways for the building sector. The methodology is based
                    on:
                    <List sx={{ listStyleType: "decimal", pl: 4 }}>
                        <StyledListItem>
                            The SDA (Sectoral Decarbonization Approach)
                            methodology
                        </StyledListItem>
                        <StyledListItem>
                            The country’s baseline carbon-intensity using:
                            <StyledInnerList>
                                <StyledListItem>
                                    Country building stock in square meters
                                    (residential and commercial Gross Internal
                                    Area (GIA) according to IPMS2).
                                </StyledListItem>
                                <StyledListItem>
                                    Country baseline for EUI (energy use
                                    intensity in kWh/m²) for different
                                    use-types.
                                </StyledListItem>
                                <StyledListItem>
                                    Energy mix / sources used for property
                                    stock.
                                </StyledListItem>
                                <StyledListItem>
                                    Emission factors (EF) of energy sources.
                                </StyledListItem>
                            </StyledInnerList>
                        </StyledListItem>
                        <StyledListItem>
                            The assumption of converging carbon-intensities
                            until 2050 with respect to the global figures of
                            1.5°C scenario on country level using:
                            <StyledInnerList>
                                <StyledListItem>
                                    Projections for energy mix / sources.
                                </StyledListItem>
                                <StyledListItem>
                                    Projections for emission factors for energy
                                    source.
                                </StyledListItem>
                                <StyledListItem>
                                    Growth rate for floor space in different
                                    countries
                                </StyledListItem>
                            </StyledInnerList>
                        </StyledListItem>
                        <StyledListItem>
                            Underlying datasets for the calculation of GHG
                            intensity pathways for individual countries.
                        </StyledListItem>
                        <StyledListItem>
                            Global floor area and growth projections aligned to
                            IEA and individual country data sets when available.
                        </StyledListItem>
                        <StyledListItem>
                            Baseline 2020 buildings’ GHG intensity figures based
                            on energy use intensity and the average energy mix
                            for each country, property type and respective
                            emission factors.
                        </StyledListItem>
                    </List>
                </Trans>
            </StyledPopover>
        </>
    );
};

const TargetEmissionsLabels = ({ baselineYear }: InputProps) => {
    const { t } = useTranslation();
    const { targetSettings, areTargetSettingsShown } = useTargetSettings();

    if (!areTargetSettingsShown || !targetSettings) {
        return null;
    }

    const { type, targetYear, reduction, pathwayName } = targetSettings;

    const isYoY = type === TargetType.YEAR_OVER_YEAR;

    const labels = [
        {
            label: t("columns.baselineYear", "Baseline Year", { ns: "report" }),
            value: baselineYear,
        },
        {
            label: t("forecastDashboard.labels.targetYear", "Target Year"),
            value: targetYear,
        },
        {
            label: t(
                "forecastDashboard.labels.reductionType",
                "Reduction Type"
            ),
            value: isYoY ? "%" : "C°",
        },
        {
            label: isYoY
                ? t("forecastDashboard.labels.reduction", "Reduction")
                : t("forecastDashboard.labels.targetDegree", "Target Degree"),
            value: isYoY ? reduction : `${pathwayName}C`,
        },
    ];

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
            }}
        >
            {labels.map((l) => (
                <BlockLabel key={l.label}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography color="primary">{l.label}</Typography>
                        {l.value === "C°" && <TargetDegreePopover />}
                    </Box>
                    <Typography variant="h2">{l.value}</Typography>
                </BlockLabel>
            ))}
        </Box>
    );
};

export default TargetEmissionsLabels;
