import { chain } from "lodash";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory, CountryCode } from "graphql-types/graphql";
import { useRegionTranslation } from "hooks";

import { FilterOptionSelect } from "../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionCountry(props: Props) {
    const { onChange } = props;

    const { getRegionName } = useRegionTranslation();

    const countries = chain(CountryCode)
        .pick(["DK", "GB", "GR"])
        .map((countryKey) => {
            return {
                key: countryKey,
                value: getRegionName(countryKey),
            };
        })
        .value();

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.COUNTRY,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect
            list={countries}
            onSelectedValue={onSelectedValue}
        />
    );
}
