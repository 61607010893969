import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";

import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { getStartAndEndOfYear } from "utils/date.utils";

export interface DateContext {
    from: string;
    to: string;
    dateContextType: DateContextType;
}
export interface ReportDateContext extends DateContext {
    setDateContextType: (newType: DateContextType) => void;
    setSelectedDates: (from: string, to: string) => void;
}

export enum DateContextType {
    YEAR = "YEAR",
    DATE_RANGE = "DATE_RANGE",
}

const SelectedReportDateContext = createContext<ReportDateContext | null>(null);

export const useReportDateContext = () => {
    const context = useContext(SelectedReportDateContext);

    if (!context) {
        throw new Error(
            "useReportDateContext must be used within a ReportDateContextProvider"
        );
    }

    return context;
};

interface InputProps {
    children: React.ReactNode;
}

function ReportDateContextProvider(props: InputProps) {
    const { activeYear, setActiveYear } = useSelectedYearContext();
    const [dateContextType, setDateContextType] = useState<DateContextType>(
        DateContextType.YEAR
    );

    const yearRange = getStartAndEndOfYear(activeYear);
    const [from, setFrom] = useState(yearRange.from);
    const [to, setTo] = useState(yearRange.to);

    const handleFromToChangeChange = useCallback(
        (newFrom: string, newTo: string) => {
            setFrom(newFrom);
            setTo(newTo);
        },
        [setFrom, setTo]
    );

    const handleSetDateContextType = useCallback(
        (newType: DateContextType) => {
            if (newType === DateContextType.YEAR) {
                setActiveYear(parseInt(from.split("-")[0], 10));
            }
            setDateContextType(newType);
        },
        [setDateContextType, setActiveYear, from]
    );

    useEffect(() => {
        if (dateContextType === DateContextType.YEAR) {
            const newFromTo = getStartAndEndOfYear(activeYear);
            setFrom(newFromTo.from);
            setTo(newFromTo.to);
        }
    }, [dateContextType, setFrom, setTo, activeYear]);

    const value = {
        from,
        to,
        dateContextType,
        setDateContextType: handleSetDateContextType,
        setSelectedDates: handleFromToChangeChange,
    };

    const { children } = props;

    return (
        <SelectedReportDateContext.Provider value={value}>
            {children}
        </SelectedReportDateContext.Provider>
    );
}

export default ReportDateContextProvider;
