import { useTheme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { sum } from "lodash";
import { useTranslation } from "react-i18next";

import {
    benchmarksToSeries,
    legacyBenchmarkToSeries,
} from "components/PortfolioDashboard/Charts/utils";
import { BenchmarkMarkers } from "components/types";
import { Chart } from "glue/Chart";
import {
    assessmentVerticalToColor,
    assessmentVerticalToTitle,
} from "utils/assessment";

import { AssessmentChartTooltip } from "./MUIAssessmentChartTooltip";
import { AssessmentStats, OrderedAssessmentVerticals } from "../types";

type Props = {
    isYearly: boolean;
    benchmarkMarkers: BenchmarkMarkers;
    assessmentsData: AssessmentStats[];
};

export const MUIAssessmentChart = (props: Props) => {
    const { assessmentsData, benchmarkMarkers, isYearly } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();

    const labels = assessmentsData.map((assessment) => assessment.chartLabel);

    const series = OrderedAssessmentVerticals.flatMap((vertical) => {
        const data = assessmentsData.map(
            (assessment) => assessment.data?.[vertical]?.emission || null
        );

        if (sum(data) === 0) {
            return [];
        }

        return {
            type: "bar",
            label: assessmentVerticalToTitle(vertical, t),
            stack: "total",
            id: vertical,
            color: palette[assessmentVerticalToColor(vertical)]?.main,
            data,
        } as BarSeriesType;
    });

    const projected = assessmentsData.map(
        (assessment) => assessment.projectedTotal || 0
    );

    const benchmarkSeries = benchmarksToSeries(
        benchmarkMarkers,
        assessmentsData.length,
        true,
        t
    );

    const legacyBenchmarkSeries = legacyBenchmarkToSeries(
        assessmentsData.map((assessment) => Number(assessment.id)),
        benchmarkMarkers,
        true,
        t
    );

    const chartSeries = [...benchmarkSeries, ...legacyBenchmarkSeries];

    return (
        <Chart
            customTooltip={AssessmentChartTooltip}
            margin={{ left: 75 }}
            series={[
                ...series,
                {
                    type: "bar",
                    label: t(
                        "forecastDashboard.labels.projectedEmissions",
                        "Projected Emissions"
                    ),
                    stack: "total",
                    id: "projected",
                    data: projected,
                    color: palette.secondary.main,
                } as BarSeriesType,
                ...(!isYearly ? chartSeries : []),
            ]}
            xAxis={[{ data: labels, scaleType: "band" }]}
            yAxis={[
                {
                    label: "kg CO2e",
                    labelStyle: {
                        lineHeight: 10,
                        //place label at the bottom of the axis
                        transform: "translateX(-20px)",
                    },
                },
            ]}
        />
    );
};
