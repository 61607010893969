import { gql } from "@apollo/client";

export const LOCATIONS_QUERY = gql`
    query GetAssetsGroupLocations {
        me {
            id
            organization {
                getLocations {
                    id
                    name
                    displayName
                    externalId
                }
            }
        }
    }
`;
