import { Box, MenuItem, TextField } from "@mui/material";

import InfoPopover from "components/Popovers/InfoPopover";

interface TextDropdownProps<T> {
    value: T;
    position?: "left" | "right";
    options: { title: string; value: T; explainer?: string }[];
    width?: number | string;
    onChange: (newValue: T) => void;
}

export default function TextDropdown<T extends number | string>({
    value,
    onChange,
    options,
    width,
}: TextDropdownProps<T>) {
    const explainer = options.find(
        (option) => option.value === value
    )?.explainer;

    return (
        <Box display={"flex"} alignItems={"center"} gap={2}>
            <TextField
                variant="outlined"
                size="small"
                style={{ width: width ?? 260 }}
                value={value}
                onChange={(e) => onChange(e.target.value as T)}
                select
            >
                {options.map(({ title, value }) => (
                    <MenuItem key={value} value={value}>
                        {title}
                    </MenuItem>
                ))}
            </TextField>

            {explainer && <InfoPopover element={explainer} />}
        </Box>
    );
}
