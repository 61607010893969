import { PropsWithChildren } from "react";

import { Vertical } from "./Vertical";
import { Popover } from "./VerticalPopover";
import { S } from "./verticals-coverage.styles";

export const VerticalCoverage = (props: PropsWithChildren) => {
    const { children } = props;
    return <S.Verticals>{children}</S.Verticals>;
};

VerticalCoverage.Vertical = Vertical;
VerticalCoverage.Popover = Popover;
