import ErrorIcon from "@mui/icons-material/Error";
import { Chip, Tooltip } from "@mui/material";

export const ErrorLabel = ({
    tooltip,
    label,
}: {
    tooltip: string;
    label: string;
}) => (
    <Tooltip placement="top" arrow title={tooltip}>
        <Chip
            sx={{ cursor: "default" }}
            label={label}
            color="error"
            variant="outlined"
            icon={<ErrorIcon fontSize="small" />}
        />
    </Tooltip>
);
