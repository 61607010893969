import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const ReportDeleteModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: (isReportToBeDeleted: boolean) => void;
}) => {
    const { t } = useTranslation(["report"]);

    return (
        <Dialog open={isOpen} sx={{ "& .MuiDialog-paper": { maxWidth: 400 } }}>
            <DialogTitle variant="h4">
                {t("labels.confirmationQuestion", "Delete report")}
            </DialogTitle>
            <DialogContent dividers>
                {t(
                    "labels.deleteReportConfirmation",
                    "Are you sure you want to delete this entry? This cannot be undone."
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={() => onClose(false)}>
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onClose(true)}
                    color="error"
                >
                    {t("labels.delete", "Delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
