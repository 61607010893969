import { PropsWithChildren } from "react";

import { PropertyAllocationItem } from "./property-allocation.types";
import { PropertyAllocationViewField } from "./PropertyAllocationViewField";

type Props = {
    data: PropertyAllocationItem[];
    disabled?: boolean;
};

export const PropertyAllocationContainer = (
    props: PropsWithChildren<Props>
) => {
    const { disabled, data, children } = props;

    if (disabled) {
        return <PropertyAllocationViewField propertyList={data} />;
    }

    return children;
};
