import { Box, styled } from "@mui/material";

const StyledCard = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    width: "100%",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
}));

interface InputProps {
    children: React.ReactNode;
}

function Card(props: InputProps) {
    return <StyledCard>{props.children}</StyledCard>;
}

export default Card;
