import { Link } from "react-router-dom";

import { StyledTab } from "../navTab.styles";

interface Props {
    label?: string;
    path: string;
    selected?: boolean;
    value: string;
}

export const TabLink = (props: Props) => {
    return (
        <StyledTab
            component={Link}
            aria-current={props.selected && "page"}
            to={props.path}
            {...props}
        />
    );
};
