import { useMutation } from "@apollo/client";
import {
    Alert,
    Button,
    DialogActions,
    FormControl,
    FormLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import { chain } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BaseModal } from "components/Modal/BaseModal";
import { FormField } from "glue/Form/FormField";
import {
    AssessmentVertical,
    ConsumptionType,
    CreateManualConsumptionInput,
    CreateManualConsumptionsMutation,
    CreateManualConsumptionsMutationVariables,
    EmissionUnit,
    UpdateManualConsumptionInput,
    UpdateManualConsumptionMutation,
    UpdateManualConsumptionMutationVariables,
} from "graphql-types/graphql";
import { assessmentVerticalToTitle } from "utils/assessment";
import { formatInputDate } from "utils/date.utils";
import { getDirtyValues } from "utils/forms.utils";

import { ConfirmMultipleEntries } from "./components/ConfirmMultipleEntries";
import { SelectField } from "./components/Fields/SelectField";
import { PeriodEntries } from "./components/PeriodEntry";
import {
    areEntriesOverlapping,
    formatCreateManualEntries,
    formatUpdateManualEntry,
    getCategories,
    getConsumptionTypeUnits,
    resetFormUnitsOnCategoryChange,
} from "./manualConsumptionModal.helpers";
import {
    CREATE_MANUAL_CONSUMPTIONS,
    UPDATE_MANUAL_CONSUMPTION,
} from "./manualConsumptionModal.queries";
import {
    ManualEntryCategory,
    ManualEntryInputs,
} from "./manualConsumptionModal.types";
import { getAssessmentsOverviewCacheKey } from "../../AssetOverview/assetOverviewQuery";
import { ASSET_MANUAL_SOURCES } from "../ManualSourcesTable/manualSourcesTable.query";
import { ManualAssessment } from "../ManualSourcesTable/manualSourcesTable.types";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    assessments: ManualAssessment[];
    locationId: string;
    editEntry?: ManualAssessment | null;
};

export const ManualConsumptionModal = ({
    isOpen,
    onClose,
    assessments,
    locationId,
    editEntry,
}: Props) => {
    const isEditMode = !!editEntry;
    const [createManyManuelConsumptionEntries] = useMutation<
        CreateManualConsumptionsMutation,
        CreateManualConsumptionsMutationVariables
    >(CREATE_MANUAL_CONSUMPTIONS, {
        refetchQueries: [ASSET_MANUAL_SOURCES],
        update(cache) {
            cache.evict({
                id: getAssessmentsOverviewCacheKey(locationId),
            });
        },
    });

    const [updateManualMeterEntry] = useMutation<
        UpdateManualConsumptionMutation,
        UpdateManualConsumptionMutationVariables
    >(UPDATE_MANUAL_CONSUMPTION, {
        refetchQueries: [ASSET_MANUAL_SOURCES],
        update(cache) {
            cache.evict({
                id: getAssessmentsOverviewCacheKey(locationId),
            });
        },
    });

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedVertical, setSelectedVertical] = useState<
        AssessmentVertical | undefined
    >();
    const { t } = useTranslation();

    const allCategories: ManualEntryCategory[] = getCategories(t);

    const initialValues = useMemo(() => {
        const consumptionType =
            (editEntry?.consumptionType as ConsumptionType) ??
            ("" as ConsumptionType);

        if (editEntry) {
            const vertical = allCategories.find(
                (category) => category.consumptionType === consumptionType
            )?.vertical;

            setSelectedVertical(vertical);
        }

        const filename = editEntry?.origin?.file?.name;

        const initialPeriod = {
            from: formatInputDate(editEntry?.from) ?? "",
            to: formatInputDate(editEntry?.to) ?? "",
            consumption: editEntry?.consumption ?? 0,
            emissionUnit: editEntry?.unit ?? ("" as EmissionUnit),
            file: filename ? ({ name: filename } as File) : undefined,
        };

        const identifier =
            editEntry?.data && "identifier" in editEntry.data
                ? editEntry.data.identifier ?? ""
                : "";

        return {
            editId: editEntry?.id,
            identifier,
            periods: [initialPeriod],
            consumptionType,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editEntry]);

    const verticals = chain(allCategories)
        .map(({ vertical }) => ({
            value: vertical,
            label: assessmentVerticalToTitle(vertical as AssessmentVertical, t),
        }))
        .unionBy("value")
        .sortBy("label")
        .value();

    const {
        control,
        watch,
        handleSubmit,
        clearErrors,
        setValue,
        reset,
        formState: { errors, dirtyFields, isDirty },
    } = useForm<ManualEntryInputs>({
        values: initialValues,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "periods",
    });

    const hasErrors = Object.keys(errors).length > 0;

    const consumptionType = watch("consumptionType");
    const categories = useMemo(() => {
        return allCategories.filter(
            (category) => category.vertical === selectedVertical
        );
    }, [selectedVertical, allCategories]);

    const units = useMemo(() => {
        return getConsumptionTypeUnits(consumptionType, t);
    }, [consumptionType, t]);

    useEffect(() => {
        resetFormUnitsOnCategoryChange(fields, units, setValue);
    }, [units, fields, setValue]);

    const handleCancel = () => {
        onClose();
        clearErrors();
        reset();

        setSelectedVertical(undefined);
    };

    const onSubmit: SubmitHandler<ManualEntryInputs> = useCallback(
        async (data) => {
            let mutation;
            let input:
                | CreateManualConsumptionInput[]
                | UpdateManualConsumptionInput;

            const hasOverlap = areEntriesOverlapping(data, assessments);

            if (hasOverlap && !confirmOpen) return setConfirmOpen(true);

            if (data.editId) {
                const updatedData = getDirtyValues(dirtyFields, data);
                input = formatUpdateManualEntry(updatedData, data.editId);

                mutation = updateManualMeterEntry({
                    variables: {
                        input,
                    },
                });
            } else {
                input = formatCreateManualEntries(data, locationId);
                mutation = createManyManuelConsumptionEntries({
                    variables: {
                        input,
                    },
                });
            }

            toast.promise(
                mutation.then(() => {
                    setConfirmOpen(false);
                    onClose();
                    reset();
                }),
                {
                    pending: t("labels.loading", "Loading..."),
                    success: {
                        render() {
                            return t(
                                "manualSources.modal.success",
                                "Successfully saved entry",
                                {
                                    count: Array.isArray(input)
                                        ? input.length
                                        : 1,
                                }
                            );
                        },
                    },
                    error: t(
                        "common.toast.error",
                        "Something went wrong, please try again"
                    ),
                }
            );
        },
        [
            dirtyFields,
            assessments,
            locationId,
            confirmOpen,
            onClose,
            setConfirmOpen,
            createManyManuelConsumptionEntries,
            t,
            updateManualMeterEntry,
            reset,
        ]
    );

    const modalTitle = isEditMode
        ? t("manualSources.modal.editTitle", "Edit Manual Meter Entry")
        : t("manualSources.modal.title", "Manually Add Meter Entry");

    return (
        <BaseModal
            title={modalTitle}
            handleClose={handleCancel}
            open={isOpen}
            maxWidth="md"
            fullWidth
        >
            <Alert icon={false} severity="info" sx={{ mb: 5 }}>
                {t(
                    "manualSources.modal.note",
                    "Please note: All manual meter data with documentation will be classified as medium quality. Entries without documentation will be classified as low quality due to compliance and audit standards."
                )}
            </Alert>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container columnSpacing={5} rowSpacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{ mb: 1 }}>
                                {t("common.labels.vertical", "Vertical")}
                            </FormLabel>
                            <SelectField
                                ref={null}
                                disabled={isEditMode}
                                selected={selectedVertical}
                                options={verticals}
                                placeholder={t(
                                    "dataPage.selectVertical",
                                    "Please select a vertical"
                                )}
                                onChange={(value) => {
                                    setValue(
                                        "consumptionType",
                                        "" as ConsumptionType
                                    );
                                    setSelectedVertical(value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormField
                            label={t("common.labels.category", "Category")}
                            name="consumptionType"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <SelectField
                                    {...field}
                                    selected={consumptionType}
                                    disabled={!selectedVertical || isEditMode}
                                    hasError={!!errors.consumptionType}
                                    placeholder={t(
                                        "dataPage.selectCategory",
                                        "Please select a category"
                                    )}
                                    options={categories.map((cat) => ({
                                        label: cat.label,
                                        value: cat.consumptionType,
                                    }))}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormField
                            label={`${t(
                                "common.labels.identifier",
                                "Identifier"
                            )} (${t("common.labels.optional", "Optional")})`}
                            control={control}
                            name="identifier"
                            render={({ field }) => (
                                <TextField
                                    placeholder="Ex: 1234abc"
                                    {...field}
                                    size="small"
                                    type="text"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <PeriodEntries
                    control={control}
                    units={units}
                    errors={errors}
                    remove={remove}
                    isEditMode={isEditMode}
                    fields={fields}
                />

                {!isEditMode && (
                    <Link
                        component="button"
                        underline="always"
                        sx={{ mt: 2 }}
                        type="button"
                        onClick={() => append(initialValues.periods)}
                    >
                        {t(
                            "manualSources.modal.addAnotherPeriod",
                            "add another entry"
                        )}
                    </Link>
                )}
                {hasErrors && (
                    <Alert
                        severity="error"
                        variant="filled"
                        sx={{
                            fontWeight: "bold",
                            py: "6px",
                            px: "16px",
                            width: "fit-content",
                            mx: "auto",
                            mb: 3,
                        }}
                    >
                        {t(
                            "manualSources.modal.errorWarning",
                            "Fields marked in red must be filled in."
                        )}
                    </Alert>
                )}
                <Typography align="center" sx={{ pt: 2, px: 4 }}>
                    {t(
                        "manualSources.modal.info",
                        "Once saved you are able to edit or delete this entry in the manual sources tab."
                    )}
                </Typography>
                <DialogActions sx={{ justifyContent: "center", pb: 6, pt: 3 }}>
                    <Button variant="outlined" onClick={handleCancel}>
                        {t("common.actions.cancel", "Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isEditMode && !isDirty}
                    >
                        {t("common.actions.save", "Save")}
                    </Button>
                </DialogActions>
            </form>
            <ConfirmMultipleEntries
                isOpen={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onContinue={handleSubmit(onSubmit)}
            />
        </BaseModal>
    );
};
