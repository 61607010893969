import { DesignServices } from "@mui/icons-material";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

type Props = {
    isOpen: boolean;
    onContinue: () => void;
    onClose: () => void;
};

export const ConfirmMultipleEntries = ({
    isOpen,
    onContinue,
    onClose,
}: Props) => {
    const { t } = useTranslation();
    return (
        <Dialog open={isOpen} onClose={onClose} sx={{ textAlign: "center" }}>
            <DesignServices
                color="primary"
                fontSize="large"
                sx={{ margin: "auto", mt: 6 }}
            />
            <DialogTitle variant="h2">
                {t(
                    "manualSources.modal.confirm.title",
                    "There are multiple manual meter entries during the period you selected."
                )}
            </DialogTitle>
            <DialogContent>
                <Trans i18nKey="manualSources.modal.confirm.content">
                    Proceed to click <b>continue</b>, if you would like the
                    entries to be summed.
                    <br />
                    To modify existing entry, click <b>cancel</b>and edit on the
                    manual data list.
                </Trans>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 6, pt: 3 }}>
                <Button variant="outlined" onClick={onClose}>
                    {t("common.actions.cancel", "Cancel")}
                </Button>
                <Button variant="contained" onClick={onContinue}>
                    {t("common.actions.continue", "Continue")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
