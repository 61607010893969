import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Avatar,
    Box,
    Button,
    Container,
    Paper,
    styled,
    TextField,
    Typography,
} from "@mui/material";

import { convertToRem } from "utils/maths";

export const FullSizeContainer = styled(Container)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));

export const BackContainer = styled(Container)({
    display: "grid",
    height: "fit-content",
    width: "fit-width",
    justifyContent: "center",

    alignItems: "center",
});

export const LoginContainer = styled(Paper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(12.5),
    width: theme.spacing(150),
}));

export const ImageBox = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(60),
    height: "100%",
}));

export const ImageContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(0),
    marginBottom: theme.spacing(10),
}));

export const TextTypo = styled(Typography)(({ theme }) => ({
    display: "flex",
    fontSize: theme.typography.h4.fontSize,
    fontWeight: "bold",
}));

export const InputText = styled(TextField)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.spacing(4),

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderWidth: convertToRem(2),
            borderColor: theme.palette.primary.light,
        },
    },
    "& .MuiInputBase-input": {
        padding: theme.spacing(3),
        //remove blue background when autofilled
        "&:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
        },
    },
}));

export const LoginButton = styled(Button)(({ theme }) => ({
    width: "100%",
    height: theme.spacing(12.252),
    marginTop: theme.spacing(6),
    borderRadius: convertToRem(5),
    "&:hover": {
        outline: "none",
    },
}));

export const ForgotPasswordBox = styled(Box)(({ theme }) => ({
    maxWidth: "100vw",
    height: "3rem",
    backgroundColor: "rgba(248,215,218,0.6)",
    paddingLeft: theme.spacing(2),
}));

export const WrongCredentialsHeadline = styled(Typography)(({ theme }) => ({
    width: "fit-width",
    height: convertToRem(19),
    margin: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    textAlign: "left",
    color: theme.palette.error.main,
}));
export const WrongCredentialsText = styled(Typography)(({ theme }) => ({
    width: "fit-width",
    height: convertToRem(19),
    margin: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    textAlign: "left",
    color: theme.palette.error.main,
}));

export const PasswordVisibilityBox = styled(VisibilityIcon)(({ theme }) => ({
    display: "inline-flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fill: "rgba(0,0,0,0.5)",
    padding: theme.spacing(1.2),
    cursor: "pointer",
    border: "3rem",
    "&:hover": {
        fill: "black",
    },
}));
