import { gql } from "@apollo/client";

export const CREATE_MANUAL_CONSUMPTIONS = gql`
    mutation CreateManualConsumptions(
        $input: [CreateManualConsumptionInput!]!
    ) {
        createManualConsumptions(input: $input) {
            id
        }
    }
`;

export const UPDATE_MANUAL_CONSUMPTION = gql`
    mutation UpdateManualConsumption($input: UpdateManualConsumptionInput!) {
        updateManualConsumption(input: $input) {
            id
        }
    }
`;
