import {
    Box,
    FormControlLabel,
    RadioGroup,
    styled,
    Typography,
    Button,
    Link,
    Radio,
    TextField,
    InputAdornment,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type RadioValueType = string | number;
type LabelValueType = { label: string; value: RadioValueType };
type RadioOptions = {
    type: "radio";
    options: LabelValueType[];
};

type NumberOptions = {
    type: "number";
    min: number;
    max: number;
};

export enum CloseActionEnum {
    SAVE = "save",
    CANCEL = "cancel",
    DELETE = "delete",
}

const ModalBox = styled(Box)(({ theme }) => ({
    maxWidth: 720,
    minWidth: 620,
    padding: theme.spacing(2, 0),
}));

const ModalInnerBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 6),

    "&:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
}));

interface LabelsValueType extends LabelValueType {
    onChange: (value: RadioValueType) => void;
    inputOptions: RadioOptions | NumberOptions;
}

const TargetSettingInput = ({
    label,
    value,
    inputOptions,
    onChange,
}: LabelsValueType) => {
    if (inputOptions.type === "number") {
        const { min, max } = inputOptions;

        return (
            <TextField
                size="small"
                type="number"
                value={value}
                inputProps={{ min, max }}
                InputProps={{
                    endAdornment:
                        max === 100 ? (
                            <InputAdornment position="end">%</InputAdornment>
                        ) : null,
                }}
                onChange={(e) =>
                    onChange(e.target.value ? Number(e.target.value) : "")
                }
                onBlur={(e) => {
                    const value = Number(e.target.value);

                    const validValue = Math.min(Math.max(value, min), max);
                    onChange(validValue);
                }}
            />
        );
    }

    return (
        <RadioGroup
            row
            name={label}
            value={value}
            sx={{ p: 0, m: 0 }}
            onChange={(e) => onChange(e.target.value)}
        >
            {inputOptions.options.map(({ value, label }) => (
                <FormControlLabel
                    sx={{ m: 0 }}
                    key={label}
                    label={label}
                    value={value}
                    control={<Radio size="small" />}
                />
            ))}
        </RadioGroup>
    );
};

export const TargetSettingBox = ({
    labels,
    handleClose,
}: {
    labels: (LabelsValueType | LabelValueType)[];
    handleClose: (action: CloseActionEnum) => void;
}) => {
    const { t } = useTranslation();

    const isTargetSettingsFilled = labels.every((label) => !!label.value);

    return (
        <ModalBox>
            {labels.map(({ label, value, ...rest }) => (
                <ModalInnerBox key={label}>
                    <Typography variant="h5" fontWeight={700} color="grey.600">
                        {label}
                    </Typography>

                    {"inputOptions" in rest ? (
                        <TargetSettingInput
                            label={label}
                            value={value}
                            {...rest}
                        />
                    ) : (
                        <b>{value}</b>
                    )}
                </ModalInnerBox>
            ))}

            <ModalInnerBox>
                <Button
                    color="error"
                    onClick={() => handleClose(CloseActionEnum.CANCEL)}
                >
                    {t("settings.cancel", "Cancel")}
                </Button>
                <Button
                    disabled={!isTargetSettingsFilled}
                    color="primary"
                    variant="contained"
                    onClick={() => handleClose(CloseActionEnum.SAVE)}
                >
                    {t("forecastDashboard.labels.setTarget", "Set Target")}
                </Button>
            </ModalInnerBox>
        </ModalBox>
    );
};

export const NoTargetSettingBox = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <ModalBox>
            <ModalInnerBox>
                <Typography variant="h3">
                    {t(
                        "forecastDashboard.labels.noTarget",
                        "Begin adding targets!"
                    )}
                </Typography>
            </ModalInnerBox>
            <ModalInnerBox sx={{ display: "block" }}>
                <Typography mb={2}>
                    <Trans i18nKey="forecastDashboard.labels.noTargetDescription">
                        Target setting begins with defining your baseline year.
                        You may do this in your
                        <Link onClick={() => navigate("/settings")}>
                            Account Settings
                        </Link>
                        .
                    </Trans>
                </Typography>
                <Typography>
                    <Trans i18nKey="forecastDashboard.labels.whatIsBaselineYear">
                        <b>What is a baseline year?</b>
                        <br />A Baseline Year is a historic point of comparison
                        that you can use to track changes and improvements of
                        your property over time. Best practice is to select the
                        first year for which your property has 12 full months of
                        data.
                    </Trans>
                </Typography>
            </ModalInnerBox>
        </ModalBox>
    );
};
