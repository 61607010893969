import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";

import { UnitMass } from "components/AssessmentStats/types";
import {
    getTotalEmissionBySize,
    getTotalForecastedEmissionBySize,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import {
    AbsoluteLineSeriesType,
    xAxisBandConfig,
} from "glue/Chart/chart.types";
import { AssessmentDataType } from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import { YearlyIntensitySeriesType } from "./yearlyIntensityChart.types";
import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";

export function getYearlyPortfolioIntensityChartData(
    t: TFunction,
    activeDataType: AssessmentDataType,
    assessmentSummaries: SummaryYearOverview[]
): {
    series: YearlyIntensitySeriesType[];
    xAxis: xAxisBandConfig[];
    years: number[];
} {
    const filteredLocationsByYear = assessmentSummaries.filter(
        ({ locations }) => {
            const totalEmissionPerArea = getTotalEmissionBySize(
                locations,
                activeDataType
            );
            return totalEmissionPerArea !== null;
        }
    );

    const xAxisData = filteredLocationsByYear.map(({ year }) => year);
    const seriesData = filteredLocationsByYear.map(({ locations }) => {
        const totalEmissionPerArea = getTotalEmissionBySize(
            locations,
            activeDataType
        );
        return totalEmissionPerArea
            ? getConvertedGramsToMassUnit(
                  totalEmissionPerArea,
                  UnitMass.KILOGRAM
              )
            : null;
    });
    const projectedSeriesData = filteredLocationsByYear.map((summedYear) => {
        const isCurrentYear = getCurrentYear() === summedYear.year;
        const projectedTotalEmissionPerArea = isCurrentYear
            ? getTotalForecastedEmissionBySize(summedYear)
            : null;

        const totalEmissionPerArea = getTotalEmissionBySize(
            summedYear.locations,
            activeDataType
        );

        const calculatedProjectedEmissions =
            projectedTotalEmissionPerArea &&
            totalEmissionPerArea &&
            projectedTotalEmissionPerArea - totalEmissionPerArea;

        if (!calculatedProjectedEmissions || calculatedProjectedEmissions < 0) {
            return null;
        }

        return getConvertedGramsToMassUnit(
            calculatedProjectedEmissions,
            UnitMass.KILOGRAM
        );
    });

    const assetCountData = filteredLocationsByYear.map(
        ({ assetCount }) => assetCount
    );
    const totalAreaData = filteredLocationsByYear.map(
        ({ totalArea }) => totalArea
    );

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.KILOGRAM, t) || "";

    return {
        series: [
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.intensity", "Intensity"),
                stack: "total",
                id: "emission",
                data: seriesData,
                valueFormatter,
                assetCount: assetCountData,
                totalArea: totalAreaData,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.projected", "Projected"),
                stack: "total",
                id: "projected",
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
}

const formatSeries = (
    label: any,
    data: (number | null)[] | undefined,
    t: TFunction
) => [
    label?.toString() ?? "",
    ...(data?.map((value) =>
        value !== null ? `${formatNumberLocale(value, t, { max: 3 })}` : ""
    ) ?? []),
    t(
        "portfolioDashboard.locationTable.subheader.emissionPerSize",
        "kg/CO2e/m²"
    ),
];

export function getYearlyPortfolioIntensityChartCSVData(
    years: number[],
    series: YearlyIntensitySeriesType[],
    benchmarkSeries: AbsoluteLineSeriesType[],
    t: TFunction
): string[][] {
    const headers = ["", ...years.map(String), t("common.labels.unit", "Unit")];

    const rows = [
        ...series.map(({ label, data }) => formatSeries(label, data, t)),
        formatSeries(
            t("portfolioDashboard.boxes.assets", "Assets"),
            series[0]?.assetCount,
            t
        ),
        formatSeries(
            t("portfolioDashboard.boxes.area", "Area"),
            series[0]?.totalArea,
            t
        ),
        ...benchmarkSeries.map(({ label, data }) =>
            formatSeries(label, data, t)
        ),
    ];

    return [headers, ...rows];
}
