import { useTranslation } from "react-i18next";

import { AssessmentVertical } from "graphql-types/graphql";

export const useVerticalTranslations = () => {
    const { t } = useTranslation();

    const translations: Record<AssessmentVertical, string> = {
        [AssessmentVertical.HEATING]: t(
            "assessmentOverview.heat.title",
            "Heat",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.COOLING]: t(
            "assessmentOverview.cooling.title",
            "Cooling",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.ELECTRICITY]: t(
            "assessmentOverview.electricity.title",
            "Electricity",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.MATERIALS]: t(
            "assessmentOverview.materials.title",
            "Materials",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.TRANSPORT]: t(
            "assessmentOverview.transport.title",
            "Transport",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.WASTE]: t(
            "assessmentOverview.waste.title",
            "Waste",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.WATER]: t(
            "assessmentOverview.water.title",
            "Water",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.ENERGY]: t(
            "assessmentOverview.energy.title",
            "Energy Combined",
            {
                ns: "translation",
            }
        ),
        [AssessmentVertical.UN_CATEGORIZED]: t(
            "assessmentOverview.uncategorized.title",
            "Uncategorized",
            { ns: "translation" }
        ),
    };

    return {
        translations,
    };
};
