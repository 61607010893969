import { Box, Typography, styled } from "@mui/material";

export const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: theme.typography.h4.fontSize,
    marginRight: theme.spacing(1.5),
}));

export const Item = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(6, 4),
    borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey[300]}`,
}));

export const ItemContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}));

export const ItemDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
}));
