import { Clear } from "@mui/icons-material";
import { styled } from "@mui/material";

export const StyledCloseIcon = styled(Clear)(({ theme }) => ({
    transition: "ease-out 0.1s all",
    width: "fit-content",

    "&:hover": {
        cursor: "pointer",
        fontSize: theme.spacing(8),
    },
}));
