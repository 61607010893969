import { useMutation, useQuery } from "@apollo/client";

import { GET_ASSET_PROPERTY_TREE } from "containers/AssetPage/AssetDetails/components";
import {
    AssetPropertyTree,
    GetAssetIntegrationAllocationsQuery,
    GetAssetIntegrationAllocationsQueryVariables,
    GetAssetPropertyTreeQuery,
    GetAssetPropertyTreeQueryVariables,
    UpdateAssetIntegrationAllocationMutation,
    UpdateAssetIntegrationAllocationMutationVariables,
} from "graphql-types/graphql";

import { getAllocationPropertyTree } from "./property-allocation.helpers";
import {
    GET_ASSET_INTEGRATION_ALLOCATIONS,
    UPDATE_ASSET_INTEGRATION_ALLOCATION,
} from "../drawerFields.query";

export const usePropertyAllocationQuery = (
    id: string,
    assetName: string,
    assetId: string
) => {
    const { data, loading } = useQuery<
        GetAssetPropertyTreeQuery,
        GetAssetPropertyTreeQueryVariables
    >(GET_ASSET_PROPERTY_TREE, { variables: { assetId } });
    const { data: allocations, loading: allocationLoading } = useQuery<
        GetAssetIntegrationAllocationsQuery,
        GetAssetIntegrationAllocationsQueryVariables
    >(GET_ASSET_INTEGRATION_ALLOCATIONS, {
        variables: { assetIntegrationId: id },
    });

    const propertyTree = data?.getAssetPropertyTree || [];

    const flatPropertyList = getAllocationPropertyTree(
        propertyTree as AssetPropertyTree[],
        allocations?.locationIntegration.allocations || [],
        assetId,
        assetName
    );

    const [updateAllocation, { loading: updateLoading }] = useMutation<
        UpdateAssetIntegrationAllocationMutation,
        UpdateAssetIntegrationAllocationMutationVariables
    >(UPDATE_ASSET_INTEGRATION_ALLOCATION, {
        update: (cache, { data }) => {
            cache.writeQuery({
                query: GET_ASSET_INTEGRATION_ALLOCATIONS,
                variables: { assetIntegrationId: id },
                data: {
                    locationIntegration: {
                        __typename: "LocationIntegration",
                        id,
                        allocations:
                            data?.updateAssetIntegrationAllocations.allocations,
                    },
                },
            });
        },
    });

    return {
        data: flatPropertyList,
        loading: loading || allocationLoading || updateLoading,
        updateAllocation,
    };
};
