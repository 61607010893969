import { Box, Paper } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Chart } from "glue/Chart";
import { AssessmentDataType } from "graphql-types/graphql";

import { ForecastAssetSearch } from "./ForecastAssetSearch";
import { ForecastChartTooltip } from "./ForecastChartTooltip";
import ForecastDashboardHeader from "./ForecastDashboardHeader";
import { ChartDisclaimer } from "./ForecastDisclaimer";
import { getTargeSettingSeries } from "./helpers/chartHelpers";
import { getLocationEmissionsOnType } from "./helpers/utilities";
import TargetEmissionsLabels from "./TargetEmissionLabels";
import { ForecastLocation, LocationWithFilteringLabel } from "./types";
import { DASH_SEPARATOR } from "../../utils/strings.helpers";
import useTargetSettings from "../TargetSettings/useTargetSettings";

type Props = {
    locations: ForecastLocation[];
    baselineYear: number | null;
};

const ForecastDashboardTabs = (props: Props) => {
    const { locations, baselineYear } = props;
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(AssessmentDataType.COMBINED);
    const { areTargetSettingsShown } = useTargetSettings();

    const [filteringId, setFilteringId] = useState("");

    const yearlyEmissionSummary = useMemo(
        () => getLocationEmissionsOnType(locations, activeTab),
        [activeTab, locations]
    );

    const filteredYearlyEmissionSummary = useMemo(() => {
        return filteringId
            ? _.filter(yearlyEmissionSummary, (l) => l.id === filteringId)
            : yearlyEmissionSummary;
    }, [yearlyEmissionSummary, filteringId]);

    const handleChange = useCallback(
        (value: AssessmentDataType) => setActiveTab(value),
        [setActiveTab]
    );

    const locationsWithFilteringLabel = useMemo(() => {
        return yearlyEmissionSummary
            .map(
                ({
                    id,
                    displayName,
                    integrations,
                    externalId,
                    allEmissions,
                }) => {
                    // We only get DK EPCs integrations on the query
                    const origins = integrations?.map((e) => e.originId) || [];
                    const totalOrigins = origins.length;
                    const totalOriginsLabel =
                        totalOrigins > 1 ? ` [+${totalOrigins - 1}]` : "";

                    return {
                        id,
                        name: displayName || DASH_SEPARATOR,
                        origin: totalOrigins
                            ? `${origins[0]}${totalOriginsLabel}`
                            : "",
                        externalId: externalId || "",
                        allEmissions,
                    };
                }
            )
            .sort((a, b) => {
                const formatName = (name: string) =>
                    name.toUpperCase().replace(/^AA/, "A");

                return formatName(a.name).localeCompare(
                    formatName(b.name),
                    "da-DK"
                );
            });
    }, [yearlyEmissionSummary]);

    const handleFilterSelection = (l: LocationWithFilteringLabel) => {
        setFilteringId(l?.id || "");
    };

    const { series, xAxis } = getTargeSettingSeries(
        filteredYearlyEmissionSummary,
        t,
        activeTab,
        areTargetSettingsShown
    );

    return (
        <Box display="grid" gap={4}>
            <ForecastDashboardHeader
                activeTab={activeTab}
                baselineYear={baselineYear}
                handleChange={handleChange}
            />

            {baselineYear && (
                <TargetEmissionsLabels baselineYear={baselineYear} />
            )}

            <Paper style={{ padding: 32 }}>
                <ForecastAssetSearch
                    assets={locationsWithFilteringLabel}
                    onAssetSelected={handleFilterSelection}
                />
                <Chart
                    series={series}
                    xAxis={xAxis}
                    customTooltip={ForecastChartTooltip}
                    legend={{
                        hidden: false,
                        position: { horizontal: "right" },
                    }}
                />
            </Paper>

            <ChartDisclaimer />
        </Box>
    );
};

export default ForecastDashboardTabs;
