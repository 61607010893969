import { gql } from "@apollo/client";

const UPDATE_USER_PROFILE_MUTATION = gql`
    mutation UpdateMe($updateMeInput: UpdateUserInput!) {
        updateMe(input: $updateMeInput) {
            id
            name
            email
            phone
        }
    }
`;

export default UPDATE_USER_PROFILE_MUTATION;
