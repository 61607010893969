import { gql } from "@apollo/client";

export const GET_ASSET_EMISSION_SUMMARY_INVENTORY = gql`
    query GetAssetEmissionSummaryInventory(
        $locationId: ID!
        $aggregation: AssessmentAggregation!
        $dataType: AssessmentDataType!
        $from: DateTime!
        $to: DateTime!
        $language: ReportLanguage!
    ) {
        simpleAssetInventoryHeaders {
            key
            header
        }
        location(id: $locationId) {
            id
            emissionSummaryInventory(
                aggregation: $aggregation
                dataType: $dataType
                from: $from
                to: $to
                language: $language
            ) {
                id
                nationalBuildingIds
                addresses
                assetArea
                assetAreaUnit
                assetPercentageOwnership
                from
                to
                activity
                consumptionType
                assetConsumption
                ownedConsumption
                consumptionUnit
                consumptionDataSource
                scope
                assetEmission
                ownedEmission
                emissionUnit
                emissionFactor
                emissionFactorUnit
                emissionDataSource
                emissionSourceNote
            }
        }
    }
`;
