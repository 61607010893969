import {
    ApolloError,
    FetchResult,
    useMutation,
    useQuery,
} from "@apollo/client";
import { Box, Button } from "@mui/material";
import { TFunction } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ADD_LOCATION_INTEGRATION_MUTATION } from "containers/IntegrationOnboarding/graphql/locationIntegrationOnboardingMutation";
import { buildElectralinkMutationPayload } from "containers/IntegrationOnboarding/IntegrationCreationModal/utils/integrationUtils";
import { integrationTypeToGqlRequestMap } from "containers/IntegrationOnboarding/utils";
import {
    SupportedIntegrationForOnboarding,
    AddLocationIntegrationMutation,
    AddLocationIntegrationMutationVariables,
    GetElectralinkMeterLocationsQuery,
} from "graphql-types/graphql";

import { formatElectralinkLocation } from "./electralink.helper";
import { AddMappingState } from "./IntegrationOnboardingTypes";
import { LocationIntegrationMappingTable } from "./LocationIntegrationMapping/LocationIntegrationMappingTable";

const INTEGRATION_TYPE = SupportedIntegrationForOnboarding.ELECTRALINK;

const handleAddElectralinkLocationIntegration = (
    t: TFunction,
    meterLocations: AddMappingState,
    mutation: (
        payload: any
    ) => Promise<FetchResult<AddLocationIntegrationMutation>>
) => {
    let locationCount = 0;
    let integrationsCount = 0;

    const payload = Object.keys(meterLocations).flatMap((locationId) => {
        const { integrations, period } = meterLocations[locationId];

        if (!integrations.length) return [];

        locationCount += 1;
        integrationsCount += integrations.length;

        return integrations.map((data) =>
            buildElectralinkMutationPayload(
                {
                    assetId: locationId,
                    originId: data.value,
                    startedAt: period.from?.toJSDate() ?? null,
                    endedAt: period.to?.toJSDate() ?? null,
                },
                data
            )
        );
    });

    toast.promise<any, ApolloError>(mutation(payload), {
        pending: {
            render: () =>
                integrationsCount > 5
                    ? t(
                          "integrationOnboarding.addingIntegrations",
                          "Adding {{integrationsCount}} integrations for {{locationCount}} locations",
                          { integrationsCount, locationCount }
                      )
                    : null,
        },
        success: {
            render: () =>
                t(
                    "integrationOnboarding.createSuccess",
                    "Successfully added {{integrationsCount}} integrations for {{locationCount}} locations",
                    { integrationsCount, locationCount }
                ),
        },
        error: {
            render: ({ data }) =>
                t(
                    "integrationOnboarding.createError",
                    "Failed to add integrations \n {{error}} ",
                    { error: data && data.message }
                ),
        },
    });
};

export const AddElectralinkLocationIntegration = () => {
    const { t } = useTranslation();

    const [meterLocations, setMeterLocations] = useState<AddMappingState>({});

    const { meterLocationsQuery } =
        integrationTypeToGqlRequestMap[INTEGRATION_TYPE];

    const { data, loading } = useQuery<GetElectralinkMeterLocationsQuery>(
        meterLocationsQuery,
        { variables: { types: [INTEGRATION_TYPE] } }
    );

    const [addLocationIntegration] = useMutation<
        AddLocationIntegrationMutation,
        AddLocationIntegrationMutationVariables
    >(ADD_LOCATION_INTEGRATION_MUTATION);

    const handleAddLocationIntegrations = () =>
        handleAddElectralinkLocationIntegration(t, meterLocations, (input) =>
            addLocationIntegration({ variables: { input } })
        );

    const locations = useMemo(() => {
        if (!data) return [];

        const electralinkLocations =
            data.locationsForElectralinkIntegration ?? {};
        const electralinkMeterLocations =
            electralinkLocations.meterLocations ?? [];

        const locations =
            electralinkLocations.initialLocationIntegrationsState.flatMap(
                (initialState) =>
                    formatElectralinkLocation(
                        initialState,
                        electralinkMeterLocations
                    )
            );

        return locations;
    }, [data]);

    const disabled =
        loading ||
        !locations.length ||
        Object.keys(meterLocations).every(
            (id) => meterLocations[id].integrations.length === 0
        );

    return (
        <Box>
            <LocationIntegrationMappingTable
                locations={locations}
                isLoading={loading}
                integrationType={INTEGRATION_TYPE}
                setLocationIntegrations={setMeterLocations}
            />
            <Button
                disabled={disabled}
                onClick={handleAddLocationIntegrations}
                variant="contained"
                color="success"
                sx={{ mt: 2 }}
            >
                {t(
                    "integrationOnboarding.addLocationIntegrationsButtonLabel",
                    "Add Location Integrations"
                )}
            </Button>
        </Box>
    );
};
