import ErrorIcon from "@mui/icons-material/Error";
import { Box, Stack, Tooltip, Chip, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { useCallback } from "react";

import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import { ErrorLabel } from "components/Labels/ErrorLabel";

import { AssessmentOverviewVerticalValue } from "./types";

export const EmissionDetailsCell = (value: any, t: TFunction) => {
    const { emissionValue, consumptionValues, sources, hasEmissionUnit } =
        value as AssessmentOverviewVerticalValue;

    const EmissionRow = useCallback(() => {
        if (!consumptionValues.length) {
            return <Typography>—</Typography>;
        }

        if (!emissionValue || !hasEmissionUnit) {
            return (
                <ErrorLabel
                    tooltip={t(
                        "warnings.noEmissionFactor",
                        "We can't verify the emission for this period, because the emission factor is missing. Please contact our customer success."
                    )}
                    label={t(
                        "common.units.emissionNotVerified",
                        "Emission not verified"
                    )}
                />
            );
        }

        return <Typography>{emissionValue}</Typography>;
    }, [hasEmissionUnit, consumptionValues, emissionValue, t]);

    const ConsumptionRow = useCallback(() => {
        if (!consumptionValues.length) {
            return (
                <Typography color="error" fontWeight={600}>
                    {t("warnings.noConsumption", "No consumption data")}
                </Typography>
            );
        }

        return consumptionValues.map((consumptionValue, i) => (
            <Typography
                key={i}
                color="grey.500"
                display="flex"
                alignItems="center"
                gap={1}
            >
                {consumptionValue.consumptionFormatted}&nbsp;
                {!consumptionValue.unit ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={t(
                            "warnings.missingUnit",
                            "This consumption doesn't have a verified unit. Please contact our customer success."
                        )}
                    >
                        <ErrorIcon sx={{ fontSize: "1rem" }} />
                    </Tooltip>
                ) : (
                    consumptionValue.unit
                )}
            </Typography>
        ));
    }, [consumptionValues, t]);

    return (
        <Stack gap={1.5}>
            <EmissionRow />
            <ConsumptionRow />

            <Box display="flex" gap={1} flexWrap="wrap">
                {Array.isArray(sources) &&
                    sources.map((source) => (
                        <Chip
                            key={source}
                            label={mapDataQualitySources(source, t)}
                            size="small"
                        />
                    ))}
            </Box>
        </Stack>
    );
};
