import {
    Box,
    List,
    ListItem,
    Paper,
    Popper,
    Typography,
    styled,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { DASH_SEPARATOR } from "../../utils/strings.helpers";

const NationalIdentifiersContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
    "& > *": {
        display: "inline-block",
        fontSize: theme.typography.h5.fontSize,
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1, 2),
        textAlign: "center",
    },
}));

const NationalBuildingId = styled(Typography)(() => ({
    width: "120px",
}));

export const StyledPaper = styled(Paper)(() => ({
    backgroundColor: "rgb(253, 253, 253)",
    borderRadius: "5px",
    boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
    "& > *": {
        fontSize: "0.85em",
    },
    "& > *:not(:last-child)": {
        marginBottom: "0.25rem",
    },
}));

const UserSelectItem = styled(Typography)(() => ({
    userSelect: "all",
    fontSize: "inherit",
}));

function NationalBuildingIdsCell(nationalBuildingIds: string[]) {
    const wrapper = useRef<HTMLDivElement | null>(null);
    const popperWrapper = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        setShowPopper(true);
        setAnchorEl(popperWrapper.current);
    };

    const handleMouseLeave = () => {
        setShowPopper(false);
    };

    if (!nationalBuildingIds.length) {
        return <Typography>{DASH_SEPARATOR}</Typography>;
    }

    const [firstNationalBuildingId, ...restOfNationalBuildingIds] =
        nationalBuildingIds;

    return (
        <NationalIdentifiersContainer>
            <NationalBuildingId>{firstNationalBuildingId}</NationalBuildingId>
            {restOfNationalBuildingIds.length > 0 && (
                <div
                    ref={wrapper}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        position: "relative",
                        lineHeight: "1.25rem",
                    }}
                >
                    +{restOfNationalBuildingIds.length}
                    <div
                        ref={popperWrapper}
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "100%",
                        }}
                    >
                        {showPopper && (
                            <Popper
                                open={anchorEl !== null}
                                anchorEl={anchorEl}
                            >
                                <StyledPaper
                                    elevation={1}
                                    style={{
                                        minHeight:
                                            wrapper.current?.offsetHeight,
                                    }}
                                >
                                    <List>
                                        {restOfNationalBuildingIds.map(
                                            (item, index) => (
                                                <ListItem key={index}>
                                                    <UserSelectItem>
                                                        {item}
                                                    </UserSelectItem>
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </StyledPaper>
                            </Popper>
                        )}
                    </div>
                </div>
            )}
        </NationalIdentifiersContainer>
    );
}

export default NationalBuildingIdsCell;
