import { Box, Paper, Typography, styled } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import InfoPopover from "components/Popovers/InfoPopover";
import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";

import { getReportGridTitle } from "./report.helpers";
import { ReportTab } from "./reportPageHelpers";
import { ReportEmissionFactor } from "./ReportTablesBackEnd/ReportEmissionFactors";
import { ReportInventory } from "./ReportTablesBackEnd/ReportInventory";
import { ReportSummary } from "./ReportTablesBackEnd/ReportSummary";

export const AssetsShowingBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    fontSize: theme.spacing(3.5),
    fontWeight: "bold",
    padding: theme.spacing(2),
    width: "fit-content",
    borderRadius: theme.spacing(2),
}));
interface InputProps {
    activeTab: ReportTab;
    dataType: AssessmentDataType;
    reportType: ReportFramework;
}

const ReportContent = ({ activeTab, dataType, reportType }: InputProps) => {
    const { t } = useTranslation(["report"]);

    const ReportGrid = useCallback(() => {
        switch (activeTab) {
            case ReportTab.SUMMARY:
                return (
                    <ReportSummary
                        reportType={reportType}
                        dataType={dataType}
                    />
                );

            case ReportTab.INVENTORY: {
                return (
                    <ReportInventory
                        dataType={dataType}
                        reportType={reportType}
                    />
                );
            }
            case ReportTab.EMISSIONFACTORS:
                return (
                    <ReportEmissionFactor
                        reportType={reportType}
                        dataType={dataType}
                    />
                );
        }
    }, [activeTab, reportType, dataType]);

    return (
        <>
            <Box sx={{ mb: 5 }} display={"flex"} alignItems={"center"} gap={2}>
                <Typography variant="h3">
                    {getReportGridTitle(activeTab, t)}
                </Typography>
                {activeTab === ReportTab.INVENTORY && (
                    <InfoPopover
                        element={t(
                            "tabs.emissionSummaryInfoPop",
                            "Our emissions summary aggregates the consumption and emission totals on a monthly level. To access the full unsummarized inventory for auditors, please select the option, then download the report.",
                            { ns: "report" }
                        )}
                    />
                )}
            </Box>
            <Paper>
                <ReportGrid />
            </Paper>
        </>
    );
};

export default ReportContent;
