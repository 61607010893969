import { Select, SelectChangeEvent, MenuItem } from "@mui/material";
import { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form";

type Props<T> = {
    disabled: boolean;
    selected: T | undefined;
    options: { label: string; value: T }[];
    onChange: (value: T) => void;
    hasError?: boolean;
    placeholder?: string;
};

export const SelectField = forwardRef(
    <T extends string>(props: Partial<ControllerRenderProps> & Props<T>) => {
        const { onChange, placeholder, selected, options, hasError, ...rest } =
            props;

        return (
            <Select
                {...rest}
                size="small"
                fullWidth
                displayEmpty
                error={props.hasError}
                value={selected || ""}
                renderValue={(value) => {
                    const selectedOption = options.find(
                        (option) => option.value === value
                    );

                    return selectedOption ? selectedOption.label : placeholder;
                }}
                onChange={(event: SelectChangeEvent) => {
                    onChange(event.target.value as T);
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        );
    }
);
