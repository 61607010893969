import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
    onClick: () => void;
    editsCount: number;
};

export const ChangeLogEditButton = (props: Props) => {
    const { onClick, editsCount } = props;

    const { t } = useTranslation();

    return (
       <>
            {editsCount ? (
                <Button onClick={onClick}>
                    {t("settings.edit", { count: editsCount })}
                </Button>
            ) : (
                <Typography
                    color="grey.500"
                    fontStyle="italic"
                    fontSize="inherit"
                >
                    {t(
                        "asset.changeLog.noChanges",
                        "No changes have been made"
                    )}
                </Typography>
            )}
       </>
    );
};
