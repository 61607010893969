import { range } from "lodash";
import { useEffect, useState } from "react";

import {
    FilterItemOption,
    FilterRangeValue,
} from "components/AssetsGroup/types";
import { AssetGroupFilterCategory } from "graphql-types/graphql";

import { FilterOptionRange } from "../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

function isValidRange(range: FilterRangeValue): boolean {
    if (range.start === "" || range.end === "") {
        return false;
    }

    return range.start <= range.end;
}

export function FilterOptionConstructionYear(props: Props) {
    const { onChange } = props;

    const [constructionYearRange, setConstructionYearRange] = useState<
        { key: string; value: string }[]
    >([]);

    const [constructionYear, setConstructionYear] = useState<FilterRangeValue>({
        start: "",
        end: "",
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 200;

        const yearsList = range(currentYear, startYear).map((year) => ({
            key: year.toString(),
            value: year.toString(),
        }));

        setConstructionYearRange(yearsList);
    }, []);

    function onSelectRange(range: FilterRangeValue) {
        setConstructionYear(range);
        onChange({
            category: AssetGroupFilterCategory.CONSTRUCTIONYEAR,
            values: [range],
        });
    }

    function onRangeChange(range: FilterRangeValue) {
        setConstructionYear(range);
    }

    const isValid = isValidRange(constructionYear);

    return (
        <FilterOptionRange
            disabled={!isValid}
            range={constructionYearRange}
            onSelectRange={onSelectRange}
            onRangeChange={onRangeChange}
        />
    );
}
