import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentVertical } from "graphql-types/graphql";
import { assessmentVerticalToTitle } from "utils/assessment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            backgroundColor: "#fff",
            border: "2px solid currentColor",
        },
    },
};

const InputStyle = {
    background: "#fff",

    "&.Mui-focused fieldset": {
        background: "transparent",
        color: "currentColor",
    },
};

type FilterProps = {
    filters: AssessmentVertical[];
    activeFilters: AssessmentVertical[];
    onFilterChange: (filter: AssessmentVertical[]) => void;
};

export const VerticalFilter = ({
    filters,
    activeFilters,
    onFilterChange,
}: FilterProps) => {
    const { t } = useTranslation();

    const verticalLabel = t(
        "assessmentOverview.assetList.filter.verticals",
        "Verticals"
    );
    const options = useMemo(
        () =>
            filters.map((filter) => ({
                label: assessmentVerticalToTitle(filter, t),
                value: filter,
            })),
        [t, filters]
    );
    const onChange = useCallback(
        (e: SelectChangeEvent<AssessmentVertical[]>) => {
            const value =
                // On autofill we get a stringified value.
                typeof e.target.value === "string"
                    ? (e.target.value.split(",") as AssessmentVertical[])
                    : e.target.value;

            onFilterChange(value);
        },
        [onFilterChange]
    );

    return (
        <FormControl
            sx={{
                minWidth: "250px",
                maxWidth: "350px",
                height: "100%",
                width: "100%",
            }}
        >
            <InputLabel id="verticals-label" size="small">
                {verticalLabel}
            </InputLabel>
            <Select
                multiple
                size="small"
                labelId="verticals-label"
                input={<OutlinedInput sx={InputStyle} label={verticalLabel} />}
                value={activeFilters}
                renderValue={(selected) =>
                    selected.length === filters.length
                        ? t("assessmentOverview.assetList.filter.all", "All")
                        : selected
                              .map((s) => assessmentVerticalToTitle(s, t))
                              .join(", ")
                }
                onChange={onChange}
                MenuProps={MenuProps}
            >
                {options.map(({ label, value }) => (
                    <MenuItem
                        key={value}
                        value={value}
                        sx={{
                            "&.MuiMenuItem-root": {
                                padding: 0,
                            },
                        }}
                    >
                        <Checkbox
                            checked={activeFilters.indexOf(value) > -1}
                            size="small"
                        />
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
