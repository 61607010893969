import { useState } from "react";

import AssessmentOverviewStatsLayout from "components/AssessmentStats/AssessmentOverviewStatsLayout";
import { PortfolioPage } from "components/PortfolioDashboard/types";
import SubHeaderWithTabs from "components/SubHeaderWithTabs/SubHeaderWithTabs";

import { useAssessmentDataTypeContext } from "../AssessmentDataTypeContext";
import AssetBestEffortCoverage from "../AssetDataOverview/AssetBestEffortCoverage";

const AssetOverview = ({ locationId }: { locationId: string }) => {
    const { assessmentType: activeTab, setAssessmentType: setActiveTab } =
        useAssessmentDataTypeContext();

    const [activeSubTab, setActiveSubTab] = useState(
        PortfolioPage.YEARLY_BREAKDOWN
    );

    const AssetOverviewContent = () => {
        switch (activeSubTab) {
            case PortfolioPage.COVERAGE:
                return <AssetBestEffortCoverage locationId={locationId} />;
            case PortfolioPage.YEARLY_BREAKDOWN:
            case PortfolioPage.OVERVIEW:
                return (
                    <AssessmentOverviewStatsLayout
                        isYearly={
                            activeSubTab === PortfolioPage.YEARLY_BREAKDOWN
                        }
                        activeTab={activeTab}
                        locationId={locationId}
                    />
                );
        }
    };

    return (
        <>
            <SubHeaderWithTabs
                tab={{ active: activeSubTab, onChange: setActiveSubTab }}
                assessmentType={{ active: activeTab, onChange: setActiveTab }}
                hasCoverage
            />
            <AssetOverviewContent />
        </>
    );
};

export default AssetOverview;
