import { Flag, PrivacyTip } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import Popover from "components/Popovers/Popover";
import { AssetAlertFlag, AssetAlertFragment } from "graphql-types/graphql";

import { parseDate } from "../../containers/DataUploadPage/utilities";

const MessageBox = styled(Box)(({ theme }) => ({
    minWidth: theme.spacing(80),

    gap: theme.spacing(2),
}));

export const getAssetAlertInfo = (
    t: TFunction,
    type: AssetAlertFlag,
    data?: AssetAlertFragment["data"]
) => {
    switch (type) {
        case AssetAlertFlag.EPC_EXPIRES_SOON: {
            const date =
                data &&
                "epcExpiryDates" in data &&
                data.epcExpiryDates.length > 0
                    ? data.epcExpiryDates[0]
                    : "";

            return date
                ? [
                      t(
                          "assessmentOverview.assetList.assetAlerts.epcWillExpireOn",
                          "EPC will expire on {{expiryDate}}",
                          { expiryDate: parseDate(date) }
                      ),
                  ]
                : [
                      t(
                          "assessmentOverview.assetList.assetAlerts.epcWillExpireSoon",
                          "EPC will expire soon"
                      ),
                  ];
        }
        case AssetAlertFlag.MISSING_METER_DATA:
            if (
                !data ||
                !("meterIntegrations" in data) ||
                data.meterIntegrations.length === 0
            ) {
                return [
                    t(
                        "assessmentOverview.assetList.assetAlerts.missingMeterData",
                        "Meter is not returning consumption data"
                    ),
                ];
            }

            return data.meterIntegrations.map(({ type, lastReading }) =>
                t(
                    "assessmentOverview.assetList.assetAlerts.missingMeterDataWithIntegration",
                    "{{type}} integration is not returning consumption data (last reading: {{lastReading}})",
                    { type, lastReading: parseDate(lastReading) }
                )
            );
        case AssetAlertFlag.DATA_GAPS_IN_YEAR:
            return [
                t(
                    "assessmentOverview.assetList.assetAlerts.dataGapsInYear",
                    "Consumption data doesn't cover the entire year"
                ),
            ];
        case AssetAlertFlag.EPC_EXPIRED_IN_CURRENT_YEAR:
            return [
                t(
                    "assessmentOverview.assetList.assetAlerts.anEPCexpiredincurrentyear",
                    "An EPC expired in current year"
                ),
            ];
        case AssetAlertFlag.DUPLICATED_EPC:
            return [
                t(
                    "assessmentOverview.assetList.assetAlerts.duplicatedEPC",
                    "This asset has multiple EPCs on a building"
                ),
            ];
        default:
            console.error(`Asset alert type ${type} not handled`);
            return [];
    }
};

const AssetAlertCell = ({
    assetAlerts,
}: {
    assetAlerts: AssetAlertFragment[];
}) => {
    const { t } = useTranslation();

    if (assetAlerts.length === 0) return null;

    const AssetAlertPop = () => (
        <MessageBox>
            {assetAlerts.map(({ type, data }) => {
                const assetAlertInfo = getAssetAlertInfo(t, type, data);

                return assetAlertInfo.map((text, index) => (
                    <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <PrivacyTip
                            fontSize="small"
                            sx={{ marginRight: 2 }}
                            color="primary"
                        />
                        <Typography key={type}>{text}</Typography>
                    </Box>
                ));
            })}
        </MessageBox>
    );

    return (
        <Popover
            mouseOver
            parent={() => <Flag data-testid="flag-icon" />}
            parentBoxProps={{ sx: { display: "flex", alignItems: "center" } }}
            hasMaxWidth={false}
            origin={{
                anchor: {
                    vertical: "top",
                    horizontal: "center",
                },
                transform: {
                    vertical: "bottom",
                    horizontal: "center",
                },
            }}
        >
            <AssetAlertPop />
        </Popover>
    );
};

export default AssetAlertCell;
