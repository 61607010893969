import {
    AssessmentDataType,
    GetPortfolioOverviewAssessmentSummariesQuery,
} from "graphql-types/graphql";

type PortfolioOrganization = NonNullable<
    GetPortfolioOverviewAssessmentSummariesQuery["me"]["organization"]
>;
export type Location = NonNullable<PortfolioOrganization["getLocations"]>[0];

export interface SummaryYearOverview {
    assessmentSummaries: Location["assessmentSummaries"];
    locations: Location[];
    assetCount: number;
    totalArea: number;
    emission: number;
    year: number;
    dataType: AssessmentDataType;
    projectedEmission: number;
    projectedEmissionPerArea: number;
}

export enum ChartType {
    PORTFOLIO_INTENSITY,
    TOTAL_EMISSION,
    MARKET_PERFORMANCE,
    WEIGHTED_AVERAGE,
    BASELINE_PERFORMANCE,
    MONTH_BY_MONTH,
    DATA_QUALITY,
    DATA_QUALITY_MONTH_BY_MONTH,
}

export enum PortfolioPage {
    OVERVIEW,
    YEARLY_BREAKDOWN,
    COVERAGE,
}

export type ChartDataItem = {
    title: string;
    header: number | string | null;
    value: number;
    formattedValue: string | null;
    formattedProjectedValue?: string | null;
    assetCount?: number;
    totalArea?: number;
};

export type MonthChartData = {
    label: string | null;
} & ChartDataItem;
