import { AssessmentVertical } from "graphql-types/graphql";

type Colors =
    | "electricity"
    | "heat"
    | "water"
    | "transport"
    | "waste"
    | "materials"
    | "cooling"
    | "energy"
    | "uncategorized";

export const useVerticalColors = () => {
    const verticalColors: Record<AssessmentVertical, Colors> = {
        [AssessmentVertical.TRANSPORT]: "transport",
        [AssessmentVertical.WASTE]: "waste",
        [AssessmentVertical.MATERIALS]: "materials",
        [AssessmentVertical.HEATING]: "heat",
        [AssessmentVertical.ELECTRICITY]: "electricity",
        [AssessmentVertical.WATER]: "water",
        [AssessmentVertical.COOLING]: "cooling",
        [AssessmentVertical.ENERGY]: "energy",
        [AssessmentVertical.UN_CATEGORIZED]: "uncategorized",
    };

    return {
        verticalColors,
    };
};
