import { Box, styled } from "@mui/material";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import FromToDatePicker from "components/DatePicker/FromToDatePicker";
import { AssessmentDataType } from "graphql-types/graphql";

import { DateContextType, useReportDateContext } from "./reportDateContext";
import { getTranslatedTabName, ReportTab } from "./reportPageHelpers";
import TabsPanel from "../TabsPanel/TabsPanel";
import YearAndAssessmentTypeFilter from "../YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";

const DateSelectorContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "24px 0",
    gap: "0.75rem",
});

interface InputHeaderProps {
    activeTab: ReportTab;
    setActiveTab: (tab: ReportTab) => any;
    setSelectedFilter: (filter: string) => any;
    dataType: AssessmentDataType;
}

const ReportFilter = (props: InputHeaderProps) => {
    const { t } = useTranslation(["report"]);
    const { activeTab, setActiveTab, setSelectedFilter, dataType } = props;
    const { dateContextType, from, to, setSelectedDates } =
        useReportDateContext();

    const fromDateTime = useMemo(() => {
        return DateTime.fromISO(from);
    }, [from]);

    const toDateTime = useMemo(() => {
        return DateTime.fromISO(to).startOf("day");
    }, [to]);

    const handleFromToChangeChange = useCallback(
        (from: DateTime | null, to: DateTime | null) => {
            if (from && to) {
                setSelectedDates(
                    from.toFormat("yyyy-MM-dd"),
                    `${to.endOf("day").toISO({ includeOffset: false })}`
                );
            }
        },
        [setSelectedDates]
    );

    const tabsData = [
        {
            title: getTranslatedTabName(t, ReportTab.SUMMARY),
        },
        {
            title: getTranslatedTabName(t, ReportTab.INVENTORY),
        },
        {
            title: getTranslatedTabName(t, ReportTab.EMISSIONFACTORS),
        },
    ];

    const handleTabChange = useCallback(
        (e: React.ChangeEvent<object>, newValue: ReportTab) => {
            setActiveTab(newValue);
        },
        [setActiveTab]
    );

    const handleFilterChange = useCallback(
        (value: AssessmentDataType) => setSelectedFilter(value),
        [setSelectedFilter]
    );

    const isCustomReportingPeriod =
        dateContextType === DateContextType.DATE_RANGE;

    return (
        <DateSelectorContainer>
            <TabsPanel
                tabsData={tabsData}
                activeTab={activeTab}
                onChange={handleTabChange}
            />
            <Box display="flex" alignItems="center" gap={2}>
                {isCustomReportingPeriod && (
                    <FromToDatePicker
                        from={fromDateTime}
                        to={toDateTime}
                        onChange={handleFromToChangeChange}
                    />
                )}
                <YearAndAssessmentTypeFilter
                    activeType={dataType}
                    isYearSelectorHidden={isCustomReportingPeriod}
                    onAssessmentTypeChange={handleFilterChange}
                />
            </Box>
        </DateSelectorContainer>
    );
};

export default ReportFilter;
