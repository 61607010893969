import { Box } from "@mui/material";
import { DateTime } from "luxon";

import { convertToInclusiveDateTime } from "utils/date.utils";

import BasicDatePicker, { BasicDatePickerProps } from "./DatePicker";

interface FromToDatePickerProps {
    from: DateTime | null;
    to: DateTime | null;
    onChange: (from: DateTime | null, to: DateTime | null) => void;
    disabled?: boolean;
}

function FromToDatePicker(props: FromToDatePickerProps) {
    const { onChange, from, to, disabled } = props;

    const handleFromChange = (newDate: DateTime | null) => {
        onChange(newDate, to);
    };

    const handleToChange = (newDate: DateTime | null) => {
        onChange(from, newDate);
    };

    const slotProps: BasicDatePickerProps["slotProps"] = {
        textField: {
            size: "small",
        },
    };

    return (
        <Box display="flex" gap={3} alignItems="center">
            <BasicDatePicker
                disabled={disabled}
                timezone="utc"
                slotProps={slotProps}
                value={from}
                maxDate={to ?? undefined}
                onChange={handleFromChange}
            />
            -
            <BasicDatePicker
                disabled={disabled}
                timezone="utc"
                slotProps={slotProps}
                value={to ? convertToInclusiveDateTime(to) : null}
                minDate={from ?? undefined}
                onChange={handleToChange}
            />
        </Box>
    );
}

export default FromToDatePicker;
