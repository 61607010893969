import { gql } from "@apollo/client";

export const ReportFragment = gql`
    fragment ReportFragment on Report {
        id
        from
        to
        framework
        segmentName
        numberOfAssets
        createdAt
        note
        source
        createdBy {
            name
        }
    }
`;

export const SAVED_REPORTS = gql`
    query GetSavedReports {
        getActiveReports {
            ...ReportFragment
        }
    }
    ${ReportFragment}
`;

export const DELETE_REPORT = gql`
    mutation DeleteReport($input: DeleteReportInput!) {
        deleteReport(input: $input) {
            id
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation UpdateReport($input: UpdateOneReportInput!) {
        updateOneReport(input: $input) {
            id
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($reportInput: CreateReportInput!) {
        createReport(input: $reportInput) {
            ...ReportFragment
        }
    }
    ${ReportFragment}
`;
