import { useQuery } from "@apollo/client";
import { Box, Container, styled, Fade, Modal, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    GetIntegrationsTypesForOnboardingQuery,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";

import { INTEGRATIONS_TYPES_FOR_ONBOARDING_QUERY } from "./graphql/locationIntegrationOnboardingQuery";
import ModalMain from "./IntegrationCreationModal/ModalMain";
import IntegrationTypeSelectorBody from "./IntegrationTypeSelector/SelectorBody";
import IntegrationTypeSelectorHeader from "./IntegrationTypeSelector/SelectorHeader";
import { FormState, HandleChangeEventTarget } from "./types";
import Loading from "../../components/Loading/Loading";
import { StyledCloseIcon } from "../../utils/styledComponents";

const StyledModalContentBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "15vh",
    width: "75vw",
    maxHeight: "75vh",
    minHeight: "30vh",
    backgroundColor: theme.palette.common.white,
    boxShadow: `0 ${theme.spacing(2)}px ${theme.spacing(
        6
    )}px 0 rgba(133, 133, 133, 0.1)`,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
}));

const StyledModalContainer = styled(Modal)({
    display: "flex",
    justifyContent: "center",
});

const DEFAULT_STATE: FormState = { integration: "" };

const IntegrationOnboarding = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [formValues, setFormValues] = useState<FormState>(DEFAULT_STATE);
    const [open, setOpen] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState<
        SupportedIntegrationForOnboarding | ""
    >("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { data, loading } = useQuery<GetIntegrationsTypesForOnboardingQuery>(
        INTEGRATIONS_TYPES_FOR_ONBOARDING_QUERY
    );

    const integrations = useMemo(() => {
        if (!data || !data.integrationTypesForOnboarding) {
            return null;
        }

        return data.integrationTypesForOnboarding.filter(
            (integration) =>
                integration !== SupportedIntegrationForOnboarding.ELECTRALINK
        );
    }, [data]);

    if (loading || !integrations) {
        return (
            <Container>
                <Loading description={t("loading.title", "Loading")} />
            </Container>
        );
    }

    const handleChange = (e: { target: HandleChangeEventTarget }) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSelectedIntegration(
            formValues.integration as SupportedIntegrationForOnboarding
        );
        handleOpen();
    };

    return (
        <Container>
            <IntegrationTypeSelectorHeader />
            <IntegrationTypeSelectorBody
                formValues={formValues}
                integrations={integrations}
                handleChange={handleChange}
                onSubmit={handleSubmit}
            />
            {selectedIntegration && (
                <StyledModalContainer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                >
                    <Fade in={open}>
                        <StyledModalContentBox>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: theme.spacing(12),
                                }}
                            >
                                <Box style={{ flex: "1 1 auto" }} />
                                <StyledCloseIcon
                                    onClick={handleClose}
                                    style={{ margin: theme.spacing(3) }}
                                />
                            </Box>
                            <ModalMain integrationType={selectedIntegration} />
                        </StyledModalContentBox>
                    </Fade>
                </StyledModalContainer>
            )}
        </Container>
    );
};

export default IntegrationOnboarding;
