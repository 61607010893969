import { Tab, Tabs, styled } from "@mui/material";

import { StyledTabProps, StyledTabsProps } from "./tabs.types";

export const S = {
    Tabs: styled((props: StyledTabsProps) => <Tabs {...props} />)(
        ({ theme }) => ({
            marginBottom: theme.spacing(5),
            overflow: "visible",
            "& .MuiTabs-scroller": {
                overflow: "visible !important",
            },
            "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.primary.main,
                height: 5,
                borderRadius: 3,
                marginBottom: -3,
            },
        })
    ),
    Tab: styled(({ children, ...props }: StyledTabProps) => (
        <Tab disableRipple {...props} />
    ))(({ theme }) => ({
        textTransform: "none",
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 20,
        minWidth: "auto",
        marginRight: theme.spacing(10),
        "&.Mui-selected": {
            color: theme.palette.primary.main,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
        },
    })),
};
