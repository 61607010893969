import { Box, Button, Popover } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { TargetType } from "graphql-types/graphql";

import {
    CloseActionEnum,
    NoTargetSettingBox,
    RadioValueType,
    TargetSettingBox,
} from "./TargetSettingsModalContent";
import useTargetSettings from "./useTargetSettings";

const TargetSettingModal = ({
    baselineYear,
}: {
    baselineYear: number | null;
}) => {
    const {
        pathways,
        targetSettings,
        areTargetSettingsShown,
        deleteTargetSettings,
        updateTargetSettings,
        createTargetSettings,
    } = useTargetSettings();
    const { t } = useTranslation(["translation", "report"]);

    const initialPathwayId = useMemo(
        () =>
            pathways.find(
                (pathway) => targetSettings?.pathwayName === pathway.name
            )?.id ?? pathways[0].id,
        [targetSettings, pathways]
    );

    const [targetYear, setTargetYear] = useState(
        targetSettings?.targetYear ?? 0
    );
    const [targetType, setTargetType] = useState<TargetType>(
        targetSettings?.type || TargetType.YEAR_OVER_YEAR
    );
    const [reduction, setReduction] = useState(targetSettings?.reduction ?? 0);
    const [pathwayId, setPathwayId] = useState(initialPathwayId);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const labels = useMemo(() => {
        const isYoY =
            targetType === TargetType.YEAR_OVER_YEAR ||
            targetType === undefined;

        const reductionTypeOptions = [
            {
                label:
                    t(
                        "forecastDashboard.labels.emissionsReduction",
                        "Emissions Reduction"
                    ) + " (%)",
                value: TargetType.YEAR_OVER_YEAR,
            },
            {
                label:
                    t(
                        "forecastDashboard.labels.targetDegree",
                        "Target Degree"
                    ) + " (°C)",
                value: TargetType.PATHWAY,
            },
        ];

        const pathwayOptions = pathways.map((pathway) => ({
            label: pathway.name,
            value: pathway.id,
        }));

        const currentYear = new Date().getFullYear();

        return [
            {
                label: t("columns.baselineYear", "Baseline Year", {
                    ns: "report",
                }),
                value: baselineYear ?? 0,
            },
            {
                label: t("forecastDashboard.labels.targetYear", "Target Year"),
                value: targetYear,
                onChange: (value: number) => setTargetYear(value),
                inputOptions: {
                    type: "number",
                    min: currentYear,
                    max: 2050,
                },
            },
            {
                label: t(
                    "forecastDashboard.labels.reductionType",
                    "Reduction Type"
                ),
                value: targetType,
                onChange: (value: RadioValueType) =>
                    setTargetType(value as TargetType),
                inputOptions: {
                    type: "radio",
                    options: reductionTypeOptions,
                },
            },
            isYoY
                ? {
                      label: `${t(
                          "forecastDashboard.labels.reduction",
                          "Reduction"
                      )} (${t(
                          "forecastDashboard.labels.yearOverYear",
                          "Year over Year"
                      )})`,
                      value: reduction,
                      onChange: (value: number) => setReduction(value),
                      inputOptions: {
                          type: "number",
                          min: 0,
                          max: 100,
                      },
                  }
                : {
                      label: t(
                          "forecastDashboard.labels.targetDegree",
                          "Target Degree"
                      ),
                      value: pathwayId,
                      onChange: (value: RadioValueType) =>
                          setPathwayId(value as string),
                      inputOptions: {
                          type: "radio",
                          options: pathwayOptions,
                      },
                  },
        ];
    }, [
        baselineYear,
        pathways,
        pathwayId,
        targetYear,
        targetType,
        reduction,
        t,
    ]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget),
        []
    );

    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

    const saveTargetSettings = useCallback(() => {
        const newTargetSetting = {
            targetYear,
            type: targetType,
            reduction,
            pathwayId,
        };

        if (areTargetSettingsShown) {
            updateTargetSettings(newTargetSetting);
        } else {
            createTargetSettings(newTargetSetting);
        }
    }, [
        targetYear,
        targetType,
        reduction,
        pathwayId,
        areTargetSettingsShown,
        updateTargetSettings,
        createTargetSettings,
    ]);

    const handleClose = useCallback(
        (action: CloseActionEnum) => {
            setAnchorEl(null);

            switch (action) {
                case CloseActionEnum.SAVE:
                    saveTargetSettings();
                    break;
                case CloseActionEnum.DELETE:
                    deleteTargetSettings();
                    break;
                case CloseActionEnum.CANCEL:
                    setTargetYear(targetSettings?.targetYear ?? 0);
                    setTargetType(
                        targetSettings?.type ?? TargetType.YEAR_OVER_YEAR
                    );
                    setReduction(targetSettings?.reduction ?? 0);
                    setPathwayId(initialPathwayId);
                    break;
            }
        },
        [
            targetSettings,
            initialPathwayId,
            saveTargetSettings,
            deleteTargetSettings,
        ]
    );

    return (
        <Box my={2} display="flex" gap={10}>
            <Button variant="contained" onClick={handleClick}>
                {areTargetSettingsShown
                    ? t("forecastDashboard.labels.editTarget", "Edit Target")
                    : t("forecastDashboard.labels.addTarget", "Add Target")}
            </Button>
            {areTargetSettingsShown && (
                <Button
                    disableFocusRipple
                    onClick={() => handleClose(CloseActionEnum.DELETE)}
                >
                    Remove Target
                </Button>
            )}

            <Popover
                role="dialog"
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose(CloseActionEnum.CANCEL)}
                sx={{ mt: 2 }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                {baselineYear ? (
                    <TargetSettingBox
                        labels={labels}
                        handleClose={handleClose}
                    />
                ) : (
                    <NoTargetSettingBox />
                )}
            </Popover>
        </Box>
    );
};

export default TargetSettingModal;
