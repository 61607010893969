import {
    ArrowUpward,
    ArrowDownward,
    OpenInNew,
    Clear,
} from "@mui/icons-material";
import { Drawer, Box, styled, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { formatNumberLocale } from "../../../utils/report.helpers";

const StyledCloseIcon = styled(Clear)(({ theme }) => ({
    transition: "ease-out 0.1s all",
    display: "block",
    "&:hover": {
        cursor: "pointer",
        fontSize: theme.spacing(8),
    },
}));

const StyledDrawer = styled(Drawer)(() => ({
    width: "50vw",
    transition: "ease-out 0.2s all",
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
    position: "relative",
    color: theme.palette.primary.main,
    fontSize: theme.spacing(5),
    fontWeight: "bold",
    gap: "0.5rem",
    backgroundColor: theme.palette.grey[100],
    padding: "2rem",
}));

const StyledCloseBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "auto",
    position: "absolute",
    top: "2rem",
    right: "2rem",
}));

const PerformanceBuildingTitle = styled(Box)(({ theme }) => ({
    fontSize: theme.spacing(3.5),
    fontWeight: "bold",
}));

const PerformanceBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
}));

const DrawerSubTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontWeight: "bold",
}));

const DrawerTitle = styled(Box)(({ theme }) => ({
    fontSize: theme.typography.h2.fontSize,
    fontWeight: "bold",
    marginTop: "1rem",
    display: "flex",
    alignItems: "center",
}));

const AssetCount = styled(Typography)(() => ({
    fontStyle: "italic",
    opacity: 0.5,
    fontSize: "0.85rem",
    margin: "2rem 0 0.5rem 0",
}));

const AssetList = styled(Box)(() => ({
    margin: "0 2rem",
}));

const AssetItem = styled(Box)(() => ({
    display: "flex",
    alignItem: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "1rem 0",
}));

const AddressContainer = styled(Box)(() => ({
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
}));

const EpcLabel = styled(Box)(({ theme }) => ({
    fontSize: "0.75rem",
    textTransform: "uppercase",
    marginLeft: "1rem",
    color: theme.palette.grey[700],
    padding: "0.25rem 0.5rem",
    backgroundColor: theme.palette.grey[300],
    borderRadius: "0.5rem",
}));

const EmissionNumbers = styled(Box)(({ theme }) => ({
    width: "150px",
    textAlign: "right",
    fontWeight: "bold",
    alignItems: "center",
    color: theme.palette.grey[600],
    margin: "0 1.5rem",
}));

export type BuildingData = {
    emission?: number | null;
    emissionPerArea?: number | null;
    externalId: string | null | undefined;
    address: string;
    performance: number;
    link: string;
    hasExpiredEpc?: boolean;
};

interface Panel {
    location: boolean;
    onClose: () => void;
    overallPercentage: number;
    buildingData: BuildingData[];
}

function PortfolioPerformanceSidepanel(props: Panel) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const { location, onClose, overallPercentage, buildingData } = props;
    const sideBarData = useMemo(
        () => _.sortBy(buildingData, "performance"),
        [buildingData]
    );

    const percentageReturn = (p: number) => {
        const percentage = p > 100 ? p - 100 : 100 - p;

        if (p === 100) {
            return (
                <Typography>
                    {t("portfolioDashboard.drawer.benchmark", "Benchmark")}
                </Typography>
            );
        }

        const Icon = p > 100 ? ArrowUpward : ArrowDownward;

        return (
            <DrawerTitle>
                <Icon color="inherit" fontSize="inherit" />
                {percentage}%
            </DrawerTitle>
        );
    };

    const individualPerformance = (p: number) => {
        const percentage = p > 100 ? p - 100 : 100 - p;

        if (p === 100) {
            return (
                <Typography>
                    {t("portfolioDashboard.drawer.benchmark", "Benchmark")}
                </Typography>
            );
        }
        const Icon = p > 100 ? ArrowUpward : ArrowDownward;

        return (
            <PerformanceBox
                style={{
                    color:
                        p > 100
                            ? theme.palette.error.main
                            : theme.palette.success.main,
                }}
            >
                <Icon color="inherit" fontSize="inherit" />
                <PerformanceBuildingTitle>
                    {percentage}%
                </PerformanceBuildingTitle>
            </PerformanceBox>
        );
    };

    const goToDetails = useCallback(
        (link: string) => {
            navigate({
                pathname: `/location-overview/${link}`,
            });
        },
        [navigate]
    );

    return (
        <StyledDrawer anchor="right" open={Boolean(location)} onClose={onClose}>
            <DrawerHeader>
                <DrawerSubTitle>
                    {t(
                        "portfolioDashboard.drawer.performanceDistribution",
                        "Performance Distribution"
                    )}
                </DrawerSubTitle>

                {percentageReturn(overallPercentage)}

                <StyledCloseBox>
                    <StyledCloseIcon onClick={onClose} />
                </StyledCloseBox>
            </DrawerHeader>

            <AssetList>
                {sideBarData && (
                    <AssetCount>
                        {t(
                            "portfolioDashboard.drawer.keyWithCount",
                            "{{count}} Assets",
                            {
                                count: sideBarData.length,
                            }
                        )}
                    </AssetCount>
                )}
                {sideBarData &&
                    sideBarData.map((data, index) => (
                        <AssetItem key={index}>
                            <AddressContainer>
                                <Box flex={1} flexDirection={"column"}>
                                    <Typography>{data.address}</Typography>
                                    <Typography color="textSecondary">
                                        {data.externalId}
                                    </Typography>
                                </Box>
                                {data.hasExpiredEpc && (
                                    <EpcLabel>
                                        {t(
                                            "assessmentOverview.assetList.assetAlerts.anEPCexpiredincurrentyear",
                                            "An EPC expired in current year"
                                        )}
                                    </EpcLabel>
                                )}
                            </AddressContainer>
                            <PerformanceBox>
                                {individualPerformance(data.performance)}
                            </PerformanceBox>
                            {data.emission && (
                                <EmissionNumbers>
                                    {formatNumberLocale(data.emission, t)}{" "}
                                    {t(
                                        "portfolioDashboard.drawer.kgCO2e",
                                        " kg CO2e"
                                    )}
                                </EmissionNumbers>
                            )}
                            <OpenInNew
                                onClick={() => goToDetails(data.link)}
                                color="primary"
                                sx={{ cursor: "pointer" }}
                            />
                        </AssetItem>
                    ))}
            </AssetList>
        </StyledDrawer>
    );
}

export default PortfolioPerformanceSidepanel;
