import { Alert, Snackbar } from "@mui/material";
import { useMemo } from "react";

export enum NotificationType {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export function NotificationBar({
    notificationType,
    message,
    open,
    setOpen,
}: {
    notificationType: NotificationType | null;
    message?: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const handleClose = () => {
        setOpen(false);
    };

    const severity = useMemo(() => {
        switch (notificationType) {
            case NotificationType.SUCCESS:
                return "success";
            case NotificationType.ERROR:
                return "error";
            default:
                return "info";
        }
    }, [notificationType]);

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity={severity} onClose={handleClose}>
                {message}
            </Alert>
        </Snackbar>
    );
}
