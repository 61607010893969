import { gql } from "@apollo/client";

export const REPORT_EMISSION_FACTORS = gql`
    query ReportEmissionFactor($input: ReportQuery!, $assetGroupId: ID) {
        getReportEmissionFactorHeaders(input: $input) {
            key
            header
        }
        getReportEmissionFactor(input: $input, assetGroupId: $assetGroupId) {
            id
            activity
            ghgCategory
            totalEmissionShare
            emissionFactor
            emissionFactorUnit
            emissionFactorSourceNote
            emissionDataSource
            source
            consumptionType
            releaseDate
            coveragePeriod
            calculationMethod
            region
            sourceDownloadLink
            externalSourceLink
        }
    }
`;

const REPORT_GHG_SUMMARY = gql`
    fragment ReportGHGSummary on ReportGHGSummaryItem {
        id
        title
        value
        unit
        baselineYearUnit
        baselineYearValue
    }
`;

const REPORT_ESG_SUMMARY = gql`
    fragment ReportESGSummary on ReportESGSummaryItem {
        id
        title
        value
        unit
    }
`;

const REPORT_CRREM_SUMMARY = gql`
    fragment ReportCRREMSummary on ReportCRREMSummaryItem {
        id
        assetId
        inclusion
        assetName
        reportingYear
        buildingPercentageOwnership
        startReportingPeriod
        monthsReportingPeriod
        legalOwner
        country
        city
        zipCode
        address
        crremPropertyType
        buildingArea
        buildingDistrictCoolingChilledWaterUsageKWh
        buildingDistrictCoolingChilledWaterDataCoverageM2
        buildingGridElectricityUsageKWh
        buildingGridElectricityDataCoverageM2
        buildingNaturalGasUsageKWh
        buildingNaturalGasDataCoverageM2
        buildingDistrictHeatingSteamUsageKWh
        buildingDistrictHeatingSteamDataCoverageM2
        onSiteRenewableElectricityGeneratedAndConsumedOnSite
        buildingOtherEnergyConsumptionType1Type
        buildingOtherEnergyConsumptionType1UsageKWh
        buildingOtherEnergyConsumptionType1DataCoverageM2
    }
`;

export const REPORT_SUMMARY = gql`
    query ReportSummary($input: ReportQuery!, $assetGroupId: ID) {
        getReportSummaryHeader(input: $input) {
            key
            header
            columnStyle {
                fill
            }
        }
        getReportSummary(input: $input, assetGroupId: $assetGroupId) {
            __typename
            ... on ReportGHGSummaryItem {
                ...ReportGHGSummary
            }
            ... on ReportESGSummaryItem {
                ...ReportESGSummary
            }
            ... on ReportCRREMSummaryItem {
                ...ReportCRREMSummary
            }
        }
    }
    ${REPORT_GHG_SUMMARY}
    ${REPORT_ESG_SUMMARY}
    ${REPORT_CRREM_SUMMARY}
`;

export const REPORT_INVENTORY = gql`
    query ReportInventory($input: ReportQuery!, $assetGroupId: ID) {
        getReportInventoryHeaders {
            key
            header
        }
        getReportInventory(input: $input, assetGroupId: $assetGroupId) {
            id
            activity
            addresses
            assetArea
            assetAreaUnit
            assetConsumption
            assetConsumptionUnit
            assetEmission
            assetEmissionUnit
            assetPercentageOwnership
            buildingArea
            buildingAreaUnit
            buildingConsumption
            buildingConsumptionUnit
            buildingEmission
            buildingEmissionUnit
            buildingPercentageOwnership
            ghgCategory
            classification
            consumption
            consumptionDataNote
            consumptionDataSource
            dataArea
            dataAreaUnit
            dataGap
            dataPercentageOwnership
            emissionDataSource
            emissionFactor
            emissionFactorUnit
            emissionSourceNote
            epcLink
            epcProxy
            from
            ghgCategory
            identifier
            legalOwner
            locationId
            locationName
            locationOwnership
            management
            nationalBuildingIds
            ownedArea
            ownedAreaUnit
            ownedConsumption
            ownedConsumptionUnit
            ownedEmission
            ownedEmissionUnit
            partialOwnershipPercentDistribution
            percentDistribution
            propertyType
            purchaseDate
            scope
            to
            totalArea
            totalAreaUnit
            totalEmission
            totalEmissionUnit
            unit
        }
    }
`;
