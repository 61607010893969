import { useQuery } from "@apollo/client";
import { Paper } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import Loading from "components/Loading/Loading";
import { Table } from "components/Table";
import { mapEpcSources } from "containers/AssetPage/AssetDetails/AssetDetails.helpers";
import { GetDataOverviewEpcAssessmentsQuery } from "graphql-types/graphql";

import { S } from "./epc-dashboard.styles";
import { epcOverviewColumns } from "./epc-table.definitions";
import { DATA_OVERVIEW_EPC_QUERY } from "../data-overview.queries";
import { EPCSidebar } from "../EPCSidebar";

export const EPCDashboard = () => {
    const { t } = useTranslation();

    const { data, loading } = useQuery<GetDataOverviewEpcAssessmentsQuery>(
        DATA_OVERVIEW_EPC_QUERY
    );

    const [assessments, assetCoverage] = useMemo(() => {
        if (!data) return [[], { count: 0, total: 0 }];

        const assets = data.getAssets;
        const ownedAssets = assets.filter((l) => !l.saleDate);
        const ownedAssetsWithEPCs = ownedAssets.filter(
            (l) => l.assessments?.length
        );

        const count = {
            count: ownedAssetsWithEPCs.length,
            total: ownedAssets.length,
        };

        const assessments = _.chain(assets)
            .flatMap((asset) => asset.assessments)
            .groupBy(({ data, from }) => {
                const epcIdentifier =
                    data &&
                    (("epcIdentifier" in data && data.epcIdentifier) ||
                        ("lmkKey" in data && data.lmkKey));

                return `${epcIdentifier}-${from}`;
            })
            .entries()
            .map(([_, assessments]) => assessments)
            .value();

        return [assessments, count];
    }, [data]);

    const epcSources = useMemo(() => {
        const epcData = assessments.map((assessments) =>
            mapEpcSources(assessments, t)
        );

        const activeEPCS = epcData.filter(
            (epc) => epc.status !== DataSourceStatus.EXPIRED
        );

        const activeAndNotReplacedEPCs = epcData.filter((epc) => {
            if (epc.status !== DataSourceStatus.EXPIRED) return true;

            const isReplaced = activeEPCS.some((activeEPC) =>
                activeEPC.nationalIdentifiers.every((id) =>
                    epc.nationalIdentifiers.includes(id)
                )
            );

            return !isReplaced;
        });

        return _.orderBy(
            activeAndNotReplacedEPCs,
            (checkStatus) => checkStatus.nationalIdentifiers,
            ["desc"]
        );
    }, [assessments, t]);

    if (loading) return <Loading description={t("loading.title", "Loading")} />;

    return (
        <S.Container>
            <EPCSidebar sources={epcSources} assetCoverage={assetCoverage} />

            <Paper sx={{ pt: 6 }}>
                <Table
                    rows={epcSources}
                    columns={epcOverviewColumns(t)}
                    sortable={true}
                />
            </Paper>
        </S.Container>
    );
};
