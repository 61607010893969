import { match } from "ts-pattern";

import { Actions, AssetGroupState } from "components/AssetsGroup/types";

import { filtersState } from "../utils";

export const initialState: AssetGroupState = {
    groupName: "",
    groupId: null,
    assets: [],
    previewAssets: [],
    activeFilters: [],
    isEdit: false,
    isCreateDisabled: true,
};

export function reducer(
    state: AssetGroupState,
    action: Actions
): AssetGroupState {
    return match(action)
        .with({ type: "UPDATE_NAME" }, (action) => ({
            ...state,
            groupName: action.payload.groupName,
            isCreateDisabled:
                action.payload.groupName === "" ||
                state.activeFilters.length === 0,
        }))
        .with({ type: "RESET" }, () => ({
            ...initialState,
        }))
        .with({ type: "INIT" }, (action) => {
            const previewAssets = filtersState.getAssetsDiff(
                state.assets,
                state.assets
            );

            return {
                ...action.payload,
                previewAssets,
                isEdit: !!action.payload.groupId,
                isCreateDisabled:
                    action.payload.groupName === "" ||
                    action.payload.activeFilters.length === 0,
            };
        })
        .with({ type: "UPDATE_ASSETS" }, (action) => {
            const previewAssets = filtersState.getAssetsDiff(
                state.assets,
                action.payload
            );

            return {
                ...state,
                previewAssets,
            };
        })
        .with({ type: "ADD_FILTER" }, (action) => {
            const pendingFilters = filtersState.update(
                state.activeFilters,
                action.payload
            );
            return {
                ...state,
                activeFilters: pendingFilters,
                isCreateDisabled:
                    state.groupName === "" || pendingFilters.length === 0,
            };
        })
        .with({ type: "REMOVE_FILTER" }, (action) => {
            const pendingFilters = filtersState.remove(
                state.activeFilters,
                action.payload
            );
            return {
                ...state,
                activeFilters: pendingFilters,
                isCreateDisabled:
                    state.groupName === "" || pendingFilters.length === 0,
            };
        })
        .otherwise(() => state);
}
