import { Link } from "@mui/material";
import { Trans } from "react-i18next";

export const MarketBenchmarkInfo = () => {
    return (
        <>
            <Trans i18nKey="portfolioDashboard.locationTable.headerInfoBox.marketBenchmarkDK">
                The Market Benchmark value is 12.39 kg CO2-eql/m²/year in
                Denmark based on
                <Link
                    target="_blank"
                    href="https://build.dk/Assets/Klimapaavirkning-fra-60-bygninger/SBi-2020-04.pdf"
                >
                    the Property Statistics Report Emission effects of 60
                    buildings
                </Link>
            </Trans>
            <Trans i18nKey="portfolioDashboard.locationTable.headerInfoBox.marketBenchmarkUK">
                In UK the value is 19.90 kg CO2-eql/m²/year based on
                <Link
                    target="_blank"
                    href="https://carbonaccountingfinancials.com/files/downloads/ghg_emissions_real_estate_guidance_1.0.pdf"
                >
                    2022 CREEM Report for UK Real Estate Buildings
                </Link>
            </Trans>
        </>
    );
};

export const PortfolioBenchmarkInfo = () => (
    <Trans i18nKey="portfolioDashboard.locationTable.headerInfoBox.portfolioBenchmark" />
);
