import { Container } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import { Table, TableHeader, TablePagination } from "components/Table";
import {
    AssessmentDataType,
    FillStyle,
    ReportFramework,
    ReportSummaryQuery,
} from "graphql-types/graphql";

import { REPORT_SUMMARY } from "./reportQueries";
import { getHeaderInfo } from "./reportTables.helpers";
import { useReportQuery } from "./useReportQuery";

export const ReportSummary = ({
    dataType,
    reportType,
}: {
    dataType: AssessmentDataType;
    reportType: ReportFramework;
}) => {
    const { t } = useTranslation();

    const isCRREM = useMemo(
        () => reportType === ReportFramework.CRREM,
        [reportType]
    );

    const { data, loading } = useReportQuery<ReportSummaryQuery>(
        REPORT_SUMMARY,
        dataType,
        reportType
    );

    const columns = useMemo((): GridColDef[] => {
        if (!data) {
            return [];
        }

        return data.getReportSummaryHeader.map(
            ({ key, header, columnStyle }) => ({
                field: key,
                headerName: header,
                renderHeader: (params) =>
                    TableHeader({
                        params,
                        infoText: getHeaderInfo(key, t),
                    }),
                ...(isCRREM
                    ? { sortable: true, width: 180 }
                    : {
                          flex: key.toLowerCase().includes("unit")
                              ? 0.25
                              : key.toLowerCase().includes("value")
                              ? 0.5
                              : 1,
                      }),
                ...(columnStyle?.fill === FillStyle.DISABLED && {
                    cellClassName: "header-row",
                    valueFormatter: () => "",
                    sortable: false,
                }),
            })
        );
    }, [isCRREM, data, t]);

    const rows = useMemo(() => data?.getReportSummary || [], [data]);

    const gridModifiers = useMemo(
        () => ({
            ...(isCRREM
                ? {
                      componentsProps: {
                          pagination: { ActionsComponent: TablePagination },
                      },
                  }
                : {
                      getRowClassName: ({ row }: any) =>
                          !row.value || !row.unit ? "header-row" : "",
                  }),
        }),
        [isCRREM]
    );
    if (loading || !data) {
        return (
            <Container sx={{ textAlign: "center", padding: 2 }}>
                {loading ? (
                    <Loading
                        description={t(
                            "labels.loading",
                            "We are processing your report",
                            { ns: "report" }
                        )}
                    />
                ) : (
                    t(
                        "assessment.common.noData",
                        "No available data for this period."
                    )
                )}
            </Container>
        );
    }

    return (
        <Table
            rows={rows}
            columns={columns}
            isLoading={loading}
            hideFooter={!isCRREM}
            setCustomHeight={isCRREM ? undefined : "fit-content"}
            sortable={isCRREM}
            {...gridModifiers}
        />
    );
};
