import { Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation } from "react-i18next";

import {
    getDataQualityTitle,
    mapDataQualitySources,
} from "components/DataQuality/DataQuality.helpers";
import { getAssessmentCategory } from "containers/DataUploadPage/utilities";
import { ConsumptionType, DataQualityTypes } from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils";
import {
    assessmentVerticalToTitle,
    getAssessmentCategoryTranslation,
    mapConsumptionTypeToVertical,
} from "utils/assessment";
import { validateAndFormatDateString } from "utils/date.utils";

import { S } from "./dataSourceDrawer.styles";
import { AllocationNoteField } from "./DrawerFields";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const SourceDetailsPanel = (props: Props) => {
    const { data } = props;
    const { t } = useTranslation();

    const { useAssetIntegrationAsDataSource } = useFlags();

    const verticals = Array.isArray(data.consumptionType)
        ? data.consumptionType.map(mapConsumptionTypeToVertical)
        : [data.vertical];
    const categories = Array.isArray(data.consumptionType)
        ? data.consumptionType.map(getAssessmentCategory)
        : [getAssessmentCategory(data.consumptionType as ConsumptionType)];

    const integrationId = useAssetIntegrationAsDataSource
        ? data.id
        : data.integrationId;

    const details = [
        {
            label: t("common.labels.type", "Type"),
            data: data.type,
        },
        {
            label: t("common.labels.source", "Source"),
            data: mapDataQualitySources(
                data.source.source ?? DASH_SEPARATOR,
                t
            ),
        },
        {
            label: t("common.labels.dataQuality", "Data Quality"),
            data: data.dataQuality
                ? getDataQualityTitle(
                      DataQualityTypes[data.dataQuality],
                      false,
                      t
                  )
                : "-",
        },
        {
            label: t("common.labels.address", "Address"),
            data: data.address,
        },
        ...(!useAssetIntegrationAsDataSource
            ? [
                  {
                      label: t(
                          "common.labels.nationalPropertyId",
                          "National Property ID"
                      ),
                      data: data.nationalPropertyId,
                  },
              ]
            : []),
        {
            label: t("common.labels.identifier", "Identifier"),
            data: data.identifier,
        },
        {
            label: t("common.labels.vertical", "Vertical"),
            data: verticals
                .map((vertical) => assessmentVerticalToTitle(vertical, t))
                .join(", "),
        },
        {
            label: t("common.labels.category", "Category"),
            data: categories
                .map((category) =>
                    getAssessmentCategoryTranslation(category, t)
                )
                .join(", "),
        },
        // Not available yet, need to add it
        // {
        //     label: t("common.labels.supplier", "Supplier"),
        //     data: "Supplier",
        // },
        {
            label: t("common.labels.from", "From"),
            data: validateAndFormatDateString(data.from),
        },
        {
            label: t("common.labels.to", "To"),
            data: validateAndFormatDateString(data.to),
        },
    ];

    return (
        <S.Panel>
            {details.map(({ label, data }) => (
                <S.Container key={label}>
                    <Typography fontWeight="bold">{label}</Typography>
                    <Typography textAlign="right">
                        {data || DASH_SEPARATOR}
                    </Typography>
                </S.Container>
            ))}
            <AllocationNoteField
                id={integrationId}
                note={data.allocationNote ?? ""}
            />
        </S.Panel>
    );
};
