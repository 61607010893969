import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const GoToSecretPageButton = () => {
    const navigate = useNavigate();
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/secret")}
        >
            Back to secret page
        </Button>
    );
};

export default GoToSecretPageButton;
