import { Box, Popper, styled } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DataQualityFragment } from "graphql-types/graphql";

import { formatDataQualityToDataQualityItems } from "./DataQuality.helpers";
import { DataQualitySummary } from "./DataQualitySummary";
import { StyledPaper } from "../Cells/NationalBuildingIdsCell";

interface DataQualityTooltipProps {
    dataQuality: DataQualityFragment;
    children?: React.ReactNode;
}

const Tooltip = styled(Box)({
    width: "100%",
});

const TooltipInner = styled(Box)({
    minWidth: "110px",
    padding: "0.25rem 0.5rem",
});

function DataQualityTooltip(props: DataQualityTooltipProps) {
    const { dataQuality, children } = props;

    const { t } = useTranslation();

    const wrapper = useRef<HTMLDivElement | null>(null);
    const popperWrapper = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showPopper, setShowPopper] = useState(false);

    const handleMouseEnter = () => {
        setShowPopper(true);
        setAnchorEl(popperWrapper.current);
    };

    const handleMouseLeave = () => {
        setShowPopper(false);
    };

    const dataQualityItems = useMemo(
        () =>
            dataQuality
                ? formatDataQualityToDataQualityItems(dataQuality, t)
                : [],
        [dataQuality, t]
    );

    return (
        <Tooltip>
            {dataQuality && (
                <div
                    ref={wrapper}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        position: "relative",
                        lineHeight: "1.25rem",
                        padding: "1rem 0",
                    }}
                >
                    {children}

                    <div
                        ref={popperWrapper}
                        style={{
                            position: "absolute",
                            top: "100%",
                            left: "100%",
                        }}
                    >
                        {showPopper && (
                            <Popper
                                open={anchorEl !== null}
                                anchorEl={anchorEl}
                            >
                                <StyledPaper
                                    elevation={1}
                                    style={{
                                        minHeight:
                                            wrapper.current?.offsetHeight,
                                    }}
                                >
                                    <TooltipInner>
                                        <DataQualitySummary
                                            dataQuality={dataQualityItems}
                                            hasFullTitle={false}
                                            showPercentages={false}
                                        />
                                    </TooltipInner>
                                </StyledPaper>
                            </Popper>
                        )}
                    </div>
                </div>
            )}
        </Tooltip>
    );
}

export default DataQualityTooltip;
