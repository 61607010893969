import { Box } from "@mui/material";

import TableBrick from "components/Brick/TableBrick";
import EpcClassificationsCell from "components/Cells/EpcClassificationsCell";

export const TableBrickMock = () => {
    const headerRow = ["Active EPCs", "#", "%"];
    const values = [
        ["A2015", "15", "2%"],
        ["A", "1", "2%"],
        ["B", "3", "4%"],
        ["C", "5", "6%"],
        ["D", "7", "8%"],
        ["E", "9", "10%"],
        ["F", "11", "12%"],
    ];

    const epcLabels = values.map((row) => EpcClassificationsCell([row[0]]));
    const valuesWithEpc = values.map((row, i) => [
        epcLabels[i],
        ...row.slice(1),
    ]);

    return (
        <Box display="flex" gap={12} width="100%">
            <TableBrick headerRow={headerRow} rows={values} />
            <TableBrick headerRow={headerRow} rows={values} />
            <TableBrick headerRow={headerRow} rows={valuesWithEpc} />
        </Box>
    );
};
