import { ChartsTextProps } from "@mui/x-charts";

export const PerformanceAxisLabel = (props: ChartsTextProps) => {
    const { x, y, style: styleProps, text, ownerState, ...textProps } = props;

    const { textAnchor, dominantBaseline, ...style } = styleProps ?? {};

    const transforms: string[] = [];
    const percentage = parseInt(text.replace("%", ""));

    const textLabel = percentage < 100 ? percentage : percentage - 100;

    return (
        <text
            {...textProps}
            transform={transforms.length > 0 ? transforms.join(" ") : undefined}
            x={x}
            y={y}
            textAnchor={textAnchor}
            dominantBaseline={dominantBaseline}
            style={{
                ...style,
                fill: percentage < 100 ? "green" : "red",
            }}
        >
            {textLabel}%
        </text>
    );
};
