import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
    DatePicker,
    DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export type BasicDatePickerProps = MuiDatePickerProps<DateTime>;
type CustomDatePickerProps = BasicDatePickerProps & {
    isClearAllowed?: boolean;
};

function BasicDatePicker({
    slotProps,
    isClearAllowed,
    ...rest
}: CustomDatePickerProps) {
    const { i18n } = useTranslation();

    return (
        <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={i18n.language === "en" ? "en-GB" : i18n.language}
        >
            <DatePicker
                {...rest}
                slotProps={{
                    ...slotProps,
                    textField: { size: "small", ...slotProps?.textField },
                    actionBar: { actions: isClearAllowed ? ["clear"] : [] },
                }}
            />
        </LocalizationProvider>
    );
}

export default BasicDatePicker;
