import { useMutation, useQuery } from "@apollo/client";
import { ElectricalServices, Handyman } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography, styled } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Loading from "components/Loading/Loading";
import { ADD_ORGANIZATION_INTEGRATION_MUTATION } from "containers/IntegrationOnboarding/graphql/organizationIntegrationOnboardingMutation";
import { ACTIVE_INTEGRATIONS } from "containers/InternalToolboxPage/IntegrationSetup/integrationQueries";
import {
    ActiveOrgIntegrationsQuery,
    AddOrganizationIntegrationMutation,
    SupportedIntegrationForOnboarding,
} from "graphql-types/graphql";

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const IntegrationLink = ({
    integration,
    isActive,
    onClick,
    setActiveTab,
}: {
    integration: SupportedIntegrationForOnboarding;
    isActive: boolean;
    onClick: () => void;
    setActiveTab: (tab: SupportedIntegrationForOnboarding | null) => void;
}) => {
    const { t } = useTranslation();

    const isElectralink =
        integration === SupportedIntegrationForOnboarding.ELECTRALINK;

    const Icon = !isActive ? ElectricalServices : Handyman;

    return (
        <Item>
            <Typography variant="h3">{integration}</Typography>

            <Button
                disabled={!isElectralink}
                onClick={() => setActiveTab(integration)}
                sx={{ border: "1px solid" }}
                color={isActive ? "success" : "primary"}
                {...(isActive
                    ? { to: integration.toLowerCase() }
                    : { onClick })}
            >
                <Icon fontSize="small" sx={{ mr: 2 }} />
                {isActive
                    ? t("integrationOnboarding.configure", "Configure")
                    : t("integrationOnboarding.connect", "Connect")}
            </Button>
        </Item>
    );
};

const OrganizationIntegrationPage = ({
    setActiveTab,
}: {
    setActiveTab: (tab: SupportedIntegrationForOnboarding | null) => void;
}) => {
    const { t } = useTranslation();

    const { data: activeIntegrationsData, loading } =
        useQuery<ActiveOrgIntegrationsQuery>(ACTIVE_INTEGRATIONS);

    const [addOrganizationIntegration] =
        //NOTE: Only works for organizations without data (ex: ELECTRALINK)
        useMutation<AddOrganizationIntegrationMutation>(
            ADD_ORGANIZATION_INTEGRATION_MUTATION,
            { refetchQueries: [ACTIVE_INTEGRATIONS] }
        );

    const handleAddOrganizationIntegration = async (
        integrationType: SupportedIntegrationForOnboarding
    ) => {
        toast.promise(
            addOrganizationIntegration({
                variables: {
                    input: {
                        organizationIntegration: {
                            type: integrationType,
                        },
                    },
                },
            }),
            {
                success: t(
                    "integrationOnboarding.success",
                    `{{integrationType}} integration created!`,
                    { integrationType }
                ),
                error: t("integrationOnboarding.error", "Error!"),
            }
        );
    };

    const [integrations, activeIntegrations] = useMemo(
        () => [
            [SupportedIntegrationForOnboarding.ELECTRALINK],
            activeIntegrationsData?.me.organization?.integrations?.map(
                (integration) => integration.type
            ) || [],
        ],
        [activeIntegrationsData]
    );

    return (
        <Box>
            {loading ? (
                <Loading description={t("loading.title", "Loading")} />
            ) : (
                <Grid container spacing={4}>
                    {integrations.map((integration) => (
                        <Grid item key={integration} xs={12} md={6} xl={4}>
                            <IntegrationLink
                                integration={integration}
                                setActiveTab={setActiveTab}
                                onClick={() =>
                                    handleAddOrganizationIntegration(
                                        integration
                                    )
                                }
                                isActive={activeIntegrations.includes(
                                    integration as any
                                )}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    );
};

export default OrganizationIntegrationPage;
