import { useState } from "react";

import LocationIntegrationPage from "containers/IntegrationOnboardingV2/LocationIntegrationPage";
import OrganizationIntegrationPage from "containers/IntegrationOnboardingV2/OrganizationIntegrationPage";
import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";

export const SettingsIntegrationsTab = () => {
    const [activeTab, setActiveTab] =
        useState<SupportedIntegrationForOnboarding | null>(null);

    if (!activeTab) {
        return <OrganizationIntegrationPage setActiveTab={setActiveTab} />;
    }
    return (
        <LocationIntegrationPage
            integrationType={activeTab}
            setActiveTab={setActiveTab}
        />
    );
};
