import { t } from "i18next";
import { capitalize, compact, intersection, uniq } from "lodash";
import { match } from "ts-pattern";

import {
    AllocationObjectType,
    ConsumptionType,
    IntegrationType,
} from "graphql-types/graphql";

import { AutomaticSource } from "../../asset-sources.helpers";
import { DataSourceStatusFilter } from "../../types/dataSourceTypes";
import { mapAutomaticSourceToIntegrationType } from "../automatic-sources-table.helpers";

export const getStatusFilter = (status: boolean | null | undefined) =>
    match(status)
        .with(true, () => [DataSourceStatusFilter.ACTIVE])
        .with(false, () => [DataSourceStatusFilter.EXPIRED])
        .with(null, () => [
            DataSourceStatusFilter.ACTIVE,
            DataSourceStatusFilter.EXPIRED,
        ])
        .with(undefined, () => [])
        .exhaustive();

export const getStatusTranslations = (status: DataSourceStatusFilter) =>
    match(status)
        .with(DataSourceStatusFilter.ACTIVE, () =>
            t("common.status.acitve", "Active", {
                ns: "translation",
            })
        )
        .with(DataSourceStatusFilter.EXPIRED, () =>
            t("common.status.expired", "Expired", {
                ns: "translation",
            })
        )
        .exhaustive();

export const getFilterRenderValues = <T extends string>(
    selectedValues: T[],
    allOptionsLength: number,
    formatting?: (value: T) => string
) => {
    if (selectedValues.length === allOptionsLength) {
        return t("common.all", "All", {
            ns: "translation",
        });
    }

    return selectedValues
        .map((value) =>
            formatting ? formatting(value) : capitalize(value).replace("_", " ")
        )
        .join(", ");
};

export const filterSourcesByStatus = (
    sources: AutomaticSource[],
    selectedStatus: DataSourceStatusFilter[]
) => {
    if (
        selectedStatus.length === 0 ||
        selectedStatus.length === Object.values(DataSourceStatusFilter).length
    ) {
        return sources;
    }

    let sourceState: AutomaticSource[] = [];

    selectedStatus.forEach((status) => {
        match(status)
            .with(DataSourceStatusFilter.ACTIVE, () => {
                sourceState = [
                    ...sourceState,
                    ...sources.filter((source) => source.status),
                ];
            })
            .with(DataSourceStatusFilter.EXPIRED, () => {
                sourceState = [
                    ...sourceState,
                    ...sources.filter((source) => !source.status),
                ];
            })
            .exhaustive();
    });

    return sourceState;
};

export const filterSourcesByIntegrationType = (
    sources: AutomaticSource[],
    selectedIntegrationType: IntegrationType[]
) => {
    if (
        selectedIntegrationType.length === 0 ||
        selectedIntegrationType.length === Object.values(IntegrationType).length
    ) {
        return sources;
    }

    return sources.filter((source) =>
        selectedIntegrationType.includes(
            mapAutomaticSourceToIntegrationType(source.type) as IntegrationType
        )
    );
};

export const filterSourcesByConsumptionType = (
    sources: AutomaticSource[],
    selectedConsumptionType: ConsumptionType[]
) => {
    if (
        selectedConsumptionType.length === 0 ||
        selectedConsumptionType.length === Object.values(IntegrationType).length
    ) {
        return sources;
    }

    return sources.filter((source) =>
        selectedConsumptionType.includes(
            source.consumptionType as ConsumptionType
        )
    );
};

export const filterSourcesByPropertyAllocation = (
    sources: AutomaticSource[],
    selectedPropertyAllocation: AllocationObjectType[]
) => {
    if (
        selectedPropertyAllocation.length === 0 ||
        selectedPropertyAllocation.length ===
            Object.values(AllocationObjectType).length
    ) {
        return sources;
    }

    return sources.filter((source) => {
        const { allocations } = source;

        const propertyAllocations = uniq(
            compact(
                allocations.map((allocation) =>
                    allocation.assetId
                        ? AllocationObjectType.ASSET
                        : allocation.property
                        ? AllocationObjectType[allocation.property.objectType]
                        : undefined
                )
            )
        );

        return (
            intersection(propertyAllocations, selectedPropertyAllocation)
                .length > 0
        );
    });
};

export const filterAutomaticSources = (
    sources: AutomaticSource[],
    selectedStatus: DataSourceStatusFilter[],
    selectedIntegrationType: IntegrationType[],
    selectedConsumptionType: ConsumptionType[],
    selectedPropertyAllocation: AllocationObjectType[]
) => {
    let sourcesState: AutomaticSource[] = sources;
    sourcesState = filterSourcesByStatus(sourcesState, selectedStatus);
    sourcesState = filterSourcesByIntegrationType(
        sourcesState,
        selectedIntegrationType
    );
    sourcesState = filterSourcesByConsumptionType(
        sourcesState,
        selectedConsumptionType
    );
    sourcesState = filterSourcesByPropertyAllocation(
        sourcesState,
        selectedPropertyAllocation
    );

    return sourcesState;
};
