import { TFunction } from "i18next";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import {
    AllocationObjectType,
    AssetAutomaticDataSourceItemFragment,
    AutomaticSourceAllocationFragment,
    PropertyObjectType,
} from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils";
import { getPropertyObjectTypeTranslation } from "utils/translations";

export type AutomaticSource = AssetAutomaticDataSourceItemFragment;

const isAutomaticSourceEPC = (source: AutomaticSource) => {
    return source.type.includes("EPC");
};

export const getAutomaticSourceType = (
    source: AutomaticSource,
    t: TFunction
) => {
    if (isAutomaticSourceEPC(source)) {
        return source.type.split("_").join(" ");
    }

    return t("dataQuality.source.automaticMeter", "Automatic Meter", {
        ns: "translation",
    });
};

export const getAutomaticSourceStatus = (status?: boolean | null) => {
    switch (status) {
        case true:
            return DataSourceStatus.ACTIVE;
        default: {
            return DataSourceStatus.EXPIRED;
        }
    }
};

export const getAutomaticSourceAllocationTypeLabel = (
    allocations: AutomaticSourceAllocationFragment[]
) => {
    if (allocations.length === 0) {
        return DASH_SEPARATOR;
    }

    const objectTypeCount = allocations.reduce((acc, curr) => {
        const objectType = curr.property
            ? PropertyObjectType[curr.property.objectType]
            : AllocationObjectType.ASSET;

        if (!acc[objectType]) {
            acc[objectType] = 0;
        }

        acc[objectType] += 1;
        return acc;
    }, {} as Record<PropertyObjectType | AllocationObjectType, number>);

    return Object.entries(objectTypeCount)
        .map(([objectType, count]) => {
            const label = getPropertyObjectTypeTranslation(
                objectType as PropertyObjectType | AllocationObjectType
            );
            const countLabel = count > 1 ? ` (${count})` : "";

            return `${label}${countLabel}`;
        })
        .join(" / ");
};
