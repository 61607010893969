import { useTranslation } from "react-i18next";

import { Maybe } from "graphql-types/graphql";

export function useRegionTranslation() {
    const { i18n } = useTranslation();

    const regionNames = new Intl.DisplayNames([i18n.language], {
        type: "region",
    });

    const getRegionName = (regionCode?: Maybe<string>) => {
        try {
            return regionNames.of(regionCode ?? "") ?? "";
        } catch (error) {
            return regionCode ?? "";
        }
    };

    return {
        getRegionName,
    };
}
