import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { EditMappingState } from "./IntegrationOnboardingTypes";

type ConfirmationModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    selectedLocations: EditMappingState;
    operation: "update" | "delete";
};
const getConfirmationMessage = (
    operation: "update" | "delete",
    selectedLocations: EditMappingState
) => {
    const locationIds = Object.keys(selectedLocations).filter(
        (location) => selectedLocations[location].integrations.length
    );

    return (
        <Box>
            {locationIds.map((locationId) => {
                const { displayName, integrations, period } =
                    selectedLocations[locationId];

                return (
                    <Box key={locationId}>
                        <Typography>
                            <b>{displayName}</b> - {integrations.length}{" "}
                            integrations will be {operation}d
                        </Typography>
                        {operation === "update" && (
                            <>
                                <Typography>
                                    From:{" "}
                                    {period.from?.toFormat("dd/MM/yyyy") ??
                                        "Null"}{" "}
                                    - To:{" "}
                                    {period.to?.toFormat("dd/MM/yyyy") ??
                                        "Null"}
                                </Typography>
                            </>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export const ConfirmationModal = ({
    open,
    onClose,
    onConfirm,
    operation,
    selectedLocations,
}: ConfirmationModalProps) => {
    const { t } = useTranslation();

    const title =
        operation === "update"
            ? t("labels.update", "Update")
            : t("labels.delete", "Delete");

    const message = getConfirmationMessage(operation, selectedLocations);

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle variant="h3">{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions sx={{ bgcolor: "grey.100" }}>
                <Button onClick={onClose} color="error">
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button onClick={handleConfirm} variant="outlined" autoFocus>
                    {t("labels.confirm", "Confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
