import { Check, Upload } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ContextMenu } from "components/ContextMenu";

type Props = {
    file: File | undefined;
    onFileSelect: (file: File | null) => void;
};

enum ContextOptions {
    update = "update",
    delete = "delete",
}

export const FileUploadField = (props: Props) => {
    const { file, onFileSelect } = props;

    const { t } = useTranslation();

    const isFileSelected = Boolean(file);

    const [filename, setFilename] = useState<string | null>(file?.name || null);
    const ref = useRef<HTMLInputElement | null>(null);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files || files.length < 1) return;

        const file = files[0];

        setFilename(file?.name);
        onFileSelect(file);
    };

    const Icon = isFileSelected ? Check : Upload;

    const options = [
        { label: t("labels.update", "Update"), value: ContextOptions.update },
        { label: t("labels.delete", "Delete"), value: ContextOptions.delete },
    ];

    const onChangeContextMenu = (value: ContextOptions) => {
        switch (value) {
            case "delete":
                if (ref.current) ref.current.value = "";
                setFilename(null);
                onFileSelect(null);
                break;
            case "update":
                ref.current?.click();
                break;
        }
    };

    return (
        <ContextMenu
            options={options}
            onChange={onChangeContextMenu}
            enabled={isFileSelected}
        >
            <Tooltip title={filename}>
                <IconButton component="label" role={undefined}>
                    <Icon color="primary" />
                    <input
                        ref={ref}
                        type="file"
                        onChange={handleFileUpload}
                        hidden
                    />
                </IconButton>
            </Tooltip>
        </ContextMenu>
    );
};
