import { Square, SquareOutlined } from "@mui/icons-material";
import { Box, Chip, Typography, styled, useTheme } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import Popover from "components/Popovers/Popover";
import { IntegrationType, AssessmentVertical } from "graphql-types/graphql";
import {
    assessmentVerticalToTitle,
    assessmentVerticalToColor,
    MandatoryVerticals,
} from "utils/assessment";

type VerticalCoverageCellProps = {
    verticals: {
        vertical: AssessmentVertical;
        sources: IntegrationType[];
    }[];
};

const ColorSquaresBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
}));

const PopoverContent = ({
    title,
    color,
    active,
    sources,
}: {
    title: string;
    color: string;
    active: boolean;
    sources: string[];
}) => {
    const { t } = useTranslation();

    const Shape = useCallback(() => {
        const Shape = active ? Square : SquareOutlined;

        return <Shape sx={{ color, borderRadius: 4 }} />;
    }, [active, color]);

    const Content = useCallback(
        () => (
            <Box
                width={140}
                display="flex"
                justifyContent="space-between"
                flexDirection={active ? "column" : "row"}
            >
                <Typography sx={{ color, fontWeight: 700 }}>{title}</Typography>
                {!active ? (
                    <Typography>
                        {t("dataLocationCategory.noData", "No Data")}
                    </Typography>
                ) : (
                    <>
                        <Typography color="grey.500" mb={1} fontSize={14}>
                            {t("dataLocationCategory.source", "Active Sources")}
                            :
                        </Typography>
                        <Box display="flex" gap={1} flexWrap="wrap">
                            {sources.map((source) => (
                                <Chip
                                    key={source}
                                    label={source}
                                    size="small"
                                />
                            ))}
                        </Box>
                    </>
                )}
            </Box>
        ),
        [title, active, color, sources, t]
    );

    return <Popover parent={Shape} children={<Content />} mouseOver={true} />;
};

export const VerticalCoverageCell = ({
    verticals,
}: VerticalCoverageCellProps) => {
    const { t } = useTranslation();
    const { palette } = useTheme();

    const color = useMemo(() => {
        if (!verticals) {
            return [];
        }

        return MandatoryVerticals.map((item) => {
            const vertical = verticals.find((v) => v.vertical === item);

            return {
                title: assessmentVerticalToTitle(item, t),
                color: palette[assessmentVerticalToColor(item) || "primary"]
                    .main,
                active: vertical !== undefined,
                sources:
                    vertical?.sources.map((s) => mapDataQualitySources(s, t)) ||
                    [],
            };
        });
    }, [verticals, palette, t]);

    return (
        <ColorSquaresBox>
            {color.map((color) => (
                <PopoverContent key={color.title} {...color} />
            ))}
        </ColorSquaresBox>
    );
};
