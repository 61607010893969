import { Box, Popover, Typography, styled } from "@mui/material";

export const S = {
    Verticals: styled(Box)(() => ({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    })),

    PopoverPaper: styled(Popover)(({ theme }) => ({
        maxWidth: theme.spacing(50),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.spacing(1),
        boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
        padding: theme.spacing(2, 3),
    })),

    PopoverTitle: styled(Typography)(({ theme }) => ({
        fontWeight: 700,
        marginBottom: theme.spacing(1),
    })),

    PopoverContent: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
    })),
};
