import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { useOrganizationContext } from "context";
import { AssessmentDataType } from "graphql-types/graphql";

import Loading from "../Loading/Loading";
import { AssetsShowingBox } from "../Report/ReportContent";
import TargetSettingModal from "../TargetSettings/TargetSettingsModal";
import useTargetSettings from "../TargetSettings/useTargetSettings";
import YearAndAssessmentTypeFilter from "../YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";

type Props = {
    activeTab: AssessmentDataType;
    baselineYear: number | null;
    handleChange: (value: AssessmentDataType) => void;
};

const NavigateInfo = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
}));

const ForecastDashboardHeader = (props: Props) => {
    const { activeTab, handleChange, baselineYear } = props;

    const { t } = useTranslation(["translation", "report"]);

    const { loading } = useTargetSettings();
    const organization = useOrganizationContext();

    const [activeAssetGroup] = useActiveAssetGroup();

    const valuesLabel =
        activeTab === AssessmentDataType.ESTIMATE
            ? t(
                  "forecastDashboard.chartSubtitle.epcDataSources",
                  "Values are based on Electricity and Heat from EPCs"
              )
            : t(
                  "forecastDashboard.chartSubtitle.allDataSources",
                  "Values are based on a best effort combination of your data sources (which can include EPCs, automatic and/or manual meters readings)"
              );

    return (
        <NavigateInfo>
            <Box maxWidth="50%">
                <Typography variant="h2">
                    {t("pageheader.forecasting", "Forecasting")}
                </Typography>

                <Typography color="grey.400" fontStyle="italic">
                    {valuesLabel}
                </Typography>

                <TargetSettingModal baselineYear={baselineYear} />

                <AssetsShowingBox>
                    {t(
                        "labels.outOfLocations",
                        "Showing {{count}} location out of {{total}} locations",
                        {
                            count: activeAssetGroup.count,
                            total: organization?.allLocationsCount,
                            ns: "report",
                        }
                    )}
                </AssetsShowingBox>
            </Box>

            {loading && (
                <Loading
                    horizontal={true}
                    description={t("loading.title", "Loading")}
                />
            )}

            <YearAndAssessmentTypeFilter
                isYearSelectorHidden
                activeType={activeTab}
                onAssessmentTypeChange={handleChange}
            />
        </NavigateInfo>
    );
};

export default ForecastDashboardHeader;
