import { Card as MuiCard } from "@mui/material";
import { PropsWithChildren, isValidElement } from "react";

import { S } from "./card.styles";

function Title(props: PropsWithChildren) {
    const { children } = props;
    return <S.Title>{children}</S.Title>;
}

function Header(props: PropsWithChildren) {
    const { children } = props;
    const listChildren = Array.isArray(children) ? children : [children];

    const title = listChildren.find((child) => {
        return isValidElement(child) && child.type === Title;
    });

    return <S.Header>{title}</S.Header>;
}

function Content(props: PropsWithChildren) {
    const { children } = props;
    return <S.Content>{children}</S.Content>;
}

function Footer(props: PropsWithChildren) {
    const { children } = props;
    return <S.Footer>{children}</S.Footer>;
}

export const Card = (props: PropsWithChildren) => {
    const { children } = props;

    const listChildren = Array.isArray(children) ? children : [children];

    const header = listChildren.find((child) => {
        return isValidElement(child) && child.type === Header;
    });

    const content = listChildren.find((child) => {
        return isValidElement(child) && child.type === Content;
    });

    const footer = listChildren.find((child) => {
        return isValidElement(child) && child.type === Footer;
    });

    return (
        <MuiCard sx={{ maxWidth: "320px" }}>
            {header}
            {content}
            {footer}
        </MuiCard>
    );
};

Card.Header = Header;
Card.Title = Title;
Card.Content = Content;
Card.Footer = Footer;
