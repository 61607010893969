import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { match } from "ts-pattern";

import { AutomaticDataSourceStatusCell } from "components/Cells/AutomaticDataSourceStatusCell";
import { DataSourceStatus } from "components/Cells/DataStatusCell";
import NationalBuildingIdsCell from "components/Cells/NationalBuildingIdsCell";
import {
    AutomaticSourceAllocationFragment,
    AutomaticSourceFragment,
    ConsumptionType,
    IntegrationType,
} from "graphql-types/graphql";
import { dateSorter } from "pages/DataOverviewPage/data-overview.helper";
import { validateAndFormatDateString } from "utils/date.utils";
import { DASH_SEPARATOR } from "utils/strings.helpers";
import { getConsumptionTypeTranslation } from "utils/translations";

import {
    AutomaticSource,
    getAutomaticSourceAllocationTypeLabel,
    getAutomaticSourceStatus,
} from "../asset-sources.helpers";

export const getColumns = (
    t: TFunction,
    useAssetIntegration: boolean
): GridColDef[] => {
    const nationalPropertyIdColumn: GridColDef = {
        field: "nationalPropertyId",
        headerName: t("columns.nationalPropertyId", "National Property ID", {
            ns: "report",
        }),
        renderCell: ({ value }) =>
            value ? NationalBuildingIdsCell(value.split(",")) : DASH_SEPARATOR,
        flex: 0.75,
        sortable: true,
    };

    return [
        {
            field: "source",
            headerName: t("common.labels.source", "Source", {
                ns: "translation",
            }),
            flex: 0.5,
            renderCell: ({ value }) =>
                AutomaticDataSourceStatusCell({
                    source: value.source,
                    status: value.status,
                }),
            valueFormatter: (value: { source: string }) => value.source ?? "",
            sortComparator: (v1, v2) => v1.source.localeCompare(v2.source),
            sortable: true,
        },
        {
            field: "address",
            headerName: t("columns.address", "Address", { ns: "report" }),
            flex: 1,
            sortable: !useAssetIntegration,
        },
        ...(!useAssetIntegration ? [nationalPropertyIdColumn] : []),
        {
            field: "identifier",
            headerName: t("columns.identifierLong", "Identifier", {
                ns: "report",
            }),
            flex: 0.75,
            sortable: true,
        },
        {
            field: "consumptionType",
            headerName: t("columns.consumptionType", "Consumption Type", {
                ns: "report",
            }),
            valueFormatter: (value?: ConsumptionType | ConsumptionType[]) => {
                if (!value || value.length === 0) {
                    return DASH_SEPARATOR;
                }

                if (Array.isArray(value)) {
                    return value.map(getConsumptionTypeTranslation).join(", ");
                }

                return getConsumptionTypeTranslation(value);
            },
            flex: 0.75,
            sortable: !useAssetIntegration,
        },
        {
            field: "from",
            headerName: t("common.timeframe.from", "From", {
                ns: "translation",
            }),
            valueFormatter: (value: string) =>
                validateAndFormatDateString(value) ?? DASH_SEPARATOR,
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
        {
            field: "to",
            headerName: t(
                "common.timeframe.to_lastReading",
                "To / Last Reading",
                { ns: "translation" }
            ),
            valueFormatter: (value: string) =>
                validateAndFormatDateString(value) ?? DASH_SEPARATOR,
            flex: 0.5,
            sortComparator: dateSorter,
            sortable: true,
        },
    ];
};

export const getAllocationColumns = (t: TFunction): GridColDef[] => [
    {
        field: "propertyAllocation",
        headerName: t("common.allocation", "Property Allocation", {
            ns: "translation",
        }),
        valueFormatter: (value: AutomaticSourceAllocationFragment[]) => {
            if (!value) {
                return DASH_SEPARATOR;
            }

            return getAutomaticSourceAllocationTypeLabel(value);
        },
        flex: 0.5,
    },
    {
        field: "allocationNote",
        headerName: t(
            "asset.sourceDrawer.additionalNotes",
            "Additional Notes",
            { ns: "translation" }
        ),
        valueFormatter: (value: string) => {
            return value ?? "";
        },
        flex: 0.5,
        sortable: true,
    },
];

export const getStatusColor = (
    status: DataSourceStatus
): "active" | "expired" | "manual" => {
    return match(status)
        .with(DataSourceStatus.ACTIVE, () => "active" as const)
        .with(DataSourceStatus.EXPIRED, () => "expired" as const)
        .with(DataSourceStatus.MANUAL, () => "manual" as const)
        .exhaustive();
};

export const mapAutomaticSourceToIntegrationType = (type: string) => {
    if (type === "EPC_PROXY") {
        return IntegrationType.EPC;
    }

    return type as IntegrationType;
};

export const formatAutomaticDataRows = (sources: AutomaticSource[]) => {
    return sources.map((source) => ({
        ...source,
        type: mapAutomaticSourceToIntegrationType(source.type),
        source: {
            status: getAutomaticSourceStatus(source.status),
            source: source.source,
        },
        propertyAllocation: source.allocations,
    }));
};

export const formatAssetIntegrationsToAutomaticSources = (
    assetIntegrations: AutomaticSourceFragment[],
    assetAddress: string
) => {
    return assetIntegrations.map((integration) => ({
        ...integration,
        address: integration.address ?? assetAddress,
        source: {
            status: getAutomaticSourceStatus(integration.isActive),
            source: integration.type,
        },
        consumptionType: integration.consumptionTypes,
        propertyAllocation: integration.allocations,
    }));
};
