import { useQuery } from "@apollo/client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, createContext, useMemo } from "react";

import { LegacyBenchmarkMarker } from "components/types";
import {
    Benchmark,
    GetLegacyBenchmarksQuery,
    GetLegacyBenchmarksQueryVariables,
    GetOrganizationBenchmarksQuery,
    LegacyBenchmark,
    LegacyBenchmarkValueType,
} from "graphql-types/graphql";
import { useLegacyBenchmarkMarker } from "hooks";
import { BENCHMARKS_QUERY } from "queries/benchmarksQuery";
import { LEGACY_BENCHMARKS_QUERY } from "queries/legacyBenchmarksQuery";

type BenchmarksContextType = {
    loading: boolean;
    benchmarks: Benchmark[];
    legacyBenchmarks: LegacyBenchmark[];
    markers: {
        legacyBenchmarks: LegacyBenchmarkMarker[];
    };
};

export const BenchmarksContext = createContext<BenchmarksContextType>({
    loading: false,
    benchmarks: [],
    legacyBenchmarks: [],
    markers: {
        legacyBenchmarks: [],
    },
});

export const BenchmarksProvider = (props: PropsWithChildren) => {
    const { useLegacyBenchmark } = useFlags();
    const { convertToLegacyBenchmarkMarker } = useLegacyBenchmarkMarker();

    const { data, loading: benchmarkLoading } =
        useQuery<GetOrganizationBenchmarksQuery>(BENCHMARKS_QUERY);

    const { data: legacyBenchmarkData, loading: legacyBenchmarkLoading } =
        useQuery<GetLegacyBenchmarksQuery, GetLegacyBenchmarksQueryVariables>(
            LEGACY_BENCHMARKS_QUERY,
            {
                skip: !useLegacyBenchmark,
                variables: {
                    valueType: LegacyBenchmarkValueType.EMISSION,
                },
            }
        );

    const isLoading = benchmarkLoading || legacyBenchmarkLoading;

    const benchmarks = useMemo(() => {
        if (!data) return [];

        return data.benchmarks.edges.map((edge) => edge.node);
    }, [data]);

    const legacyBenchmarks = legacyBenchmarkData?.getLegacyBenchmarks ?? [];

    const legacyBenchmarkMarkers =
        convertToLegacyBenchmarkMarker(legacyBenchmarks);

    return (
        <BenchmarksContext.Provider
            value={{
                benchmarks,
                legacyBenchmarks,
                markers: {
                    legacyBenchmarks: legacyBenchmarkMarkers,
                },
                loading: isLoading,
            }}
        >
            {props.children}
        </BenchmarksContext.Provider>
    );
};
