import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { NavTabs } from "glue/NavTabs";

const ReportsListPage = () => {
    const { t } = useTranslation(["report"]);

    const tabs = [
        {
            path: "/reports",
            label: t("reportsList.frameworks", "Frameworks", {
                ns: "report",
            }),
        },
        {
            path: "/reports/saved",
            label: t("reportsList.savedReports", "Saved Reports", {
                ns: "report",
            }),
        },
    ];

    return (
        <>
            <Typography variant="h2" sx={{ mb: 4 }}>
                {t("reportsList.title", "Reports", { ns: "report" })}
            </Typography>
            <NavTabs tabs={tabs} />

            <Outlet />
        </>
    );
};

export default ReportsListPage;
