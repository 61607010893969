import { gql } from "@apollo/client";

export const ASSET_MANUAL_SOURCES = gql`
    query GetAssetManualSources($locationId: ID!) {
        location(id: $locationId) {
            displayName
            manualAssessments {
                id
                consumption
                consumptionType
                unit
                to
                from
                vertical
                createdAt
                updatedAt
                origin {
                    file {
                        name
                        url
                    }
                    assessmentType
                }
                isEditable
                data {
                    ... on ManualAssessmentData {
                        identifier
                    }
                    ... on IntegrationEpcAssessmentData {
                        classification
                        pdfLink
                    }
                    ... on IntegrationEpcUkAssessmentData {
                        lmkKey
                    }
                }
            }
        }
    }
`;
