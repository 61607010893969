import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GetForecastAssessmentsQuery } from "graphql-types/graphql";

import ForecastDashboardTabs from "./ForecastDashboardTabs";
import { FORECAST_DASHBOARD_QUERY } from "./forecastQueries";
import Loading from "../Loading/Loading";
import { TargetSettingProvider } from "../TargetSettings/useTargetSettings";

const ForecastDashboard = () => {
    const { t } = useTranslation(["translation", "report"]);

    const { data, loading } = useQuery<GetForecastAssessmentsQuery>(
        FORECAST_DASHBOARD_QUERY
    );

    const organization = useMemo(() => data?.me?.organization || null, [data]);
    const locations = useMemo(
        () => organization?.getLocations?.map((l) => l) || null,
        [organization]
    );
    const baselineYear = useMemo(
        () => organization?.baselineYear || null,
        [organization]
    );
    const targetSettings = useMemo(
        () => organization?.targetSetting || null,
        [organization]
    );

    const pathways = useMemo(
        () => data?.pathways.edges.map((e) => e.node) || [],
        [data]
    );

    if (loading || !organization || !locations) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <TargetSettingProvider value={{ pathways, targetSettings }}>
            <ForecastDashboardTabs
                locations={locations}
                baselineYear={baselineYear}
            />
        </TargetSettingProvider>
    );
};

export default ForecastDashboard;
