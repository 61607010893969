import { styled, TextField } from "@mui/material";

export const S = {
    NumberInput: styled(TextField)(({ disabled, error, theme }) => ({
        "& .MuiInputBase-input": {
            textAlign: "right",
        },

        "& > *, & .MuiOutlinedInput-input.Mui-disabled": {
            color: `${error ? "red" : "blue"} !important`,
            WebkitTextFillColor: error ? "red" : "blue",
        },

        "& .MuiOutlinedInput-root": {
            background: theme.palette.grey[100],

            "& fieldset": {
                border: "none",
            },
        },

        ...(disabled && {
            "& .MuiOutlinedInput-root": {
                background: "white",

                "& fieldset": {
                    border: "none",
                },
            },
        }),
    })),
};
