import { Outlet } from "react-router-dom";

import { Page } from "glue";
import { BenchmarksProvider } from "utils/benchmarksContext";

const AssessmentRoute = () => {
    return (
        <BenchmarksProvider>
            <Page>
                <Outlet />
            </Page>
        </BenchmarksProvider>
    );
};

export default AssessmentRoute;
