import { styled, Box, Typography, Button } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { convertToRem } from "utils/maths";

const StyledModalStepBody = styled(Box)({
    maxHeight: "60vh",
    overflowY: "scroll",
});

const ModalHeaderTypo = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
}));

const StyledButton = styled(Button)(() => ({
    fontSize: convertToRem(14),
}));

const StyledInnerBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(1.5, 4),
}));

interface StepProps {
    header: React.ReactNode;
    body: React.ReactNode;
    disableSubmitBtn: boolean;
    onSubmit: (() => void) | null;
    onSelectAll?: (isSelectAll: boolean) => void;
}

const GenericModalStep: FC<StepProps> = ({
    header,
    body,
    disableSubmitBtn,
    onSubmit,
    onSelectAll,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <ModalHeaderTypo>{header}</ModalHeaderTypo>
            <StyledModalStepBody>{body}</StyledModalStepBody>
            {onSubmit && (
                <StyledInnerBox>
                    {onSelectAll && (
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <StyledButton
                                variant="contained"
                                onClick={() => onSelectAll(true)}
                            >
                                {t(
                                    "integrationOnboarding.selectAll",
                                    "Select All"
                                )}
                            </StyledButton>
                            <StyledButton
                                variant="contained"
                                onClick={() => onSelectAll(false)}
                            >
                                {t(
                                    "integrationOnboarding.deselectAll",
                                    "Deselect All"
                                )}
                            </StyledButton>
                        </Box>
                    )}

                    <StyledButton
                        variant="contained"
                        onClick={onSubmit}
                        disabled={disableSubmitBtn}
                        sx={{ ml: "auto", mr: 0 }}
                    >
                        {t("integrationOnboarding.submit", "Submit")}
                    </StyledButton>
                </StyledInnerBox>
            )}
        </>
    );
};

export default GenericModalStep;
