import { Box, List, ListItem, styled } from "@mui/material";

export const S = {
    Preview: styled(Box)(({ theme }) => ({
        overflow: "auto",
        border: "1px solid #d9e1fc",
        height: theme.spacing(84),
    })),

    EmptyPreview: styled(Box)(({ theme }) => ({
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(3),
    })),

    List: styled(List)(() => ({
        height: "100%",
        paddingTop: 0,
    })),

    ListItem: styled(ListItem)(({ theme }) => ({
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #d9e1fc",
        gap: theme.spacing(12),
        padding: theme.spacing(3, 4),

        "&:last-child": {
            borderBottom: 0,
        },

        "&.new": {
            backgroundColor: "#8de388",
        },

        "&.removed": {
            backgroundColor: "#e38888",
        },

        "&.unchanged": {
            backgroundColor: theme.palette.background.paper,
        },
    })),
};
