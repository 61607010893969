import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    styled,
    Button,
} from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";

import { FormState, HandleChangeEventTarget } from "../types";

const ContentBox = styled(Box)(({ theme }) => ({
    margin: theme.spacing(8, 6),
    borderRadius: theme.spacing(2),
    background: theme.palette.common.white,
    boxShadow: `0 ${theme.spacing(2)}px ${theme.spacing(
        6
    )}px 0 rgba(133, 133, 133, 0.1)`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: `0px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
}));

type Props = {
    formValues: FormState;
    integrations: readonly SupportedIntegrationForOnboarding[];
    handleChange: (e: { target: HandleChangeEventTarget }) => void;
    onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void;
};

const IntegrationSelector = (props: Props) => {
    const { formValues, integrations, handleChange, onSubmit } = props;
    return (
        <ContentBox>
            <form onSubmit={onSubmit}>
                <FormControl fullWidth>
                    <InputLabel id="integration-select-label">
                        <Trans i18nKey="integrationOnboarding.integrations">
                            Integrations
                        </Trans>
                    </InputLabel>
                    <Select
                        labelId="integration-select-label"
                        name="integration"
                        label="integrations"
                        value={formValues.integration}
                        style={{
                            padding: "4px",
                            borderRadius: "4px 4px 0px 0px",
                        }}
                        onChange={handleChange}
                        required
                    >
                        {integrations.map((int, i) => (
                            <MenuItem
                                key={i}
                                value={int}
                                id={`integration-${int}`}
                            >
                                {int}
                            </MenuItem>
                        ))}
                    </Select>
                    <StyledButton
                        variant="contained"
                        color="primary"
                        type="submit"
                    >
                        <Trans i18nKey="integrationOnboarding.submit">
                            Submit
                        </Trans>
                    </StyledButton>
                </FormControl>
            </form>
        </ContentBox>
    );
};

export default IntegrationSelector;
