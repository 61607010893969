import { Box, styled } from "@mui/material";

export const S = {
    Label: styled(Box)(() => ({
        display: "flex",
        gap: 4,
        textTransform: "capitalize",

        "& .value": {
            fontWeight: "bold",
        },
    })),
};
