import { gql } from "@apollo/client";

export const ADD_ORGANIZATION_INTEGRATION_WITH_DATA_MUTATION = gql`
    mutation AddOrganizationIntegrationWithData(
        $integrationData: OrganizationIntegrationData!
    ) {
        addOrganizationIntegrationWithData(integrationData: $integrationData) {
            id
        }
    }
`;

export const ADD_ORGANIZATION_INTEGRATION_MUTATION = gql`
    mutation AddOrganizationIntegration(
        $input: CreateOneOrganizationIntegrationInput!
    ) {
        createOneOrganizationIntegration(input: $input) {
            id
        }
    }
`;
