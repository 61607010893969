import { Box, styled } from "@mui/material";

const StyledChartContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    marginTop: theme.spacing(10),
    minHeight: theme.spacing(110),
}));

type Props = {
    children: React.ReactNode;
};

function ChartContainer(props: Props) {
    return <StyledChartContainer>{props.children}</StyledChartContainer>;
}

export default ChartContainer;
