import { Dialog, styled } from "@mui/material";

export const S = {
    Dialog: styled(Dialog)(({ theme }) => ({
        zIndex: 999999,
        tabIndex: 3,
        "& .MuiDialog-paper": {
            width: theme.spacing(100),
            borderRadius: theme.spacing(2),
        },
    })),
};
