import { Box } from "@mui/material";
import { PropsWithChildren, isValidElement, useEffect, useState } from "react";

import { S } from "./drawer.styles";

function WaterMark(props: PropsWithChildren) {
    const { children } = props;
    return <S.WaterMark>{children}</S.WaterMark>;
}

function Title(props: PropsWithChildren) {
    const { children } = props;
    return <S.Title>{children}</S.Title>;
}

function Panel(props: PropsWithChildren) {
    const { children } = props;
    return children;
}

function Header(props: PropsWithChildren) {
    const { children } = props;
    const listChildren = Array.isArray(children) ? children : [children];

    const waterMark = listChildren.find((child) => {
        return isValidElement(child) && child.type === WaterMark;
    });
    const title = listChildren.find((child) => {
        return isValidElement(child) && child.type === Title;
    });
    const panel = listChildren.find((child) => {
        return isValidElement(child) && child.type === Panel;
    });
    return (
        <S.Header>
            {waterMark}
            {title}
            {panel}
        </S.Header>
    );
}

function Content(props: PropsWithChildren) {
    const { children } = props;
    return <Box>{children}</Box>;
}

type Props = {
    isOpen: boolean;
    onClose: () => void;
    width?: string | number;
    anchor?: "left" | "top" | "right" | "bottom";
};

export function Drawer(props: PropsWithChildren<Props>) {
    const { isOpen, width, onClose, children, anchor } = props;

    const [toggleDrawer, setToggleDrawer] = useState(false);

    function onCloseDrawer() {
        setToggleDrawer(!toggleDrawer);
        onClose();
    }

    useEffect(() => {
        setToggleDrawer(isOpen);
    }, [isOpen]);

    const listChildren = Array.isArray(children) ? children : [children];

    const header = listChildren.find((child) => {
        return isValidElement(child) && child.type === Header;
    });

    const content = listChildren.find((child) => {
        return isValidElement(child) && child.type === Content;
    });

    return (
        <S.Drawer
            PaperProps={{ sx: { minWidth: "30vw", width } }}
            anchor={anchor}
            open={toggleDrawer}
            onClose={onCloseDrawer}
        >
            <S.CloseBox>
                <S.CloseIcon onClick={onCloseDrawer} />
            </S.CloseBox>
            {header}
            {content}
        </S.Drawer>
    );
}

Drawer.Title = Title;
Drawer.Header = Header;
Drawer.Content = Content;
Drawer.WaterMark = WaterMark;
Drawer.Panel = Panel;
