import { Box, Tab, Tabs, Typography, styled } from "@mui/material";
import { ComponentProps, ElementType } from "react";
import { Link } from "react-router-dom";

import { theme } from "styling/themes";

export const TabsContainer = styled(Box)(({ theme }) => ({
    height: theme.spacing(9),
    display: "flex",
    maxWidth: "100%",
    width: "fit-content",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "7px",
}));

export const TabsBox = styled(Tabs)(({ theme }) => ({
    minHeight: theme.spacing(9),
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 600px)"]: {
        width: theme.spacing(87.5),
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 500px)"]: {
        width: theme.spacing(62.5),
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 400px)"]: {
        width: "70vw",
    },
}));

export const StyledTab = styled(
    (
        props: ComponentProps<typeof Tab> &
            ComponentProps<typeof Link> & {
                path: string;
                component?: ElementType;
            }
    ) => <Tab disableRipple {...props} />
)(({ theme }) => ({
    minHeight: theme.spacing(9),
    textAlign: "left",
    color: theme.palette.text.primary,
    fontSize: theme.spacing(3.25),
    padding: theme.spacing(2, 10),
    fontWeight: "bold",
    whiteSpace: "nowrap",
    zIndex: 1,

    "&.Mui-selected": {
        color: theme.palette.background.paper,
    },

    "&:focus": {
        outline: "0px auto -webkit-focus-ring-color",
    },

    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width: 400px)"]: {
        minWidth: "20px",
    },
}));

export const TabSubtext = styled(Typography)(({ theme }) => ({
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: theme.spacing(2.8),
    textWrap: "wrap",
}));

export const tabIndicatorProps = {
    style: {
        height: "100%",
        padding: "10px 4px",
        borderRadius: "7px",
        border: "solid 1px #dee8ff",
        backgroundColor: theme.palette.primary.main,
    },
};
