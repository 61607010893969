// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { isEmpty } from "lodash";

import apolloClient from "./apolloClient";

const TOKEN_KEY = "jwtToken";
const USER_INFO = "userInfo";

const parse = JSON.parse;
const stringify = JSON.stringify;

const auth = {
    clearAppStorage() {
        apolloClient.clearStore();
        if (localStorage) {
            const myLanguageSetting = localStorage.getItem("i18nextLng");
            localStorage.clear();
            localStorage.setItem("i18nextLng", myLanguageSetting);
        }

        if (sessionStorage) {
            sessionStorage.clear();
        }
    },

    get(key) {
        if (localStorage && localStorage.getItem(key)) {
            return parse(localStorage.getItem(key)) || null;
        }

        if (sessionStorage && sessionStorage.getItem(key)) {
            return parse(sessionStorage.getItem(key)) || null;
        }

        return null;
    },

    getToken(tokenKey = TOKEN_KEY) {
        return auth.get(tokenKey);
    },

    getUserInfo(userInfo = USER_INFO) {
        return auth.get(userInfo);
    },

    getEndpointUrl() {
        return process.env.REACT_APP_API_URL;
    },

    set(value, key) {
        if (isEmpty(value)) {
            return null;
        }

        if (localStorage) {
            return localStorage.setItem(key, stringify(value));
        }

        if (sessionStorage) {
            return sessionStorage.setItem(key, stringify(value));
        }

        return null;
    },

    setToken(value = "", tokenKey = TOKEN_KEY) {
        return auth.set(value, tokenKey);
    },

    setUserInfo(value = "", userInfo = USER_INFO) {
        return auth.set(value, userInfo);
    },
};

export default auth;
