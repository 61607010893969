import { useQuery } from "@apollo/client";
import { Stack, useTheme, Box } from "@mui/material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";

import { DATA_QUALITY_QUERY } from "./Queries/dataQualityQuery";
import { Label } from "../../styling/typography";
import Card from "../Cards/Card";
import { formatDataQualityToDataQualityItems } from "../DataQuality/DataQuality.helpers";
import DataQualityDistributionBar from "../DataQuality/DataQualityDistributionBar";
import { DataQualitySummary } from "../DataQuality/DataQualitySummary";
import InfoPopover from "../Popovers/InfoPopover";
import { useSelectedYearContext } from "../YearSelector/YearContext";

interface InputProps {
    activeTab: AssessmentDataType;
}

export const DataQualityTooltipInfo = () => {
    return (
        <Trans i18nKey="dataQuality.tooltipInfo" style={{ width: 20 }}>
            Our data quality breakdown will define the quality of consumption
            sources used on each asset and aggregate it on a portfolio level.
            The quality parameters are based on the following.
            <br />
            <br />
            <b>High</b> - All automatic meter readings.
            <br />
            <b>Medium</b> - EPC data and manual meter reading with
            documentation.
            <br />
            <b>Low</b> - EPC proxy data and manual meter reading without
            documentation.
        </Trans>
    );
};

function PortfolioDataQuality(props: InputProps) {
    const { activeTab } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const { activeYear } = useSelectedYearContext();

    const variables: GetDataQualityQueryVariables = useMemo(() => {
        return {
            from: `${activeYear}-01-01`,
            to: `${activeYear}-12-31`,
            assessmentDataType: activeTab,
        };
    }, [activeTab, activeYear]);

    const { data, previousData } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables,
    });

    const dataQualityForOrganization = useMemo(() => {
        const dataLoader = data ?? previousData;

        const dataQualityInActiveYear =
            dataLoader?.me.organization?.dataQuality.find(
                (d) => new Date(d.from).getFullYear() === activeYear
            );

        return dataQualityInActiveYear;
    }, [data, previousData, activeYear]);

    const dataQualityItems = useMemo(
        () =>
            dataQualityForOrganization
                ? formatDataQualityToDataQualityItems(
                      dataQualityForOrganization,
                      t
                  )
                : [],
        [dataQualityForOrganization, t]
    );

    if (!dataQualityForOrganization) {
        return null;
    }

    return (
        <Card>
            <Stack gap={theme.spacing(1)}>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={theme.spacing(0.5)}
                    justifyContent={"space-between"}
                >
                    <Label>
                        {t(
                            "portfolioDashboard.boxes.yourDataQuality",
                            "Your Data Quality"
                        )}
                    </Label>
                    <InfoPopover element={<DataQualityTooltipInfo />} />
                </Box>

                {dataQualityForOrganization && (
                    <DataQualityDistributionBar
                        {...dataQualityForOrganization}
                    />
                )}

                <DataQualitySummary
                    dataQuality={dataQualityItems}
                    hasFullTitle={true}
                />
            </Stack>
        </Card>
    );
}

export default PortfolioDataQuality;
