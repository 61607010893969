import { gql } from "@apollo/client";

export const ACTIVE_INTEGRATIONS = gql`
    query ActiveOrgIntegrations {
        me {
            id
            organization {
                id
                name
                integrations {
                    id
                    type
                }
            }
        }
    }
`;

export const CREATE_ORG_INTEGRATIONS = gql`
    mutation CreateOrganizationIntegrations(
        $createManyOrganizationIntegrations: CreateManyOrganizationIntegrationsInput!
    ) {
        createManyOrganizationIntegrations(
            input: $createManyOrganizationIntegrations
        ) {
            id
            __typename
        }
    }
`;
