import { Chip, styled } from "@mui/material";

export const Tag = styled(Chip)(({ theme }) => ({
    padding: theme.spacing(0.5, 2),
    fontWeight: "bold",
    fontSize: "80%",
    height: "auto",
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    "& .MuiChip-label": {
        padding: 0,
    },
}));
