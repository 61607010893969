import { createContext, PropsWithChildren, useContext, useState } from "react";

import { downloadCSV } from "utils/file.utils";

type DownloadData = {
    data: string[][];
    fileName: string;
};

type DownloadContextType = {
    onDownload: (updatedFileName?: string, filterRow?: string) => void;
    setDownloadData: (data: DownloadData) => void;
};

const DownloadContext = createContext<DownloadContextType | undefined>(
    undefined
);

export const useDownloadContext = () => {
    const context = useContext(DownloadContext);
    if (!context) {
        throw new Error(
            "useDownloadContext must be used within a DownloadProvider"
        );
    }
    return context;
};

export const DownloadProvider = (props: PropsWithChildren) => {
    const [downloadData, setDownloadData] = useState<
        DownloadData | undefined
    >();

    const onDownload = (updatedFileName?: string, filterRow?: string) => {
        if (!downloadData) return;

        const { data, fileName } = downloadData;
        const filteredRows = data.filter(
            (row, index) =>
                !filterRow ||
                index === 0 || // Keep the header
                row[0].toLowerCase().includes(filterRow.toLowerCase())
        );

        downloadCSV(filteredRows, updatedFileName || fileName);
    };

    return (
        <DownloadContext.Provider value={{ onDownload, setDownloadData }}>
            {props.children}
        </DownloadContext.Provider>
    );
};
