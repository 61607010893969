import { useQuery } from "@apollo/client";
import { TFunction } from "i18next";
import { useMemo } from "react";

import {
    AssessmentAggregation,
    GetEmissionSummariesQuery,
    GetEmissionSummariesQueryVariables,
    AssessmentDataType,
} from "graphql-types/graphql";
import {
    getCurrentYear,
    getStartAndEndOfYear,
    getYTDAmountOfValue,
} from "utils/date.utils";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import { BenchmarkPerformanceChartData } from "./charts.types";
import { UnitMass } from "../../AssessmentStats/types";
import { EMISSION_SUMMARIES_QUERY } from "../Queries/emissionSummariesQuery";

export const getYearLabel = (year: number, t: TFunction): string => {
    const currentYear = new Date().getFullYear();
    const isCurrentYear = currentYear === year;

    return isCurrentYear
        ? year + t("portfolioDashboard.yearSelect.ytd", " YTD")
        : year.toString();
};

export const getActiveBenchmarkFromPerformanceData = (
    performanceData: BenchmarkPerformanceChartData[] | null,
    activeYear: number
) => {
    let benchmark = performanceData ? performanceData[0].benchmark : null;

    if (benchmark) {
        const isSelectedYearCurrentYear = activeYear === getCurrentYear();
        benchmark = isSelectedYearCurrentYear
            ? getYTDAmountOfValue(benchmark)
            : benchmark;
    }

    return benchmark;
};

export const getFormattedConvertedGramsToMassUnit = (
    value: number | null,
    unit: UnitMass,
    t: TFunction,
    isValuePerArea = true
) => {
    if (!value) return null;

    const roundedValue = getConvertedGramsToMassUnit(value, unit);
    return getFormattedValueToMassUnit(roundedValue, unit, t, isValuePerArea);
};

export const getFormattedValueToMassUnit = (
    value: number | null,
    unit: UnitMass,
    t: TFunction,
    isValuePerArea = true
) => {
    if (!value) return null;

    const formattedValue = formatNumberLocale(value, t);
    const unitString = getUnitMassString(unit, t);

    return `${formattedValue} ${unitString} ${
        isValuePerArea
            ? t("portfolioDashboard.chartHelpers.CO2em2", "CO2e / m²")
            : t("portfolioDashboard.chartHelpers.CO2e", "CO2e")
    }`;
};

export const getUnitMassString = (unit: UnitMass, t: TFunction) => {
    switch (unit) {
        case UnitMass.KILOGRAM:
            return t("portfolioDashboard.chartHelpers.kg", "kg");
        case UnitMass.TON:
            return t("portfolioDashboard.chartHelpers.t", "t");
    }
};

const getQueryVariablesForMonthlyData = (
    year: number,
    dataType: AssessmentDataType
) => {
    const yearRange = getStartAndEndOfYear(year);
    return {
        aggregation: AssessmentAggregation.MONTH,
        from: yearRange.from,
        to: yearRange.to,
        ignoreProxy: dataType !== AssessmentDataType.COMBINED,
        filter: { dataType },
        dataType,
    };
};

export const useGetMonthlyEmissionSummaries = (
    activeYear: number,
    activeDataType: AssessmentDataType
) => {
    const { data, loading, previousData } = useQuery<
        GetEmissionSummariesQuery,
        GetEmissionSummariesQueryVariables
    >(EMISSION_SUMMARIES_QUERY, {
        variables: getQueryVariablesForMonthlyData(activeYear, activeDataType),
    });

    const emissionSummaries = useMemo(() => {
        const dataLoader = data ?? previousData;

        return dataLoader?.me.organization?.getLocations ?? [];
    }, [data, previousData]);

    return { emissionSummaries, loading };
};
