import { AllocationObjectType } from "graphql-types/graphql";

import { EditItem } from "../EditableFieldList";

export type AllocationItem = {
    label: string;
    value: number;
    isChecked: boolean;
};

export type PropertyAllocationEditItem = Required<EditItem> & {
    objectType: AllocationObjectType;
};

export type PropertyAllocationItem = {
    id: string;
    depth: number;
    name: string;
    objectType: AllocationObjectType;
    allocation?: number;
};

export enum AllocationError {
    AllocationZero = "AllocationZero",
    TotalOver100 = "TotalOver100",
}
