import { Typography, CircularProgress, Box, styled } from "@mui/material";

export const LoadingOverlay = styled(Box)(() => ({
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
}));

const StyledLoadingBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
});

type Props = {
    description: string;
    horizontal?: boolean;
};

const Loading = (props: Props) => {
    const { description, horizontal } = props;
    return (
        <StyledLoadingBox
            style={{ flexDirection: horizontal ? "row-reverse" : "column" }}
        >
            <Typography>{description}</Typography>
            <CircularProgress />
        </StyledLoadingBox>
    );
};

export default Loading;
