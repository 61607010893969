import { Box } from "@mui/material";

import YearAndAssessmentTypeFilter from "components/YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";
import { useSelectedYearContext } from "components/YearSelector/YearContext";

import { AssetEmissionSummaryInventory } from "./AssetEmissionSummaryInventory";
import { useAssessmentDataTypeContext } from "../AssessmentDataTypeContext";

const AssetInventory = ({ locationId }: { locationId: string }) => {
    const { assessmentType, setAssessmentType } =
        useAssessmentDataTypeContext();

    const { activeFrom, activeTo } = useSelectedYearContext();

    return (
        <>
            <Box display="flex" justifyContent="flex-end" marginBottom={4}>
                <YearAndAssessmentTypeFilter
                    activeType={assessmentType}
                    onAssessmentTypeChange={setAssessmentType}
                />
            </Box>
            <AssetEmissionSummaryInventory
                locationId={locationId}
                from={activeFrom}
                to={activeTo}
                assessmentType={assessmentType}
            />
        </>
    );
};

export default AssetInventory;
