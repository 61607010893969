import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentVertical } from "graphql-types/graphql";

import AssetsCheckbox, { AssetsCheckboxValue } from "./AssetsCheckbox";
import RadioDropdown, { DropDownPerformanceType } from "./RadioDropdown";
import SearchFilter from "./SearchFilter";
import { VerticalFilter } from "./VerticalFilter";
import OrganizationYearSelector from "../YearSelector/OrganizationYearSelector";

export interface FilterState {
    performance: DropDownPerformanceType;
    assets: AssetsCheckboxValue[];
    searchInput: string;
    verticals: AssessmentVertical[];
}

export const emptyFilterState: FilterState = {
    performance: DropDownPerformanceType.ALL,
    assets: [],
    searchInput: "",
    verticals: [],
};

interface FilterProps {
    filterState: FilterState;
    totalAssets?: number;
    onFilterChange: (key: keyof FilterState | FilterState, value?: any) => void;
}

const FilterBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "0.25rem",
    "& > *": {
        marginBottom: `${theme.spacing(2)} !important`,
    },

    margin: theme.spacing(4, 0),
}));

export default function AssetListFilter(props: FilterProps) {
    const { totalAssets, filterState, onFilterChange } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const [performance, setPerformance] = useState(
        filterState.performance ?? "All"
    );
    const [assetNames, setAssetNames] = useState<AssetsCheckboxValue[]>(
        filterState.assets ?? []
    );
    const [inputVal, setInputVal] = useState(filterState.searchInput ?? "");
    const [verticals, setVerticals] = useState<AssessmentVertical[]>(
        filterState.verticals ?? []
    );

    const clearFilter = useCallback(() => {
        setPerformance(DropDownPerformanceType.ALL);
        setAssetNames([]);
        setInputVal("");
        setVerticals([]);

        onFilterChange(emptyFilterState);
    }, [onFilterChange]);

    const handlePerformanceChange = useCallback(
        (value: DropDownPerformanceType) => {
            onFilterChange("performance", value);
            setPerformance(value);
        },
        [onFilterChange]
    );

    const handleAssetsChange = (dataSource: AssetsCheckboxValue[]) => {
        setAssetNames(dataSource);
        onFilterChange("assets", dataSource);
    };

    const debouncedSearch = debounce(async (text: string) => {
        onFilterChange("searchInput", text);
    }, 800);

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            debouncedSearch(e.target.value);
            setInputVal(e.target.value);
        },
        [debouncedSearch]
    );

    const assessmentVerticals = useMemo(() => {
        return Object.values(AssessmentVertical).filter(
            (v) => v !== AssessmentVertical.UN_CATEGORIZED
        );
    }, []);

    const handleVerticalChange = useCallback(
        (verticals: AssessmentVertical[]) => {
            onFilterChange("verticals", verticals);
            setVerticals(verticals);
        },
        [onFilterChange]
    );

    return (
        <FilterBox>
            <SearchFilter inputValue={inputVal} onChange={handleInputChange} />
            <OrganizationYearSelector />
            <AssetsCheckbox value={assetNames} onChange={handleAssetsChange} />
            <VerticalFilter
                filters={assessmentVerticals}
                activeFilters={verticals}
                onFilterChange={handleVerticalChange}
            />
            <RadioDropdown
                selectedValue={performance}
                onChange={handlePerformanceChange}
            />
            <Button
                onClick={clearFilter}
                variant="text"
                sx={{
                    padding: theme.spacing(2, 4),
                    margin: theme.spacing(0, 4),
                }}
            >
                <Typography variant="h5" fontWeight={"bold"}>
                    {t(
                        "assessmentOverview.assetList.filter.resetFilter",
                        "Reset Filters"
                    )}
                </Typography>
            </Button>
            <Typography
                variant="h5"
                fontWeight={"bold"}
                color={"primary"}
                sx={{ padding: theme.spacing(0, 8) }}
            >
                {totalAssets}{" "}
                {t("assessmentOverview.assetList.filter.assets", "Assets")}
            </Typography>
        </FilterBox>
    );
}
