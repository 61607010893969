import _ from "lodash";
import { DateTime } from "luxon";

export function aggregateSumBy<T>(
    inputArray: T[] | _.Dictionary<T> | null | undefined,
    valuePicker: (arrayItem: T) => number | null | undefined
) {
    if (!inputArray) {
        return null;
    }

    const numbers = _.values(inputArray)
        .map((item) => valuePicker(item))
        .filter((n): n is number => _.isFinite(n));

    if (numbers.length === 0) {
        return null;
    }

    return _.chain(numbers).sum().round(2).value();
}

export const getAssessmentMonths = (
    activeYear: number,
    purchaseDate?: Date,
    saleDate?: Date
) => {
    let startMonth = 1;
    let endMonth = 13;

    if (purchaseDate) {
        if (DateTime.fromJSDate(purchaseDate).year === activeYear) {
            startMonth = DateTime.fromJSDate(purchaseDate).month;
        }
    }

    if (saleDate) {
        if (DateTime.fromJSDate(saleDate).year === activeYear) {
            endMonth = DateTime.fromJSDate(saleDate).month + 1;
        }
    }

    return _.range(startMonth, endMonth).map((month) =>
        DateTime.fromObject({ year: activeYear, month }).toFormat("yyyy-MM")
    );
};
