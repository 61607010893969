import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AssetGroupOption, SelectorAction } from "components/AssetsGroup/types";

import { S } from "../asset-group-selector.styles";

type MenuItemLineProps = {
    position: number;
    options: string[];
};

const MenuItemLine = (props: MenuItemLineProps) => {
    const { position, options } = props;

    const isFirst = position === 0;
    const isLast = position === options.length - 1;

    if (isFirst) {
        return <S.TopVerticalLine />;
    }

    return (
        <>
            {isLast ? <S.LastVerticalLine /> : <S.ChildVerticalLine />}
            <S.ChildHorizontalLine />
        </>
    );
};

type Props = {
    value: string;
    option: AssetGroupOption;
    position: number;
    isActive: boolean;
    options: string[]; // asset group ids
    onMenuAction: (filterId: string, action: SelectorAction) => void;
};

export function SelectorMenuItem(props: Props) {
    const { option, position, options, isActive, onMenuAction, ...rest } =
        props;

    const { t } = useTranslation();

    const isHeader = position === 0;

    const handleMenuAction = (
        event: React.MouseEvent<SVGSVGElement>,
        action: SelectorAction
    ) => {
        event.stopPropagation();
        onMenuAction(option.id, action);
    };

    const assetGroupIcon = isHeader ? (
        <S.AssetGroupIcon variant="empty" />
    ) : (
        <S.AssetGroupIcon variant={isActive ? "active" : "default"} />
    );

    return (
        <S.MenuItem {...rest} sx={{ pl: position > 0 ? 8 : 3 }}>
            <MenuItemLine position={position} options={options} />
            {assetGroupIcon}

            <S.MenuItemText>
                {option.name}
                <Typography fontSize={12} color="grey.500">
                    {t("shared.assetWithCount", { count: option.count })}
                </Typography>
            </S.MenuItemText>

            {position !== 0 && (
                <S.MenuIconContainer>
                    <S.EditIcon
                        fontSize="small"
                        onClick={(event) => handleMenuAction(event, "edit")}
                    />
                    <S.DeleteIcon
                        fontSize="small"
                        onClick={(event) => handleMenuAction(event, "delete")}
                    />
                </S.MenuIconContainer>
            )}
        </S.MenuItem>
    );
}
