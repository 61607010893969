import { gql } from "@apollo/client";

const ME_QUERY = gql`
    query GetMe {
        me {
            id
            email
            name
            organization {
                id
                name
                reportingYear
                baselineYear
                allLocationsCount
            }
        }
    }
`;

export default ME_QUERY;
