import { Box, styled } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(4, 8),
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
}));

const S = {
    Container,
};

export default S;
