import { PropsWithChildren, useEffect } from "react";

import { S } from "./page.styles";

type Props = {
    title?: string;
};

export const Page = (props: PropsWithChildren<Props>) => {
    const { title = "Legacy", children } = props;

    useEffect(() => {
        document.title = title;
    }, [title]);

    return <S.Page>{children}</S.Page>;
};
