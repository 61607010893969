import { Delete, Edit } from "@mui/icons-material";
import {
    Box,
    InputAdornment,
    MenuItem,
    Select,
    Theme,
    styled,
} from "@mui/material";
import { match } from "ts-pattern";

type AssetIconProps = { theme: Theme } & {
    variant: "active" | "empty" | "default";
};

const LineStyle = {
    position: "absolute",
    overflow: "hidden",
    backgroundColor: "rgba(230, 230, 230, 0.5)",
    width: "1px",
    zIndex: 0,
} as const;

const IconStyle = (theme: Theme) =>
    ({
        marginRight: theme.spacing(1),
        transformOrigin: "50% 50%",
        color: theme.palette.primary.main,
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
            zIndex: 5,
            transform: "scale(1.2)",
        },
    } as const);

export const S = {
    Select: styled(Select)(({ theme }) => ({
        width: theme.spacing(94),
        padding: theme.spacing(0, 2),
        fontWeight: 600,
    })),
    MenuItem: styled(MenuItem)(({ theme }) => ({
        display: "flex",
        gap: theme.spacing(2),
        "&.Mui-selected": {
            backgroundColor: theme.palette.common.white,
        },
    })),
    SelectAdornment: styled(InputAdornment)(() => ({
        padding: 2,
        "&:hover": { cursor: "pointer" },
    })),
    AssetIcon: styled(Box, {
        shouldForwardProp: (prop) => prop !== "active",
    })((props: AssetIconProps) => {
        const { theme, variant = "default" } = props;
        const iconSize = theme.spacing(5);

        const backgroundColor = match(variant)
            .with("empty", () => theme.palette.common.white)
            .with("active", () => theme.palette.success.main)
            .with("default", () => theme.palette.primary.main)
            .exhaustive();

        const notificationBadge = {
            content: '" "',
            borderRadius: theme.spacing(0.5),
            width: theme.spacing(3),
            height: theme.spacing(3),
            marginLeft: theme.spacing(2.5),
            marginTop: theme.spacing(2.5),
            backgroundColor,
            display: "inline-block",
        };

        return {
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: theme.spacing(1),
            width: iconSize,
            height: iconSize,

            "&:after": notificationBadge,
        };
    }),
    AssetGroupIcon: styled(Box, {
        shouldForwardProp: (prop) => prop !== "active",
    })((props: AssetIconProps) => {
        const { theme, variant = "default" } = props;

        const backgroundColor = match(variant)
            .with("empty", () => theme.palette.common.white)
            .with("active", () => theme.palette.success.main)
            .with("default", () => theme.palette.primary.main)
            .exhaustive();

        const borderColor = match(variant)
            .with("active", () => theme.palette.success.main)
            .with("default", () => theme.palette.primary.main)
            .otherwise(() => theme.palette.primary.main);

        const iconSize = theme.spacing(4);

        return {
            borderColor,
            backgroundColor,
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: theme.spacing(1),
            width: iconSize,
            height: iconSize,
            marginLeft: theme.spacing(2),
            zIndex: 1,
        };
    }),
    TopVerticalLine: styled(Box)(({ theme }) => ({
        ...LineStyle,
        marginLeft: theme.spacing(3.75),
        height: "35%",
        bottom: 0,
    })),
    LastVerticalLine: styled(Box)(({ theme }) => ({
        ...LineStyle,
        marginLeft: theme.spacing(-1.25),
        height: "50%",
        top: 0,
    })),
    ChildVerticalLine: styled(Box)(({ theme }) => ({
        ...LineStyle,
        marginLeft: theme.spacing(-1.25),
        height: "100%",
        bottom: 0,
    })),
    ChildHorizontalLine: styled(Box)(({ theme }) => ({
        ...LineStyle,
        marginLeft: theme.spacing(-1.25),
        width: "3%",
        height: "1px",
    })),

    MenuItemText: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
    })),

    MenuIconContainer: styled(Box)(({ theme }) => ({
        display: "flex",
        marginLeft: "auto",
        gap: theme.spacing(0),
    })),

    EditIcon: styled(Edit)(({ theme }) => IconStyle(theme)),
    DeleteIcon: styled(Delete)(({ theme }) => IconStyle(theme)),

    CTASection: styled(Box)(({ theme }) => ({
        width: "100%",
        paddingTop: theme.spacing(2),
        borderTop: "1px solid #E0E0E0",
    })),
};
