import { styled } from "@mui/material";

import { Table } from "components/Table";

export const S = {
    Table: styled(Table)({
        padding: 0,
        "& .MuiDataGrid-columnHeader[data-field='source']": {
            paddingLeft: 6,
        },
        "& .MuiDataGrid-cell[data-field='source']": {
            paddingLeft: 0,
        },
        "& .MuiDataGrid-columnHeader": {
            cursor: "pointer",
        },
        "& .MuiDataGrid-root": {
            padding: 0,
        },
    }),
};
