import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { Chart } from "glue/Chart";

import { getTotalEmissionsChartData } from "./yearlyTotalEmissionsChart.helpers";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";

type Props = {
    assessmentSummaries: SummaryYearOverview[];
};

export const YearlyTotalEmissionsChart = (props: Props) => {
    const { assessmentSummaries } = props;
    const { t } = useTranslation();

    const { series, xAxis } = useMemo(() => {
        return getTotalEmissionsChartData(t, assessmentSummaries);
    }, [t, assessmentSummaries]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={[
                {
                    label: t(
                        "portfolioDashboard.chartHelpers.tonsCO2",
                        "tons CO2e"
                    ),
                },
            ]}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
