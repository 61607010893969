import { Theme } from "@mui/material";

export const getEPCPaletteFromTheme = (theme: Theme, epcRating?: string) => {
    if (!epcRating || epcRating === "-") {
        return theme.palette.error;
    }
    const rating = epcRating.toUpperCase()[0];

    switch (rating) {
        case "B":
            return theme.palette.epc.b;
        case "C":
            return theme.palette.epc.c;
        case "D":
            return theme.palette.epc.d;
        case "E":
            return theme.palette.epc.e;
        case "F":
            return theme.palette.epc.f;
        case "G":
            return theme.palette.epc.g;
        default:
            return theme.palette.epc.a;
    }
};
