import { useMutation, useQuery } from "@apollo/client";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    styled,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
    GetUserProfileQuery,
    UpdateMeMutation,
    UpdateMeMutationVariables,
} from "graphql-types/graphql";

import USER_PROFILE_QUERY from "./userProfileQueries";
import UPDATE_USER_PROFILE_MUTATION from "../../mutations/accountSettings/updateUserProfile";
import Loading from "../Loading/Loading";

const ControlsContainer = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
}));

const ButtonContainer = styled(Container)(({ theme }) => ({
    height: theme.spacing(10),
    display: "flex",
    gridGap: "1rem",
    justifyContent: "flex-end",
    marginTop: theme.spacing(5),
}));

const GridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing(2),
}));

type UserProfileInput = {
    name: string;
    email: string;
    phone: string | null;
    password?: string;
    newPassword?: string;
    confirmPassword?: string;
};

type Props = {
    defaultValues: UserProfileInput;
    onSubmit: (data: UserProfileInput) => Promise<any>;
};

const UserProfileForm = (props: Props) => {
    const { t } = useTranslation();
    const { defaultValues, onSubmit } = props;

    const {
        handleSubmit,
        formState,
        reset,
        setError,
        register,
        watch,
        formState: { errors },
    } = useForm<UserProfileInput>({});

    const [ChangePasswordActive, setChangePassword] = useState(false);
    const { isDirty, isSubmitting } = formState;
    const handleClick = () => {
        setChangePassword(!ChangePasswordActive);
    };

    const handleReset = useCallback(() => {
        setChangePassword(false);
        reset(defaultValues);
    }, [reset, defaultValues, setChangePassword]);

    useEffect(handleReset, [handleReset]);

    const submitHandler = useCallback(
        (data: UserProfileInput) => {
            onSubmit(data)
                .then(handleReset)
                .catch((errors) => {
                    const { graphQLErrors } = errors;

                    graphQLErrors.forEach((error: any) => {
                        console.log(
                            "error.extensions.response?.message",
                            error.extensions.response?.message
                        );
                        if (error.extensions.response?.message) {
                            const message = error.extensions.response.message;
                            setError("name", {
                                type: "server",
                                message:
                                    message instanceof Array
                                        ? message.join(", ")
                                        : message,
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.credentials.invalid"
                        ) {
                            setError("password", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.invalidCredentials",
                                    "Forkert kodeord"
                                ),
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.organization.provide.password"
                        ) {
                            setError("password", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.provide.password",
                                    "Udfyld kodeord"
                                ),
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.organization.provide.newPassword"
                        ) {
                            setError("newPassword", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.provide.newPassword",
                                    "Repeat new password"
                                ),
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.organization.provide.confirmPassword"
                        ) {
                            setError("confirmPassword", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.provide.newPassword",
                                    "Repeat new password"
                                ),
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.password.differ"
                        ) {
                            setError("newPassword", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.newPasswordDifferent",
                                    "Nyt kodeord skal være forskelligt fra nuværende kodeord"
                                ),
                            });
                        }
                        if (
                            error.extensions.exception?.data.id ===
                            "Auth.form.error.password.matching"
                        ) {
                            setError("confirmPassword", {
                                type: "server",
                                message: t(
                                    "settings.profile.errors.newPasswordMatch",
                                    "Nyt kodeord og bekræft kodeord skal være ens"
                                ),
                            });
                        }
                    });
                });
        },
        [onSubmit, handleReset, setError, t]
    );

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={4}>
                <GridItem item xs={4}>
                    <TextField
                        {...register("name")}
                        variant="outlined"
                        fullWidth
                        name="name"
                        defaultValue={defaultValues.name}
                        label={t("settings.profile.name", "Name")}
                        error={Boolean(errors.name)}
                        helperText={errors.name && errors.name.message}
                    />

                    <TextField
                        {...register("email")}
                        variant="outlined"
                        fullWidth
                        name="email"
                        defaultValue={defaultValues.email}
                        label={t("settings.profile.email", "Email")}
                        type="email"
                    />
                </GridItem>
                <GridItem item xs={4}>
                    {ChangePasswordActive && (
                        <TextField
                            {...register("password")}
                            variant="outlined"
                            fullWidth
                            name="password"
                            label={t(
                                "settings.profile.password",
                                "Nuværende kodeord"
                            )}
                            type="password"
                            required
                            disabled={!ChangePasswordActive}
                            error={Boolean(errors.password)}
                            helperText={
                                errors.password && errors.password.message
                            }
                        />
                    )}
                    {ChangePasswordActive && (
                        <TextField
                            {...register("newPassword", {
                                validate: (value: any) =>
                                    value !== watch("password") ||
                                    t(
                                        "settings.profile.errors.newPasswordDifferent",
                                        "Nyt kodeord skal være forskelligt fra nuværende kodeord"
                                    ),
                            })}
                            variant="outlined"
                            fullWidth
                            error={Boolean(errors.newPassword)}
                            helperText={
                                errors.newPassword && errors.newPassword.message
                            }
                            name="newPassword"
                            label={t(
                                "settings.profile.newPassword",
                                "Nyt kodeord"
                            )}
                            required
                            defaultValue=""
                            type="password"
                        />
                    )}
                    {ChangePasswordActive && (
                        <TextField
                            {...register("confirmPassword", {
                                validate: (value: any) =>
                                    value !== watch("password") ||
                                    value !== watch("newPassword") ||
                                    t(
                                        "settings.profile.errors.newPasswordMatch",
                                        "Nyt kodeord og bekræft kodeord skal være ens"
                                    ),
                            })}
                            variant="outlined"
                            fullWidth
                            error={Boolean(errors.confirmPassword)}
                            helperText={
                                errors.confirmPassword &&
                                errors.confirmPassword.message
                            }
                            name="confirmPassword"
                            label={t(
                                "settings.profile.confirmPassword",
                                "Bekræft kodeord"
                            )}
                            required
                            defaultValue=""
                            type="password"
                        />
                    )}
                </GridItem>

                <GridItem item xs={4}>
                    <ControlsContainer>
                        <Button onClick={handleClick}>
                            {ChangePasswordActive
                                ? t(
                                      "settings.profile.changePasswordCancel",
                                      "Undo password change"
                                  )
                                : t(
                                      "settings.profile.changePassword",
                                      "Change Password"
                                  )}
                        </Button>
                    </ControlsContainer>
                </GridItem>
                {(ChangePasswordActive || isDirty) && (
                    <ButtonContainer>
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick={handleReset}
                        >
                            {t("settings.cancel", "Cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {isSubmitting ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                t("settings.saveChanges", "Save Changes")
                            )}
                        </Button>
                    </ButtonContainer>
                )}
            </Grid>
        </form>
    );
};

const UserProfile = () => {
    const { t } = useTranslation();

    const { data, loading, refetch } = useQuery<GetUserProfileQuery>(
        USER_PROFILE_QUERY,
        {
            fetchPolicy: "network-only",
        }
    );
    const me = useMemo(() => {
        return data?.me;
    }, [data]);
    const defaultValues = useMemo((): UserProfileInput | null => {
        if (!data || !data.me) {
            return null;
        }

        return {
            email: data.me.email || "",
            name: data.me.name || "",
            phone: data.me.phone || "",
        };
    }, [data]);

    const [updateUserProfile] = useMutation<
        UpdateMeMutation,
        UpdateMeMutationVariables
    >(UPDATE_USER_PROFILE_MUTATION);

    const onSubmit = (data: UserProfileInput) => {
        if (!me) {
            return Promise.reject();
        }

        return updateUserProfile({
            variables: {
                updateMeInput: {
                    name: data.name,
                    email: data.email,
                    phone: data.phone || null,
                    currentPassword: data.password,
                    newPassword: data.newPassword,
                    confirmPassword: data.confirmPassword,
                },
            },
        }).then(() => {
            refetch();
        });
    };

    if (loading || !defaultValues) {
        return (
            <Container>
                <Loading description={t("loading.title", "Loading")} />
            </Container>
        );
    }
    return (
        <UserProfileForm onSubmit={onSubmit} defaultValues={defaultValues} />
    );
};

export default UserProfile;
