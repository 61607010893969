import { Asset, AssetPreview } from "components/AssetsGroup/types";

function toAssets(previewAssets: AssetPreview[]): Asset[] {
    return previewAssets
        .filter((asset) => asset.status !== "removed")
        .map((asset) => {
            return {
                id: asset.id,
                name: asset.name,
                externalId: asset.externalId,
            };
        });
}

export const previewAssetsUtils = {
    toAssets,
};
