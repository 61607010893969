import {
    BrowserTracing,
    reactRouterV6Instrumentation,
    init,
} from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

export const initSentry = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT || "development";

    return init({
        environment,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        tracePropagationTargets: [
            new RegExp(`^${process.env.REACT_APP_API_URL}`),
        ],
        integrations: [
            new BrowserTracing({
                routingInstrumentation: reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],
        beforeBreadcrumb: excludeGraphQLFetch,
        // Performance Monitoring
        tracesSampleRate: environment !== "production" ? 1.0 : 0.75,
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
