import { useApolloClient } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { useOrganizationContext } from "context";
import { useBrowserStorage } from "hooks";

import { useAssetGroups } from "./useAssetGroups";

const ACTIVE_ASSET_GROUP_KEY = "assetGroupId";

const emptyGroup = {
    id: "",
    name: "",
    count: 0,
};

export function useActiveAssetGroup() {
    const apolloClient = useApolloClient();

    const organization = useOrganizationContext();

    const [activeGroupId, setActiveGroupId] = useBrowserStorage<string | null>(
        ACTIVE_ASSET_GROUP_KEY,
        null,
        null,
        "local",
        true
    );
    const { assetGroups } = useAssetGroups();

    const activeAssetGroup = useMemo(() => {
        if (!organization) {
            return emptyGroup;
        }
        if (!activeGroupId) {
            return {
                id: organization.id,
                name: organization.name,
                count: organization.allLocationsCount,
            };
        }

        return (
            assetGroups.find((group) => group.id === activeGroupId) ??
            emptyGroup
        );
    }, [activeGroupId, assetGroups, organization]);

    useEffect(() => {
        window.addEventListener("storage", (event) => {
            if (event.key === ACTIVE_ASSET_GROUP_KEY) {
                window.location.reload();
            }
        });

        return () => {
            window.removeEventListener("storage", () => {
                apolloClient.clearStore();
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [activeAssetGroup, setActiveGroupId] as const;
}
