import PortfolioDashboard from "components/PortfolioDashboard/PortfolioDashboard";
import AssessmentDataTypeContextProvider from "containers/AssetPage/AssessmentDataTypeContext";

function PortfolioPage() {
    return (
        <AssessmentDataTypeContextProvider>
            <PortfolioDashboard />
        </AssessmentDataTypeContextProvider>
    );
}

export default PortfolioPage;
