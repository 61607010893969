import { styled, Box, Typography, Paper } from "@mui/material";
import React from "react";

export interface ListItemProps {
    label: React.ReactNode | JSX.Element;
    description: React.ReactNode | JSX.Element;
}

const ListItemContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #d9e1fc",
    gap: theme.spacing(12),
    padding: theme.spacing(3, 4),

    "&:last-child": {
        borderBottom: 0,
    },
}));

const List = ({ listContent }: { listContent: ListItemProps[] }) => {
    return (
        <Paper sx={{ height: "fit-content" }}>
            {listContent.map(({ label, description }, i) => (
                <ListItemContainer key={i}>
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                        {label}
                    </Typography>
                    <Typography textAlign="right">{description}</Typography>
                </ListItemContainer>
            ))}
        </Paper>
    );
};

export default List;
