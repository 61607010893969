import { Box, Button, Stack, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";

import { GetAllAssetDetailsQuery, CountryCode } from "graphql-types/graphql";

import AssetMasterData from "./AssetMasterData";
import { AssetPropertyList } from "./components";
import { AssetPropertyAllocationCount } from "./components/AssetPropertyAllocationCount";
import { PropertyModal, ChangeLogModal } from "./modals";
import { usePropertyMutations } from "./queries/usePropertyMutations";

type Props = {
    editsCount: number;
    asset: GetAllAssetDetailsQuery["location"] | undefined;
};

export const AssetDetailsContent = (props: PropsWithChildren<Props>) => {
    const { asset, editsCount, children } = props;

    const { t } = useTranslation();
    const { useAddingPropertiesFlow, useConsumptionAllocation } = useFlags();

    const [openChangeLog, setOpenChangeLog] = useState(false);
    const [openAddPropertyModal, setOpenAddPropertyModal] = useState(false);

    const { onSaveProperty, onEditProperty, onPropertyDelete } = usePropertyMutations(
        asset?.id
    );

    if (!asset) {
        return (
            <Typography variant="h4" color="grey.400">
                {t(
                    "portfolioDashboard.sidePanel.buildingDetails.notFound",
                    "No building found with this National Building ID"
                )}
            </Typography>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    gap: 4,
                    gridTemplateColumns: "3fr 7fr",
                }}
            >
                <AssetMasterData
                    asset={asset}
                    editsCount={editsCount}
                    onChangeLogOpenClick={() => setOpenChangeLog(true)}
                />

                {!useAddingPropertiesFlow && (
                    <Box>
                        <Typography variant="h3" mb={4}>
                            {t("assetDetailsComponent.buildings", "Buildings")}
                        </Typography>
                        {children}
                    </Box>
                )}

                {useAddingPropertiesFlow && (
                    <Box>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={4}
                        >
                            <Typography variant="h3" fontWeight={700}>
                                {t(
                                    "assetDetailsComponent.propertyList.title",
                                    "Properties"
                                )}
                            </Typography>
                            <Box justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                        setOpenAddPropertyModal(true)
                                    }
                                >
                                    {t(
                                        "assetDetailsComponent.addProperty",
                                        "add property"
                                    )}
                                </Button>
                            </Box>
                        </Stack>
                        <AssetPropertyList
                            countryCode={asset.countryCode as CountryCode}
                            assetId={asset.id}
                            onItemDelete={onPropertyDelete}
                            onItemAdd={onSaveProperty}
                            onItemEdit={onEditProperty}
                            areAllocationIconsShown={useConsumptionAllocation}
                        />
                        {useConsumptionAllocation && (
                            <AssetPropertyAllocationCount assetId={asset.id} />
                        )}
                    </Box>
                )}
            </Box>
            <ChangeLogModal
                isOpen={openChangeLog}
                assetId={asset.id}
                assetName={asset.displayName || "-"}
                editsCount={editsCount}
                handleClose={() => setOpenChangeLog(false)}
            />
            <PropertyModal
                parentName={asset?.displayName}
                countryCode={asset.countryCode as CountryCode}
                isOpen={openAddPropertyModal}
                onConfirmAddProperty={(property) => onSaveProperty(property)}
                onClose={() => setOpenAddPropertyModal(false)}
            />
        </>
    );
};
