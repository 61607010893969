import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
} from "@mui/icons-material";
import { Box, IconButton, styled } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number
    ) => void;
}

const ArrowButton = styled(IconButton)(() => ({
    padding: "0",
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(8),
    gap: "0.5rem",
}));

export const TablePagination = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
}: TablePaginationActionsProps) => {
    const { t } = useTranslation();

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <StyledBox>
            <ArrowButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label={t("general.pagination.firstPage", "First page")}
            >
                <FirstPage />
            </ArrowButton>
            <ArrowButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label={t(
                    "general.pagination.previousPage",
                    "Previous page"
                )}
            >
                <KeyboardArrowLeft />
            </ArrowButton>
            <ArrowButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("general.pagination.nextPage", "Next page")}
            >
                <KeyboardArrowRight />
            </ArrowButton>
            <ArrowButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t("general.pagination.lastPage", "Last page")}
            >
                <LastPage />
            </ArrowButton>
        </StyledBox>
    );
};
