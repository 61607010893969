import { Button, Chip, Typography, capitalize } from "@mui/material";
import { t } from "i18next";

type Props = {
    onClick?: () => void;
    benchmarkCount?: number;
};

export function BenchmarkButton(props: Props) {
    const { onClick, benchmarkCount = 0 } = props;

    const counterIcon = benchmarkCount ? (
        <Chip label={benchmarkCount} color="primary" sx={{ borderRadius: 0 }} />
    ) : null;

    return (
        <Button
            onClick={onClick}
            sx={{ backgroundColor: "grey.200" }}
            size="small"
            startIcon={counterIcon}
        >
            <Typography fontWeight={700}>
                {capitalize(t("benchmark.plural", "Benchmarks"))}
            </Typography>
        </Button>
    );
}
