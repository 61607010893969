import { gql } from "@apollo/client";

export const ADD_LOCATION_INTEGRATION_MUTATION = gql`
    mutation AddLocationIntegration($input: [CreateAssetIntegrationInput!]!) {
        addLocationIntegration(input: $input) {
            id
        }
    }
`;

export const DELETE_LOCATION_INTEGRATION = gql`
    mutation DeleteLocationIntegration(
        $input: [DeleteLocationIntegrationInput!]!
    ) {
        deleteLocationIntegration(input: $input) {
            originId
            assetId
        }
    }
`;

export const UPDATE_LOCATION_INTEGRATION = gql`
    mutation UpdateLocationIntegration(
        $startedAt: DateTime
        $endedAt: DateTime
        $ids: [ID!]
    ) {
        updateManyLocationIntegrations(
            input: {
                filter: { id: { in: $ids } }
                update: { startedAt: $startedAt, endedAt: $endedAt }
            }
        ) {
            updatedCount
        }
    }
`;

export const DELETE_LOCATION_INTEGRATION_BY_IDS = gql`
    mutation DeleteLocationIntegrationByIds($ids: [ID!]!) {
        deleteManyLocationIntegrations(
            input: { filter: { id: { in: $ids } } }
        ) {
            deletedCount
        }
    }
`;
