import {
    Box,
    MenuItem,
    Typography,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import InfoPopover from "components/Popovers/InfoPopover";

import {
    getPortfolioChartTooltips,
    getPortfolioOverviewOptions,
    getYearlyBreakdownOptions,
} from "./helpers/portfolioSelectorHelpers";
import { DataQualityTooltipInfo } from "./PortfolioDataQuality";
import { ChartType } from "./types";

interface SelectorOptions {
    activeChart: ChartType;
    isYearlyBreakdown: boolean;
    onChange: (chartType: ChartType) => void;
}

function PortfolioSelector(props: SelectorOptions) {
    const { onChange, isYearlyBreakdown, activeChart } = props;

    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<ChartType>) => {
        onChange(event.target.value as ChartType);
    };

    const tooltip = getPortfolioChartTooltips().find(
        (t) => t.value === activeChart
    );

    const options = isYearlyBreakdown
        ? getYearlyBreakdownOptions(t)
        : getPortfolioOverviewOptions(t);

    return (
        <Box sx={{ display: "flex", alignItems: "center", gridGap: 12 }}>
            <Select<ChartType>
                size="small"
                onChange={handleChange}
                value={activeChart}
                defaultValue={undefined}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Typography fontWeight="bold" display="inline">
                            {option.title}
                        </Typography>
                        <Typography color="grey.500" display="inline" pl={1}>
                            ({option.unit})
                        </Typography>
                    </MenuItem>
                ))}
            </Select>

            {tooltip && <InfoPopover element={<DataQualityTooltipInfo />} />}
        </Box>
    );
}

export default PortfolioSelector;
