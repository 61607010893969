import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";

export const BelowBenchmarkArrow = styled(ArrowDownward)(({ theme }) => ({
    fontSize: theme.spacing(5),
    verticalAlign: "middle",
    display: "block",
}));

export const AboveBenchmarkArrow = styled(ArrowUpward)(({ theme }) => ({
    fontSize: theme.spacing(5),
    verticalAlign: "middle",
    display: "block",
}));

export const HintText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    fontStyle: "italic",
    color: "#8e8e8e",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    marginBottom: -8,
    marginTop: -8,
}));
