import { TFunction } from "i18next";
import _ from "lodash";

import { AssessmentDataType } from "graphql-types/graphql";
import { formatNumberLocale } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import {
    AssessmentSummary,
    getAssessmentTypeEmission,
    hasAssessmentDataType,
    getTotalArea,
} from "./utilities";
import { UnitMass } from "../../AssessmentStats/types";
import { SummaryYearOverview, Location } from "../types";

export const BENCHMARK_LABEL = "Benchmark";

export const emissionUnit = UnitMass.TON;
const emissionIsTon = emissionUnit.valueOf() === UnitMass.TON.valueOf();
export const emissionLabel = emissionIsTon ? "t" : "kg";

export const getTotalEmissionBySize = (
    locations: Location[],
    activeDataType: AssessmentDataType
) => {
    const getFilter = (
        activeDataType: AssessmentDataType,
        a: AssessmentSummary
    ) => {
        return hasAssessmentDataType(activeDataType, a);
    };

    const locationWithOwnedArea = locations.filter(
        (l) =>
            l.ownedArea !== null &&
            l.assessmentSummaries.some((a) => getFilter(activeDataType, a))
    );

    if (locationWithOwnedArea.length === 0) {
        return null;
    }

    const totalEmission = _.chain(locationWithOwnedArea)
        .sumBy((l) => {
            return _.chain(l.assessmentSummaries)
                .sumBy(
                    (a) =>
                        getAssessmentTypeEmission(
                            activeDataType,
                            a.emissionDataGrams.emission
                        ) || 0
                )
                .value();
        })
        .value();

    const totalArea = getTotalArea(locationWithOwnedArea);

    return totalEmission / totalArea;
};

export const getBuildingAverageEmissionPerArea = (
    locations: Location[],
    activeDataType: AssessmentDataType
) => {
    const getFilter = (
        activeDataType: AssessmentDataType,
        a: AssessmentSummary
    ) => {
        return hasAssessmentDataType(activeDataType, a);
    };

    const buildingAverageEmissionPerArea = _.chain(locations)
        .filter(
            (l) =>
                l.ownedArea !== null &&
                l.assessmentSummaries.some((a) => getFilter(activeDataType, a))
        )
        .meanBy((l) => {
            const sum = _.chain(l.assessmentSummaries)
                .sumBy(
                    (a) =>
                        getAssessmentTypeEmission(
                            activeDataType,
                            a.emissionDataGrams.emission
                        ) || 0
                )
                .value();

            return sum / (l.ownedArea || 1);
        })
        .value();

    return buildingAverageEmissionPerArea;
};

export const getProjectedBuildingAverageEmissionPerArea = (
    assessmentSummaryOverview: SummaryYearOverview,
    activeDataType: AssessmentDataType
) => {
    const projectedBuildingAverageEmissionPerArea =
        assessmentSummaryOverview?.locations
            ? _.chain(assessmentSummaryOverview.locations)
                  .meanBy((l) => {
                      const projectedLocationTotal = _.sumBy(
                          l.projectedAssessmentSummaries,
                          (p) =>
                              getAssessmentTypeEmission(
                                  activeDataType,
                                  p.emissionDataGrams.emission
                              ) || 0
                      );

                      return l.ownedArea
                          ? projectedLocationTotal / l.ownedArea
                          : null;
                  })
                  .value()
            : 0;

    return projectedBuildingAverageEmissionPerArea;
};

export const getTotalForecastedEmissionBySize = (
    assessmentSummaryOverview: SummaryYearOverview
) => {
    return (
        assessmentSummaryOverview?.projectedEmission /
        assessmentSummaryOverview?.totalArea
    );
};

export const getLabel = (percentage: number) => {
    if (percentage < 100) {
        return `${100 - percentage}`;
    } else if (percentage === 100) {
        return BENCHMARK_LABEL;
    } else {
        return `${percentage}`;
    }
};

export const setStroke = (percentage: number) => {
    if (percentage < 100) {
        return `${100 - percentage}`;
    } else if (percentage === 100) {
        return BENCHMARK_LABEL;
    } else {
        return `${percentage - 100}`;
    }
};

export const roundTo = function (number: number, interval: number) {
    return _.round(number / interval) * interval;
};

export function formatSummedEmission(
    sumEmission: number | undefined,
    t: TFunction
) {
    if (!sumEmission) return DASH_SEPARATOR;

    const formattedSumEmission = `${formatNumberLocale(sumEmission, t)} ${t(
        "portfolioDashboard.portfolioSelector.kgsAvg",
        "kg CO2e/m²"
    )}`;

    return formattedSumEmission;
}
