import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import TabsPanel from "components/TabsPanel/TabsPanel";

import { AutomaticSourcesTable } from "./AutomaticSourcesTable";
import { ManualSourcesTable } from "./ManualSourcesTable";

enum DataSourcesTab {
    Automatic,
    Manual,
}

export default function AssetDataOverview({
    locationId,
}: {
    locationId: string;
}) {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(DataSourcesTab.Automatic);

    const handleTabChange = useCallback(
        (_: React.ChangeEvent<object>, newValue: DataSourcesTab) =>
            setActiveTab(newValue),
        [setActiveTab]
    );

    const tabsData = useMemo(() => {
        return [
            {
                title: t("portfolioDashboard.tabs.automatic", "Automatic"),
                value: DataSourcesTab.Automatic,
            },
            {
                title: t("portfolioDashboard.tabs.manual", "Manual"),
                value: DataSourcesTab.Manual,
            },
        ];
    }, [t]);

    const ActiveTabContent = useCallback(() => {
        switch (activeTab) {
            case DataSourcesTab.Automatic:
                return <AutomaticSourcesTable assetId={locationId} />;
            case DataSourcesTab.Manual:
                return <ManualSourcesTable locationId={locationId} />;
        }
    }, [locationId, activeTab]);

    return (
        <Box sx={{ mb: 4, mt: 4 }}>
            <Box
                sx={{ mb: 4 }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <TabsPanel
                    tabsData={tabsData}
                    activeTab={activeTab}
                    onChange={handleTabChange}
                />
            </Box>

            <ActiveTabContent />
        </Box>
    );
}
