import { useQuery } from "@apollo/client";
import {
    getGridStringOperators,
    GridColDef,
    GridFilterPanel,
    GridToolbarContainer,
} from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";

import {
    DYNAMIC_GRID_TABLE_HEIGHT,
    Table,
    TableToolbarExport,
} from "components/Table";
import {
    GetAssetIntegrationAllocationsQueryVariables,
    GetAssetIntegrationsQuery,
} from "graphql-types/graphql";
import { formatDate } from "utils/date.utils";

import { dateSorter } from "./data-overview.helper";
import { GET_ASSET_INTEGRATIONS } from "./data-overview.queries";

const stringOperators = getGridStringOperators().filter((op) =>
    ["contains"].includes(op.value)
);

export const IntegrationsTable = () => {
    const { data, loading } = useQuery<
        GetAssetIntegrationsQuery,
        GetAssetIntegrationAllocationsQueryVariables
    >(GET_ASSET_INTEGRATIONS);

    const rows = useMemo(() => {
        if (!data) {
            return [];
        }

        const rows = data.getAssets.flatMap((asset) =>
            asset.integrations.map((integration) => {
                const data = integration.data
                    ? Object.entries(integration.data)
                          .filter(([key]) => key !== "__typename")
                          .map(([key, value]) => `${key}: ${value}`)
                          .join(", ")
                    : "-";

                return {
                    id: integration.id,
                    type: integration.type,
                    asset: asset.displayName,
                    originId: integration.originId,
                    data: data || "-",
                    from: integration.from,
                    to: integration.to,
                    consumptionTypes: integration.consumptionTypes,
                };
            })
        );

        return rows;
    }, [data]);

    const CustomToolbar = useCallback(
        () => (
            <GridToolbarContainer
                sx={{ justifyContent: "flex-end", marginTop: "0.5rem" }}
            >
                <GridFilterPanel />
                <TableToolbarExport
                    hasCustomStyle
                    csvOptions={{ fileName: "asset-integrations" }}
                />
            </GridToolbarContainer>
        ),
        []
    );

    const columns = useMemo(
        () =>
            [
                { field: "type", flex: 0.5 },
                { field: "asset", flex: 0.75 },
                { field: "originId", flex: 1 },
                { field: "data", flex: 1, sortable: false },
                {
                    field: "consumptionTypes",
                    flex: 1,
                    valueFormatter: (value: any) =>
                        Array.isArray(value) && value.length
                            ? value.join(", ")
                            : "-",
                    sortable: false,
                },

                {
                    field: "from",
                    flex: 1,
                    valueFormatter: (value: any) =>
                        value ? formatDate(value) : "-",
                    sortComparator: dateSorter,
                    filterable: false,
                },
                {
                    field: "to",
                    flex: 1,
                    valueFormatter: (value: any) =>
                        value ? formatDate(value) : "-",
                    sortComparator: dateSorter,
                    filterable: false,
                },
            ].map((column) => ({
                ...column,
                filterOperators: stringOperators,
            })) as GridColDef[],
        []
    );

    return (
        <Table
            rows={rows}
            columns={columns}
            isLoading={loading}
            setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
            slots={{ toolbar: CustomToolbar }}
            sortable
            filterable
        />
    );
};
