import { Warning } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useCallback } from "react";

import Popover from "components/Popovers/Popover";

const WarningPopover = ({ element }: { element: React.ReactNode }) => {
    const WarningIcon = useCallback(
        ({ isActive }: { isActive?: boolean }) => (
            <Warning
                style={{ fontSize: "20px" }}
                color={isActive ? "warning" : "inherit"}
            />
        ),
        []
    );

    return (
        <Popover
            mouseOver={true}
            parent={WarningIcon}
            parentBoxProps={{
                sx: {
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 5,
                },
            }}
        >
            <Typography fontSize={12}>{element}</Typography>
        </Popover>
    );
};

export default WarningPopover;
