import { match } from "ts-pattern";

import { FilterItemOption } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory } from "graphql-types/graphql";

import {
    FilterOptionCity,
    FilterOptionConstructionYear,
    FilterOptionCountry,
    FilterOptionGeneralProperty,
    FilterOptionLegalOwners,
    FilterOptionLocationId,
    FilterOptionPropertySize,
    FilterOptionCrremPropertyType,
    FilterOptionNationalPropertyType,
} from "./Options";

type Props = {
    filterCategory: AssetGroupFilterCategory | "";
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOption(props: Props) {
    const { filterCategory, onChange } = props;

    if (!filterCategory) {
        return null;
    }

    function onFilterChange(filter: FilterItemOption) {
        onChange(filter);
    }

    const FilterOptionComponents = match(filterCategory)
        .with(AssetGroupFilterCategory.ID, () => FilterOptionLocationId)
        .with(AssetGroupFilterCategory.CITY, () => FilterOptionCity)
        .with(AssetGroupFilterCategory.COUNTRY, () => FilterOptionCountry)
        .with(
            AssetGroupFilterCategory.LEGALOWNER,
            () => FilterOptionLegalOwners
        )
        .with(
            AssetGroupFilterCategory.PROPERTYSIZE,
            () => FilterOptionPropertySize
        )
        .with(
            AssetGroupFilterCategory.CONSTRUCTIONYEAR,
            () => FilterOptionConstructionYear
        )
        .with(
            AssetGroupFilterCategory.CRREMPROPERTYTYPES,
            () => FilterOptionCrremPropertyType
        )
        .with(
            AssetGroupFilterCategory.GENERALPROPERTYTYPES,
            () => FilterOptionGeneralProperty
        )
        .with(
            AssetGroupFilterCategory.NATIONALPROPERTYTYPES,
            () => FilterOptionNationalPropertyType
        )
        .exhaustive();

    if (FilterOptionComponents === null) {
        return null;
    }

    return <FilterOptionComponents onChange={onFilterChange} />;
}
