import {
    Box,
    InputAdornment,
    InputLabel,
    TextField,
    TextFieldProps,
    styled,
} from "@mui/material";

import WarningPopover from "components/Popovers/WarningPopover";

const disabledTextFieldStyles = {
    "& .MuiInputBase-input.Mui-disabled": {
        color: "black",
        WebkitTextFillColor: "black",

        pr: "14px",
    },

    "& .MuiInputBase-inputAdornedEnd.Mui-disabled": {
        pr: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
    },

    "& input[type=number]": {
        MozAppearance: "textfield",
    },

    " .MuiSvgIcon-root": {
        display: "none",
        margin: 0,
    },
};

export const getTextFieldStyle = (disabled?: boolean) => ({
    "& .MuiInputBase-input": {
        textAlign: "right",
    },

    "& .MuiFormHelperText-root": {
        fontSize: 12,
        lineHeight: "14px",
        m: 0,
    },

    ...(disabled ? disabledTextFieldStyles : {}),
});

export const TextFieldBox = styled(InputLabel)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "130px 1fr",
    alignItems: "center",
    gap: theme.spacing(4),
    borderBottom: "1px solid #d9e1fc",
    padding: theme.spacing(2, 4),

    margin: 0,
    fontSize: 14,
    "&:last-child": {
        borderBottom: 0,
    },
}));

export const TextFieldWithLabel = ({
    label,
    tooltipText,
    helperText,
    endAdornment,
    ...rest
}: TextFieldProps & {
    label: string;
    tooltipText?: string;
    endAdornment?: string;
}) => (
    <TextFieldBox>
        <Box display={"flex"}>
            {label}
            {tooltipText && <WarningPopover element={tooltipText} />}
        </Box>
        <TextField
            {...rest}
            id={label}
            size="small"
            sx={getTextFieldStyle(rest.disabled)}
            helperText={rest.error && helperText}
            InputProps={
                endAdornment
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  {endAdornment}
                              </InputAdornment>
                          ),
                      }
                    : {}
            }
        >
            {rest.children}
        </TextField>
    </TextFieldBox>
);
