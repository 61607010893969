import { Info, Warning } from "@mui/icons-material";
import { Tooltip, Box } from "@mui/material";
import { isDate } from "lodash";
import { DateTime } from "luxon";
import { cloneElement } from "react";
import { Control, Controller, FieldPath } from "react-hook-form";

import { S } from "./property-modal.styles";
import { ModalProperty } from "../../types";

type Props = {
    id: FieldPath<ModalProperty>;
    title: string;
    control: Control<ModalProperty>;
    rules?: Record<string, unknown>;
    children: JSX.Element;
    tooltip?: string;
    iconType?: "info" | "warning";
};

export const PropertyFormField = (props: Props) => {
    const { id, title, control, rules, children, tooltip } = props;

    return (
        <Controller
            name={id}
            rules={rules}
            control={control}
            render={({ field: { value, onChange } }) => (
                <S.Row item xs={6}>
                    <Box display="flex">
                        <S.Title htmlFor={id} id={`${id}-label`}>
                            { tooltip && (
                                <Tooltip title={tooltip}>
                                    { props.iconType === "info" ?
                                        <Info sx={{fontSize: "20px", verticalAlign: "middle"}}/>
                                        : <Warning sx={{fontSize: "20px", verticalAlign: "middle"}}/>
                                    }
                                </Tooltip>
                            )}

                            {title}
                        </S.Title>
                    </Box>
                    {cloneElement(children, {
                        id,
                        size: "small",
                        value: isDate(value)
                            ? DateTime.fromJSDate(value)
                            : value,
                        onChange: isDate(value)
                            ? (value: DateTime) => {
                                if(!value) return onChange(null);
                                return onChange(value.toJSDate())
                            }
                            : onChange,
                    })}
                </S.Row>
            )}
        />
    );
};
