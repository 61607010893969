import { Box, Typography, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { AssetPreview } from "components/AssetsGroup/types";
import Loading from "components/Loading/Loading";

import { S } from "./asset-preview-list.styles";

type Props = {
    list: AssetPreview[];
    loading: boolean;
};

export function AssetPreviewList(props: Props) {
    const { list, loading } = props;

    const { t } = useTranslation();

    if (!list.length || loading) {
        return (
            <S.Preview>
                <S.EmptyPreview>
                    {loading ? (
                        <Loading description={t("loading.title", "Loading")} />
                    ) : (
                        <Typography>
                            {capitalize(t("assetGroup.noAssets", "No Assets"))}
                        </Typography>
                    )}
                </S.EmptyPreview>
            </S.Preview>
        );
    }

    const items = list.map((item) => {
        const status = match(item.status)
            .with("new", () => "new")
            .with("removed", () => "removed")
            .with("unchanged", () => "unchanged")
            .exhaustive();

        return (
            <S.ListItem key={item.id} className={status}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography>{item.name}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexGrow: 1 }}>
                        <Typography sx={{ marginLeft: "auto" }}>
                            {item.externalId}
                        </Typography>
                    </Box>
                </Box>
            </S.ListItem>
        );
    });

    return (
        <S.Preview>
            <S.List>{items}</S.List>
        </S.Preview>
    );
}
