import { Box, Divider, TextField, capitalize } from "@mui/material";
import { ChangeEvent, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useAssetGroups } from "components/AssetsGroup";
import { ActiveFilter, FilterItemOption } from "components/AssetsGroup/types";
import Loading from "components/Loading/Loading";
import { Maybe } from "graphql-types/graphql";

import { S } from "./asset-group-modal.styles";
import { AssetPreviewList, Filter, FilterOverview } from "./components";
import { initialState, reducer } from "./store";
import { filtersState, previewAssetsUtils } from "./utils";
import { useActiveAssetGroup } from "../hooks/useActiveAssetGroup";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    assetGroupId: Maybe<string>;
};

export function AssetGroupModal(props: Props) {
    const { isOpen, assetGroupId, onClose } = props;

    const [state, dispatch] = useReducer(reducer, initialState);

    const [, setActiveGroupId] = useActiveAssetGroup();
    const { createAssetGroup, updateAssetGroup, preview, assetGroup } =
        useAssetGroups();

    const { t } = useTranslation();
    const {
        isEdit,
        isCreateDisabled,
        groupName,
        activeFilters,
        previewAssets,
    } = state;

    function onCloseModal() {
        dispatch({ type: "RESET", payload: null });
        onClose();
    }

    useEffect(() => {
        if (!assetGroupId) {
            return;
        }

        assetGroup.get(assetGroupId).then((response) => {
            if (!response) {
                return;
            }
            dispatch({
                type: "INIT",
                payload: {
                    groupId: assetGroupId,
                    groupName: response.name,
                    assets: response.assets,
                    previewAssets: [],
                    activeFilters: response.filters,
                },
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetGroupId]);

    useEffect(() => {
        if (activeFilters.length === 0) {
            return dispatch({ type: "UPDATE_ASSETS", payload: [] });
        }

        preview.get(filtersState.toJSON(activeFilters)).then((response) => {
            dispatch({ type: "UPDATE_ASSETS", payload: response });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters, dispatch]);

    function onGroupNameChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch({
            type: "UPDATE_NAME",
            payload: { groupName: e.target.value },
        });
    }

    async function onSave() {
        const successMessages = {
            edit: t(
                "assetGroup.assetGroupUpdatedSuccessfully",
                "Asset group updated successfully"
            ),
            create: t(
                "assetGroup.assetGroupCreatedSuccessfully",
                "Asset group created successfully"
            ),
        };

        const errorMessages = {
            edit: t(
                "assetGroup.assetGroupUpdateFailed",
                "Asset group update failed"
            ),
            create: t(
                "assetGroup.assetGroupCreationFailed",
                "Asset group creation failed"
            ),
        };

        const pendingAssets = previewAssetsUtils.toAssets(previewAssets);

        const action = {
            edit: () =>
                updateAssetGroup(
                    assetGroupId,
                    groupName,
                    filtersState.toJSON(activeFilters),
                    pendingAssets
                ),
            create: () =>
                createAssetGroup(
                    groupName,
                    filtersState.toJSON(activeFilters),
                    pendingAssets
                ),
        };

        const mode = isEdit ? "edit" : "create";

        function onSuccess() {
            onCloseModal();
            return successMessages[mode];
        }

        /**
         * Response is the id of the created asset group
         * When we set the active group id, the app will automatically
         * fetch the group and set it as active
         */
        const response = await toast.promise(
            action[mode] as unknown as Promise<void | string | undefined>,
            {
                success: onSuccess(),
                error: errorMessages[mode],
            }
        );

        if (!isEdit && response) {
            setActiveGroupId(response);
        }
    }

    function onFilterSelected(filter: FilterItemOption) {
        dispatch({ type: "ADD_FILTER", payload: filter });
    }

    function onFilterDelete(filter: ActiveFilter) {
        dispatch({ type: "REMOVE_FILTER", payload: filter });
    }

    return (
        <S.Modal open={isOpen} onClose={onCloseModal}>
            <S.ModalBody>
                {assetGroup.isLoading && (
                    <S.LoadingOverlay>
                        <Loading description={t("labels.loading")} />
                    </S.LoadingOverlay>
                )}
                <S.Header>
                    <S.HeaderTitle>
                        {isEdit
                            ? t("assetGroup.editAssetGroup", "Edit Asset Group")
                            : t(
                                  "assetGroup.createAssetGroup",
                                  "Create Asset Group"
                              )}
                    </S.HeaderTitle>
                    <S.ClosedIconBox>
                        <S.ClosedIcon onClick={onCloseModal} />
                    </S.ClosedIconBox>
                </S.Header>
                <S.Layout>
                    <Box className="column">
                        <S.Section>
                            <S.Title>
                                {t("assetGroup.filter", "Filter")}
                            </S.Title>
                            <Filter onSelected={onFilterSelected} />
                        </S.Section>
                        <S.Section>
                            <S.Title>
                                {t(
                                    "assetGroup.activeFilters",
                                    "Active Filters"
                                )}
                            </S.Title>
                            <FilterOverview
                                filters={activeFilters}
                                onDelete={onFilterDelete}
                            />
                        </S.Section>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box className="column">
                        <S.Section>
                            <S.Title>
                                {t("assetGroup.groupName", "Group Name")}
                            </S.Title>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                onChange={onGroupNameChange}
                                label={capitalize(
                                    t(
                                        "assetGroup.assetGroupNamePlaceholder",
                                        "Group name"
                                    )
                                )}
                                value={groupName}
                            />
                        </S.Section>
                        <S.Section>
                            <S.Title>
                                {t(
                                    "assetGroup.assetsInGroup",
                                    "Assets in Group"
                                )}
                            </S.Title>
                            <AssetPreviewList
                                list={previewAssets}
                                loading={preview.isLoading}
                            />
                        </S.Section>
                        <S.CTASection>
                            <S.Counter>
                                {t("shared.assetWithCount", {
                                    count: previewAssetsUtils.toAssets(
                                        previewAssets
                                    ).length,
                                })}
                            </S.Counter>
                            <S.Button
                                variant="contained"
                                disabled={isCreateDisabled}
                                onClick={onSave}
                            >
                                {isEdit
                                    ? t("assetGroup.saveGroup", "Save Group")
                                    : t(
                                          "assetGroup.createGroup",
                                          "Create Group"
                                      )}
                            </S.Button>
                        </S.CTASection>
                    </Box>
                </S.Layout>
            </S.ModalBody>
        </S.Modal>
    );
}
