import { compact } from "lodash";
import { createContext, PropsWithChildren, useContext, useState } from "react";

import { Benchmark } from "graphql-types/graphql";

interface ActiveCountryBenchmarkContextType {
    activeCountryCode: string | null;
    countryCodes: string[];
    setCountryCode: (country: string) => void;
}

const ActiveCountryBenchmarkContext =
    createContext<ActiveCountryBenchmarkContextType | null>(null);

type Props = {
    benchmarks: Benchmark[];
} & PropsWithChildren;

export const ActiveCountryBenchmarkProvider = (props: Props) => {
    const { benchmarks } = props;
    const countryCodes = compact(
        benchmarks.map(({ countryCode }) => countryCode)
    );

    const [countryCode, setCountryCode] = useState<string | null>(
        countryCodes[0] || null
    );

    return (
        <ActiveCountryBenchmarkContext.Provider
            value={{
                // benchmarks are an empty list on first render
                // so we need to set the default value
                activeCountryCode: countryCode ?? countryCodes[0],
                countryCodes,
                setCountryCode,
            }}
        >
            {props.children}
        </ActiveCountryBenchmarkContext.Provider>
    );
};

export const useActiveCountryBenchmark = () => {
    const context = useContext(ActiveCountryBenchmarkContext);

    if (!context) {
        throw new Error(
            "useActiveCountryBenchmark must be used within a ActiveCountryBenchmarkProvider"
        );
    }

    return context;
};
