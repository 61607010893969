export const getDirtyValues = <
    DirtyFields extends Record<string, unknown>,
    Values extends Partial<Record<keyof DirtyFields, unknown>>
>(
    dirtyFields: DirtyFields,
    values: Values
): Partial<typeof values> => {
    const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
        // When fieldArray is used and a new item is added, this will set all other fields to false
        // This will make sure those fields are not included in the output
        if (!dirtyFields[key]) return prev;

        return {
            ...prev,
            [key]:
                typeof dirtyFields[key] === "object"
                    ? getDirtyValues(
                          dirtyFields[key] as DirtyFields,
                          values[key] as Values
                      )
                    : values[key],
        };
    }, {});

    return dirtyValues;
};
