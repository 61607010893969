import { ArrowDropDown } from "@mui/icons-material";
import {
    Typography,
    Accordion as MaterialAccordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import React, { PropsWithChildren } from "react";

type Props = {
    paneTitle: string;
    defaultExpanded?: boolean;
    onChange?: (event: React.ChangeEvent<object>, expanded: boolean) => void;
};

const Accordion = (props: PropsWithChildren<Props>) => {
    const { paneTitle, children, defaultExpanded = true, onChange } = props;

    return (
        <MaterialAccordion
            defaultExpanded={defaultExpanded}
            onChange={onChange}
        >
            <AccordionSummary
                expandIcon={<ArrowDropDown />}
                sx={{
                    paddingLeft: 4,
                    borderRadius: 1,
                    backgroundColor: "grey.200",

                    "&.Mui-expanded": {
                        minHeight: "48px !important",
                    },
                    "& .Mui-expanded": {
                        margin: "0px !important",
                    },
                }}
            >
                <Typography fontWeight={700}>{paneTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
        </MaterialAccordion>
    );
};

export default Accordion;
