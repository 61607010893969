import { useQuery } from "@apollo/client";
import { Paper } from "@mui/material";
import { chain, orderBy } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "components/Table";
import {
    GetAssetEpcDataQuery,
    GetAssetEpcDataQueryVariables,
} from "graphql-types/graphql";
import { ASSET_EPC_DATA } from "pages/DataOverviewPage/data-overview.queries";
import { epcOverviewColumns } from "pages/DataOverviewPage/EPCDashboard/epc-table.definitions";

import { mapEpcSources } from "../AssetDetails/AssetDetails.helpers";

type Props = {
    assetId: string;
};

export const AssetEPCData = (props: Props) => {
    const { assetId } = props;

    const { data, loading } = useQuery<
        GetAssetEpcDataQuery,
        GetAssetEpcDataQueryVariables
    >(ASSET_EPC_DATA, { variables: { assetId } });

    const { t } = useTranslation();

    const rows = useMemo(() => {
        const assessments = chain(data?.location.assessments || [])
            .groupBy(({ data, from }) => {
                const epcIdentifier =
                    data &&
                    (("epcIdentifier" in data && data.epcIdentifier) ||
                        ("lmkKey" in data && data.lmkKey));

                return `${epcIdentifier}-${from}`;
            })
            .entries()
            .map(([_, assessments]) => assessments)
            .value();

        const epcData = assessments.map((assessments) =>
            mapEpcSources(assessments, t)
        );

        return orderBy(
            epcData,
            (checkStatus) => checkStatus.nationalIdentifiers[0],
            ["desc"]
        );
    }, [data, t]);

    return (
        <Paper sx={{ pt: 6 }}>
            <Table
                rows={rows}
                isLoading={loading}
                columns={epcOverviewColumns(t)}
                sortable={true}
            />
        </Paper>
    );
};
