import {
    CircularProgress,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import React, { SetStateAction } from "react";
import { Trans } from "react-i18next";

import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { LocationIntegrationMappingRow } from "./LocationIntegrationMappingRow";
import { MappingTableContainer } from "./MappingTableContainer";
import {
    IntegrationOption,
    LocationIntegrationRowType,
    LocationIntegrationState,
} from "../IntegrationOnboardingTypes";

type Props<
    I extends IntegrationOption,
    L extends LocationIntegrationRowType<I>
> = {
    locations: L[];
    isLoading: boolean;
    selectAllOnInitialize?: boolean;
    integrationType: SupportedIntegrationForOnboarding;
    setLocationIntegrations: (
        value: SetStateAction<LocationIntegrationState<I>>
    ) => void;
};

const LoadingRow = () => (
    <TableRow
        sx={{
            "&:last-child td": { borderBottom: "none" },
        }}
    >
        <TableCell
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress sx={{ mt: 12 }} />
        </TableCell>
    </TableRow>
);

export const LocationIntegrationMappingTable = <
    I extends IntegrationOption,
    L extends LocationIntegrationRowType<I>
>(
    props: Props<I, L>
) => {
    const {
        locations,
        integrationType,
        setLocationIntegrations,
        selectAllOnInitialize = false,
    } = props;

    if (props.isLoading) {
        return (
            <MappingTableContainer>
                <LoadingRow />
            </MappingTableContainer>
        );
    }

    if (!locations.length) {
        return (
            <MappingTableContainer>
                <Typography variant="h2" sx={{ py: 12, textAlign: "center" }}>
                    <Trans
                        i18nKey="integrationOnboarding.noMeterLocationsLabel"
                        values={{ integrationType }}
                    />
                </Typography>
            </MappingTableContainer>
        );
    }

    return (
        <MappingTableContainer>
            {locations.map(({ id, displayName, integrations }) => (
                <LocationIntegrationMappingRow
                    key={id}
                    displayName={displayName ?? DASH_SEPARATOR}
                    integrations={integrations}
                    selectAllOnInitialize={selectAllOnInitialize}
                    onClose={(selected) =>
                        setLocationIntegrations((prev) => ({
                            ...prev,
                            [id]: {
                                displayName,
                                ...selected,
                            },
                        }))
                    }
                />
            ))}
        </MappingTableContainer>
    );
};
