import { useQuery } from "@apollo/client";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
    GetAssetPropertyAllocationCountQuery,
    GetAssetPropertyAllocationCountQueryVariables,
} from "graphql-types/graphql";

import { GET_ASSET_PROPERTY_ALLOCATION_COUNT } from "./asset-property-allocation-count.query";
import { S } from "./asset-property-allocation-count.styles";

type Props = {
    assetId: string;
};

export const AssetPropertyAllocationCount = (props: Props) => {
    const { assetId } = props;

    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetAssetPropertyAllocationCountQuery,
        GetAssetPropertyAllocationCountQueryVariables
    >(GET_ASSET_PROPERTY_ALLOCATION_COUNT, {
        variables: { assetId },
    });

    const count = data?.locationIntegrations.totalCount;

    if (!count || loading) return null;

    const thereIsLabel =
        count > 1
            ? t("common.thereAre", "there are")
            : t("common.thereIs", "there is");
    return (
        <S.Content>
            <S.Typography>{thereIsLabel}</S.Typography>
            <Link href={`/location-overview/${assetId}/data-sources`}>
                <S.Typography>
                    {t(
                        "common.labels.dataSourceWithCount",
                        "{{count}} data sources",
                        { count }
                    )}
                </S.Typography>
            </Link>
            <S.Typography>
                {t(
                    "assetDetailsComponent.integrationsOnAsset.notAllocatedIntegrationsMessage",
                    "on the asset that have not been allocated to a property.",
                    { count }
                )}
            </S.Typography>
        </S.Content>
    );
};
