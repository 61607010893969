import { useTranslation } from "react-i18next";

import { LegacyBenchmarkType } from "graphql-types/graphql";

export const useBenchmarkTranslations = () => {
    const { t } = useTranslation();

    const translations: Record<string, string> = {
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-100`]: t(
            "benchmark.bbr.100",
            "farmhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-120`]: t(
            "benchmark.bbr.120",
            "detached single family house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-121`]: t(
            "benchmark.bbr.121",
            "single family house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-122`]: t(
            "benchmark.bbr.122",
            "detached single family house in dense low-rise buildings"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-130`]: t(
            "benchmark.bbr.130",
            "(deprecated) row-, chain- or double house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-131`]: t(
            "benchmark.bbr.131",
            "row-, chain- and clusterhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-132`]: t(
            "benchmark.bbr.132",
            "double house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-140`]: t(
            "benchmark.bbr.140",
            "apartment building and multifamily housing"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-150`]: t(
            "benchmark.bbr.150",
            "dormitory"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-160`]: t(
            "benchmark.bbr.160",
            "residential institution"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-185`]: t(
            "benchmark.bbr.185",
            "residential annex"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-190`]: t(
            "benchmark.bbr.190",
            "other residential"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-210`]: t(
            "benchmark.bbr.210",
            "(deprecated) commercial production buildings related to agriculture and mining"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-211`]: t(
            "benchmark.bbr.211",
            "pigsty"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-212`]: t(
            "benchmark.bbr.212",
            "cowshed"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-213`]: t(
            "benchmark.bbr.213",
            "poultry barn"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-214`]: t(
            "benchmark.bbr.214",
            "mink farm"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-215`]: t(
            "benchmark.bbr.215",
            "greenhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-216`]: t(
            "benchmark.bbr.216",
            "barn for feed and crop"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-217`]: t(
            "benchmark.bbr.217",
            "garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-218`]: t(
            "benchmark.bbr.218",
            "hay shed"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-219`]: t(
            "benchmark.bbr.219",
            "other building related to farming"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-220`]: t(
            "benchmark.bbr.220",
            "(deprecated) factory, workshop etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-221`]: t(
            "benchmark.bbr.221",
            "industrial building with integrated production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-222`]: t(
            "benchmark.bbr.222",
            "industrial building without integrated production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-223`]: t(
            "benchmark.bbr.223",
            "workshop"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-229`]: t(
            "benchmark.bbr.229",
            "other production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-230`]: t(
            "benchmark.bbr.230",
            "(deprecated) power-, gas-, heating plant, waterworks, incinerator etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-231`]: t(
            "benchmark.bbr.231",
            "energy production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-232`]: t(
            "benchmark.bbr.232",
            "supply- and energy distribution facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-233`]: t(
            "benchmark.bbr.233",
            "water facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-234`]: t(
            "benchmark.bbr.234",
            "waste- and sewage facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-239`]: t(
            "benchmark.bbr.239",
            "other energy facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-290`]: t(
            "benchmark.bbr.290",
            "(deprecated) other farming and industrial facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-310`]: t(
            "benchmark.bbr.310",
            "(deprecated) transport and garage facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-311`]: t(
            "benchmark.bbr.311",
            "rail and bus facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-312`]: t(
            "benchmark.bbr.312",
            "aviation facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-313`]: t(
            "benchmark.bbr.313",
            "parking and transport facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-314`]: t(
            "benchmark.bbr.314",
            "residential garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-315`]: t(
            "benchmark.bbr.315",
            "seaport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-319`]: t(
            "benchmark.bbr.319",
            "other transport facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-320`]: t(
            "benchmark.bbr.320",
            "(deprecated) office, trade, warehouse or governmental administration building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-321`]: t(
            "benchmark.bbr.321",
            "office building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-322`]: t(
            "benchmark.bbr.322",
            "retail building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-323`]: t(
            "benchmark.bbr.323",
            "warehouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-324`]: t(
            "benchmark.bbr.324",
            "shopping center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-325`]: t(
            "benchmark.bbr.325",
            "gas station"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-329`]: t(
            "benchmark.bbr.329",
            "other building for office, retail, and warehouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-330`]: t(
            "benchmark.bbr.330",
            "(deprecated) hotel, restaurant and service company"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-331`]: t(
            "benchmark.bbr.331",
            "hotel, inn or conference center with overnight stay"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-332`]: t(
            "benchmark.bbr.332",
            "bed & breakfast"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-333`]: t(
            "benchmark.bbr.333",
            "restaurant, cafe and conference center without overnight stay"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-334`]: t(
            "benchmark.bbr.334",
            "private service company"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-339`]: t(
            "benchmark.bbr.339",
            "other building for service industry"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-390`]: t(
            "benchmark.bbr.390",
            "(deprecated) other building for transport, trade etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-410`]: t(
            "benchmark.bbr.410",
            "(deprecated) cinema, theater, library, museum, church etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-411`]: t(
            "benchmark.bbr.411",
            "cinema, theater, concert hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-412`]: t(
            "benchmark.bbr.412",
            "museum"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-413`]: t(
            "benchmark.bbr.413",
            "library"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-414`]: t(
            "benchmark.bbr.414",
            "church or other building for exercise of faith"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-415`]: t(
            "benchmark.bbr.415",
            "assembly hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-416`]: t(
            "benchmark.bbr.416",
            "amusement park"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-419`]: t(
            "benchmark.bbr.419",
            "other cultural building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-420`]: t(
            "benchmark.bbr.420",
            "(deprecated) educational building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-421`]: t(
            "benchmark.bbr.421",
            "school"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-422`]: t(
            "benchmark.bbr.422",
            "university"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-429`]: t(
            "benchmark.bbr.429",
            "other building for education and research"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-430`]: t(
            "benchmark.bbr.430",
            "(deprecated) hospital, nursing home, maternity clinic etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-431`]: t(
            "benchmark.bbr.431",
            "hospital"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-432`]: t(
            "benchmark.bbr.432",
            "hospice, treatment home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-433`]: t(
            "benchmark.bbr.433",
            "health, medical center, and maternity clinic"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-439`]: t(
            "benchmark.bbr.439",
            "other health-related building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-440`]: t(
            "benchmark.bbr.440",
            "(deprecated) daycare center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-441`]: t(
            "benchmark.bbr.441",
            "daycare center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-442`]: t(
            "benchmark.bbr.442",
            "service function for residential care institutions"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-443`]: t(
            "benchmark.bbr.443",
            "barracks"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-444`]: t(
            "benchmark.bbr.444",
            "prison, jail etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-449`]: t(
            "benchmark.bbr.449",
            "other building for institutional purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-490`]: t(
            "benchmark.bbr.490",
            "(deprecated) building for other institution, barracks, prison etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-510`]: t(
            "benchmark.bbr.510",
            "cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-520`]: t(
            "benchmark.bbr.520",
            "(deprecated) summer camp, hostel and other vacation home except cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-521`]: t(
            "benchmark.bbr.521",
            "summer camp, camping center etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-522`]: t(
            "benchmark.bbr.522",
            "commercial rental vacation home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-523`]: t(
            "benchmark.bbr.523",
            "vacation home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-529`]: t(
            "benchmark.bbr.529",
            "other building for vacation purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-530`]: t(
            "benchmark.bbr.530",
            "(deprecated) sports facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-531`]: t(
            "benchmark.bbr.531",
            "clubhouse related to leisure and sport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-532`]: t(
            "benchmark.bbr.532",
            "swimming pool"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-533`]: t(
            "benchmark.bbr.533",
            "sports hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-534`]: t(
            "benchmark.bbr.534",
            "stadium tribune"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-535`]: t(
            "benchmark.bbr.535",
            "stables"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-539`]: t(
            "benchmark.bbr.539",
            "other building for sports purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-540`]: t(
            "benchmark.bbr.540",
            "allotment house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-585`]: t(
            "benchmark.bbr.585",
            "annex for cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-590`]: t(
            "benchmark.bbr.590",
            "other building for leisure purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-910`]: t(
            "benchmark.bbr.910",
            "garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-920`]: t(
            "benchmark.bbr.920",
            "carport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-930`]: t(
            "benchmark.bbr.930",
            "outhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-940`]: t(
            "benchmark.bbr.940",
            "greenhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-950`]: t(
            "benchmark.bbr.950",
            "detached cover"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-960`]: t(
            "benchmark.bbr.960",
            "detached conservatory"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-970`]: t(
            "benchmark.bbr.970",
            "leftover farmbuilding"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-990`]: t(
            "benchmark.bbr.990",
            "ruin"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-999`]: t(
            "benchmark.bbr.999",
            "unknown"
        ),
    };

    return {
        translations,
    };
};
