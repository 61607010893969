import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { getFormattedPropertyType } from "containers/AssetPage/AssetDetails/AssetDetails.helpers";
import { PropertyType } from "graphql-types/graphql";
import { formatDate } from "utils/date.utils";
import {
    formatNumberLocale,
    formatPercentageLocale,
} from "utils/report.helpers";

export const formatFields = (
    value: string | null | undefined,
    field: string,
    t: TFunction
) => {
    if (value === null || value === undefined) {
        return "-";
    }

    const parsedValue = JSON.parse(value);

    if (!parsedValue) {
        return "-";
    }
    if (!isNaN(parsedValue)) {
        const number = Number(parsedValue);
        return field === "ownership"
            ? formatPercentageLocale(number / 100, t)
            : formatNumberLocale(parsedValue, t);
    }
    if (DateTime.fromISO(parsedValue).isValid) {
        return formatDate(parsedValue);
    }
    if (Object.values(PropertyType).includes(parsedValue)) {
        return getFormattedPropertyType(parsedValue, t);
    }

    return parsedValue;
};

export const mapFieldTranslation = (field: string, t: TFunction) => {
    switch (field) {
        case "name":
            return t("assetDetailsComponent.name", "Name", {
                ns: "translation",
            });
        case "buildingArea":
            return t("assetDetailsComponent.totalArea", "Total Area", {
                ns: "translation",
            });
        case "saleDate":
            return t("assetDetailsComponent.saleDate", "Sale Date", {
                ns: "translation",
            });
        case "propertyType":
            return t("assetDetailsComponent.propertyType", "Property Type", {
                ns: "translation",
            });
        case "purchaseDate":
            return t("assetDetailsComponent.purchaseDate", "Purchase Date", {
                ns: "translation",
            });
        case "ownership":
            return t("assetDetailsComponent.ownership", "Ownership", {
                ns: "translation",
            });
        case "legalOwner":
            return t("assetDetailsComponent.legalOwner", "Legal Owner", {
                ns: "translation",
            });
        case "management":
            return t("assetDetailsComponent.management", "Management", {
                ns: "translation",
            });
        case "externalId":
            return t("assetDetailsComponent.internalID", "Internal ID", {
                ns: "translation",
            });
        default:
            return field;
    }
};

export const getTableColumns = (
    isChangeLogPage: boolean,
    t: TFunction,
    locale: string
): GridColDef[] => [
    {
        field: "timestamp",
        headerName: t("columns.timestamp", "Timestamp", { ns: "report" }),
        flex: 1,
        valueFormatter: (value: any) =>
            new Date(value).toLocaleString(locale === "en" ? "en-GB" : locale),
    },
    {
        field: "eventType",
        headerName: t("common.labels.type", "Event Type"),
        flex: 0.75,
    },
    ...(isChangeLogPage
        ? [
              {
                  field: "externalId",
                  headerName: t("columns.assetId", { ns: "report" }),
                  flex: 0.75,
              },
              {
                  field: "name",
                  headerName: t("columns.assetName", { ns: "report" }),
                  flex: 0.75,
              },
          ]
        : []),
    {
        field: "field",
        headerName: t("columns.field", "Field", { ns: "report" }),
        flex: 1,
    },
    {
        field: "before",
        headerName: t("columns.before", "Before", { ns: "report" }),
        flex: 1,
    },
    {
        field: "after",
        headerName: t("columns.after", "After", { ns: "report" }),
        flex: 1,
    },
    {
        field: "user",
        headerName: t("columns.who", "Who", { ns: "report" }),
        flex: 0.75,
    },
];

export const getChangeLogFileName = (
    t: TFunction,
    isChangeLogPage: boolean,
    assetName?: string,
    organizationName?: string
) => {
    const asset = assetName || t("common.asset", "Asset");
    const organization =
        organizationName || t("common.organization", "Organization");

    const prefix = isChangeLogPage ? organization : asset;

    return `${prefix} - ${t("navbar.changeLog", "Change Log")}`;
};
