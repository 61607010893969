import { TFunction } from "i18next";
import { upperFirst } from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { getFormattedValueToMassUnit } from "components/PortfolioDashboard/Charts/charts.helper";

export const formatValue = (value: any, t: TFunction) =>
    getFormattedValueToMassUnit(value, UnitMass.KILOGRAM, t, false) ?? "";

export const getProjectedSection = (series: any[], dataIndex: number) => {
    const [projectedSeries]: any | undefined = series.filter(
        ({ data, id }) => data[dataIndex] && id === "projected"
    );

    if (!projectedSeries) return null;

    return {
        label: projectedSeries?.label,
        value: projectedSeries.valueFormatter(projectedSeries.data[dataIndex], {
            dataIndex,
        }),
    };
};

export const getBenchmarkSection = (
    series: any[],
    dataIndex: number,
    t: TFunction
) => {
    const benchmarkSeries = series
        .filter(({ id }) => (id as string).includes("benchmark"))
        .sort((a, b) => b.data[dataIndex] - a.data[dataIndex]);

    if (benchmarkSeries.length === 0) {
        return undefined;
    }

    const items = benchmarkSeries.map((benchmark: any) => {
        const { color, label, data } = benchmark;

        const tooltipLabel = benchmark.id.includes("legacy-benchmark")
            ? label
            : label.split(" ")[0];

        return {
            label: tooltipLabel,
            value: benchmark.valueFormatter(data[dataIndex], { dataIndex }),
            iconColor: color,
        };
    });

    return {
        header: upperFirst(t("benchmark.plural", "Benchmarks")),
        items,
    };
};
