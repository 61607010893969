import { Box, Button, Container, Typography } from "@mui/material";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import Loading from "components/Loading/Loading";
import { useUserContext } from "context";
import request from "utils/request";

import IntegrationSetup from "./IntegrationSetup/IntegrationSetup";

function InternalToolBoxPage() {
    const { name, organization } = useUserContext() || {};

    const handleReloadOrg = useCallback(() => {
        if (!organization) {
            return;
        }

        const endpoint = process.env.REACT_APP_API_URL;
        const url = `${endpoint}/integration/reports/reload-organization`;
        return request(url)
            .then(() => {
                alert(`Started portfolio reload for ${organization.name}`);
            })
            .catch(() => {
                alert(`Portfolio reload for ${organization.name} failed`);
            });
    }, [organization]);

    if (!organization) {
        return <Loading description={"Loading organization"} />;
    }

    return (
        <Container>
            <Typography variant="h1">
                Active org: {organization?.name}
            </Typography>
            <Typography variant="caption">
                Org id: {organization?.id}
            </Typography>
            <Typography variant="h2">Active user: {name}</Typography>
            <Box display="flex">
                <Box display="flex" flexDirection="column">
                    <Link to="/internal-data-upload">Go to CSV Import</Link>
                    <Link to="/internal-customer-onboarding">
                        Go to Customers Onboarding
                    </Link>
                    <Link to="/integration-onboarding">
                        Go to Integration Onboarding
                    </Link>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReloadOrg}
                    >
                        Reload portfolio
                    </Button>
                </Box>
            </Box>
            <IntegrationSetup />
        </Container>
    );
}

export default InternalToolBoxPage;
