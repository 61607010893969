import { gql } from "@apollo/client";

export const UPDATE_ORGANIZATION_MUTATION = gql`
    mutation updateOrganization($input: UpdateOneOrganizationInput!) {
        updateOneOrganization(input: $input) {
            id
            baselineYear
        }
    }
`;
