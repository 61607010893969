import ApartmentIcon from "@mui/icons-material/Apartment";
import { Button, styled, Menu, Box } from "@mui/material";
import { PropsWithChildren, useState } from "react";

import { convertToRem } from "utils/maths";

const AccountButton = styled(Button)(() => ({
    "&:focus": {
        outline: "none !important",
    },
}));

const navMenuStyle = {
    marginLeft: 64,
    marginTop: 20,
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.05)",
    width: convertToRem(320),
    borderRadius: convertToRem(1),
};

const NavMenu = (props: PropsWithChildren) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            <AccountButton onClick={handleClick}>
                <ApartmentIcon
                    fontSize="small"
                    sx={(theme) => ({
                        fill: theme.palette.grey[100],
                        background: theme.palette.primary.main,
                        borderRadius: theme.spacing(2.5),
                        width: theme.spacing(8.75),
                        height: theme.spacing(8.75),
                        padding: theme.spacing(1.25),
                    })}
                />
            </AccountButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                keepMounted
                disableScrollLock
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                slotProps={{
                    paper: {
                        style: navMenuStyle,
                    },
                }}
            >
                {props.children}
            </Menu>
        </Box>
    );
};

export default NavMenu;
