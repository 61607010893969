import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { capitalize, sortBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useFilterTranslations } from "components/AssetsGroup/hooks";
import { FilterItemOption } from "components/AssetsGroup/types";
import { AssetGroupFilterCategory } from "graphql-types/graphql";

import { FilterOption } from "../FilterOption";

type Props = {
    onSelected: (filter: FilterItemOption) => void;
};

export function Filter(props: Props) {
    const { onSelected } = props;

    const { t } = useTranslation();
    const { translations } = useFilterTranslations();

    const [selectedFilterCategory, setSelectedFilterCategory] = useState<
        AssetGroupFilterCategory | ""
    >("");

    function onChange(event: SelectChangeEvent<AssetGroupFilterCategory>) {
        setSelectedFilterCategory(
            event.target.value as AssetGroupFilterCategory
        );
    }

    function onFilterChange(filter: FilterItemOption) {
        onSelected(filter);
        setSelectedFilterCategory("");
    }

    const options = Object.entries(AssetGroupFilterCategory).map((option) => {
        const [, key] = option;

        let label = translations[key as AssetGroupFilterCategory];

        if (key !== AssetGroupFilterCategory.CRREMPROPERTYTYPES) {
            label = capitalize(label);
        }

        return { key, value: label };
    });

    /**
     * We would like to group all Property Types filter options together
     */
    const filterOptions = sortBy(options, (option) => {
        return option.key.includes("PropertyTypes") ? 0 : 1;
    });

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
            }}
        >
            <FormControl sx={{ flexGrow: 1, maxWidth: 225 }}>
                <InputLabel id="select-filter-category" size="small">
                    {t("assetGroup.selectCategory", "select a category")}
                </InputLabel>
                <Select<AssetGroupFilterCategory>
                    fullWidth
                    size="small"
                    labelId="select-filter-category"
                    value={selectedFilterCategory}
                    onChange={onChange}
                    displayEmpty={true}
                    label={t("assetGroup.selectCategory", "select a category")}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            },
                        },
                    }}
                >
                    {filterOptions.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                            <Typography fontWeight="bold" display="inline">
                                {option.value}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FilterOption
                filterCategory={selectedFilterCategory}
                onChange={onFilterChange}
            />
        </Box>
    );
}
