import { ChartsLegend, LegendRendererProps } from "@mui/x-charts";
import { AnchorPosition } from "@mui/x-charts/ChartsLegend/legend.types";

export type ChartLegendProps = {
    hidden?: boolean;
    position?: Partial<AnchorPosition>;
    slotProps?: Partial<LegendRendererProps>;
};

export const ChartLegend = (props: ChartLegendProps) => {
    const { hidden, position, slotProps } = props;

    return (
        <ChartsLegend
            position={{
                vertical: "top",
                horizontal: "middle",
                ...position,
            }}
            hidden={hidden ?? true}
            slotProps={{
                legend: {
                    padding: {
                        bottom: position?.vertical === "bottom" ? -2 : 0,
                    },
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                    labelStyle: { fontSize: 16 },
                    ...slotProps,
                },
            }}
        />
    );
};
