import { gql } from "@apollo/client";

const DexmaMeterLocationFragment = gql`
    fragment DexmaMeterLocation on DexmaLocationData {
        id
        key
        name
        activity
        reference_devices {
            type
            device {
                id
            }
        }
    }
`;

const TechemMeterLocationFragment = gql`
    fragment TechemMeterLocation on TechemLocationData {
        property_number
    }
`;

const VitaniMeterLocationFragment = gql`
    fragment VitaniMeterLocation on VitaniLocationData {
        Name
        DirectoryID
        BuildingID
    }
`;

const HoforMeterLocationFragment = gql`
    fragment HoforMeterLocation on HoforLocationData {
        installationNumber
    }
`;

const EntolabsMeterLocationFragment = gql`
    fragment EntolabsMeterLocation on EntolabsLocationData {
        entolabsOrganizationId
        meterId
        meterName
        meterType
        siteId
        siteName
    }
`;

const ElectralinkMeterLocationFragment = gql`
    fragment ElectralinkMeterLocation on ElectralinkLocation {
        postCode
        address
        hashedMPAN
        partialMPAN
    }
`;

const CustomerLocationFragment = gql`
    fragment CustomerLocation on Location {
        id
        displayName
        externalId
        shortAddress
        longAddress
        zipCode
        buildings {
            name
            shortAddress
            longAddress
            nationalIdentifier
            zipCode
        }
        integrations {
            assetId
            type
            originId
            startedAt
            endedAt
            data {
                ... on IntegrationDexmaLocationIntegrationData {
                    id
                    type
                }
                ... on IntegrationTechemLocationIntegrationData {
                    propertyId
                }
                ... on IntegrationVitaniLocationIntegrationData {
                    directoryId
                }
                ... on IntegrationHoforLocationIntegrationData {
                    installationNumber
                }
            }
        }
    }
`;

export const INTEGRATIONS_TYPES_FOR_ONBOARDING_QUERY = gql`
    query GetIntegrationsTypesForOnboarding {
        integrationTypesForOnboarding
    }
`;

export const GET_DEXMA_METER_LOCATIONS_QUERY = gql`
    query GetDexmaMeterLocations {
        locationsForDexmaIntegration {
            meterLocations {
                ...DexmaMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...DexmaMeterLocation
                }
                rowIndex
            }
        }
    }
    ${DexmaMeterLocationFragment}
    ${CustomerLocationFragment}
`;

export const GET_TECHEM_METER_LOCATIONS_QUERY = gql`
    query GetTechemMeterLocations {
        locationsForTechemIntegration {
            meterLocations {
                ...TechemMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...TechemMeterLocation
                }
                rowIndex
            }
        }
    }
    ${TechemMeterLocationFragment}
    ${CustomerLocationFragment}
`;

export const GET_VITANI_METER_LOCATIONS_QUERY = gql`
    query GetVitaniMeterLocations {
        locationsForVitaniIntegration {
            meterLocations {
                ...VitaniMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...VitaniMeterLocation
                }
                rowIndex
            }
        }
    }
    ${VitaniMeterLocationFragment}
    ${CustomerLocationFragment}
`;

export const GET_HOFOR_METER_LOCATIONS_QUERY = gql`
    query GetHoforMeterLocations {
        locationsForHoforIntegration {
            meterLocations {
                ...HoforMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...HoforMeterLocation
                }
                rowIndex
            }
        }
    }
    ${HoforMeterLocationFragment}
    ${CustomerLocationFragment}
`;

export const GET_ENTOLABS_METER_LOCATIONS_QUERY = gql`
    query GetEntolabsMeterLocations {
        locationsForEntolabsIntegration {
            meterLocations {
                ...EntolabsMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...EntolabsMeterLocation
                }
                rowIndex
            }
        }
    }
    ${EntolabsMeterLocationFragment}
    ${CustomerLocationFragment}
`;

export const GET_OIS_METER_LOCATIONS_QUERY = gql`
    query GetOisMeterLocations($types: [IntegrationType!]) {
        me {
            organization {
                integrations {
                    type
                }
                getLocations {
                    id
                    name
                    externalId
                    shortAddress
                    longAddress
                    buildings {
                        name
                        shortAddress
                        longAddress
                        nationalIdentifier
                    }
                    integrations(filter: { type: { in: $types } }) {
                        assetId
                        type
                        originId
                        startedAt
                        endedAt
                        data {
                            ... on IntegrationOisLocationIntegrationData {
                                bbr
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ELECTRALINK_METER_LOCATIONS_QUERY = gql`
    query GetElectralinkMeterLocations {
        locationsForElectralinkIntegration {
            meterLocations {
                ...ElectralinkMeterLocation
            }
            initialLocationIntegrationsState {
                customerLocation {
                    ...CustomerLocation
                }
                meterLocations {
                    ...ElectralinkMeterLocation
                }
                rowIndex
            }
        }
    }
    ${ElectralinkMeterLocationFragment}
    ${CustomerLocationFragment}
`;
