import { useQuery } from "@apollo/client";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import {
    AssetGroupFilterCategory,
    GetAssetsGroupCitiesQuery,
} from "graphql-types/graphql";

import { CITY_QUERY } from "./city.query";
import { FilterOptionSelect } from "../../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionCity(props: Props) {
    const { onChange } = props;

    const { data } = useQuery<GetAssetsGroupCitiesQuery>(CITY_QUERY);

    const cityList =
        data?.me.organization?.buildingCities?.map(({ city }) => ({
            key: city,
            value: city,
        })) || [];

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.CITY,
            values: [value],
        });
    }

    return (
        <FilterOptionSelect list={cityList} onSelectedValue={onSelectedValue} />
    );
}
