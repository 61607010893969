import { Typography } from "@mui/material";
import { TFunction } from "i18next";

import { DASH_SEPARATOR } from "utils/strings.helpers";

export enum DataSourceStatus {
    ACTIVE = "ACTIVE",
    EXPIRED = "EXPIRED",
    MANUAL = "MANUAL",
}

const formatDataSourceStatus = (
    status: DataSourceStatus | string,
    t: TFunction
) => {
    switch (status) {
        case DataSourceStatus.EXPIRED:
            return t("dataLocationCategory.expired", "Expired", {
                ns: "translation",
            });
        case DataSourceStatus.ACTIVE:
            return t("dataLocationCategory.active", "Active", {
                ns: "translation",
            });
        case DataSourceStatus.MANUAL:
            return t("dataLocationCategory.manual", "Manual upload", {
                ns: "translation",
            });
        default:
            return status ?? DASH_SEPARATOR;
    }
};

export const DataStatusCell = ({
    status,
    t,
}: {
    status: DataSourceStatus | string;
    t: TFunction;
}) => {
    const label = formatDataSourceStatus(status, t);

    return (
        <Typography
            color={
                status === DataSourceStatus.EXPIRED
                    ? "error.main"
                    : "common.black"
            }
        >
            {label}
        </Typography>
    );
};
