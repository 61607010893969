import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Typography, IconButton } from "@mui/material";
import { useCallback } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Confirmation = () => {
    const navigate = useNavigate();

    const handleRedirect = useCallback(() => {
        navigate("/reports?section=saved");
    }, [navigate]);

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            onClick={handleRedirect}
        >
            <Typography flexGrow={1}>
                <Trans i18nKey="labels.confirmation" ns="report">
                    Your report has been saved and downloaded. <br />
                    Go to saved reports
                </Trans>
            </Typography>
            <IconButton color="primary">
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};
