import { createContext, useContext, useState } from "react";

import { AssessmentDataType } from "graphql-types/graphql";

export interface AssessmentDataTypeContext {
    assessmentType: AssessmentDataType;
    setAssessmentType(newType: AssessmentDataType): void;
}

const SelectedAssessmentDataTypeDateContext =
    createContext<AssessmentDataTypeContext | null>(null);

export const useAssessmentDataTypeContext = () => {
    const context = useContext(SelectedAssessmentDataTypeDateContext);

    if (!context) {
        throw new Error(
            "useAssessmentDataContext must be used within a AssessmentDataTypeContextProvider"
        );
    }

    return context;
};

interface InputProps {
    children: React.ReactNode;
}

function AssessmentDataTypeContextProvider(props: InputProps) {
    const [assessmentType, setAssessmentType] = useState(
        AssessmentDataType.COMBINED
    );

    const value = {
        assessmentType,
        setAssessmentType,
    };

    const { children } = props;

    return (
        <SelectedAssessmentDataTypeDateContext.Provider value={value}>
            {children}
        </SelectedAssessmentDataTypeDateContext.Provider>
    );
}

export default AssessmentDataTypeContextProvider;
