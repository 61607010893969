import { Clear } from "@mui/icons-material";
import { Box, Button, Modal, Typography, styled } from "@mui/material";

export const S = {
    Modal: styled(Modal)(() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    })),

    ModalBody: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: theme.spacing(270),
        height: theme.spacing(164),
        backgroundColor: theme.palette.background.paper,
    })),

    Header: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey[300]}`,
        height: theme.spacing(18),
        width: "100%",
        paddingLeft: theme.spacing(8),
    })),

    LoadingOverlay: styled(Box)(({ theme }) => ({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(128,128,128,0.75)",
        zIndex: theme.zIndex.modal,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    })),

    HeaderTitle: styled(Typography)(({ theme }) => ({
        fontSize: theme.spacing(4.5),
        fontWeight: "bold",
        textTransform: "capitalize",
    })),

    Title: styled(Typography)(({ theme }) => ({
        fontSize: theme.spacing(4),
        fontWeight: "bold",
        textTransform: "capitalize",
        marginBottom: theme.spacing(4),
    })),

    ClosedIconBox: styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: theme.spacing(12.5),
        width: theme.spacing(12.5),
        marginLeft: "auto",
        marginRight: theme.spacing(4),
    })),

    ClosedIcon: styled(Clear)(({ theme }) => ({
        transition: "ease-out 0.1s all",
        display: "block",
        "&:hover": {
            cursor: "pointer",
            fontSize: theme.spacing(8),
        },
    })),

    Layout: styled(Box)(({ theme }) => ({
        display: "flex",
        height: `calc(100% - ${theme.spacing(18)})`,

        "& > .column": {
            flex: 1,
            padding: theme.spacing(6, 8, 12),
        },
    })),

    Section: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(6),
    })),

    CTASection: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        gap: theme.spacing(8),
    })),

    Counter: styled(Box)(({ theme }) => ({
        fontSize: theme.spacing(3.5),
        fontWeight: 600,
        textTransform: "capitalize",
        color: theme.palette.primary.main,
    })),

    Button: styled(Button)(() => ({
        textTransform: "capitalize",
    })),
};
