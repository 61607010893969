import { Box, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TabsPanel } from "components/TabsPanel";

import { EPCDashboard } from "./EPCDashboard/EPCDashboard";
import { IntegrationsTable } from "./IntegrationsTable";

enum DataOverviewTab {
    EPC,
    Integrations,
}

export const DataOverviewPage = () => {
    const { t } = useTranslation();

    const { showIntegrationsInDataPage } = useFlags();

    const [activeTab, setActiveTab] = useState(DataOverviewTab.EPC);

    const tabsData = [
        { title: t("pageheader.epcdata", "EPC"), value: DataOverviewTab.EPC },
        {
            title: t("integrationOnboarding.integrations", "Integrations"),
            value: DataOverviewTab.Integrations,
        },
    ];

    const sectionTitle =
        activeTab === DataOverviewTab.EPC
            ? t("epcDashboard.title", "Energy Performance Certificates")
            : t("integrationOnboarding.integrations", "Integrations");

    const handleTabChange = (
        _: React.ChangeEvent<object>,
        newValue: DataOverviewTab
    ) => setActiveTab(newValue);

    const title = showIntegrationsInDataPage
        ? `${t("dataOverview.title", "Your Data Overview")} - ${sectionTitle}`
        : sectionTitle;

    return (
        <Box sx={{ display: "grid", gap: 8 }}>
            <Typography variant="h2">{title}</Typography>
            {showIntegrationsInDataPage && (
                <TabsPanel
                    tabsData={tabsData}
                    activeTab={activeTab}
                    onChange={handleTabChange}
                />
            )}

            {activeTab === DataOverviewTab.EPC && <EPCDashboard />}
            {activeTab === DataOverviewTab.Integrations && (
                <IntegrationsTable />
            )}
        </Box>
    );
};
