import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { MonthChartData } from "components/PortfolioDashboard/types";
import { EmissionSummary, Location } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedConvertedGramsToMassUnit } from "../charts.helper";

interface IntensityEmissionByMonth {
    emissionIntensity: number;
    month: number;
    assetCount: number;
    totalArea: number;
}
interface AssetEmissionByMonth {
    emission: number;
    month: number;
    assetArea: number;
}

export type EmissionSummaryLocation = {
    emissionSummaries: Pick<EmissionSummary, "id" | "from" | "ownedEmission">[];
    ownedArea?: Location["ownedArea"];
};

export const getAssetEmissionSummariesEmissionByMonth = (
    emissionSummaryAsset: EmissionSummaryLocation
): AssetEmissionByMonth[] | null => {
    const assetArea = emissionSummaryAsset.ownedArea;
    if (!assetArea) {
        return null;
    }

    const emissionSummariesGroupedByMonth = _.chain(
        emissionSummaryAsset.emissionSummaries
    )
        .groupBy(
            (emissionSummary) => DateTime.fromISO(emissionSummary.from).month
        )
        .map((emissionSummariesByMonth, month): AssetEmissionByMonth => {
            return {
                assetArea,
                month: Number(month),
                emission: _.sumBy(
                    emissionSummariesByMonth,
                    (emissionSummary) => emissionSummary.ownedEmission || 0
                ),
            };
        })
        .sortBy((emissionSummariesByMonth) => emissionSummariesByMonth.month)
        .value();

    return emissionSummariesGroupedByMonth;
};

export const getIntensityEmissionByMonth = (
    assetEmissionsByMonth: AssetEmissionByMonth[]
): IntensityEmissionByMonth[] => {
    const assessmentsGroupedByMonth = _.chain(assetEmissionsByMonth)
        .groupBy((assetEmission) => assetEmission.month)
        .map((assetEmissions, month) => {
            const totalEmission = _.sumBy(
                assetEmissions,
                (assetEmission) => assetEmission.emission
            );
            const totalArea = _.sumBy(
                assetEmissions,
                (assetEmission) => assetEmission.assetArea
            );
            return {
                totalArea,
                assetCount: assetEmissions.length,
                month: Number(month),
                emissionIntensity: totalEmission / totalArea,
            };
        })
        .sortBy((assessmentsByMonth) => assessmentsByMonth.month)
        .value();

    return assessmentsGroupedByMonth;
};

export const getIntensityEmissionByMonthChartData = (
    intensityEmissionsByMonth: IntensityEmissionByMonth[],
    activeYear: number,
    t: TFunction
) => {
    return intensityEmissionsByMonth.map(
        ({
            emissionIntensity,
            month,
            totalArea,
            assetCount,
        }): MonthChartData => {
            const date = DateTime.local(activeYear, month, 1);

            return {
                value: getConvertedGramsToMassUnit(
                    emissionIntensity,
                    UnitMass.KILOGRAM
                ),
                label: date.monthShort,
                title: t(
                    "portfolioDashboard.hoverBox.portIntensity",
                    "Portfolio Intensity"
                ),
                header: date.monthLong,
                formattedValue: getFormattedConvertedGramsToMassUnit(
                    emissionIntensity,
                    UnitMass.KILOGRAM,
                    t,
                    true
                ),
                assetCount,
                totalArea,
            };
        }
    );
};
