import { Delete, Add, Edit } from "@mui/icons-material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { VerticalCoverage } from "glue/VerticalCoverage";
import { AssessmentVertical, ConsumptionType } from "graphql-types/graphql";
import {
    getPropertyObjectTypeTranslation,
    getPropertyOwnershipTranslation,
} from "utils/translations";

import { S } from "./asset-property-list.styles";
import { EventAction, PropertyFlatTree } from "./asset-property-list.types";
import { AssetSourceLabel } from "./AssetSourceLabel";
import { PropertySourcesCount } from "./PropertySourcesCount";

type Props = {
    item: PropertyFlatTree;
    onTriggerEvent: (action: EventAction, item: PropertyFlatTree) => void;
    areAllocationIconsShown: boolean;
};

export const AssetPropertyItem = (props: Props) => {
    const { item, onTriggerEvent, areAllocationIconsShown } = props;

    const {
        id,
        depth,
        name,
        objectType,
        ownershipType,
        allocationsCount,
        qualityCount,
    } = item;

    const { t } = useTranslation();

    const objectTypeLabel = getPropertyObjectTypeTranslation(objectType);

    const icon = depth > 0 ? <SubdirectoryArrowRightIcon color="info" /> : null;

    const paddingLeftValue = depth <= 1 ? 0 : `${depth * 20}px`;

    const hasAddress = item.longAddress && item.longAddress.length > 0;

    const addTooltipTitle =
        depth >= 5
            ? t(
                  "assetDetailsComponent.addPropertyTooltip",
                  "The property cannot be segmented further"
              )
            : null;

    const onEvent = (e: React.MouseEvent, action: EventAction) => {
        e.stopPropagation();
        onTriggerEvent(action, item);
    };

    return (
        <S.Item key={id} onClick={(e) => onEvent(e, "select")}>
            <S.Body sx={{ paddingLeft: paddingLeftValue }}>
                {icon}
                <S.Content>
                    <S.Title>
                        <Typography
                            sx={{ fontWeight: "bold" }}
                            component="span"
                        >
                            {name}
                        </Typography>
                        {`${getPropertyOwnershipTranslation(
                            ownershipType
                        )} • ${objectTypeLabel}`}
                    </S.Title>

                    {hasAddress && (
                        <Typography variant="body1">
                            {item.longAddress}
                        </Typography>
                    )}
                </S.Content>
            </S.Body>
            <S.Panel>
                {areAllocationIconsShown && (
                    <PropertySourcesCount qualityCount={qualityCount} />
                )}
                {areAllocationIconsShown && (
                    <VerticalCoverage>
                        <VerticalCoverage.Vertical
                            active={
                                allocationsCount[
                                    ConsumptionType.ENERGY_ELECTRICITY
                                ] > 0
                            }
                            type={AssessmentVertical.ELECTRICITY}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[
                                            ConsumptionType.ENERGY_ELECTRICITY
                                        ]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                        <VerticalCoverage.Vertical
                            active={
                                allocationsCount[
                                    ConsumptionType.ENERGY_HEATING
                                ] > 0
                            }
                            type={AssessmentVertical.HEATING}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[
                                            ConsumptionType.ENERGY_HEATING
                                        ]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                        <VerticalCoverage.Vertical
                            active={allocationsCount[ConsumptionType.WATER] > 0}
                            type={AssessmentVertical.WATER}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[ConsumptionType.WATER]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                    </VerticalCoverage>
                )}
                <S.CTA>
                    <Tooltip title={addTooltipTitle}>
                        <Box>
                            <IconButton
                                disabled={depth >= 5}
                                size="small"
                                color="primary"
                                onClick={(e) => onEvent(e, "add")}
                            >
                                <Add />
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => onEvent(e, "edit")}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        size="small"
                        color="primary"
                        onClick={(e) => onEvent(e, "remove")}
                    >
                        <Delete />
                    </IconButton>
                </S.CTA>
            </S.Panel>
        </S.Item>
    );
};
