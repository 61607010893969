import { PaletteColor, Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { AxisValueFormatterContext } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import { chain, flatMap, round } from "lodash";
import { DateTime } from "luxon";

import {
    getDistributionPalette,
    mapDataQualitySources,
    sumDataQualities,
} from "components/DataQuality/DataQuality.helpers";
import {
    DataQuality,
    DataQualityDistribution,
    DataQualityFragment,
} from "graphql-types/graphql";
import { percentageCalc } from "utils/maths";

import {
    DataQualityChartData,
    DataQualityGradient,
    DataQualitySeriesData,
} from "./dataQualityChart.types";

const getDataQualityGradient = (
    dataQuality: readonly DataQualityDistribution[],
    palette: PaletteColor,
    total: number,
    quality: "low" | "medium" | "high"
) => {
    // Spread to create a new array as dataQuality items are readonly
    return [...dataQuality]
        .sort((a, b) => a.priorityDistribution - b.priorityDistribution)
        .reduce((acc, item) => {
            acc[item.sources.join("_")] = {
                percentage: round(
                    percentageCalc(item.assessmentDays, total),
                    2
                ),
                color: getDistributionPalette(
                    item.priorityDistribution,
                    palette
                ),
                quality,
            };
            return acc;
        }, {} as DataQualityGradient);
};

const mapSourcesByPriority = (qualities: DataQualityDistribution[]): string[] =>
    chain(qualities)
        .sortBy("priorityDistribution")
        .map(({ sources }) => sources.join("_"))
        .uniq()
        .value();

const getPossibleSources = (
    organizationQuality: readonly DataQualityFragment[]
): string[] => {
    return [
        ...mapSourcesByPriority(flatMap(organizationQuality, "highQuality")),
        ...mapSourcesByPriority(flatMap(organizationQuality, "mediumQuality")),
        ...mapSourcesByPriority(flatMap(organizationQuality, "lowQuality")),
    ];
};

const getQualityGradients = ({
    dataQuality,
    theme,
}: {
    dataQuality: DataQuality;
    theme: Theme;
}) => {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;

    const { sumHighQuality, sumMediumQuality, sumLowQuality } =
        sumDataQualities(dataQuality);
    const total = sumHighQuality + sumMediumQuality + sumLowQuality;

    return {
        ...getDataQualityGradient(
            lowQuality,
            theme.palette.error,
            total,
            "low"
        ),
        ...getDataQualityGradient(
            mediumQuality,
            theme.palette.warning,
            total,
            "medium"
        ),
        ...getDataQualityGradient(
            highQuality,
            theme.palette.success,
            total,
            "high"
        ),
    };
};

export const getDataQualityChartData = ({
    dataQualityForOrganization,
    byMonth,
    theme,
    t,
}: {
    dataQualityForOrganization: readonly DataQuality[];
    byMonth?: boolean;
    theme: Theme;
    t: TFunction;
}): DataQualityChartData => {
    const xAxisData = dataQualityForOrganization.map(({ from }) =>
        byMonth
            ? DateTime.fromISO(from).monthLong
            : DateTime.fromISO(from).year.toString()
    );
    const sources = getPossibleSources(dataQualityForOrganization);
    const seriesData = sources.reduce((acc, key) => {
        acc[key] = {
            data: [],
            color: null,
            quality: null,
        };
        return acc;
    }, {} as DataQualitySeriesData);

    chain(dataQualityForOrganization)
        .sortBy("from")
        .forEach((dataQuality) => {
            const gradients = getQualityGradients({
                dataQuality,
                theme,
            });

            sources.forEach((source) => {
                if (gradients[source]) {
                    seriesData[source].color = gradients[source].color;
                    seriesData[source].quality = gradients[source].quality;
                    seriesData[source].data.push(gradients[source].percentage);
                } else {
                    seriesData[source].data.push(null);
                }
            });
        })
        .value();

    const seriesBars: BarSeriesType[] = Object.entries(seriesData).map(
        ([key, serie]) => ({
            type: "bar",
            data: serie.data,
            label: mapDataQualitySources(key, t),
            color: serie.color ?? undefined,
            stack: "dataQuality",
            stackOrder: "reverse",
            quality: serie.quality,
            valueFormatter: (value: number | null) =>
                value === null ? "" : `${value}%`,
        })
    );

    const dateFormatter = (
        date: string,
        context: AxisValueFormatterContext
    ) => {
        if (context.location === "tick" && byMonth) {
            return date.substring(0, 3);
        } else {
            return date;
        }
    };

    return {
        series: seriesBars,
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: dateFormatter,
            },
        ],
        yAxis: [
            {
                max: 100,
                label: t("portfolioDashboard.chartHelpers.percentage", "%"),
            },
        ],
    };
};
