import _ from "lodash";

export const percentageCalc = (value: number, target: number) => {
    const onepercent = target / 100;
    return value / onepercent;
};

export const isValidNumber = function (value: string | undefined) {
    if (!value) {
        return false;
    }

    const parsed = parseFloat(value);
    return _.isFinite(parsed);
};
export const convertToRem = (pixel: number | string) => {
    const pixelNumber = typeof pixel === "string" ? _.toNumber(pixel) : pixel;

    return `${_.round(pixelNumber / 16, 3)}rem`;
};

export default convertToRem;

export const getPercentage = (value: number, total: number) => {
    let percentage = _.round(percentageCalc(value, total), 2);

    const MIN_POINT_SIZE = 1;
    const isPercentageVisiblySmall =
        percentage > 0 && percentage < MIN_POINT_SIZE;
    if (isPercentageVisiblySmall) {
        percentage = MIN_POINT_SIZE;
    }

    return percentage;
};
