import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import { getOnboardingInputTranslatedPlaceholder } from "./utils/tableUtils";

type SelectInputProps = {
    selectorValues: { value: string; label: string }[];
    onChange: (key: string, value: string | string[]) => void;
    keys: string[];
};

export const IntegrationOnboardingSelect = ({
    selectorValues,
    onChange,
    keys,
}: SelectInputProps) => {
    const { t } = useTranslation();

    // Only the first key is used for the select input
    const key = keys[0];
    const lowerCaseKey = key.toLowerCase();
    const placeholder = getOnboardingInputTranslatedPlaceholder(
        t,
        lowerCaseKey
    );

    const handleChange = (_: SyntheticEvent, value: typeof selectorValues) => {
        const values = value.map(({ value }) => value);
        onChange(key, values);
    };

    return (
        <Autocomplete
            multiple
            options={selectorValues}
            getOptionLabel={(option) => option.label}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={placeholder} />
            )}
        />
    );
};
