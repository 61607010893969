import { useLocation, useMatch } from "react-router-dom";

import { TabLink } from "./components/TabLink";
import {
    TabSubtext,
    TabsBox,
    TabsContainer,
    tabIndicatorProps,
} from "./navTab.styles";
import { TabObject } from "./navTab.types";

type Props = {
    tabs: TabObject[];
    explainer?: string;
};

export const NavTabs = (props: Props) => {
    const { explainer, tabs } = props;

    const { pathname } = useLocation();
    const routeMatch = useMatch(pathname);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <TabsContainer>
            <TabsBox
                TabIndicatorProps={tabIndicatorProps}
                variant="fullWidth"
                value={currentTab}
                role="navigation"
            >
                {tabs.map((tab) => (
                    <TabLink key={tab.path} {...tab} value={tab.path} />
                ))}
            </TabsBox>
            {explainer && <TabSubtext>{explainer}</TabSubtext>}
        </TabsContainer>
    );
};
