import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";

export const DeleteModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: (isDeleted: boolean) => void;
}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} sx={{ "& .MuiDialog-paper": { maxWidth: 400 } }}>
            <DialogContent dividers>
                {t(
                    "labels.deleteEntry",
                    "Are you sure you want to delete this entry? This cannot be undone."
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={() => onClose(false)}>
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => onClose(true)}
                    color="error"
                >
                    {t("labels.delete", "Delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
