import { Box, Container, styled, Link } from "@mui/material";
import { TFunction } from "i18next";
import React from "react";
import { Trans } from "react-i18next";

import { MarketBenchmarkInfo } from "components/BenchmarkSelect/BenchmarkText";

const NoDataContainer = styled(Container)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    maxWidth: "800px",
}));

const HeaderText = styled(Box)(() => ({
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5rem",
}));

const SubText = styled(Box)(() => ({
    textAlign: "center",
    marginTop: "1rem",
}));

export const getBaselineBenchmarkGraphText = (
    hasBaselineBenchmark: boolean,
    hasBaselineBenchmarkForActiveDataType: boolean,
    t: TFunction
) => {
    if (!hasBaselineBenchmark) {
        return (
            <NoDataContainer>
                <HeaderText role="heading">
                    {t(
                        "portfolioDashboard.graphInfo.noBaselineYear.headline",
                        "Please select a baseline year"
                    )}
                </HeaderText>
                <SubText>
                    {t(
                        "portfolioDashboard.graphInfo.noBaselineYear.subtext",
                        "A base year is a reference point in the past with which current emissions can be compared. The base year will act as a baseline for comparing performance on intensities over time. Legacy has the ability to recalculate base year performance when better data coverage becomes available."
                    )}
                </SubText>
                <SubText>
                    <Trans i18nKey="portfolioDashboard.graphInfo.noBaselineYear.contact">
                        Please contact our Customer Success team
                        <Link href="mailto:support@wearelegacy.dk">
                            support@wearelegacy.dk
                        </Link>
                        to set up your organization’s baseline year in Legacy.
                    </Trans>
                </SubText>
            </NoDataContainer>
        );
    } else if (!hasBaselineBenchmarkForActiveDataType) {
        return (
            <NoDataContainer>
                <HeaderText role="heading">
                    {t(
                        "portfolioDashboard.graphInfo.noDataInBaselineYear.headline",
                        "Your portfolio doesn't have any data in your base year for the chosen data type"
                    )}
                </HeaderText>
                <SubText>
                    {t(
                        "portfolioDashboard.graphInfo.noDataInBaselineYear.subtext",
                        "A base year is a reference point in the past with which current emissions can be compared. The base year will act as a baseline for comparing performance on intensities over time. Legacy has the ability to recalculate base year performance when better data coverage becomes available."
                    )}
                </SubText>
                <SubText>
                    <Trans i18nKey="portfolioDashboard.graphInfo.noDataInBaselineYear.contact">
                        Please contact our Customer Success team
                        <Link href="mailto:support@wearelegacy.dk">
                            support@wearelegacy.dk
                        </Link>
                        to set up your organization’s baseline year in Legacy.
                    </Trans>
                </SubText>
            </NoDataContainer>
        );
    }
};

export const getMarketBenchmarkGraphText = (t: TFunction) => {
    return (
        <NoDataContainer>
            <HeaderText role="heading">
                {t(
                    "portfolioDashboard.graphInfo.noMarketBenchmark.headline",
                    "Please add a market benchmark"
                )}
            </HeaderText>
            <SubText>
                <Trans i18nKey="portfolioDashboard.graphInfo.noMarketBenchmark.subtext">
                    The market benchmark maps the performance of your assets on
                    a distribution curve relative to the national benchmark
                    value.
                </Trans>
                <MarketBenchmarkInfo />
            </SubText>
            <SubText>
                <Trans i18nKey="portfolioDashboard.graphInfo.noMarketBenchmark.contact">
                    Please contact our Customer Success team
                    <Link href="mailto:support@wearelegacy.dk">
                        support@wearelegacy.dk
                    </Link>
                    to set up a market benchmark for your organization in
                    Legacy.
                </Trans>
            </SubText>
        </NoDataContainer>
    );
};

export const getNoDataGraphText = (t: TFunction) => {
    return (
        <NoDataContainer data-testid="test-id-no-chart-data">
            <HeaderText
                data-testid="test-id-no-chart-data-header"
                role="heading"
            >
                {t(
                    "portfolioDashboard.graphInfo.noDataAvailable.headline",
                    "Your portfolio does not have any data available"
                )}
            </HeaderText>
            <SubText>
                <Trans i18nKey="portfolioDashboard.graphInfo.noDataAvailable.contact">
                    Please contact our Customer Success team
                    <Link href="mailto:support@wearelegacy.dk">
                        support@wearelegacy.dk
                    </Link>
                    if this seems to be an error.
                </Trans>
            </SubText>
        </NoDataContainer>
    );
};
