import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import { createUploadLink } from "apollo-upload-client";

import auth from "./auth";
import customFetch from "./customFetch";

// Logout when getting an UNAUTHENTICATED error from graphql
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ extensions }) => {
            if (
                extensions &&
                "code" in extensions &&
                extensions.code === "UNAUTHENTICATED"
            ) {
                auth.clearAppStorage();
                window.location.reload();
            }
        });
});

const apiUrl = process.env.REACT_APP_API_URL;

const uploadLink = createUploadLink({
    uri: `${apiUrl}/graphql`,
    fetch: customFetch as any,
    headers: { "Apollo-Require-Preflight": "true" },
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.getToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const cache = new InMemoryCache({
    typePolicies: {
        LocationIntegration: {
            fields: {
                data: { merge: true },
            },
        },
        Location: { merge: true },
        User: { merge: true },
        Organization: { merge: true },
        MissingMeterDataAlertData: { merge: true },
    },
});

const addAssetGroupMiddleware = new ApolloLink(
    (operation: Operation, forward) => {
        // Add assetGroupId to the query if it is a query and has assetGroupId as a variable
        const queryDefinition = operation.query.definitions[0];
        const isQuery =
            queryDefinition.kind === "OperationDefinition" &&
            queryDefinition.operation === "query";

        const hasAssetGroup =
            isQuery &&
            queryDefinition.variableDefinitions?.find(
                (variable) => variable.variable.name.value === "assetGroupId"
            );

        if (hasAssetGroup) {
            const assetGroupId = localStorage.getItem("assetGroupId");
            operation.variables["assetGroupId"] = JSON.parse(
                assetGroupId || "null"
            );
        }

        return forward(operation);
    }
);

const client = new ApolloClient({
    link: ApolloLink.from([
        new SentryLink({
            attachBreadcrumbs: {
                includeVariables: true,
                includeError: true,
            },
        }),
        addAssetGroupMiddleware,
        errorLink.concat(authLink.concat(uploadLink)),
    ]),
    uri: `${apiUrl}/graphql`,
    cache,
    name: "legacy-web",
});

export default client;
