import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import { getStatusColor } from "containers/AssetPage/AssetDataOverview/AutomaticSourcesTable/automatic-sources-table.helpers";
import { DASH_SEPARATOR } from "utils";

import { DataSourceStatus } from "./DataStatusCell";

type AutomaticDataSourceStatusCellProps = {
    status: DataSourceStatus;
    source?: string;
};

export const AutomaticDataSourceStatusCell = (
    props: AutomaticDataSourceStatusCellProps
) => {
    const { status, source } = props;
    const { palette } = useTheme();

    const { t } = useTranslation();

    const statusColor = getStatusColor(status);
    const sourceLabel = mapDataQualitySources(source ?? DASH_SEPARATOR, t);
    return (
        <Box
            sx={{
                borderLeft: 5,
                borderColor: palette[statusColor].main,
                paddingLeft: 5,
            }}
        >
            {sourceLabel}
        </Box>
    );
};
