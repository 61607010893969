import { Box, Typography } from "@mui/material";
import { GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Table, TableToolbarExport } from "components/Table";

import { AssessmentStats } from "../types";
interface InputProps {
    assessmentStats: AssessmentStats[];
    columns: GridColDef[];
}

const CustomToolbar = () => {
    const { t } = useTranslation();
    return (
        <Box display="flex" justifyContent="space-between">
            <Typography variant="h4">
                {t("assessmentOverview.dataTable", "Data table")}
            </Typography>
            <GridToolbarContainer>
                <TableToolbarExport
                    hasCustomStyle
                    csvOptions={{
                        allColumns: true,
                        fileName: "asset-overview",
                    }}
                />
            </GridToolbarContainer>
        </Box>
    );
};

function AssessmentTable({ assessmentStats, columns }: InputProps) {
    const rows = useMemo(() => {
        return assessmentStats
            .filter((stat) => stat.data)
            .flatMap((stat) => {
                const data = stat.data;

                if (!data) {
                    return {};
                }

                const downloadableFields = Object.keys(data).map((vertical) => {
                    const { color, consumptionLabels, sources, emission } =
                        data[vertical];

                    return {
                        [`${color}.emissionValue`]: emission,
                        [`${color}.emissionUnit`]: emission ? "kg CO2e" : "-",
                        [`${color}.consumptionValue`]: consumptionLabels,
                        [`${color}.consumptionUnit`]: consumptionLabels,
                        [`${color}.sources`]: sources,
                    };
                });

                return {
                    ...stat,
                    ...Object.assign({}, ...downloadableFields),
                };
            });
    }, [assessmentStats]);

    const hiddenColumns = useMemo(
        () =>
            columns
                .filter(
                    (column) =>
                        column.field.endsWith(".sources") ||
                        column.field.endsWith("Unit") ||
                        column.field.endsWith("Value")
                )
                .map((column) => column.field),
        [columns]
    );

    return (
        <Table
            setCustomHeight="fit-content"
            autoHeight
            columns={columns}
            rows={rows}
            hideFooter
            hasBorder
            isColumnHeaderVerticallyCentered
            isRowAlignedTop
            columnHeaderHeight={58}
            isAutoRowHeight
            slots={{ toolbar: CustomToolbar }}
            initialHiddenColumns={hiddenColumns}
        />
    );
}

export default AssessmentTable;
