import _ from "lodash";

import { castAndReplaceChars } from "components/AssetList/AssetListOverview";

import { DASH_SEPARATOR } from "./strings.helpers";

export const customNumberSorter = (a: string | number, b: string | number) => {
    if (a === b) return 0;
    if (a === DASH_SEPARATOR) return 1;
    if (b === DASH_SEPARATOR) return -1;

    a = castAndReplaceChars(a?.toString() || "");
    b = castAndReplaceChars(b?.toString() || "");

    if (isNaN(a) && isNaN(b)) return 0;
    if (isNaN(a)) return 1;
    if (isNaN(b)) return -1;

    return _.max([a, b]) === a ? 1 : -1;
};
