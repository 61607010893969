import { TFunction } from "i18next";
import { match } from "ts-pattern";

import { useFilterTranslations } from "components/AssetsGroup";
import {
    GetPropertyQuery,
    Property,
    PropertyType,
} from "graphql-types/graphql";
import { useBenchmarkTranslations } from "hooks";
import { formatDate } from "utils/date.utils";
import {
    getPropertyObjectTypeTranslation,
    getPropertyOwnershipTranslation,
} from "utils/translations";

type DetailField<T extends keyof Property> = {
    key: T;
    formatter?: (
        value: T extends keyof Property ? Property[T] : never
    ) => string;
};

const getDetailsFields = (translations: Record<string, string>) =>
    [
        {
            key: "objectType",
            formatter: getPropertyObjectTypeTranslation,
        },
        { key: "identifier" },
        { key: "buildingArea", formatter: (area: number) => `${area} m²` },
        {
            key: "generalPropertyType",
            formatter: (type: PropertyType) => translations[type],
        },
        {
            key: "crremPropertyCategory",
            formatter: (type: PropertyType) => translations[type],
        },
        {
            key: "nationalPropertyType",
            formatter: (type: PropertyType) =>
                translations[`BBR_PROPERTY_TYPE-${type}`],
        },
        {
            key: "ownershipType",
            formatter: getPropertyOwnershipTranslation,
        },
        { key: "purchaseDate", formatter: formatDate },
        { key: "saleDate", formatter: formatDate },
        {
            key: "constructionDate",
            formatter: (date: string) =>
                new Date(date).getFullYear().toString(),
        },
        {
            key: "parent",
            formatter: (parent: Pick<Property, "name">) => parent.name,
        },
        { key: "updatedAt", formatter: formatDate },
        { key: "createdAt", formatter: formatDate },
    ] as DetailField<keyof GetPropertyQuery["property"]>[];

export const getLabelTranslation = (label: keyof Property, t: TFunction) =>
    match(label)
        .with("objectType", () =>
            t("assetDetailsComponent.propertyType", "property type")
        )
        .with("identifier", () =>
            t("assetDetailsComponent.identifier", "identifier")
        )
        .with("buildingArea", () => t("assetDetailsComponent.area", "area"))
        .with("generalPropertyType", () =>
            t(
                "assetDetailsComponent.generalPropertyType",
                "general property type"
            )
        )
        .with("crremPropertyCategory", () =>
            t("assetDetailsComponent.crremPropertyType", "crrem property type")
        )
        .with("nationalPropertyType", () =>
            t(
                "assetDetailsComponent.nationalPropertyType",
                "national property type"
            )
        )
        .with("ownershipType", () =>
            t("assetDetailsComponent.ownershipType", "ownership type")
        )
        .with("purchaseDate", () =>
            t("assetDetailsComponent.purchaseDate", "purchase date")
        )
        .with("saleDate", () =>
            t("assetDetailsComponent.saleDate", "sale date")
        )
        .with("constructionDate", () =>
            t("assetDetailsComponent.constructionYear", "construction year")
        )
        .with("parent", () => t("assetDetailsComponent.parent", "parent"))
        .with("updatedAt", () => t("common.lastUpdated", "last updated"))
        .with("createdAt", () => t("common.createdOn", "created on"))
        .otherwise(() => label);

export const useFormatPropertyDetails = (
    property: GetPropertyQuery["property"] | undefined,
    t: TFunction
) => {
    const { translations: filterTranslations } = useFilterTranslations();
    const { translations: benchmarkTranslations } = useBenchmarkTranslations();
    const translations = { ...filterTranslations, ...benchmarkTranslations };

    const fields = getDetailsFields(translations);

    return fields.map((field) => {
        const { key, formatter } = field;

        const label = getLabelTranslation(key, t);

        const value = property?.[key];

        return {
            label,
            data: formatter && value ? formatter(value) : value,
        };
    });
};
