import { gql } from "@apollo/client";

export const CITY_QUERY = gql`
    query GetAssetsGroupCities {
        me {
            id
            organization {
                buildingCities {
                    city
                }
            }
        }
    }
`;
