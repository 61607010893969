import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";

import {
    AssetGroupOption,
    Asset,
    FilterItemOptions,
    FilterRangeValue,
    FilterValue,
} from "components/AssetsGroup/types";
import {
    AssetGroupFilterCategory,
    AssetGroupFilterInput,
    CreateAssetGroupMutation,
    CreateAssetGroupMutationVariables,
    DeleteAssetGroupMutation,
    DeleteAssetGroupMutationVariables,
    GetAssetGroupQuery,
    GetAssetGroupQueryVariables,
    GetAssetGroupsPreviewQuery,
    GetAssetGroupsPreviewQueryVariables,
    GetAssetGroupsQuery,
    RelationInput,
    UpdateAssetGroupMutation,
    UpdateAssetGroupMutationVariables,
} from "graphql-types/graphql";
import { DASH_SEPARATOR, parseJSON } from "utils/strings.helpers";

import {
    CREATE_ASSET_GROUP,
    DELETE_ASSET_GROUP,
    GET_ASSET_GROUP,
    GET_ASSET_GROUPS,
    GET_ASSET_GROUPS_PREVIEW,
    UPDATE_ASSET_GROUP,
} from "./queries";

type AssetGroupContextType = {
    assetGroups: AssetGroupOption[];
    createAssetGroup: (
        name: string,
        filters: AssetGroupFilterInput[],
        assets: RelationInput[]
    ) => Promise<string | undefined>;
    deleteAssetGroup: (id: string) => Promise<void>;
    updateAssetGroup: (
        id: string | null,
        name: string,
        filters: AssetGroupFilterInput[],
        assets: RelationInput[]
    ) => Promise<void>;
    preview: {
        get: (input: AssetGroupFilterInput[]) => Promise<Asset[]>;
        isLoading: boolean;
    };
    assetGroup: {
        get: (id: string) => Promise<{
            id: string;
            name: string;
            filters: FilterItemOptions;
            assets: Asset[];
        } | null>;
        isLoading: boolean;
    };
};

const AssetGroupContext = createContext<AssetGroupContextType>(
    {} as AssetGroupContextType
);

export const useAssetGroups = (): AssetGroupContextType => {
    const context = useContext(AssetGroupContext);

    if (context === undefined) {
        throw new Error(
            "useAssetGroups must be used within an AssetGroupProvider"
        );
    }

    return context;
};

export const AssetGroupProvider = (props: PropsWithChildren) => {
    const { data } = useQuery<GetAssetGroupsQuery>(GET_ASSET_GROUPS);

    const [createAssetGroup] = useMutation<
        CreateAssetGroupMutation,
        CreateAssetGroupMutationVariables
    >(CREATE_ASSET_GROUP, { refetchQueries: [{ query: GET_ASSET_GROUPS }] });

    const [deleteAssetGroup] = useMutation<
        DeleteAssetGroupMutation,
        DeleteAssetGroupMutationVariables
    >(DELETE_ASSET_GROUP, { refetchQueries: [{ query: GET_ASSET_GROUPS }] });

    const [updateAssetGroup] = useMutation<
        UpdateAssetGroupMutation,
        UpdateAssetGroupMutationVariables
    >(UPDATE_ASSET_GROUP, {
        refetchQueries: [{ query: GET_ASSET_GROUPS }],
        update(cache, { data }) {
            const id = data?.updateOneAssetGroup.id || null;
            if (!id) {
                return;
            }
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    const [getAssetGroupPreview, { loading: previewLoading }] = useLazyQuery<
        GetAssetGroupsPreviewQuery,
        GetAssetGroupsPreviewQueryVariables
    >(GET_ASSET_GROUPS_PREVIEW);
    const [getAssetGroup, { loading: assetGroupLoading }] = useLazyQuery<
        GetAssetGroupQuery,
        GetAssetGroupQueryVariables
    >(GET_ASSET_GROUP);

    const assetGroups = useMemo(() => {
        const assetGroups = data?.assetGroups.edges || [];

        return assetGroups.map(({ node }) => {
            const { id, name, assetsAggregate } = node;

            return {
                id,
                name,
                count: assetsAggregate[0]?.count?.id || 0,
            };
        });
    }, [data]);

    const handleDeleteAssetGroup = async (id: string) => {
        await deleteAssetGroup({ variables: { id } });
    };

    const handleCreateAssetGroup = async (
        name: string,
        filters: AssetGroupFilterInput[],
        assets: RelationInput[]
    ) => {
        const variables = {
            input: {
                assetGroup: {
                    name,
                    filters,
                    assets: assets.map(({ id }) => ({ id })),
                },
            },
        };

        const newAssetGroup = await createAssetGroup({ variables });

        return newAssetGroup.data?.createOneAssetGroup.id;
    };

    const handleUpdateAssetGroup = async (
        id: string | null,
        name: string,
        filters: AssetGroupFilterInput[],
        assets: RelationInput[]
    ) => {
        if (!id) {
            return;
        }

        const variables = {
            input: {
                id,
                update: {
                    name,
                    filters,
                    assets: assets.map(({ id }) => ({ id })),
                },
            },
        };

        await updateAssetGroup({ variables });
    };

    const handleGetAssetGroupPreview = async (
        input: AssetGroupFilterInput[]
    ) => {
        const { data } = await getAssetGroupPreview({ variables: { input } });

        return (
            data?.getAssetGroupPreview.map((preview) => {
                const { id, displayName, externalId } = preview;

                return {
                    id,
                    name: displayName || id,
                    externalId: externalId || DASH_SEPARATOR,
                };
            }) ?? []
        );
    };

    const handleGetAssetGroup = async (id: string) => {
        const { data } = await getAssetGroup({ variables: { id } });

        if (!data) {
            return null;
        }

        const assets = data.assetGroup.assets.map((asset) => {
            const { id, displayName, externalId } = asset;

            return {
                id,
                name: displayName || id,
                externalId: externalId || "",
            };
        });

        const filters = data.assetGroup.filters.map((filter) => {
            const { category, values } = filter;
            const isRange =
                category === AssetGroupFilterCategory.CONSTRUCTIONYEAR;
            const isIDFilter = category === AssetGroupFilterCategory.ID;

            const parsedValues = values.map((value) => {
                if (!isRange && !isIDFilter) {
                    return { key: value, value };
                }

                if (isRange) {
                    return parseJSON<FilterRangeValue>(value);
                }

                const asset = assets.find((asset) => asset.id === value);

                if (!asset) {
                    return { key: value, value };
                }

                const displayName = asset.name;
                const externalId = asset.externalId
                    ? `| ${asset.externalId}`
                    : "";
                const label = `${displayName} ${externalId}`;

                return { key: value, value: label };
            });

            return {
                category,
                values: parsedValues as FilterValue[] | FilterRangeValue[],
            };
        });

        return {
            id: data.assetGroup.id,
            name: data.assetGroup.name,
            filters,
            assets,
        };
    };

    const value = {
        assetGroups,
        deleteAssetGroup: handleDeleteAssetGroup,
        createAssetGroup: handleCreateAssetGroup,
        updateAssetGroup: handleUpdateAssetGroup,
        preview: {
            get: handleGetAssetGroupPreview,
            isLoading: previewLoading,
        },
        assetGroup: {
            get: handleGetAssetGroup,
            isLoading: assetGroupLoading,
        },
    };

    return (
        <AssetGroupContext.Provider value={value}>
            {props.children}
        </AssetGroupContext.Provider>
    );
};
