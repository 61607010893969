import { ChartsAxisContentProps } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import {
    ChartTooltip,
    formatValue,
    getBenchmarkSection,
    getProjectedSection,
} from "glue/Chart/components/ChartTooltip";

export const AssessmentChartTooltip = (props: ChartsAxisContentProps) => {
    const { axisValue, dataIndex, series } = props;

    const { t } = useTranslation();

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const header = axisValue?.toString() ?? "";

    const verticalSeries = series.filter(
        (serie: any) =>
            serie.data[dataIndex] &&
            serie.layout === "vertical" &&
            serie.id !== "projected"
    );

    const sectionItems = verticalSeries.map((serie: any) => ({
        label: serie.label,
        value: formatValue(serie.data[dataIndex], t),
        textColor: serie.color,
    }));

    const totalEmission = verticalSeries
        .map((serie: any) => serie.data[dataIndex])
        .reduce((acc: number, curr: number) => acc + curr, 0);

    const totalEmissionSubheader = {
        label: t("forecastDashboard.labels.totalEmissions", "Total Emissions"),
        value: formatValue(totalEmission, t),
    };

    const projectedSubHeader = getProjectedSection(series, dataIndex);
    const benchmarkSection = getBenchmarkSection(series, dataIndex, t);

    const subHeaders = [
        ...(totalEmission ? [totalEmissionSubheader] : []),
        ...(projectedSubHeader ? [projectedSubHeader] : []),
    ];

    return (
        <ChartTooltip
            sections={[
                {
                    header,
                    subHeaders,
                    items: sectionItems,
                },
                ...(benchmarkSection ? [benchmarkSection] : []),
            ]}
        />
    );
};
