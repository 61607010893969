import {
    CheckCircleOutlineRounded,
    ErrorOutlineRounded,
} from "@mui/icons-material";
import { Box, Button, styled, Typography, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { convertToRem } from "utils/maths";

const SuccessLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    margin: theme.spacing(-1.5, 4, 0, 0),
    fontSize: convertToRem(40),
}));

const FailLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    margin: theme.spacing(-1.5, 4, 0, 0),
    fontSize: convertToRem(40),
}));

const IntegrationCreationResultHeaderContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "6vh",
});

const IntegrationCreationResultBodyContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: theme.spacing(5),
}));

const RetryButton = styled(Button)(({ theme }) => ({
    fontSize: convertToRem(18.5),
    margin: theme.spacing(0, 2, 1.5, 2),
}));

interface IntegrationCreationResultProps {
    isSuccess: boolean;
    onRetry: () => void;
}

const IntegrationCreationResult: FC<IntegrationCreationResultProps> = ({
    isSuccess,
    onRetry,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <>
            <IntegrationCreationResultHeaderContainer>
                {isSuccess ? (
                    <>
                        <CheckCircleOutlineRounded
                            style={{
                                height: "1.75em",
                                color: theme.palette.success.main,
                                width: "2em",
                            }}
                        />
                        <SuccessLabel>
                            {t("integrationOnboarding.successLabel", "Success")}
                        </SuccessLabel>
                    </>
                ) : (
                    <>
                        <ErrorOutlineRounded
                            style={{
                                height: "1.75em",
                                color: theme.palette.error.main,
                                width: "2em",
                            }}
                        />
                        <FailLabel>
                            {t(
                                "integrationOnboarding.failedLabel",
                                "Failed to onboard, please contact support"
                            )}
                        </FailLabel>
                    </>
                )}
            </IntegrationCreationResultHeaderContainer>

            <IntegrationCreationResultBodyContainer>
                <p style={{ maxWidth: "75%" }}>
                    {isSuccess ? (
                        <>
                            {t(
                                "integrationOnboarding.locationIntegrationSuccessBody",
                                "Data will be available from tomorrow"
                            )}
                        </>
                    ) : (
                        <RetryButton
                            variant="contained"
                            color="primary"
                            onClick={() => onRetry()}
                        >
                            {t("integrationOnboarding.retry", "Retry")}
                        </RetryButton>
                    )}
                </p>
            </IntegrationCreationResultBodyContainer>
        </>
    );
};

export default IntegrationCreationResult;
