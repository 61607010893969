export const DASH_SEPARATOR = "-";
export const NO_BUILDING_FOUND = "No building found";
export const NO_NAME_OR_ADDRESS_FOR_LOCATION =
    "No name or address for available location";

export function parseJSON<T>(value: string | null): T | undefined {
    try {
        return value === "undefined" ? undefined : JSON.parse(value ?? "");
    } catch {
        return undefined;
    }
}

export function hexToRgba(hex: string | undefined, opacity: number): string {
    if (!hex) {
        return "";
    }

    if (hex.length === 4) {
        hex = hex.replace(/([^#])/g, "$1$1");
    }

    const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) ?? [
        0, 0, 0,
    ];

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        return "";
    }

    return `rgba(${r},${g},${b},${opacity})`;
}
