import { Popover as MUIPopover, Theme, Box } from "@mui/material";
import { PropsWithChildren, isValidElement, useState } from "react";

import { S } from "./popover.styles";
import { PopoverContent } from "./PopoverContent";


export const Popover = (props: PropsWithChildren) => {
    const { children } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    const onPopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const listChildren = Array.isArray(children) ? children : [children];

    const popoverContent = listChildren.find((child) => {
        return isValidElement(child) && child.type === PopoverContent;
    });

    const popoverChildren = listChildren.find((child) => {
        return isValidElement(child) && child.type !== PopoverContent;
    });

    return (
        <Box onMouseEnter={onPopoverOpen} onMouseLeave={onPopoverClose}>
            {popoverChildren}
            <MUIPopover
                sx={{ pointerEvents: "none", maxWidth: "none" }}
                anchorOrigin={
                    popoverContent.props.anchorOrigin ?? {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }
                transformOrigin={
                    popoverContent.props.transformOrigin ?? {
                        vertical: "top",
                        horizontal: "left",
                    }
                }
                slotProps={{
                    paper: {
                        sx: (theme: Theme) => ({
                            maxWidth: theme.spacing(50),
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: theme.spacing(1),
                            boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
                            padding: theme.spacing(2, 3),
                        }),
                    },
                }}
                open={Boolean(anchorEl)}
                disableRestoreFocus
                disableScrollLock
                anchorEl={anchorEl}
                children={
                    <S.PopoverContent>
                        {popoverContent.props.children}
                    </S.PopoverContent>
                }
            />
        </Box>
    );
};

Popover.Content = PopoverContent;
