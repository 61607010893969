import { useMutation, useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    DELETE_LOCATION_INTEGRATION_BY_IDS,
    UPDATE_LOCATION_INTEGRATION,
} from "containers/IntegrationOnboarding/graphql/locationIntegrationOnboardingMutation";
import {
    DeleteLocationIntegrationByIdsMutation,
    DeleteLocationIntegrationByIdsMutationVariables,
    GetLocationIntegrationsQuery,
    GetLocationIntegrationsQueryVariables,
    IntegrationElectralinkLocationIntegrationData,
    IntegrationType,
    SupportedIntegrationForOnboarding,
    UpdateLocationIntegrationMutation,
    UpdateLocationIntegrationMutationVariables,
} from "graphql-types/graphql";

import { LocationIntegrationMappingTable } from "./LocationIntegrationMappingTable";
import { ConfirmationModal } from "../ConfirmationModal";
import { EditMappingState } from "../IntegrationOnboardingTypes";
import { GET_LOCATION_INTEGRATION } from "../integrationQueries";

type Props = {
    integrationType: SupportedIntegrationForOnboarding;
};

export const LocationIntegrationMapping = (props: Props) => {
    const { integrationType } = props;

    const { t } = useTranslation();

    const { data, loading } = useQuery<
        GetLocationIntegrationsQuery,
        GetLocationIntegrationsQueryVariables
    >(GET_LOCATION_INTEGRATION, {
        variables: {
            type: integrationType.toUpperCase() as IntegrationType,
        },
    });

    const [selectedLocations, setSelectedLocations] =
        useState<EditMappingState>({});
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const [updateLocationIntegrations] = useMutation<
        UpdateLocationIntegrationMutation,
        UpdateLocationIntegrationMutationVariables
    >(UPDATE_LOCATION_INTEGRATION);

    const [deleteLocationIntegrations] = useMutation<
        DeleteLocationIntegrationByIdsMutation,
        DeleteLocationIntegrationByIdsMutationVariables
    >(DELETE_LOCATION_INTEGRATION_BY_IDS, {
        refetchQueries: [GET_LOCATION_INTEGRATION],
    });

    const locations = useMemo(() => {
        if (!data) return [];

        const allLocations = data.me?.organization?.getLocations || [];

        return allLocations.map(({ id, displayName, integrations }) => ({
            id,
            displayName,
            integrations: integrations.map((integration) => {
                const { address, partialMpan, postCode } =
                    integration.data as IntegrationElectralinkLocationIntegrationData;

                return {
                    id: integration.id,
                    address,
                    postCode,
                    partialMpan,
                    label: `${address} - ${partialMpan}`,
                    value: integration.id,
                };
            }),
        }));
    }, [data]);

    const handleUpdate = () => {
        const updates = Object.values(selectedLocations).map(
            ({ integrations, period }) => ({
                startedAt: period.from?.toISO(),
                endedAt: period.to?.toISO(),
                ids: integrations.map((i: any) => i.id),
            })
        );

        if (!updates.length) return;

        toast.promise(
            Promise.all(
                updates.map((update) =>
                    updateLocationIntegrations({
                        variables: {
                            startedAt: update.startedAt,
                            endedAt: update.endedAt,
                            ids: update.ids,
                        },
                    })
                )
            ),
            {
                pending: t("labels.updating", "Updating..."),
                success: t("labels.updated", "Updated"),
                error: t("labels.error", "Error"),
            }
        );
    };

    const handleDelete = () => {
        const deletionIds = Object.values(selectedLocations).flatMap(
            ({ integrations }) => integrations.map((i: any) => i.id)
        );

        toast.promise(
            deleteLocationIntegrations({
                variables: {
                    ids: deletionIds,
                },
            }),
            {
                pending: t("labels.deleting", "Deleting..."),
                success: t("labels.deleted", "Deleted"),
                error: t("labels.error", "Error"),
            }
        );
    };

    const disabled =
        loading ||
        !locations.length ||
        Object.keys(selectedLocations).every(
            (id) => selectedLocations[id].integrations.length === 0
        );

    return (
        <Box>
            <LocationIntegrationMappingTable
                selectAllOnInitialize
                isLoading={loading}
                locations={locations}
                integrationType={integrationType}
                setLocationIntegrations={setSelectedLocations}
            />

            <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                    disabled={disabled}
                    variant="contained"
                    onClick={() => setIsUpdateModalOpen(true)}
                >
                    {t("labels.update", "Update")}
                </Button>
                <Button
                    disabled={disabled}
                    sx={{ ml: 2 }}
                    color="error"
                    variant="contained"
                    onClick={() => setIsDeleteModalOpen(true)}
                >
                    {t("labels.delete", "Delete")}
                </Button>

                <ConfirmationModal
                    open={isUpdateModalOpen}
                    onClose={() => setIsUpdateModalOpen(false)}
                    onConfirm={handleUpdate}
                    operation="update"
                    selectedLocations={selectedLocations}
                />
                <ConfirmationModal
                    open={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={handleDelete}
                    operation="delete"
                    selectedLocations={selectedLocations}
                />
            </Box>
        </Box>
    );
};
