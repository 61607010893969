import { ArrowBackRounded } from "@mui/icons-material";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SupportedIntegrationForOnboarding } from "graphql-types/graphql";

import { AddElectralinkLocationIntegration } from "./AddElectralinkLocationIntegration";
import { LocationIntegrationMapping } from "./LocationIntegrationMapping/LocationIntegrationMapping";

export const isIntegrationTypeValid = (
    integrationType: string | undefined
): integrationType is SupportedIntegrationForOnboarding => {
    const currentIntegrationType =
        integrationType?.toUpperCase() as SupportedIntegrationForOnboarding;

    const supportedIntegrations = Object.values(
        SupportedIntegrationForOnboarding
    );

    return supportedIntegrations.includes(currentIntegrationType);
};

const IntegrationHeader = ({
    title,
    isEdit,
    onEditChange,
    setActiveTab,
}: {
    title: SupportedIntegrationForOnboarding;
    isEdit: boolean;
    onEditChange: (isEdit: boolean) => void;
    setActiveTab: (tab: SupportedIntegrationForOnboarding | null) => void;
}) => {
    const { t } = useTranslation();

    const sectionTitle = `${t(
        "integrationOnboarding.assetIntegrationMapping",
        "Asset Integration Mapping"
    )} | ${
        isEdit
            ? t(
                  "integrationOnboarding.editMeterLocationsTitle",
                  "Edit or remove meters from assets"
              )
            : t(
                  "integrationOnboarding.addMeterLocationsTitle",
                  "Add additional meters to your assets"
              )
    }`;

    return (
        <Box mb={8} sx={{ textAlign: "center" }}>
            <IconButton
                aria-label="back"
                onClick={() => setActiveTab(null)}
                sx={{ position: "absolute", left: 0 }}
            >
                <ArrowBackRounded />
            </IconButton>
            <Typography
                variant="h2"
                sx={{ fontWeight: "bold", textAlign: "center" }}
            >
                {title.toLocaleUpperCase()}
            </Typography>

            <Button
                size="large"
                variant="contained"
                color="info"
                onClick={() => onEditChange(!isEdit)}
                sx={{ position: "absolute", top: 0, right: 0 }}
            >
                {isEdit
                    ? t(
                          "integrationOnboarding.addMeterLocations",
                          "Add Meter Locations"
                      )
                    : t(
                          "integrationOnboarding.editMeterLocations",
                          "Edit Meter Locations"
                      )}
            </Button>

            <Typography variant="h4" sx={{ fontWeight: "bold", mt: 2 }}>
                {sectionTitle}
            </Typography>

            <Typography color="text.secondary" sx={{ mt: 2 }}>
                {t(
                    "integrationOnboarding.assetIntegrationMappingDescription",
                    "Map your assets to your meters to enable data collection and analysis."
                )}
            </Typography>
            <Typography color="text.secondary">
                {!isEdit &&
                    title === SupportedIntegrationForOnboarding.ELECTRALINK &&
                    t(
                        "integrationOnboarding.assetIntegrationMappingDescription2",
                        "In the dropdown, (*) suggests a possible duplicate Electralink meter."
                    )}
            </Typography>
        </Box>
    );
};

const LocationIntegrationPage = ({
    integrationType,
    setActiveTab,
}: {
    integrationType?: SupportedIntegrationForOnboarding | null;
    setActiveTab: (tab: SupportedIntegrationForOnboarding | null) => void;
}) => {
    const upperCaseIntegrationType = integrationType?.toUpperCase();

    const [isEdit, setIsEdit] = useState(true);

    if (!isIntegrationTypeValid(upperCaseIntegrationType)) {
        setActiveTab(null);
        return null;
    }

    return (
        <Box sx={{ position: "relative" }}>
            <IntegrationHeader
                isEdit={isEdit}
                onEditChange={setIsEdit}
                title={upperCaseIntegrationType}
                setActiveTab={setActiveTab}
            />

            {isEdit ? (
                <LocationIntegrationMapping
                    integrationType={upperCaseIntegrationType}
                />
            ) : (
                <AddElectralinkLocationIntegration />
            )}
        </Box>
    );
};

export default LocationIntegrationPage;
