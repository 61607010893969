import { LDClient } from "launchdarkly-js-client-sdk";
import { LDMultiKindContext } from "launchdarkly-js-sdk-common";

export function updateFlagsWithContext(
    ldClient: LDClient | undefined,
    organizationId: string,
    organizationName: string,
    userId: string,
    userName: string
) {
    if (ldClient && organizationId && organizationName) {
        const context: LDMultiKindContext = {
            kind: "multi",
            organization: {
                key: organizationId,
                name: organizationName,
            },
            user: {
                key: userId,
                name: userName,
            },
        };

        ldClient.identify(context);
    }
}
