import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { S } from "./asset-group-dialog.styles";

interface Props {
    isOpen: boolean;
    groupName: string;
    handleClose: (shouldDelete: boolean) => void;
}

export const AssetGroupDialog = (props: Props) => {
    const { isOpen, groupName, handleClose } = props;

    const { t } = useTranslation();

    return (
        <S.Dialog open={isOpen} onClose={() => handleClose(false)}>
            <DialogTitle variant="h3">
                {t("assetGroup.dialog.delete", "Delete Asset Group")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t(
                        "assetGroup.dialog.content",
                        "Would you like to delete asset group '{{ name }}'?",
                        { name: groupName }
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>
                    {t("labels.cancel", "Cancel")}
                </Button>
                <Button onClick={() => handleClose(true)}>
                    {t("labels.delete", "Delete")}
                </Button>
            </DialogActions>
        </S.Dialog>
    );
};
