import { Box, Card, styled, Typography } from "@mui/material";
import React from "react";

import { Label } from "../../styling/typography";

const Number = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(6),
    fontWeight: "bold",
}));

interface Props {
    title: string;
    number: number | string;
}

function PortfolioTitleNumberBox(props: Props) {
    const { title, number } = props;

    return (
        <Card>
            <Box
                display="flex"
                alignItems="center"
                gap={2}
                flexDirection={"column"}
                padding={6}
            >
                <Label>{title}</Label>
                <Number>{number}</Number>
            </Box>
        </Card>
    );
}

export default PortfolioTitleNumberBox;
