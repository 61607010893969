import { Delete } from "@mui/icons-material";
import {
    Grid,
    Stack,
    Typography,
    TextField,
    IconButton,
    FormLabel,
} from "@mui/material";
import { t } from "i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DateTime } from "luxon";
import {
    Control,
    UseFieldArrayRemove,
    FieldErrors,
    Controller,
    FieldArrayWithId,
} from "react-hook-form";

import BasicDatePicker from "components/DatePicker/DatePicker";
import { EmissionUnit } from "graphql-types/graphql";

import { FileUploadField } from "./Fields/FileUploadField";
import { SelectField } from "./Fields/SelectField";
import { ManualEntryInputs, Period } from "../manualConsumptionModal.types";

type PeriodProps = {
    control: Control<ManualEntryInputs, any>;
    units: { label: string; value: EmissionUnit }[];
    remove: UseFieldArrayRemove;
    isEditMode: boolean;
};

type PeriodEntriesProp = PeriodProps & {
    errors: FieldErrors<ManualEntryInputs>;
    fields: FieldArrayWithId<ManualEntryInputs, "periods", "id">[];
};

export const PeriodEntries = (props: PeriodEntriesProp) => {
    const { fields, errors, ...rest } = props;
    const { useManualSources } = useFlags();

    return (
        <Grid item container columnSpacing={5} rowSpacing={2} sx={{ mb: 0 }}>
            <Grid item xs={6}>
                <FormLabel>{t("common.labels.period", "Period")}</FormLabel>
            </Grid>
            <Grid item xs>
                <FormLabel>
                    {t("common.labels.consumption", "Consumption")}
                </FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>{t("common.labels.unit", "Units")}</FormLabel>
            </Grid>
            {useManualSources && (
                <Grid item xs={1}>
                    <FormLabel>{t("common.labels.source", "Source")}</FormLabel>
                </Grid>
            )}
            {fields.map((field, index) => (
                <PeriodEntry
                    key={field.id}
                    periods={fields.length}
                    index={index}
                    {...rest}
                    errors={
                        errors && errors.periods
                            ? errors.periods[index]
                            : undefined
                    }
                />
            ))}
        </Grid>
    );
};

type PeriodEntryProps = PeriodProps & {
    index: number;
    periods: number;
    errors?: FieldErrors<Period>;
};

export const PeriodEntry = ({
    control,
    units,
    index,
    periods,
    remove,
    errors,
    isEditMode,
}: PeriodEntryProps) => {
    const { useManualSources } = useFlags();

    const handleRemove = () => {
        remove(index);
    };

    const EditButton = () => (
        <>
            {!isEditMode && (
                <IconButton
                    size="small"
                    color="primary"
                    disabled={periods <= 1}
                    onClick={handleRemove}
                >
                    <Delete />
                </IconButton>
            )}
        </>
    );

    return (
        <>
            <Grid item xs={6}>
                <Stack direction="row" spacing={2}>
                    <Controller
                        name={`periods.${index}.from`}
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <BasicDatePicker
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                        error: !!errors?.from,
                                    },
                                }}
                                value={value ? DateTime.fromISO(value) : null}
                                onChange={(date) =>
                                    onChange(date?.toISODate() ?? "")
                                }
                            />
                        )}
                    />
                    <Typography sx={{ alignSelf: "center" }}>-</Typography>
                    <Controller
                        name={`periods.${index}.to`}
                        defaultValue=""
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <BasicDatePicker
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                        error: !!errors?.to,
                                    },
                                }}
                                value={value ? DateTime.fromISO(value) : null}
                                onChange={(date) =>
                                    onChange(date?.toISODate() ?? "")
                                }
                            />
                        )}
                    />
                </Stack>
            </Grid>
            <Grid item xs={useManualSources ? true : 4}>
                <Controller
                    name={`periods.${index}.consumption`}
                    control={control}
                    rules={{ required: true, min: 1 }}
                    render={({ field }) => (
                        <TextField
                            placeholder="Ex: 1234"
                            size="small"
                            type="number"
                            fullWidth
                            error={!!errors?.consumption}
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                                field.onChange(parseFloat(e.target.value));
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={useManualSources ? 2 : true}>
                <Controller
                    name={`periods.${index}.emissionUnit`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            disabled={!units.length}
                            selected={field.value}
                            options={units}
                            placeholder="..."
                            onChange={(value) => field.onChange(value)}
                            hasError={!!errors?.emissionUnit}
                        />
                    )}
                />
            </Grid>

            <Grid
                item
                xs={useManualSources ? 1 : "auto"}
                sx={{ display: "flex" }}
            >
                {useManualSources && (
                    <Controller
                        control={control}
                        name={`periods.${index}.file`}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <FileUploadField
                                file={field.value}
                                onFileSelect={(file) => field.onChange(file)}
                            />
                        )}
                    />
                )}

                <EditButton />
            </Grid>
        </>
    );
};
