import { Clear } from "@mui/icons-material";
import { Drawer, Box, styled, Typography } from "@mui/material";

export const S = {
    Drawer: styled(Drawer)(() => ({
        transition: "ease-out 0.2s all",
        position: "relative",
    })),

    Header: styled(Box)(({ theme }) => ({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        backgroundColor: theme.palette.grey[100],
        padding: "2rem",
    })),

    WaterMark: styled(Typography)(({ theme }) => ({
        color: theme.palette.grey[500],
        fontWeight: "bold",
        textTransform: "capitalize",
    })),

    Title: styled(Box)(({ theme }) => ({
        display: "flex",
        fontWeight: "bold",
        marginTop: "10px",
        color: theme.palette.primary.main,
        fontSize: theme.typography.h4.fontSize,
    })),

    CloseBox: styled(Box)(() => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "auto",
        position: "absolute",
        top: 26,
        right: 12,
        zIndex: 2,
    })),

    CloseIcon: styled(Clear)(({ theme }) => ({
        transition: "ease-out 0.1s all",
        display: "block",
        "&:hover": {
            cursor: "pointer",
            fontSize: theme.spacing(8),
        },
    })),
};
