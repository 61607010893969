import { Theme } from "@mui/material";
import {
    GridToolbarExportProps,
    GridToolbarExport as MUIGridToolbarExport,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const gridToolbarButtonStyle = (theme: Theme) => ({
    "&.MuiButton-root": {
        padding: theme.spacing(1.5, 3),
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        borderRadius: "8px",
        border: "2px solid currentColor",
    },
});

export const toolbarSlotProps = {
    button: { sx: gridToolbarButtonStyle },
};

export const TableToolbarExport = (
    props: GridToolbarExportProps & { hasCustomStyle?: boolean }
) => {
    const { t } = useTranslation();
    const { csvOptions, hasCustomStyle, ...rest } = props;

    const csvOptionsWithDelimiter = useMemo(
        () => ({
            delimiter: t("common.csvDelimiter", ","),
            ...csvOptions,
        }),
        [t, csvOptions]
    );

    return (
        <MUIGridToolbarExport
            {...rest}
            slotProps={hasCustomStyle ? toolbarSlotProps : undefined}
            csvOptions={csvOptionsWithDelimiter}
            printOptions={{ disableToolbarButton: true }}
        />
    );
};
