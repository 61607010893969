import { t } from "i18next";
import { compact } from "lodash";
import { match } from "ts-pattern";

import { flatPropertyTree } from "containers/AssetPage/AssetDetails/components";
import {
    AllocationObjectType,
    AssetIntegrationAllocationItemFragment,
    AssetPropertyTree,
} from "graphql-types/graphql";

import {
    AllocationError,
    AllocationItem,
    PropertyAllocationItem,
} from "./property-allocation.types";

export const isTotalOfAllocationsMax100 = (allocation: AllocationItem[]) => {
    const totalAllocation = allocation
        .filter((item) => item.isChecked)
        .reduce((sum, item) => sum + item.value, 0);

    return totalAllocation <= 100;
};

export const areAllocationsAboveZero = (allocation: AllocationItem[]) => {
    return allocation.every((item) => !item.isChecked || item.value > 0);
};

export const getAllocationErrors = (allocation: AllocationItem[]) => {
    const errors = [
        areAllocationsAboveZero(allocation)
            ? null
            : AllocationError.AllocationZero,
        isTotalOfAllocationsMax100(allocation)
            ? null
            : AllocationError.TotalOver100,
    ];

    return compact(errors);
};

export const getAllocationErrorText = (error: AllocationError) => {
    return match(error)
        .with(AllocationError.AllocationZero, () =>
            t(
                "asset.sourceDrawer.allocationZeroError",
                "it is not possible to allocate 0% to a property. Please adjust your values or deselect"
            )
        )
        .with(AllocationError.TotalOver100, () =>
            t(
                "asset.sourceDrawer.allocationTotalError",
                "it is not possible to allocate more than 100% to your properties. Please adjust your values"
            )
        )
        .exhaustive();
};

export const getAllocationPropertyTree = (
    propertyTree: AssetPropertyTree[],
    allocations: AssetIntegrationAllocationItemFragment[],
    assetId: string,
    assetName: string
) => {
    const properties = [
        {
            id: assetId,
            depth: -1,
            name: assetName,
            objectType: AllocationObjectType.ASSET,
        },
        ...flatPropertyTree(propertyTree),
    ] as PropertyAllocationItem[];

    const newAllocations = properties.map((property, index) => {
        const key = index === 0 ? "assetId" : "propertyId";
        const allocation = allocations.find(
            (allocation) => allocation[key] === property.id
        );

        return {
            ...property,
            allocation: allocation?.percentage,
            objectType: property.objectType,
        };
    });

    return newAllocations;
};

export const formatToMaxTwoDecimals = (value: string) => {
    if (isNaN(Number(value))) {
        throw new Error("Value is not a number");
    }

    let formattedValue = value;
    const decimalParts = formattedValue.split(".");
    if (decimalParts.length > 1) {
        const integerPart = decimalParts[0];
        const decimalPart = decimalParts[1].slice(0, 2); // Allow only two decimal places
        formattedValue = `${integerPart}.${decimalPart}`;
    }
    return formattedValue;
};
