import { gql } from "@apollo/client";

export const getAssessmentsOverviewCacheKey = (locationId: string) =>
    `Location:${locationId}`;

const OverviewAssessmentFragment = gql`
    fragment OverviewAssessment on Assessment {
        id
        from
        parentId
        unit
        emissionDataGrams {
            ownedEmission
        }
        consumptionData {
            ownedConsumption
        }
        integration {
            type
        }
        origin {
            assessmentType
        }
    }
`;

export const ASSESSMENT_OVERVIEW_QUERY = gql`
    query GetAllAssessments(
        $locationId: ID!
        $from: DateTime
        $to: DateTime
        $ignoreProxy: Boolean
        $verticals: [AssessmentVertical!]
        $aggregation: AssessmentAggregation
        $assessmentFilter: AssessmentQueryFilter
    ) {
        location(id: $locationId) {
            id
            saleDate
            purchaseDate
            assessmentGroups(
                aggregation: $aggregation
                verticals: $verticals
                from: $from
                to: $to
                ignoreProxy: $ignoreProxy
                filter: $assessmentFilter
            ) {
                vertical
                assessments {
                    ...OverviewAssessment
                }
            }
            projectedAssessments(
                aggregation: $aggregation
                filter: $assessmentFilter
            ) {
                from
                emissionDataGrams {
                    ownedEmission
                }
            }
        }
    }
    ${OverviewAssessmentFragment}
`;

const AssetAlertFragment = gql`
    fragment AssetAlert on assetAlerts {
        id
        to
        from
        type
        data {
            ... on EpcExpiresSoonAlertData {
                epcExpiryDates
            }
            ... on MissingMeterDataAlertData {
                meterIntegrations {
                    type
                    lastReading
                }
            }
        }
    }
`;

export const ASSET_ADDRESS_QUERY = gql`
    query GetAssetAddress($locationId: ID!) {
        location(id: $locationId) {
            id
            longAddress
            displayName
            ownedArea
            countryCode
            assetAlerts {
                ...AssetAlert
            }
        }
    }
    ${AssetAlertFragment}
`;
