import { Chip, Typography } from "@mui/material";
import { P, match } from "ts-pattern";

import { useFilterTranslations } from "components/AssetsGroup/hooks";
import {
    ActiveFilter,
    FilterRangeValue,
    FilterValue,
} from "components/AssetsGroup/types";
import { AssetGroupFilterCategory } from "graphql-types/graphql";
import { useRegionTranslation } from "hooks";

import { S } from "./filter-tag.styles";

type Props = {
    tag: ActiveFilter;
    onDelete?: (tag: ActiveFilter) => void;
};

export function FilterTag(props: Props) {
    const { tag, onDelete } = props;

    const { translations } = useFilterTranslations();
    const { getRegionName } = useRegionTranslation();

    const filterLabel = match(tag.value)
        .with({ key: P.string, value: P.string }, (filter: FilterValue) => {
            const { key, value } = filter;

            switch (tag.category) {
                case AssetGroupFilterCategory.COUNTRY:
                    return getRegionName(key);
                case AssetGroupFilterCategory.CRREMPROPERTYTYPES:
                case AssetGroupFilterCategory.GENERALPROPERTYTYPES:
                    return translations[key as keyof typeof translations];
                default:
                    return value;
            }
        })
        .with(
            { start: P.string, end: P.string },
            (value: FilterRangeValue) => `${value.start} - ${value.end}`
        )
        .otherwise(() => "");

    function onChipDelete() {
        onDelete && onDelete(tag);
    }

    const chipLabel = (
        <S.Label>
            <Typography>{translations[tag.category]}</Typography>
            <Typography className="value">{filterLabel}</Typography>
        </S.Label>
    );

    return (
        <Chip
            sx={{ borderRadius: 0, paddingY: "1.1rem" }}
            label={chipLabel}
            onDelete={onChipDelete}
        />
    );
}
