import { Box, styled } from "@mui/material";

import { convertToRem } from "utils/maths";

export const FormControls = styled(Box)(({ theme }) => ({
    marginTop: convertToRem(theme.spacing(8)),
}));

export const UploadContainer = styled(Box)(({ theme }) => ({
    marginTop: convertToRem(theme.spacing(4)),
    marginBottom: convertToRem(theme.spacing(8)),
}));

export const GridTableContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.common.white,
    marginTop: convertToRem(theme.spacing(4)),
    boxShadow: "0 8px 24px 0 rgba(133,133,133,0.1)",
}));

export const LoadingContainer = styled(Box)(({ theme }) => ({
    marginLeft: convertToRem(theme.spacing(4)),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // eslint-disable-next-line no-useless-computed-key
    ["& .MuiCircularProgress-root "]: {
        width: `${convertToRem(theme.spacing(4))} !important`,
        height: `${convertToRem(theme.spacing(4))} !important`,
    },
}));

export const SuccessBox = styled(Box)(({ theme }) => ({
    marginTop: convertToRem(theme.spacing(8)),
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: convertToRem(theme.spacing(8)),
    // eslint-disable-next-line no-useless-computed-key
    ["@media(max-width:1200px)"]: {
        marginTop: convertToRem(theme.spacing(18)),
    },
}));
