import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { PropsWithChildren } from "react";

export const MappingTableContainer = (props: PropsWithChildren) => (
    <Paper
        elevation={10}
        sx={{ border: "1px solid #e0e0e0", borderRadius: "10px" }}
    >
        <TableContainer
            sx={{ height: "min-content", minHeight: 400, maxHeight: "75vh" }}
        >
            <Table sx={{ tableLayout: "fixed" }}>
                <TableBody>{props.children}</TableBody>
            </Table>
        </TableContainer>
    </Paper>
);
