import { TFunction } from "i18next";
import _, { values } from "lodash";

import EpcClassificationsCell from "components/Cells/EpcClassificationsCell";
import { formatNumberLocale } from "utils/report.helpers";

export const getSumFormattedEPCAreaRow = (
    items: { area: number }[]
): number => {
    return items.reduce((acc, item) => acc + item.area, 0);
};

export const getScoreDistribution = (
    sources: { score: string[]; area: number }[],
    totalArea: number,
    t: TFunction
) => {
    const grades = sources
        .flatMap((source) => ({
            score: source.score,
            area: source.area,
        }))
        .filter((source) => source.score.length > 0);

    const activeAreaByScore = _.chain(grades)
        .sortBy((item) => item.score)
        .groupBy((item) => item.score)
        .map((items, score) => {
            const scoreArea = getSumFormattedEPCAreaRow(items);

            return [
                EpcClassificationsCell([score]),
                items.length.toString(),
                `${formatNumberLocale((scoreArea / totalArea) * 100, t, {
                    min: 2,
                    max: 2,
                })}%`,
            ];
        })
        .value();

    return values(activeAreaByScore);
};

export const dateSorter = (a: string, b: string) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;

    a = a.split("/").reverse().join("");
    b = b.split("/").reverse().join("");
    return a.localeCompare(b);
};
