import {
    CardContent,
    CardActions,
    styled,
    Typography,
    Box,
} from "@mui/material";

export const S = {
    Content: styled(CardContent)(({ theme }) => ({
        padding: theme.spacing(3, 6),
        display: "grid",
        gap: theme.spacing(4),
        gridTemplateRows: "220px min-content",
    })),

    Header: styled(Box)(({ theme }) => ({
        padding: theme.spacing(3, 6),
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
    })),

    Title: styled(Typography)(() => ({
        fontWeight: 700,
    })),

    Footer: styled(CardActions)(({ theme }) => ({
        padding: theme.spacing(1, 6, 6, 6),
    })),
};
