import { Clear } from "@mui/icons-material";
import {
    Box,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    IconButton,
} from "@mui/material";

type BaseModalProps = {
    title: string;
    children: React.ReactNode;
    handleClose: () => void;
} & DialogProps;

export const BaseModal = ({
    title,
    children,
    handleClose,
    ...dialogProps
}: BaseModalProps) => {
    return (
        <Dialog {...dialogProps} onClose={handleClose}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <DialogTitle variant="h3">{title}</DialogTitle>
                <IconButton
                    sx={{ height: "auto", mr: 2 }}
                    onClick={handleClose}
                >
                    <Clear />
                </IconButton>
            </Box>
            <Divider />
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};
