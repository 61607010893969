import { Typography } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import { ChartTooltip } from "glue/Chart/components/ChartTooltip";

export const ForecastChartTooltip = (props: ChartsAxisContentProps) => {
    const { t } = useTranslation();

    const { axisValue, dataIndex, series } = props;

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const header = axisValue?.toString() ?? "";
    const subHeaders = series
        .filter((serie) => serie.data[dataIndex])
        .map((serie: any) => ({
            label: serie.label,
            value: serie.valueFormatter(serie.data[dataIndex]),
        }));

    const StrandedHeader = (
        <Typography color="red" maxWidth={180}>
            {t(
                "forecastDashboard.labels.potentialStrandedAssetInYear",
                "Potential Stranded Asset in {{year}}",
                { year: header }
            )}
        </Typography>
    );

    const isStranded = series
        .filter((serie) => serie.data[dataIndex])
        .some((serie: any) => serie.showMark?.({ index: dataIndex }));

    return (
        <ChartTooltip
            sections={[
                { header, subHeaders },
                ...(isStranded ? [{ header: StrandedHeader }] : []),
            ]}
        />
    );
};
