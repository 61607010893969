import { Box, styled, useTheme } from "@mui/material";
import interpolate from "color-interpolate";

import { percentageCalc } from "utils/maths";

const BarContainer = styled(Box)(() => ({
    display: "flex",
    width: "100%",
    height: 8,
    gridGap: 2,
}));

const Bar = styled(Box)(({ theme }) => ({
    height: "100%",
    minWidth: "5%",
    borderRadius: theme.spacing(4),
    transition: "width 0.5s ease-in-out",

    display: "flex",
    gap: 1,
}));

const InnerBar = styled(Box)(({ theme }) => ({
    height: "100%",
    minWidth: "8%",

    "&:first-of-type": {
        borderTopLeftRadius: theme.spacing(4),
        borderBottomLeftRadius: theme.spacing(4),
    },
    "&:last-child": {
        borderTopRightRadius: theme.spacing(4),
        borderBottomRightRadius: theme.spacing(4),
    },
}));

type ColorBarGradient = { distribution: number; count: number }[];

type ColorBarItem = {
    value: number;
    color: string;
    gradient?: ColorBarGradient;
};

interface ColorBarProps {
    items: ColorBarItem[];
}

const InnerColorBar = ({
    color,
    gradient,
}: {
    color: string;
    gradient: ColorBarGradient;
}) => {
    const theme = useTheme();

    const colorTheme = {
        [theme.palette.success.main]: theme.palette.success,
        [theme.palette.warning.main]: theme.palette.warning,
        [theme.palette.error.main]: theme.palette.error,
    };

    const colorRange = {
        min: colorTheme[color].dark,
        max: colorTheme[color].light,
    };
    const palette = interpolate([colorRange.min, colorRange.max]);

    const total = gradient.reduce((acc, item) => acc + item.count, 0);

    return (
        <>
            {gradient.map(({ distribution, count }) => (
                <InnerBar
                    data-testid="inner-color-bar"
                    key={distribution}
                    sx={{
                        width: `${percentageCalc(count, total)}%`,
                        backgroundColor: palette(distribution / 100),
                    }}
                />
            ))}
        </>
    );
};

const ColorBar = ({ items }: ColorBarProps) => {
    const total = items.reduce((acc, item) => acc + item.value, 0);

    return (
        <BarContainer>
            {items.map(({ color, value, gradient }) =>
                value ? (
                    <Bar
                        data-testid="color-bar"
                        key={color}
                        sx={{
                            width: `${percentageCalc(value, total)}%`,
                            backgroundColor: gradient ? "transparent" : color,
                        }}
                    >
                        {gradient && (
                            <InnerColorBar color={color} gradient={gradient} />
                        )}
                    </Bar>
                ) : null
            )}
        </BarContainer>
    );
};

export default ColorBar;
