import { Download } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";

export const DownloadCell = ({
    downloadLink,
    isCentered = true,
    key,
}: {
    downloadLink: string;
    isCentered?: boolean;
    key?: string;
}) => (
    <Link
        href={downloadLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        margin={isCentered ? "auto" : "0"}
        key={key}
    >
        <IconButton>
            <Download color="primary" fontSize="medium" />
        </IconButton>
    </Link>
);
