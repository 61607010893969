import { gql } from "@apollo/client";

export const GET_ORGANIZATION_INTEGRATION = gql`
    query GetOrganizationIntegration(
        $integrationType: SupportedIntegrationForOnboarding!
    ) {
        getOrganizationIntegration(integrationType: $integrationType) {
            id
        }
    }
`;

export const GET_ENTOLABS_ORGANIZATIONS_QUERY = gql`
    query GetEntolabsOrganizations {
        entolabsOrganizations {
            id
            name
        }
    }
`;
