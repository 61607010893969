import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { ChartSeries } from "glue/Chart";
import { AssessmentDataType, TargetEmission } from "graphql-types/graphql";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import {
    emissionUnit,
    emissionLabel,
} from "../../PortfolioDashboard/helpers/chartHelpers";
import { getAssessmentTypeEmission } from "../../PortfolioDashboard/helpers/utilities";
import { AssessmentsSummaryUnion, Locations } from "../types";

const co2eYearLabel = "CO2e / year";

const getIsStranded = (
    targetEmission: AssessmentsSummaryUnion | undefined,
    type: AssessmentDataType
) => {
    if (!targetEmission) {
        return false;
    }
    const { emissionGrams } = targetEmission as TargetEmission;

    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return emissionGrams.epc.isStranded || false;
        case AssessmentDataType.COMBINED:
            return emissionGrams.bestEffort.isStranded || false;
    }
};

const transformedEmission = (
    type: AssessmentDataType,
    assessments: AssessmentsSummaryUnion[],
    t: TFunction
) => {
    const emission = _.sumBy(assessments, (a) =>
        "emissionGrams" in a
            ? getAssessmentTypeEmission(type, a.emissionGrams) || 0
            : getAssessmentTypeEmission(type, a.emissionDataGrams.emission) || 0
    );

    const value = getConvertedGramsToMassUnit(emission, emissionUnit);
    return {
        value,
        label: `${formatNumberLocale(
            value,
            t
        )} ${emissionLabel} ${co2eYearLabel}`,
    };
};

const getEmissions = (
    t: TFunction,
    type: AssessmentDataType,
    assessments: AssessmentsSummaryUnion[],
    hasTargetSettings: boolean
) => {
    const assessmentsByType = _.groupBy(assessments, (b) => b.__typename);

    const total = transformedEmission(
        type,
        assessmentsByType["AssessmentSummary"],
        t
    );
    const projected = transformedEmission(
        type,
        assessmentsByType["ProjectedAssessmentSummary"],
        t
    );

    const target = hasTargetSettings
        ? transformedEmission(type, assessmentsByType["TargetEmission"], t)
        : null;

    const firstTargetEmission = _.first(
        assessmentsByType["TargetEmission"]
    ) as TargetEmission;
    const isStranded = getIsStranded(firstTargetEmission, type);

    return {
        total,
        projected,
        target,
        isStranded,
    };
};

const formatEmission = (value: number, t: TFunction) =>
    `${formatNumberLocale(value, t)} ${emissionLabel} ${co2eYearLabel}`;

export const getTargeSettingSeries = (
    assets: Locations[],
    t: TFunction,
    type: AssessmentDataType,
    hasTargetSettings: boolean
) => {
    const showStranded = assets.length === 1;
    const emissions = _.chain(assets)
        .flatMap("allEmissions")
        .groupBy((a) => DateTime.fromISO(a.from, { zone: "local" }).year)
        .map((assessments, year) => {
            const { total, projected, target, isStranded } = getEmissions(
                t,
                type,
                assessments,
                hasTargetSettings
            );

            return {
                year,
                total: total.value,
                projected: projected.value
                    ? Math.abs(projected.value - total.value)
                    : null,
                target: target?.value || null,
                stranded: isStranded,
            };
        })
        .value();

    const totalData = emissions.map((a) => a.total);
    const projectedData = emissions.map((a) => a.projected);
    const targetData = emissions.map((a) => a.target);
    const strandedData = emissions.map((a) => a.stranded);

    const valueFormatter = (value: number) => formatEmission(value, t);

    const series = [
        {
            id: "emission",
            label: t(
                "forecastDashboard.labels.totalEmissions",
                "Total Emissions"
            ),
            stack: "total",
            type: "bar",
            valueFormatter,
            data: totalData,
        },
        {
            id: "projected",
            label: t(
                "forecastDashboard.labels.projectedEmissions",
                "Projected Emissions"
            ),
            stack: "total",
            type: "bar",
            valueFormatter,
            data: projectedData,
        },
        {
            id: "target",
            label: t(
                "forecastDashboard.labels.targetEmissions",
                "Target Emissions"
            ),
            type: "line",
            color: "green",
            valueFormatter,
            showMark: (params) => showStranded && strandedData[params.index],
            data: targetData,
        },
    ] as ChartSeries[];

    const xAxis = [
        {
            scaleType: "band",
            data: emissions.map((a) => a.year),
        },
    ] as any[];

    return { series, xAxis };
};
