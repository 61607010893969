import { Box, styled, Typography } from "@mui/material";

export const S = {
    Content: styled(Box)({
        gap: 2,
        display: "flex",
        flexDirection: "row",
    }),

    Typography: styled(Typography)(({ theme }) => ({
        ...theme.typography.h4,
        color: theme.palette["grey"][400],
    })),
};
