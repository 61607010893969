import { Box, Divider, useTheme } from "@mui/material";
import { ChartsAxisContentProps } from "@mui/x-charts";
import { useTranslation } from "react-i18next";

import {
    BENCHMARK_LABEL,
    formatSummedEmission,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { ChartTooltip } from "glue/Chart/components/ChartTooltip";

import {
    AboveBenchmarkArrow,
    BelowBenchmarkArrow,
    HintText,
} from "../performanceChart.style";

export const BenchmarkPerformanceChartTooltip = (
    props: ChartsAxisContentProps
) => {
    const { axis, axisValue, dataIndex, series } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    if (dataIndex === null || dataIndex === undefined) {
        return null;
    }

    const header =
        axis.valueFormatter && axisValue
            ? axis.valueFormatter(axisValue, {
                  location: "tooltip",
              })
            : axisValue?.toString();

    const aboveBenchmark = parseInt(axisValue as string) > 100;
    const isBenchmark = parseInt(axisValue as string) === 100;

    return (
        <ChartTooltip
            sections={[
                {
                    header: (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: !isBenchmark
                                    ? aboveBenchmark
                                        ? theme.palette.error.main
                                        : theme.palette.success.main
                                    : undefined,
                            }}
                        >
                            {!isBenchmark ? (
                                aboveBenchmark ? (
                                    <AboveBenchmarkArrow />
                                ) : (
                                    <BelowBenchmarkArrow />
                                )
                            ) : undefined}
                            <span>
                                {isBenchmark ? BENCHMARK_LABEL : header}
                            </span>
                        </div>
                    ),
                    items: [
                        {
                            label: t(
                                "portfolioDashboard.hoverBox.averageEmissionPerArea",
                                "Average Emission"
                            ),
                            value: formatSummedEmission(
                                (series[0] as any).emission[dataIndex],
                                t
                            ),
                        },
                        {
                            label: t("common.portfolio", "Portfolio"),
                            value: t(
                                "portfolioDashboard.chartHelpers.keyWithCount",
                                "{{count}} Assets",
                                {
                                    count: series[0].data[dataIndex] as number,
                                }
                            ),
                        },
                    ],
                },
            ]}
        >
            <Box>
                <Divider sx={{ my: 4 }} />
                <HintText>
                    {t(
                        "portfolioDashboard.hoverBox.clickAssets",
                        "Click to view assets"
                    )}
                </HintText>
            </Box>
        </ChartTooltip>
    );
};
