import { SquareRounded } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, IconButton, Link, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BenchmarkMarker, LegacyBenchmarkMarker } from "components/types";
import { BenchmarkType, LegacyBenchmark } from "graphql-types/graphql";
import { formatDate } from "utils/date.utils";
import { formatNumberLocale } from "utils/report.helpers";

import {
    Title,
    ItemDescription,
    Item,
    ItemContent,
} from "./benchmark-list-toggle.styles";

const sourceLinks: Record<string, string> = {
    GB: "https://www.crrem.org/pathways/",
    DK: "https://www.build.aau.dk/til-byggebranchen",
};

type Props = {
    benchmarkMarker: BenchmarkMarker | LegacyBenchmarkMarker;
    onToggleMarkerVisibility: (
        benchmark: BenchmarkMarker | LegacyBenchmarkMarker
    ) => void;
};

export function BenchmarkItemToggle(props: Props) {
    const { t } = useTranslation();
    const { benchmarkMarker, onToggleMarkerVisibility } = props;

    const [toggleDescription, setToggleDescription] = useState(false);

    function onMarkerVisibilityChange(
        benchmarkMarker: BenchmarkMarker | LegacyBenchmarkMarker,
        isVisible: boolean
    ) {
        onToggleMarkerVisibility({
            ...benchmarkMarker,
            isVisible,
        });
    }

    const { countryCode } = benchmarkMarker;

    const isLegacyBenchmark =
        benchmarkMarker.benchmarkType !== BenchmarkType.MARKET &&
        benchmarkMarker.benchmarkType !== BenchmarkType.BASELINE;

    const editLink = benchmarkMarker.benchmarkType ===
        BenchmarkType.BASELINE && (
        <Link href="/settings">Edit Baseline year</Link>
    );

    const sourceLinkHref =
        benchmarkMarker.benchmarkType === BenchmarkType.MARKET && countryCode
            ? sourceLinks[countryCode]
            : null;

    const sourceLink = sourceLinkHref ? (
        <Link href={sourceLinkHref} target="_blank">
            Source
        </Link>
    ) : null;

    const updatedAt =
        "updatedAt" in benchmarkMarker ? (
            <Box display="flex" gap={2}>
                <Typography fontWeight="bold">
                    {t("common.lastUpdated", "last updated")}:
                </Typography>
                <Typography variant="caption">
                    {formatDate(benchmarkMarker.updatedAt)}
                </Typography>
            </Box>
        ) : null;

    const numberOfBuildings =
        "numberOfBuildings" in benchmarkMarker ? (
            <Box display="flex" gap={2}>
                <Typography fontWeight="bold">
                    {t("benchmark.sampleSetSize", "sample set size")}:
                </Typography>
                <Typography variant="caption">
                    {`${formatNumberLocale(
                        benchmarkMarker.numberOfBuildings,
                        t,
                        {
                            max: 0,
                        }
                    )} ${t("common.buildings", "buildings").toLowerCase()}`}
                </Typography>
            </Box>
        ) : null;

    function onToggleDescription() {
        setToggleDescription(!toggleDescription);
    }

    function onToggleBenchmarkVisibility(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        onMarkerVisibilityChange(benchmarkMarker, event.target.checked);
    }

    const toggleDescriptionIcon = toggleDescription ? (
        <RemoveIcon color="primary" fontSize="medium" />
    ) : (
        <AddIcon color="primary" fontSize="medium" />
    );

    const title = isLegacyBenchmark
        ? benchmarkMarker.subTitle
        : benchmarkMarker.title;

    const subTitle = isLegacyBenchmark
        ? benchmarkMarker.title
        : benchmarkMarker.subTitle;

    return (
        <Item>
            <ItemContent>
                <Box
                    sx={{
                        gap: 2,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Switch
                        size="small"
                        checked={benchmarkMarker.isVisible}
                        onChange={onToggleBenchmarkVisibility}
                    />
                    <Box>
                        <Box sx={{ display: "flex" }}>
                            <Title>{title}</Title>
                            <SquareRounded
                                htmlColor={benchmarkMarker.color}
                                fontSize="small"
                            />
                        </Box>
                        {isLegacyBenchmark && (
                            <Typography variant="h5" fontWeight={600}>
                                {
                                    (benchmarkMarker as LegacyBenchmark)
                                        .segmentLabel
                                }
                            </Typography>
                        )}
                        <Typography variant="h5">{subTitle}</Typography>
                    </Box>
                </Box>
                <IconButton onClick={onToggleDescription}>
                    {toggleDescriptionIcon}
                </IconButton>
            </ItemContent>
            {toggleDescription && (
                <Box px={2}>
                    <ItemDescription>
                        {benchmarkMarker.description}
                    </ItemDescription>
                    {numberOfBuildings || updatedAt ? (
                        <Box
                            marginTop={5}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                        >
                            {numberOfBuildings}
                            {updatedAt}
                        </Box>
                    ) : null}
                    {sourceLink && <Box sx={{ mt: "1rem" }}>{sourceLink}</Box>}
                    {editLink && <Box sx={{ mt: "1rem" }}>{editLink}</Box>}
                </Box>
            )}
        </Item>
    );
}
