import { gql } from "@apollo/client";

export const UPDATE_MASTER_DATA = gql`
    mutation UpdateAssetMasterData($input: UpdateOneLocationInput!) {
        updateOneLocation(input: $input) {
            name
            externalId
            buildingArea
            ownership
            propertyType
            purchaseDate
            saleDate
            legalOwner
            management
        }
    }
`;
