import _ from "lodash";
import { DateTime } from "luxon";

import { AssetListLocationFragment } from "graphql-types/graphql";

export const getUniqEpcDataFromAssessmentSummariesInSelectedYear = (
    assessments: AssetListLocationFragment["assessmentSummaries"],
    selectedYear: number
) => {
    const epcData = _.chain(assessments)
        .filter(
            (assessment) =>
                DateTime.fromISO(assessment.from).year === selectedYear
        )
        .flatMap((assessment) => assessment.epcData)
        .compact()
        .value();

    return _.uniqWith(epcData, _.isEqual);
};

export const getClassificationsFromAssessmentSummariesInSelectedYear = (
    assessments: AssetListLocationFragment["assessmentSummaries"],
    selectedYear: number
) => {
    const uniqEpcData = getUniqEpcDataFromAssessmentSummariesInSelectedYear(
        assessments,
        selectedYear
    );

    return _.chain(uniqEpcData)
        .map((epcData) => epcData.classification)
        .sort()
        .value();
};
