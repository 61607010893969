import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";

import { IntensityEmissionByMonth } from "./monthlyIntensityChart.types";
import { AssetEmissionChartTooltip } from "../AssetEmissionChartTooltip";
import { getMonthlyChartData } from "../helpers/monthlyChart.helpers";
import { EmissionSummaryLocation } from "../types/monthlyChart.types";
import {
    getAssetEmissionSummariesEmissionByMonth,
    getIntensityEmissionByMonth,
} from "../utils/portfolioIntensityByMonthChart.utils";

type Props = {
    emissionSummaries?: EmissionSummaryLocation[];
};

export const MonthlyIntensityChart = (props: Props) => {
    const { emissionSummaries = [] } = props;

    const { t } = useTranslation();
    const { activeYear } = useSelectedYearContext();

    const assetEmissionsByMonth = useMemo(() => {
        return _.chain(emissionSummaries)
            .flatMap((asset) => getAssetEmissionSummariesEmissionByMonth(asset))
            .compact()
            .value();
    }, [emissionSummaries]);

    const intensityEmissionsByMonth = useMemo(() => {
        return getIntensityEmissionByMonth(assetEmissionsByMonth);
    }, [assetEmissionsByMonth]);

    const { series, xAxis, yAxis } = useMemo(() => {
        return getMonthlyChartData<IntensityEmissionByMonth>(
            intensityEmissionsByMonth,
            "emissionIntensity",
            t("portfolioDashboard.boxes.intensity", "Intensity"),
            activeYear,
            t
        );
    }, [intensityEmissionsByMonth, activeYear, t]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            customTooltip={AssetEmissionChartTooltip}
        />
    );
};
