import { Help } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useCallback } from "react";

import Popover from "components/Popovers/Popover";

const InfoPopover = ({ element }: { element: React.ReactNode }) => {
    const HelpIcon = useCallback(
        ({ isActive }: { isActive?: boolean }) => (
            <Help fontSize="small" color={isActive ? "info" : "inherit"} />
        ),
        []
    );

    return (
        <Popover
            mouseOver={false}
            parent={HelpIcon}
            parentBoxProps={{
                sx: {
                    display: "flex",
                    alignItems: "center",
                },
            }}
        >
            <Typography fontSize={12}>{element}</Typography>
        </Popover>
    );
};

export default InfoPopover;
