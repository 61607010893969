import { gql } from "@apollo/client";

export const ASSET_EPC_DATA_FRAGMENT = gql`
    fragment AssetEPCData on Location {
        id
        saleDate
        assessments(ignoreProxy: true, filter: { dataType: ESTIMATE }) {
            id
            to
            from
            vertical
            unit
            areaData {
                totalArea
            }
            consumptionData {
                totalConsumption
            }
            assessmentMetadata {
                addresses
                nationalBuildingIds
            }
            emissionDataGrams {
                ownedEmission
            }
            origin {
                id
            }
            integration {
                id
                type
            }
            data {
                ... on IntegrationEpcAssessmentData {
                    epcIdentifier
                    classification
                    pdfLink
                    originalPdfLink
                }
                ... on IntegrationEpcUkAssessmentData {
                    lmkKey
                    classification
                    assessmentLink
                }
            }
        }
    }
`;

export const DATA_OVERVIEW_EPC_QUERY = gql`
    query GetDataOverviewEPCAssessments($assetGroupId: ID) {
        getAssets(assetGroupId: $assetGroupId) {
            ...AssetEPCData
        }
    }
    ${ASSET_EPC_DATA_FRAGMENT}
`;

export const ASSET_EPC_DATA = gql`
    query GetAssetEPCData($assetId: ID!) {
        location(id: $assetId) {
            ...AssetEPCData
        }
    }
    ${ASSET_EPC_DATA_FRAGMENT}
`;

export const GET_ASSET_INTEGRATIONS = gql`
    query GetAssetIntegrations($assetGroupId: ID) {
        getAssets(assetGroupId: $assetGroupId) {
            displayName
            integrations(filter: { type: { notIn: [EPC, EPC_UK] } }) {
                id
                to
                from
                data {
                    ... on IntegrationDexmaLocationIntegrationData {
                        id
                        type
                    }
                }
                type
                originId
                consumptionTypes
            }
        }
    }
`;
