import { Box, Link, Popover, styled } from "@mui/material";
import React, { useCallback, useState } from "react";

import { convertToRem } from "utils/maths";

type Props = {
    text?: string;
    boldText?: string;
    element?: any;
    linkText?: string;
};
const StyledPopover = styled(Box)(({ theme }) => ({
    maxWidth: theme.spacing(105),
    borderRadius: theme.spacing(4),
    boxShadow: "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
    padding: theme.spacing(5, 6.25, 5, 6.25),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    fontSize: convertToRem(14),
}));

const DisclaimerPopover = (props: Props) => {
    const { element, linkText } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <div>
            <Link href={"#"} aria-describedby={id} onClick={handleClick}>
                {linkText}
            </Link>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                sx={{
                    "& .MuiPopover-paper": {
                        backgroundColor: "transparent",
                    },
                }}
            >
                <StyledPopover>{element}</StyledPopover>
            </Popover>
        </div>
    );
};

export default DisclaimerPopover;
