import { FormControl, FormLabel } from "@mui/material";
import { ReactElement } from "react";
import {
    Control,
    Controller,
    ControllerRenderProps,
    FieldValues,
    Path,
    RegisterOptions,
} from "react-hook-form";

type RenderProps<T extends FieldValues> = {
    field: ControllerRenderProps<T, Path<T>>;
    fieldState: {
        invalid: boolean;
        isTouched: boolean;
        isDirty: boolean;
        error?: any;
    };
    formState: any;
};

type Props<T extends FieldValues> = {
    label?: string;
    name: Path<T>;
    control: Control<T, any>;
    render: (props: RenderProps<T>) => ReactElement;
    rules?: Omit<
        RegisterOptions<T, Path<T>>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
};

export const FormField = <T extends FieldValues>(props: Props<T>) => {
    const { label, control, name, render, rules } = props;
    return (
        <FormControl fullWidth>
            {label && <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>}
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={render}
            />
        </FormControl>
    );
};
