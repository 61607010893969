import { useQuery } from "@apollo/client";

import { FilterItemOption, FilterValue } from "components/AssetsGroup/types";
import {
    AssetGroupFilterCategory,
    GetAssetGroupNationalPropertyTypeQuery,
} from "graphql-types/graphql";

import { NATIONAL_PROPERTY_TYPES_QUERY } from "./national-property-type.query";
import { FilterOptionSelect } from "../../OptionLayout";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionNationalPropertyType(props: Props) {
    const { onChange } = props;

    const { data, loading } = useQuery<GetAssetGroupNationalPropertyTypeQuery>(
        NATIONAL_PROPERTY_TYPES_QUERY
    );

    const nationalPropertyTypesList =
        data?.me.organization?.nationalPropertyTypes?.map(
            ({ label, value }) => ({
                key: value,
                value: `${label} (${value})`,
            })
        ) || [];

    function onSelectedValue(value: FilterValue) {
        onChange({
            category: AssetGroupFilterCategory.NATIONALPROPERTYTYPES,
            values: [
                {
                    key: value.key,
                    value: value.key,
                },
            ],
        });
    }

    return (
        <FilterOptionSelect
            loading={loading}
            list={nationalPropertyTypesList}
            onSelectedValue={onSelectedValue}
        />
    );
}
