import {
    ElectralinkLocation,
    GetElectralinkMeterLocationsQuery,
} from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils/strings.helpers";

type ElectralinkLocationIntegration =
    GetElectralinkMeterLocationsQuery["locationsForElectralinkIntegration"]["initialLocationIntegrationsState"][0];

export const formatElectralinkLocation = (
    { customerLocation, meterLocations }: ElectralinkLocationIntegration,
    electralinkMeterLocations: ElectralinkLocation[]
) => {
    const integrations = electralinkMeterLocations
        .filter(
            ({ postCode }) =>
                (customerLocation.zipCode &&
                    postCode ===
                        customerLocation.zipCode?.replace(/\s/g, "")) ||
                customerLocation.buildings?.some(
                    (building) =>
                        postCode === building.zipCode?.replace(/\s/g, "")
                )
        )
        .map(({ address, hashedMPAN, partialMPAN, postCode }) => {
            const isSimilarToExistingLocation = meterLocations.some(
                (meterLocation) =>
                    meterLocation.postCode === postCode &&
                    meterLocation.partialMPAN === partialMPAN &&
                    meterLocation.address === address
            );

            return {
                value: hashedMPAN,
                label:
                    (isSimilarToExistingLocation ? "(*) " : "") +
                    `${address} (${postCode}) - ${partialMPAN}`,
                sortLabel: address.toLowerCase(),
                hashedMPAN,
                address,
                postCode,
                partialMPAN,
            };
        })
        .sort((a, b) => a.sortLabel.localeCompare(b.sortLabel));

    const displayName =
        customerLocation.displayName ??
        customerLocation.shortAddress ??
        customerLocation.longAddress ??
        customerLocation.buildings[0]?.name ??
        DASH_SEPARATOR;

    return {
        id: customerLocation.id,
        displayName,
        integrations,
    };
};
