import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { AssessmentDataType, ReportFramework } from "graphql-types/graphql";

import { DateContext, DateContextType } from "./reportDateContext";
import { AssetsCheckboxValue } from "../AssetList/AssetsCheckbox";

export type AssetDisplayType = {
    filteredAssets: number;
    unfilteredAssets: number;
};

export function getTranslatedReportTitle(t: TFunction, reportName: string) {
    switch (reportName) {
        case "GHG":
            return t("reportsList.ghg.title", "GHG Protocol", { ns: "report" });
        case "ESG":
            return t("reportsList.esg.title", "ESG Report", { ns: "report" });
        case "CRREM":
            return t("reportsList.crrem.title", "CRREM Report", {
                ns: "report",
            });
        default:
            return "";
    }
}

export function getTranslatedReportDescription(
    t: TFunction,
    reportName: ReportFramework
) {
    switch (reportName) {
        case "GHG":
            return t(
                "reportsList.ghg.description",
                "Greenhouse Gas Protocol (GHG Protocol) is a global accounting standard to measure and manage greenhouse gases.",
                { ns: "report" }
            );
        case "ESG":
            return t(
                "reportsList.esg.description",
                "ESG reporting refers to the disclosure of data covering the company’s operations in three areas: Environmental, Social, and corporate Governance. Legacy only handles data covering the Environmental part.",
                { ns: "report" }
            );
        case "CRREM":
            return t(
                "reportsList.crrem.description",
                "Input report for The Carbon Risk Real Estate Monitor (CRREM) risk assessment tool. The CRREM risk assessment tool enables owners and investors to understand the CO2 risks associated within their portfolio and align with decarbonization pathways like the Paris Agreement Climate Goals.",
                { ns: "report" }
            );
        default:
            return "";
    }
}

export enum ReportTab {
    SUMMARY = 0,
    INVENTORY = 1,
    EMISSIONFACTORS = 2,
}

export const getReportPageTitle = (
    t: TFunction,
    type: AssessmentDataType,
    reportType: ReportFramework
) => {
    const frameworkName = getTranslatedFrameworkName(t, reportType);
    const assessmentType = getTranslatedAssessmentType(t, type);
    return `${frameworkName} ${assessmentType}`;
};

export function getTranslatedFrameworkName(
    t: TFunction,
    reportType: ReportFramework | "AssetsList"
) {
    switch (reportType) {
        case ReportFramework.ESG:
            return t("reportsList.esg.title", "ESG Report", { ns: "report" });
        case ReportFramework.GHG:
            return t("reportsList.ghg.title", "GHG Protocol", { ns: "report" });
        case ReportFramework.CRREM:
            return t("reportsList.crrem.title", "CRREM Report", {
                ns: "report",
            });
        case "AssetsList":
            return t("assetList.title", "Asset List");
    }
}

export function getTranslatedAssessmentType(
    t: TFunction,
    type: AssessmentDataType | AssetsCheckboxValue[]
) {
    const getString = (type: AssessmentDataType | AssetsCheckboxValue) => {
        switch (type) {
            case AssessmentDataType.ACTUAL:
            case AssetsCheckboxValue.METER:
                return t("reportsList.meter.data", "Meter Data", {
                    ns: "report",
                });
            case AssessmentDataType.COMBINED:
                return t(
                    "reportsList.combined.data",
                    "Combined Data Sources incl. secondary data",
                    { ns: "report" }
                );
            case AssessmentDataType.ESTIMATE:
            case AssetsCheckboxValue.EPC:
                return t("reportsList.epc.data", "EPC Data", { ns: "report" });
            case AssetsCheckboxValue.MISSING:
                return t("reportsList.missing.data", "Missing Data", {
                    ns: "report",
                });
        }
    };

    const typeString = [];

    if (Array.isArray(type)) {
        type.forEach((t) => typeString.push(getString(t)));
    } else {
        typeString.push(getString(type));
    }

    const lf = new Intl.ListFormat();

    return `(${lf.format(typeString)})`;
}

export function getTranslatedTabName(t: TFunction, reportTab: ReportTab) {
    switch (reportTab) {
        case ReportTab.SUMMARY:
            return t("tabs.overview", "Overview", { ns: "report" });
        case ReportTab.INVENTORY:
            return t("tabs.emissionSummary", "Emissions Summary", {
                ns: "report",
            });
        case ReportTab.EMISSIONFACTORS:
            return t("tabs.emissionFactors", "Emission Factors", {
                ns: "report",
            });
    }
}

export const getReportDateInterval = ({
    dateContextType,
    from,
    to,
}: DateContext): { from: DateTime; to?: DateTime } => ({
    from: DateTime.fromISO(from, { zone: "utc" }),
    to:
        dateContextType === DateContextType.DATE_RANGE
            ? DateTime.fromISO(to, { zone: "utc" })
            : undefined,
});

export const getSourceText = (source: AssessmentDataType, t: TFunction) => {
    switch (source) {
        case AssessmentDataType.ESTIMATE:
            return t("portfolioDashboard.tabs.epcAssets", "EPC", {
                ns: "translation",
            });
        case AssessmentDataType.ACTUAL:
            return t("portfolioDashboard.tabs.meterAssets", "Meter", {
                ns: "translation",
            });
        case AssessmentDataType.COMBINED:
            return t(
                "portfolioDashboard.tabs.allAssets",
                "Combined Data Sources",
                { ns: "translation" }
            );
    }
};
