import { useTranslation } from "react-i18next";

import {
    ActiveFilter,
    FilterItemOptions,
    FilterRangeValue,
    FilterValue,
} from "components/AssetsGroup/types";

import { S } from "./filter-overview.styles";
import { FilterTag } from "../FilterTag";

type Props = {
    filters: FilterItemOptions | null;
    onDelete: (tag: ActiveFilter) => void;
};

export function FilterOverview(props: Props) {
    const { onDelete, filters } = props;

    const { t } = useTranslation();

    if (!filters) {
        return null;
    }

    const rows = filters.map((filter) => {
        const category = filter.category;
        const filterValues = filter.values;

        if (!filterValues) {
            return null;
        }

        const row = filterValues.map(
            (filterValue: FilterValue | FilterRangeValue, index) => {
                const hasDelimiter = index < filterValues.length - 1;

                const tag = {
                    category,
                    value: filterValue,
                };

                return (
                    <S.RowBody
                        key={`filter-item-${JSON.stringify(filterValue)}`}
                    >
                        <FilterTag tag={tag} onDelete={onDelete} />
                        {hasDelimiter && (
                            <S.RowDelimiter>
                                <S.DelimiterLabel>
                                    {t("common.or", "OR")}
                                </S.DelimiterLabel>
                            </S.RowDelimiter>
                        )}
                    </S.RowBody>
                );
            }
        );

        return <S.Row key={`category-${category}`}>{row}</S.Row>;
    });

    const sections = rows.map((row, index) => {
        const hasDelimiter = index < rows.length - 1;
        return (
            <S.Section key={`${row?.key}-row`}>
                {row}
                {hasDelimiter && (
                    <S.Delimiter>
                        <S.DelimiterLabel>
                            {t("common.and", "AND")}
                        </S.DelimiterLabel>
                    </S.Delimiter>
                )}
            </S.Section>
        );
    });

    return <S.SectionList>{sections}</S.SectionList>;
}
