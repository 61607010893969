import { Box, styled, Typography } from "@mui/material";

import { Tabs } from "glue";

export const S = {
    Tabs: styled(Tabs)(({ theme }) => ({
        padding: theme.spacing(0, 8),
        marginTop: theme.spacing(-4),
        backgroundColor: theme.palette.grey[100],
        borderBottomColor: theme.palette.grey[300],
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
    })),
    Panel: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(6),
        padding: theme.spacing(4, 8),
    })),
    Container: styled(Box)({
        gap: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }),
    Typography: styled(Typography, {
        shouldForwardProp: (prop) => prop !== "loading",
    })<{ loading: boolean }>(({ loading }) => ({
        color: loading ? "transparent" : "initial",
        backgroundColor: loading ? "#f0f0f0" : "initial",
        textAlign: "right",
        borderRadius: "4px",
        width: "50%",
        animation: loading ? "pulse 1s infinite" : "none",
    })),
};
