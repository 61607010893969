import { useTheme } from "@mui/material";

import ColorBar from "../../components/ColorBar/ColorBar";

const ColorBarMock = () => {
    const { palette } = useTheme();

    const values = [
        { distribution: 50, count: 10 },
        { distribution: 75, count: 10 },
        { distribution: 100, count: 10 },
    ];

    const values2 = [
        { distribution: 0, count: 50 },
        { distribution: 25, count: 20 },
        { distribution: 50, count: 20 },
        { distribution: 75, count: 20 },
        { distribution: 100, count: 30 },
    ];

    return (
        <>
            <ColorBar
                items={[
                    {
                        value: 80,
                        gradient: values,
                        color: palette.success.main,
                    },
                    { value: 10, color: palette.error.main },
                    { value: 10, color: palette.warning.main },
                ]}
            />
            <ColorBar
                items={[
                    {
                        value: 50,
                        gradient: values,
                        color: palette.success.main,
                    },
                    {
                        value: 50,
                        gradient: values2,
                        color: palette.warning.main,
                    },
                ]}
            />
            <ColorBar
                items={[
                    {
                        value: 80,
                        gradient: values2,
                        color: palette.success.main,
                    },
                    { value: 30, color: palette.warning.main },
                    { value: 20, gradient: values2, color: palette.error.main },
                ]}
            />
        </>
    );
};

export default ColorBarMock;
