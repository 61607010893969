import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";

import { UnitMass } from "components/AssessmentStats/types";
import {
    getBuildingAverageEmissionPerArea,
    getProjectedBuildingAverageEmissionPerArea,
} from "components/PortfolioDashboard/helpers/chartHelpers";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { xAxisBandConfig } from "glue/Chart/chart.types";
import { AssessmentDataType } from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";
import { PortfolioChartSeriesType } from "../types/chart.types";

export const getYearlyBuildingAverageChartData = (
    t: TFunction,
    activeDataType: AssessmentDataType,
    assessmentSummariesByYear: SummaryYearOverview[]
): {
    series: PortfolioChartSeriesType[];
    xAxis: xAxisBandConfig[];
    years: number[];
} => {
    const xAxisData = assessmentSummariesByYear.map(({ year }) => year);

    const seriesData = assessmentSummariesByYear.map((summedYear) => {
        const buildingAveragePerArea = getBuildingAverageEmissionPerArea(
            summedYear.locations,
            activeDataType
        );

        return buildingAveragePerArea
            ? getConvertedGramsToMassUnit(
                  buildingAveragePerArea,
                  UnitMass.KILOGRAM
              )
            : null;
    });

    const projectedSeriesData = assessmentSummariesByYear.map((summedYear) => {
        const isCurrentYear = getCurrentYear() === summedYear.year;
        const projectedBuildinAveragePerArea = isCurrentYear
            ? getProjectedBuildingAverageEmissionPerArea(
                  summedYear,
                  activeDataType
              )
            : null;
        const buildingAveragePerArea = getBuildingAverageEmissionPerArea(
            summedYear.locations,
            activeDataType
        );

        const calculatedProjectedEmissions =
            projectedBuildinAveragePerArea &&
            buildingAveragePerArea &&
            projectedBuildinAveragePerArea - buildingAveragePerArea;

        if (!calculatedProjectedEmissions || calculatedProjectedEmissions < 0) {
            return null;
        }

        return getConvertedGramsToMassUnit(
            calculatedProjectedEmissions,
            UnitMass.KILOGRAM
        );
    });

    const assetCount = assessmentSummariesByYear.map(
        ({ assetCount }) => assetCount
    );
    const totalArea = assessmentSummariesByYear.map(
        ({ totalArea }) => totalArea
    );

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.KILOGRAM, t) || "";

    return {
        series: [
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.average", "Average"),
                stack: "total",
                id: "emission",
                data: seriesData,
                assetCount,
                totalArea,
                valueFormatter,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.hoverBox.projected", "Projected"),
                stack: "total",
                id: "projected",
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
};
