import { Square, SquareOutlined } from "@mui/icons-material";
import { useTheme, Popover as MUIPopover, Theme, Box } from "@mui/material";
import { useState, PropsWithChildren, isValidElement } from "react";

import { AssessmentVertical } from "graphql-types/graphql";
import { useVerticalColors, useVerticalTranslations } from "hooks";

import { Popover } from "./VerticalPopover";
import { S } from "./verticals-coverage.styles";

type VerticalProps = {
    active?: boolean;
    type: AssessmentVertical;
};

export const Vertical = (props: PropsWithChildren<VerticalProps>) => {
    const { children, active = false, type } = props;

    const { palette } = useTheme();
    const { verticalColors } = useVerticalColors();
    const { translations } = useVerticalTranslations();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const onPopoverClose = () => {
        setAnchorEl(null);
    };

    const onPopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const Shape = active ? Square : SquareOutlined;
    const color = palette[verticalColors[type] || "primary"].main;

    const listChildren = Array.isArray(children) ? children : [children];

    const popover = listChildren.find((child) => {
        return isValidElement(child) && child.type === Popover;
    });

    const verticalTitle = translations[type];

    return (
        <Box onMouseEnter={onPopoverOpen} onMouseLeave={onPopoverClose}>
            <Shape sx={{ color, borderRadius: 4 }} />
            {popover && (
                <MUIPopover
                    sx={{ pointerEvents: "none", maxWidth: "none" }}
                    anchorOrigin={
                        popover.props.anchorOrigin ?? {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }
                    transformOrigin={
                        popover.props.transformOrigin ?? {
                            vertical: "top",
                            horizontal: "left",
                        }
                    }
                    slotProps={{
                        paper: {
                            sx: (theme: Theme) => ({
                                maxWidth: theme.spacing(50),
                                border: `1px solid ${theme.palette.grey[300]}`,
                                borderRadius: theme.spacing(1),
                                boxShadow:
                                    "0 8px 24px 0 rgba(133, 133, 133, 0.1)",
                                padding: theme.spacing(2, 3),
                            }),
                        },
                    }}
                    open={Boolean(anchorEl)}
                    disableRestoreFocus
                    disableScrollLock
                    anchorEl={anchorEl}
                    children={
                        <S.PopoverContent>
                            <S.PopoverTitle sx={{ color }}>
                                {verticalTitle}
                            </S.PopoverTitle>
                            {popover.props.children}
                        </S.PopoverContent>
                    }
                />
            )}
        </Box>
    );
};
