import { gql } from "@apollo/client";

export const NATIONAL_PROPERTY_TYPES_QUERY = gql`
    query GetAssetGroupNationalPropertyType {
        me {
            id
            organization {
                nationalPropertyTypes {
                    label
                    value
                }
            }
        }
    }
`;
