import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import { useDownloadContext } from "components/PortfolioDashboard/helpers/useDownloadContext";

import S from "./benchmark-download.style";

export const BenchmarkDownload = () => {
    const { t } = useTranslation();
    const [activeAssetGroup] = useActiveAssetGroup();

    const { onDownload } = useDownloadContext();

    const onClick = () => {
        onDownload(`${activeAssetGroup.name}-benchmarks.csv`, "benchmark");
    };

    return (
        <S.Container>
            <Typography>
                {t(
                    "benchmark.downloadDescription",
                    "Data table of all benchmarks"
                )}
            </Typography>
            <Button variant="contained" onClick={onClick}>
                {t("common.download", "Download")}
            </Button>
        </S.Container>
    );
};
