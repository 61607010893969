import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography, Tab } from "@mui/material";
import React from "react";

import AssetAlertCell from "components/AssetList/AssetAlertCell";
import { AssetAlertFragment } from "graphql-types/graphql";

import { S } from "./pageHeader.styles";

type HeaderProps = {
    onBack: () => void;
    onChange: (event: React.ChangeEvent<object>, newValue: number) => void;
    title: string;
    description?: string;
    tabs: { labels: string[]; value: number };
    assetAlerts: AssetAlertFragment[];
};

export const PageHeader = (props: HeaderProps) => {
    const { onBack, onChange, title, description, tabs, assetAlerts } = props;

    return (
        <S.Container>
            <S.IconButton onClick={onBack}>
                <ClearIcon color="primary" />
            </S.IconButton>
            <Box>
                <Typography variant="h5" color="grey.400">
                    {title}
                </Typography>
                <Typography variant="h4">{description}</Typography>
            </Box>
            <S.Tabs value={tabs.value} onChange={onChange}>
                {tabs.labels.map((val, i) => (
                    <Tab key={i} label={val} color="black" />
                ))}
            </S.Tabs>
            <AssetAlertCell assetAlerts={assetAlerts} />
        </S.Container>
    );
};
